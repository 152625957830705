<template>
  <div class="body">
    <!-- <div style="position: absolute; z-index: -1">
      <BaibanReport
        @getImgUrl="getImgUrl"
        :userName="userInfo.name"
        ref="imgUrl"
      />
    </div> -->
    <van-nav-bar
      title="查看关注用户"
      left-text="返回"
      @click-left="goBack"
      fixed
      left-arrow
    >
    </van-nav-bar>
    <div class="panel-box">
      <div class="group-list">
        <div class="my-info">
          <div class="my-img">
            <van-image
              round
              width="7rem"
              height="7rem"
              :src="
                !userInfo.images || userInfo.images == '未设置'
                  ? 'https://i.postimg.cc/2SrXwLXR/litangbao.png'
                  : userInfo.images
              "
            />
          </div>
          <div class="my-item-text">
            <div>{{ showName }}</div>
            <div>手机号:{{ userInfo.iphone }}</div>
            <div>地区:{{ userInfo.city ? userInfo.city : "暂无" }}</div>
          </div>
        </div>
      </div>

      <div style="width: 100%">
        <div class="loopTitle">血糖信息</div>
        <MyEcharts :userId="userId"></MyEcharts>
      </div>

      <div
        class="loop-box"
        v-if="cgmType == 'loop' && version == 'loop' && loopTime <= 30"
        @click="goloop()"
      >
        <div class="loopTitle">闭环设备信息</div>
        <van-row type="flex" justify="space-around">
          <van-col span="10"
            >名称
            <span>{{
              loopDevice.loop ? loopDevice.loop.name : "-"
            }}</span></van-col
          >
          <van-col span="13"
            >更新时间<span>{{
              loopDevice.create_time ? loopDevice.create_time : "-"
            }}</span></van-col
          >
        </van-row>
        <van-row type="flex" justify="space-around">
          <van-col span="23"
            >胰岛素泵
            <span>
              {{ loopDevice.pump ? loopDevice.pump.reservoir : "-" }}U
              {{ loopDevice.pump ? loopDevice.pump.battery.percent : "-" }}%
              {{ loopDevice.device ? loopDevice.device : "" }}</span
            ></van-col
          >
        </van-row>
      </div>

      <div
        class="loop-box"
        v-else-if="cgmType == 'loop' && version == 'loop' && loopTime > 30"
      >
        <div class="loopTitle">闭环设备信息</div>
        <van-row type="flex" justify="space-around">
          <van-col span="23"
            ><van-icon
              name="warning"
              color="red"
              size="1.5rem"
            />闭环设备信息更新时间已超出30分钟</van-col
          >
        </van-row>
      </div>

      <van-button
        type="primary"
        style="width: 96%; margin: 1rem 0"
        @click="goReport"
        >查看报告</van-button
      >
      <van-button
        type="info"
        :loading="loading1"
        style="width: 96%"
        @click="toWaitingA()"
        >开启血糖指导</van-button
      >
      <van-button
        v-if="fromPatients == 1"
        type="primary"
        style="width: 96%; margin: 1rem 0; background-color: #7accff; border: 0"
        @click="follow"
        >关注</van-button
      >
      <van-button
        v-else
        type="warning"
        style="width: 96%; margin: 1rem 0"
        @click="unfollow"
        >取消关注</van-button
      >
    </div>
  </div>
</template>
<script>
import {
  cretaeRoom,
  cretaeRoomToken,
  openGuid,
  insertRelation,
} from "@/api/relationship";
import BaibanReport from "../reportCenter/baibanReport.vue";
import MyEcharts from "../bloodSugar/component/pointChart.vue";
import LoopDetails from "../bloodSugar/component/loopDetails.vue";
import { upload } from "@/unitls/qiniutil";
import { Dialog } from "vant";
import {
  deleteRelation,
  getUserInfoById,
  loopDevicestatus,
} from "../../api/relationship";
import { Notify } from "vant";
export default {
  components: {
    MyEcharts,
    LoopDetails,
    BaibanReport,
  },
  data() {
    return {
      showName: this.$route.query.name,
      loading: false,
      loading1: false,
      userId: null,
      userInfo: {},
      loopDevice: {},
      cgmType: "",
      baibanId: "",
      // 白板
      uuid: "",
      roomToken: "",
      imageURLs: "",
      fileList: [],
      upType: false,
      version: null,
      loopTime: "",
      fromPatients: null,
      isClick: false,
    };
  },
  watch: {
    imageURLs(newVal, oldVal) {
      if (this.isClick) {
        this.toWaitingA();
      }
    },
  },
  created() {
    this.fromPatients = this.$route.query.fromPatients;
    this.userId = this.$route.query.id;
    this.getUserInfo();
    this.getLoopStatus();
  },
  mounted() {
    this.version = localStorage.getItem("version");
    this.cgmType = this.$route.query.cgm;

    window.unifiedInterSend = this.unifiedInterSend;
  },
  methods: {
    goloop() {
      this.$router.push({
        path: "loopDetails",
        query: { id: this.$route.query.id },
      });
    },
    // 获取闭环设备信息
    getLoopStatus() {
      loopDevicestatus({ uid: this.$route.query.id }).then((res) => {
        this.loopDevice = res.data;
        if (res.data.create_time) {
          // 计算loopd断开时间
          const start = res.data.create_time;
          const end = this.$moment().format("YYYY-MM-DD HH:mm:ss");
          // this.qualifyTime = calculateMinutes(start, end);

          this.loopTime = this.$moment(end).diff(
            this.$moment(start),
            "minutes"
          );
        }
      });
    },
    // 获取个人信息
    getUserInfo() {
      getUserInfoById(this.userId).then((res) => {
        this.userInfo = res.data.data;
      });
    },
    // 跳转报告页面
    goReport() {
      this.$router.push({
        path: "/FollowReportCenter",
        query: { id: this.userId, targetLow:this.$route.query.targetLow, targetHigh:this.$route.query.targetHigh},
      });
    },
    follow() {
      const appuser = JSON.parse(localStorage.getItem("appuser"));
      let params = {
        bemangeredId: this.userId,
        bemangeredName: this.userInfo.name,
        mangerId: appuser.id,
        mangerName: appuser.name,
      };
      insertRelation(params).then((res) => {
        if (res.data.code == 200) {
          this.$toast("关注成功");
        } else if (res.data.code == 201) {
          this.$toast("已经关注过该用户了");
        } else {
          Notify({ type: "warning", message: res.data.msg });
        }
      });
    },
    // 取消关注
    unfollow() {
      deleteRelation(this.$route.query.relation_id).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: '取消关注成功',
          });
          this.goBack();
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    goBack() {
      this.$router.push("/RecordDetail");
    },
    // 上传图片
    handleRemove(file, fileList) {
      const index = this.imageURLs.findIndex((item) => item.uid === file.uid);
      this.imageURLs.splice(index, 1);
    },
    handlePreview() {},
    httpRequest() {},
    onChange(file, fileList) {
      upload(file.raw, (url) => {
        this.imageURLs.push({ url: url, uid: file.uid });
      });
    },

    getImgUrl(val) {
      if (val) {
        console.log(val, "在线图片");
        this.$nextTick(() => {
          this.loading = false;
          this.imageURLs = val;
        });
      }
    },

    // 前往等待页面A
    toWaitingA() {
        this.$router.push({
          path: "/waitingA",
          query: {
            name: this.userInfo.name,
            id: this.userId,
            img: this.userInfo.images,
          },
        });

      // this.isClick = true;
      // this.loading1 = true;
      // cretaeRoom().then(({ data }) => {
      //   this.uuid = data.uuid;
      //   cretaeRoomToken(this.uuid).then(({ data }) => {
      //     this.roomToken = data;
      //     const uid = JSON.parse(localStorage.getItem("appuser")).id;
      //     const option = {
      //       uidA: uid,
      //       uidB: this.userId,
      //       uuid: this.uuid,
      //       roomToken: this.roomToken,
      //       appId: "0f421e92d19143fab731e73c737d8f22",
      //       channel: uid,
      //       imageURLs: this.imageURLs,
      //     };

      //     if (option.imageURLs) {
      //       console.log(option.imageURLs, "有地址了");
      //       openGuid(option).then(({ data }) => {
      //         if (data.code == 200) {
      //           const message = {
      //             message: "拨打语音",
      //             FunctionName: "call",
      //             body: {
      //               code: 1,
      //             },
      //           };
      //           window.webkit.messageHandlers.unifiedInterSend.postMessage(
      //             message
      //           );
      //           this.baibanId = data.data.baibanId;
      //           this.$router.push({
      //             path: "/waitingA",
      //             query: {
      //               baibanId: this.baibanId,
      //             },
      //           });
      //         } else {
      //           this.$toast(data.msg);
      //         }
      //         this.loading1 = false;
      //         this.isClick = false;
      //       });
      //     }
      //   });
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.body {
  padding-bottom: 8rem;
  padding-top: 4rem;
  overflow: hidden;
}
.panel-box {
  // width: 100%;
  // height: 100%;
  // overflow-y: scroll;
  .group-list {
    margin-bottom: 1rem;
    background: #fff;
  }
  .items-list {
    display: flex;
    padding: 10px 0;
    margin: 0 20px;
    .items-img {
      width: 3rem;
      height: 3rem;
      margin-right: 2rem;
      img {
        width: 100%;
      }
    }
    .item-text {
      flex: 1;
      display: flex;
      font-size: 1.8rem;
      line-height: 3rem;
      .text-l {
        flex: 1;
      }
      .text-r {
        font-size: 1.2rem;
        color: #999999;
        display: flex;
        .the-ad {
          flex: 1;
          margin-right: 0.7rem;
        }
        .the-icon {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 0.7rem;
          position: relative;
          img {
            width: 100%;
          }
          .small-mark {
            position: absolute;
            background: #fc4041;
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 10px;
            top: -0.5rem;
            right: -0.7rem;
          }
        }
      }
    }
  }
  .my-info {
    display: flex;
    padding: 10px 0;
    margin: 0 20px;
  }
  .my-img {
    width: 7rem;
    height: 7rem;
    img {
      width: 100%;
    }
  }
  .my-item-text {
    flex: 1;
    padding-left: 2rem;
    text-align: left;
    div:nth-child(1) {
      font-size: 2rem;
      color: #383838;
      line-height: 3rem;
    }
    div:nth-child(2) {
      font-size: 1.4rem;
      color: #9a9a9a;
      line-height: 2rem;
      margin-top: 0.2rem;
    }
    div:nth-child(3) {
      font-size: 1.4rem;
      color: #9a9a9a;
      line-height: 2rem;
      margin-top: 0.2rem;
    }
  }
  .loop-box {
    padding: 5px 0;
    .van-col {
      height: 3rem;
      line-height: 3rem;
      background: #d3eeff;
      margin-bottom: 5px;
      border-radius: 5px;
      span {
        background: cadetblue;
        color: #fff;
        padding: 4px;
        border-radius: 5px;
        margin: 0 2px;
      }
      img {
        vertical-align: middle;
      }
    }
  }
  .loopTitle {
    font-size: 16px;
    padding: 4px;
    margin: 8px;
    text-align: left;
    color: #0286f0;
    border-left: 4px #0286f0 solid;
  }
}
::v-deep .upload-demo {
  margin: 0 0 10px 0 !important;
  .el-upload {
    width: 100%;
    button {
      height: 44px;
      border: 0;
      background-color: rgb(55, 146, 182);
      border-radius: 2px;
      font-size: 14px;
      color: #fff;
    }
  }
}
::v-deep .el-upload-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 96%;
  margin: 0 auto;
  .el-upload-list__item {
    width: 20px;
    .el-upload-list__item-name {
      display: none;
    }
  }
}
</style>
