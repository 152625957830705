<template>
    <div id="smartTree" class="flow-container"
    v-touch:swipe="swipeHandler">
    <div  v-show="!showCode"  ref="appContainer" class="app-container">
      <!-- <h1>内分泌科人员结构图</h1> -->
      <div style="font-size:12px;margin-top:30px;">
        <el-scrollbar :style="scrollTreeStyle" class="el-org-tree">
          <vue2-org-tree
              :data="treeData.data"
              :horizontal="!horizontal"
              :collapsable="collapsable"
              :label-class-name="labelClassName"
              :render-content="renderContent"
              name="organ"
              @on-expand="onExpand"
              @on-node-click="onNodeClick"/>
        </el-scrollbar>
      </div>
      <br/><br/>
    </div>
    <div  v-show="showCode" @click="closeShowCode">
      <QRCode :basicInfo="basicInfo" ref="QRCode"></QRCode>
    </div>
    </div>
  </template>

<script>
import QRCode from './QRCode.vue'
// import { getUserInfoById } from '../../api/userServices'
export default {
  name: 'TreeTest',
  components: { QRCode },
  data () {
    return {
      showCode: false,
      departmentObject: {
        structId: '10ceb1266360469987a56378a915bf96',
        province: '安徽',
        city: '合肥',
        region: '肥西',
        structName: '肥西老母鸡医院',
        department: '泌尿外科',
        createId: '6666666666666666666666666666666',
        createName: '赵赛',
        adminId: null,
        adminName: null,
        createDate: '2023-06-08 10:38:42',
        json: ''
      },
      contentString: '',
      basicInfo: { id: null, label: '---null', role: '', imageUrl: '' },
      treeData: {
        labelClassName: 'bg-color-orange',
        basicInfo: { id: null, label: '---null', role: '' },
        basicSwitch: false,
        data: {
          id: '99bcf749-5c41-44ed-9b54-6fb3f8d56ddd',
          label: '杨耀坤',
          role: '科室主任',
          children: [
            {
              id: 2,
              label: '王宝强',
              role: '主治医生',
              children: [
                {
                  id: 2,
                  label: '王宝强',
                  role: '主治医生',
                  children: [
                    {
                      id: 2,
                      label: '王宝强',
                      role: '主治医生'
                    }
                  ]
                }
              ]
            }
          ]
        }
      },
      horizontal: false, // 横版 竖版
      collapsable: false,
      expandAll: true, // 是否全部展开
      labelClassName: '白色', // 默认颜色
      scrollTreeStyle: 'width:100%;'
    }
  },
  methods: {
    closeShowCode () {
      this.showCode = false
    },
    // 渲染节点
    renderContent (h, data) {
      return (
            <div>
              <div>
                <i class="el-icon-user-solid"></i>
                <span>{data.label}</span>
              </div>
              <div style="font-size:12px;line-height:20px;">{data.role}</div>
            </div>
      )
    },
    swipeHandler (direction) {
      if (direction === 'left') {
        console.log('left')
      } else if (direction === 'right') {
        if (!this.showCode) {
          this.$router.push({ path: '/', query: { index: 4 } })
        } else {
          this.showCode = false
        }
      }
    },
    // 点击节点
    NodeClick (e, data) {
      console.log(e, data)
    },
    // 默认展开
    toggleExpand (data, val) {
      if (Array.isArray(data)) {
        data.forEach(item => {
          this.$set(item, 'expand', val)
          if (item.children) {
            this.toggleExpand(item.children, val)
          }
        })
      } else {
        this.$set(data, 'expand', val)
        if (data.children) {
          this.toggleExpand(data.children, val)
        }
      }
    },
    collapse (list) {
      list.forEach(child => {
        if (child.expand) {
          child.expand = false
        }
        child.children && this.collapse(child.children)
      })
    },
    // 展开
    onExpand (e, data) {
      if ('expand' in data) {
        data.expand = !data.expand
        if (!data.expand && data.children) {
          this.collapse(data.children)
        }
      } else {
        this.$set(data, 'expand', true)
      }
    },
    getList () {
      // 后台回去的数据 赋值给data即可
    },
    addChildren (id, children) {

    },
    // 自定义您的点击事件
    onNodeClick (e, data) {
      console.log(e, data)
      // const _this = this
      // getUserInfoById({ userid: data.id }).then((res) => {
      //   if (res.data.code === '200') {
      //     _this.basicInfo.imageUrl = res.data.data.images
      //     _this.basicInfo.id = data.id
      //     _this.basicInfo.label = data.label
      //     _this.basicInfo.role = data.role
      //     const QRCodeView = _this.$refs.QRCode
      //     _this.contentString = 'id=' + data.id + '&role=' + data.role
      //     QRCodeView.useqrcode(_this.contentString)
      //     _this.showCode = true
      //   } else {
      //     this.$message('拉取头像失败!')
      //     _this.basicInfo.id = data.id
      //     _this.basicInfo.label = data.label
      //     _this.basicInfo.role = data.role
      //     const QRCodeView = _this.$refs.QRCode
      //     _this.contentString = 'id=' + data.id + '&role=' + data.role
      //     QRCodeView.useqrcode(_this.contentString)
      //     _this.showCode = true
      //   }
      // })
    }

  },
  mounted () {
    const row = this.$route.query.row
    this.departmentObject = JSON.parse(row)
    this.treeData = {
      labelClassName: 'bg-color-orange',
      basicInfo: { id: null, label: '---null', role: '' },
      basicSwitch: false,
      data: {
        id: this.departmentObject.structId,
        label: this.departmentObject.structName,
        role: this.departmentObject.department,
        children: JSON.parse(this.departmentObject.json).children
      }
    }
  }

}
</script>

<style lang="less">
  #smartTree {
    height: 88vh;
  }
  .bg-white {
    background-color: white;
  }
  .bg-orange {
    background-color: orange;
  }
  .bg-gold {
    background-color: gold;
  }
  .bg-gray {
    background-color: gray;
  }
  .bg-lightpink {
    background-color: lightpink;
  }
  .bg-chocolate {
    background-color: chocolate;
  }
  .bg-tomato {
    background-color: tomato;
  }
  .org-tree-node-label-inner {
   color: #fff;
   background-color: rgb(81, 102, 170);
  }
  .el-org-tree {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .org-tree-node-label {
      white-space: nowrap;
    }
    .el-tree-node__content{
      background: white;
    }
    .org-tree-node-label .org-tree-node-label-inner {
      cursor: pointer;
    }
    .horizontal .org-tree-node.is-leaf {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  </style>
