<template>
  <div id="loginView">
    <div class="close" @click="toLogin">
      <img src="../../static/pic/close.png" alt="" />
    </div>
    <div
      style="
        margin-top: 6rem;
        color: #333;
        font-size: 2rem;
        font-weight: bold;
        text-align: left;
        margin-left: 4rem;
      "
    >
      注册
    </div>
    <div style="width: 90%; margin-left: 5%; margin-top: 6rem">
      <div
        style="
          background: #f5f7f7;
          width: 90%;
          margin-left: 5%;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.2rem;
          border-radius: 2rem;
        "
      >
        <el-row>
          <el-col :span="4">
            <div
              style="
                font-weight: bold;
                width: 100%;
                height: 4rem;
                text-align: center;
              "
            >
              +86
            </div>
          </el-col>
          <el-col :span="20">
            <input
              placeholder="请输入手机号"
              type="text"
              v-model="phoneNumber"
              style="border: 0 solid #fff; height: 3rem; line-height: 3rem"
            />
          </el-col>
        </el-row>
      </div>

      <div
        style="
          background: #f5f7f7;
          width: 90%;
          margin-left: 5%;
          margin-top: 2rem;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.2rem;
          border-radius: 2rem;
        "
      >
        <el-row>
          <el-col :span="14">
            <div style="text-align: left; margin-left: 4rem; color: #7a7a7a">
              <input
                placeholder="输入验证码"
                type="text"
                style="border: 0 solid #fff; height: 3rem; line-height: 3rem"
                v-model="code"
              />
            </div>
          </el-col>
          <el-col :span="10">
            <div
              v-if="showGetCode"
              @click="getCode"
              style="width: 100%; text-align: center; font-weight: 400"
            >
              发送验证码
            </div>
            <div
              v-if="!showGetCode"
              style="width: 100%; text-align: center; font-weight: 400"
            >
              {{ timeOut }}秒后重试
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 2rem">
        <el-button
          :disabled="cantClick"
          style="
            background: #266eff;
            width: 80%;
            height: 48px;
            border-radius: 2rem;
          "
          type="primary"
          @click="register"
        >
          注册<span v-if="this.cantClick">中</span>
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
// eslint-disable-next-line no-unused-vars
import { getCode, register } from "../../api/userServices";
export default {
  name: "LoginView",

  data() {
    return {
      msg: "11",
      cantClick: false,
      phoneNumber: "",
      timeOut: 120,
      showGetCode: true,
      hadGetCode: false,
      show: false,
      timer: "",
      code: "",
    };
  },

  mounted() {
    const loginStatus = JSON.parse(localStorage.getItem("loginStatus"));
    if (loginStatus) {
      this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
    }
  },

  methods: {
    toLogin() {
      this.$router.push("login");
    },
    webtonative() {
      window.native.webToNative("web to native");
    },
    nativetoweb() {
      this.msg = window.native.nativeToWeb();
    },
    register() {
      const _this = this;
      this.cantClick = true;
      setTimeout(() => {
        this.cantClick = false;
      }, 3000);
      const params = {
        phone: this.phoneNumber,
        code: this.code,
      };
      register(params)
        .then((res) => {
          const code = res.data.code;
          if (code == 200) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem(
              "appuser",
              JSON.stringify(res.data.data.appuser)
            );
            localStorage.setItem("loginStatus", true);
            const message = {
              token: res.data.data.token,
            };
            const androidMessage = JSON.stringify(message)
            try {
              const phoneType = localStorage.getItem("phoneType");
              if (phoneType == "Android") {
                window.android.setTokenToIOS(androidMessage);
              } else {
                window.webkit.messageHandlers.setTokenToIOS.postMessage(
                  message
                );
              }
              this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
            } catch (error) {}
            Toast("登陆成功！");
            this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
          } else if (code === "5004" || code === "500") {
            Toast(res.data.msg);
            this.$router.push("/register");
          }
        })
        .catch((error) => {
          Toast(res.data.msg);
        });
    },
    getCode() {
      this.waitGetCode();
    },
    waitGetCode() {
      if (!this.phoneNumber || this.phoneNumber.length === 0) {
        Toast("请先输入手机号");
      }
      const regtel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!regtel.test(this.phoneNumber) || this.phoneNumber.length !== 11) {
        Toast("手机号不正确");
        return;
      }
      this.hadGetCode = true;
      this.showGetCode = false;
      this.timeOut = 120;
      getCode(this.phoneNumber);
      const _this = this;
      this.timer = setInterval(function () {
        if (_this.timeOut > 1) {
          _this.timeOut = _this.timeOut - 1;
        } else {
          _this.closeInterval();
        }
      }, 1000);
    },
    closeInterval() {
      this.showGetCode = true;
      clearInterval(this.timer);
    },
  },
};
</script>
<style scoped>
#loginView {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: #fff;
}
input {
  background-color: transparent;
}
.close {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
