// 将接口中数据按date、data传入数组sugerarr中
export const formatData = function formatData(l) {
  const sugerarr = [];
  l.data.data.forEach((ele) => {
    const date = new Date(ele.t);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    hour = hour < 10 ? "0" + hour : hour;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    const enddate =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;
    sugerarr.push({
      date: enddate,
      data: ele.v,
    }); // 将接口中的数据处理成date、data传入数组sugerarr中
  });
  return sugerarr;
};

// 获取平均血糖
export const getaverage = function getaverage(l) {
  let n = 0;
  l.forEach((e) => {
    n += parseFloat(e.data);
  });
  return (n / l.length).toFixed(1);
};

// 获取全部血糖中位数
export const getAllMid = function getAllMid(l) {
  const numlist = [];
  l.forEach((element) => {
    numlist.push(element.data);
  }); // 将全部数据传入数组numlist中
  numlist.sort(); // 将数据从小到大分类
  if (numlist.length === 1) {
    return toFF2(numlist[0]); // 若只有一条数据 则中位数为它本身
  } else if (numlist.length % 2 === 0) {
    return toFF2(
      toFF2(numlist[numlist.length / 2 - 1] + numlist[numlist.length / 2]) / 2
    ); // 若数据个数为偶数 则中位数为中间两数平均值
  } else {
    return toFF2(numlist[(numlist.length - 1) / 2]); // 若为奇数，则中位数为最中间的数
  }
};

// 获取预估糖化血红蛋白
// 糖化血红蛋白 =（平时多次测量的血糖平均值 + 2.59）/1.59
export const getprotein = function getprotein(l) {
  let n = 0;
  l.forEach((e) => {
    n += parseFloat(e.data);
  });
  const avg = n / l.length;
  const protein = (avg + 2.59) / 1.59;
  return protein.toFixed(2);
};

// 获取标准偏差
export const getstandard = function getstandard(l, avg) {
  let square = 0;
  l.forEach((element) => {
    const mis = Math.max(element.data, avg) - Math.min(element.data, avg); // 血糖数据与平均数的差值绝对值
    const multiply = mis * mis; // 绝对值的平方
    square += multiply; // 平方的和
  });
  const num = square / l.length; // 平方和÷数据总数
  const number = Math.sqrt(num); // 开方
  return number.toFixed(2);
};

// 获取非常高、高、正常、低、非常低血糖占比
export const getRate = function getRate(l, num1, num2) {
  let n = 0;
  l.forEach((element) => {
    if (element.data > num1 && element.data <= num2) {
      // num1为范围内最低值，num2为范围内最大值
      n++;
    }
  });
  const rate = ((n / l.length) * 100).toFixed(0); // 读取的范围内血糖数量÷总血糖数量
  return rate;
};

// 获取CV变异系数
export const getCV = function getCV(avg, error) {
  const end = ((error / avg) * 100).toFixed(0); // CV(Coefficient of variation)=(血糖标准差÷平均血糖)×100%
  return end;
};

// 获得PGS血糖状态
export const getPGS = function getPGS(l) {
  let n = 0;
  l.forEach((ele) => {
    n = ele.data > n ? ele.data : n; // 餐后血糖峰值
  });
  return n;
};
// 获得全部血糖数量
export const getAllNum = function getAllNum(l) {
  let n = 0;
  l.forEach((element) => {
    if (element.data) {
      n++;
    }
  });
  return n;
};
// 获得读取的低血糖数量
export const getLow = function getLow(l,targetLow) {
  let n = 0;
  l.forEach((element) => {
    if (element.data < (targetLow?Number(targetLow):3.9)) {
      n++;
    }
  });
  const num = n === 0 ? 0 : n;
  return num;
};
// 获得读取的范围内血糖数量
export const getNormal = function getNormal(l) {
  let n = 0;
  l.forEach((element) => {
    if (element.data >= 3.9 && element.data <= 10) {
      n++;
    }
  });
  const num = n === 0 ? 0 : n;
  return num;
};
// 获取读取的高血糖数量
export const getHigh = function getHigh(l,targetHigh) {
  let n = 0;
  l.forEach((element) => {
    if (element.data > (targetHigh?Number(targetHigh):10)) {
      n++;
    }
  });
  const num = n === 0 ? 0 : n;
  return num;
};
// 返回获得按天处理的血糖数据
export const getSevenData = function getSevenData(l) {
  const daysData = [];
  const sugerarr = [];
  l.data.data.forEach((ele) => {
    const date = new Date(ele.t);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const second = date.getSeconds();
    const enddate =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      second;
    sugerarr.push({
      date: enddate,
      data: ele.v,
    });
  });
  const dateList = []; // 2020-02-02时间格式的单条血糖数据集合
  sugerarr.forEach((element) => {
    dateList.push(element.date.split(" ")[0]); // 取所有时间年月日格式到dateList
  });
  const dateSet = new Set(dateList); // 所有的日期集合 ['2020-02-02','2020-02-03']
  dateSet.forEach((element) => {
    const list = [];
    sugerarr.forEach((ele) => {
      if (element === ele.date.split(" ")[0]) {
        list.push(ele.data);
      }
    });
    const l = {
      date: element,
      data: list,
    };
    daysData.push(l);
  });
  // 以天为单位的血糖数组
  const sevendata = [];
  daysData.forEach((element) => {
    element.data.forEach((ele) => {
      sevendata.push(ele);
    });
  });
  return sevendata;
};

// 返回按小时处理的血糖数据
export const get24HourData = function get24HourData(l) {
  const hoursArr = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];
  for (let index = 0; index < l.length; index++) {
    const e = l[index];
    hoursArr[parseInt(e.date.substr(11, 2))].push(e.data);
  }
  const _10 = [];
  const _25 = [];
  const _50 = [];
  const _75 = [];
  const _90 = [];
  for (let ind = 0; ind < hoursArr.length; ind++) {
    const ele = hoursArr[ind];
    _10.push(percentile(ele, 10));
    _25.push(percentile(ele, 25));
    _50.push(percentile(ele, 50));
    _75.push(percentile(ele, 75));
    _90.push(percentile(ele, 90));
  }
  return {
    _10: _10,
    _25: _25,
    _50: _50,
    _75: _75,
    _90: _90,
  };
};

// 将血糖数据处理成差值的形式方便声场图谱
export const getLineChartData = function getLineChartData(o) {
  return {
    _10_0: o._10,
    _50: o._50,
    _25_10: getDiff(o._25, o._10),
    _50_25: getDiff(o._50, o._25),
    _75_50: getDiff(o._75, o._50),
    _90_75: getDiff(o._90, o._75),
  };
  function getDiff(l1, l2) {
    const a = [];
    for (let index = 0; index < 24; index++) {
      const e = l1[index] - l2[index];
      a.push(e);
    }
    return a;
  }
};

// 对数据进行百分位数处理
export const percentile = function percentile(l, percentage) {
  const position = (l.length + 1) * (percentage / 100); // 根据percentage判断位置
  const hadSort = l.sort((a, b) => {
    return a - b;
  });
  if (position < 1) {
    return "数据不足";
  } else {
    let min = hadSort[Math.floor(position) - 1];
    let max = hadSort[Math.ceil(position) - 1];
    let left = (position - Math.floor(position)) * (max - min);
    let result = min + left;
    return result.toFixed(2);
    // arrList.push(
    //   toFF2(
    //     toFF2(hadSort[parseInt(position)]) *
    //       (1 - (position - parseInt(position))) +
    //       toFF2(hadSort[parseInt(position)] + 1) *
    //         (position - parseInt(position))
    //   )
    // )
  }
};
// 返回获得低血糖的平均值
export const getLowAvg = function getLowAvg(l) {
  let n = 0;
  let sum = 0;
  l.forEach((element) => {
    if (element.data < 3.9) {
      n += 1;
      sum += element.data;
    }
  });
  const avg = n === 0 ? 0 : sum / n;
  return avg.toFixed(2);
};
// 返回获得范围内的平均值
export const getNormalAvg = function getNormalAvg(l) {
  let n = 0;
  let sum = 0;
  l.forEach((element) => {
    if (element.data >= 3.9 && element.data <= 10) {
      n += 1;
      sum += element.data;
    }
  });
  const avg = n === 0 ? 0 : sum / n;
  return avg.toFixed(2);
};
// 返回获得高血糖的平均值
export const getHighAvg = function getHighAvg(l) {
  let n = 0;
  let sum = 0;
  l.forEach((element) => {
    if (element.data > 10) {
      n += 1;
      sum += element.data;
    }
  });
  const avg = n === 0 ? 0 : sum / n;
  return avg.toFixed(2);
};

function toFF2(str) {
  return parseFloat(parseFloat(str).toFixed(2));
}
// 返回获得低血糖的中位数
export const getLowMid = function getLowMid(l) {
  const LowList = [];
  l.forEach((element) => {
    if (element.data < 3.9) {
      LowList.push(element.data);
    } // 将低血糖数据存入数组中
  });
  LowList.sort(); // 将数据从小到大排序
  if (LowList.length === 1) {
    return toFF2(LowList[0]);
  } else if (LowList.length % 2 === 0) {
    // 若数据长度为偶数，则为中间两数的平均值
    return toFF2(
      toFF2(LowList[LowList.length / 2 - 1] + LowList[LowList.length / 2]) / 2
    );
  } else {
    // 若数据长度为奇数，则为最中间的数
    return toFF2(LowList[(LowList.length - 1) / 2]);
  }
};
// 返回获得范围内的中位数
export const getNormalMid = function getNormalMid(l) {
  const NormalList = [];
  l.forEach((element) => {
    if (element.data >= 3.9 && element.data <= 10) {
      NormalList.push(element.data);
    }
  });
  NormalList.sort();
  if (NormalList.length === 1) {
    return toFF2(NormalList[0]);
  } else if (NormalList.length % 2 === 0) {
    return toFF2(
      toFF2(
        NormalList[NormalList.length / 2 - 1] +
          NormalList[NormalList.length / 2]
      ) / 2
    );
  } else {
    return toFF2(NormalList[(NormalList.length - 1) / 2]);
  }
};
// 返回高血糖中位数
export const getHighMid = function getHighMid(l) {
  const HighList = [];
  l.forEach((element) => {
    if (element.data > 10) {
      HighList.push(element.data);
    }
  });
  HighList.sort();
  if (HighList.length === 1) {
    return toFF2(HighList[0]);
  } else if (HighList.length === 0) {
    return "无";
  } else if (HighList.length % 2 === 0) {
    return toFF2(
      toFF2(HighList[HighList.length / 2 - 1] + HighList[HighList.length / 2]) /
        2
    );
  } else {
    return toFF2(HighList[(HighList.length - 1) / 2]);
  }
};
// 求低血糖内的标准偏差
export const getLowStandard = function getLowStandard(l) {
  const LowList = [];
  l.forEach((element) => {
    if (element.data < 3.9) {
      LowList.push(element.data); // 将低血糖数据存入数组LowList中
    }
  });
  let n = 0;
  LowList.forEach((e) => {
    n += parseFloat(e);
  });
  const Average = (n / LowList.length).toFixed(1); // 数据的平均数
  let mis = 0;
  let multiply = 0;
  let sumsqrt = 0;
  LowList.forEach((element) => {
    mis = Math.max(element, Average) - Math.min(element, Average); // 每个数据与平均数的差值的绝对值
    multiply = mis * mis; // 绝对值的平方
    sumsqrt += multiply; // 平方之和
  });
  const num = sumsqrt / LowList.length;
  const number = Math.sqrt(num);
  return number.toFixed(2);
};
// 求范围内的标准偏差
export const getNormalStandard = function getNormalStandard(l) {
  const normalList = [];
  l.forEach((element) => {
    if (element.data >= 3.9 && element.data <= 10) {
      normalList.push(element.data);
    }
  });
  let n = 0;
  normalList.forEach((e) => {
    n += parseFloat(e);
  });
  const Average = (n / normalList.length).toFixed(1);
  let mis = 0;
  let multiply = 0;
  let sumsqrt = 0;
  normalList.forEach((element) => {
    mis = Math.max(element, Average) - Math.min(element, Average);
    multiply = mis * mis;
    sumsqrt += multiply;
  });
  const num = sumsqrt / normalList.length;
  const number = Math.sqrt(num);
  return number.toFixed(2);
};
// 求高血糖内的标准偏差
export const getHighStandard = function getHighStandard(l) {
  const LowList = [];
  l.forEach((element) => {
    if (element.data > 10) {
      LowList.push(element.data);
    }
  });
  let n = 0;
  LowList.forEach((e) => {
    n += parseFloat(e);
  });
  const Average = (n / LowList.length).toFixed(1);
  let mis = 0;
  let multiply = 0;
  let sumsqrt = 0;
  LowList.forEach((element) => {
    mis = Math.max(element, Average) - Math.min(element, Average);
    multiply = mis * mis;
    sumsqrt += multiply;
  });
  const num = sumsqrt / LowList.length;
  const number = Math.sqrt(num);
  return number.toFixed(2);
};
// 计算两个日期之间的天数
export const calculate = function calculate(day1, day2) {
  console.log("这是你要比较的日期1：", day1, "这是你要比较的日期2：", day2);
  const msPerDay = 1000 * 60 * 60 * 24; // 一天的毫秒数

  // 将day1,day2的yyyy-mm-dd时间格式转换成：中国标准时间格式
  const newDay1 = new Date(day1);
  const newDay2 = new Date(day2);
  console.log(
    "来看看他们被转换成什么样啦, newDay1是：",
    newDay1,
    "newDay2是：",
    newDay2
  );

  // 再给它俩换成UTC格式
  const utc1 = Date.UTC(
    newDay1.getFullYear(),
    newDay1.getMonth(),
    newDay1.getDate()
  );
  const utc2 = Date.UTC(
    newDay2.getFullYear(),
    newDay2.getMonth(),
    newDay2.getDate()
  );

  // 最后再用Math.floor()来计算它们之间相差的天数~
  const result = Math.floor((utc2 - utc1) / msPerDay);

  // 样我们看看结果是啥
  console.log(result);
  return result + 1;
};
// 计算两个日期之间的分钟数
export const calculateMinutes = function calculateMinutes(time1, time2) {
  // 截取字符串，得到日期部分"2009-12-02",用split把字符串分隔成数组
  const begin1 = time1.substr(0, 10).split("-");
  const end1 = time2.substr(0, 10).split("-");

  // 将拆分的数组重新组合，并实例成化新的日期对象
  const date1 = new Date(begin1[1] + -+begin1[2] + -+begin1[0]);
  const date2 = new Date(end1[1] + -+end1[2] + -+end1[0]);

  // 得到两个日期之间的差值m，以分钟为单位
  // Math.abs(date2-date1)计算出以毫秒为单位的差值
  // Math.abs(date2-date1)/1000得到以秒为单位的差值
  // Math.abs(date2-date1)/1000/60得到以分钟为单位的差值
  const m = parseInt(Math.abs(date2 - date1) / 1000 / 60);

  // 小时数和分钟数相加得到总的分钟数
  // time1.substr(11,2)截取字符串得到时间的小时数
  // parseInt(time1.substr(11,2))*60把小时数转化成为分钟
  const min1 =
    parseInt(time1.substr(11, 2)) * 60 + parseInt(time1.substr(14, 2));
  const min2 =
    parseInt(time2.substr(11, 2)) * 60 + parseInt(time2.substr(14, 2));

  // 两个分钟数相减得到时间部分的差值，以分钟为单位
  const n = min2 - min1;

  // 将日期和时间两个部分计算出来的差值相加，即得到两个时间相减后的分钟数

  const minutes = m + n;
  return minutes;
};
// 计算两个日期的剩余天数
export const dateCompute = function dateCompute(date1, date2) {
  // 两个日期
  var date1 = new Date(date1);
  var date2 = new Date(date2);

  // 计算两个日期之间的毫秒数差距
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());

  // 转换为天数
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  // 计算剩余天数
  const remainingDays = 14 - daysDiff;
  console.log("剩余天数：" + remainingDays);

  return remainingDays;
};
// 计算最大血糖波动幅度
export const getMaxRange = function getMaxRange(l) {
  // 初始化最大值和最小值为数组中第一个对象的数字字段值
  let maxValue = l[0].data;
  let minValue = l[0].data;

  // 遍历数组，更新最大值和最小值
  for (const obj of l) {
    const data = obj.data;
    if (data > maxValue) {
      maxValue = data;
    }
    if (data < minValue) {
      minValue = data;
    }
  }
  console.log(maxValue, "maxValue");
  console.log(minValue, "minValue");
  // 计算差值
  const difference = maxValue - minValue;
  return difference.toFixed(1);
};
