<template>
  <div class="no-data">
    <img src="../static/pic/nodata.png" alt="No Data" class="no-data-image">
    <p class="no-data-text">暂无数据</p>
  </div>
</template>

<script>
export default {
  name: 'NoData',
}
</script>

<style scoped>
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50%;
}

.no-data-image {
  width: 200px;
  height: 200px;
}

.no-data-text {
  font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    color: rgb(16, 171, 255);
}
</style>