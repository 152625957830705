<template>
  <div>
    <div style="width: 100%;height: 4rem;margin-top: 2rem;">
      <div style="float:right;margin-right:3%;height: 48px;">
        <el-button type="primary" @click="showDialog">新增</el-button>
      </div>
    </div>
    <van-search v-model="searchOption.city" show-action label="城市(例:合肥市)" placeholder="请输入城市名">
      <template #action>
        <el-button size="mini" @click="onSearch">搜索</el-button>
      </template>
    </van-search>
    <van-search v-model="searchOption.structName" show-action label="医院(例:县医院)" placeholder="请输入医院名">
      <template #action>
        <el-button size="mini" @click="onSearch">搜索</el-button>
      </template>
    </van-search>
    <div style="width: 100%;height: 20rem;">
      <el-table border stripe :data="tableData" @row-click="clickRow" @selection-change="handleSelectionChange"
        style="width: 94%;margin-left: 3%;">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="structName" label="组织/医院">
        </el-table-column>
        <el-table-column prop="department" label="部门/科室">
        </el-table-column>
        <el-table-column prop="createName" label="创建人">
        </el-table-column>
        <el-table-column prop="createName" label="管理员">
        </el-table-column>
      </el-table>
    </div>
    <van-popup v-model="showAddPop" position="bottom" :style="{ height: '36rem' }">
      <departmentView></departmentView>
    </van-popup>
  </div>
</template>

<script>
import { findStruct } from '../../api/institution'
import departmentView from './components/departmentView.vue'
export default {
  components: { departmentView },
  data () {
    return {
      tableData: [],
      showAddPop: false,
      multipleSelection: [],
      searchOption: {
        city: '',
        structName: ''
      },
      searchInfo: {
        structId: '',
        structName: '',
        province: '',
        city: '',
        region: '',
        department: '',
        createid: '',
        json: {
          children: []
        }
      }
    }
  },
  mounted () {
    this.searchInfo.createid = JSON.parse(localStorage.getItem('appuser')).id
    this.getTableDataBySearchCondtion()
    this.fbiSF()
  },
  methods: {
    onSearch () {},
    closeDoalog () {
      this.showAddPop = false
    },
    fbiSF () {
      // 禁用双指放大
      document.documentElement.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      }, { passive: false })

      // 禁用双击放大
      let lastTouchEnd = 0
      document.documentElement.addEventListener('touchend', function (event) {
        const now = Date.now()
        if (now - lastTouchEnd <= 300) {
          event.preventDefault()
        }
        lastTouchEnd = now
      }, { passive: false })
    },
    clickRow (row) {
      this.$emit('toPersonnelManagement', row)
    },
    showDialog () {
      this.showAddPop = true
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(val)
    },
    getTableDataBySearchCondtion () {
      findStruct(this.searchInfo).then(res => {
        this.tableData = res.data.data
        console.log(JSON.stringify(this.tableData))
      })
    }
  }
}
</script>

<style></style>
