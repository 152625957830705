<template>
  <div id="userSelect">
    <el-select style="margin-top:2rem" v-model="value" placeholder="请选择">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <el-row style="margin-top: 6rem;">
      <el-col :span="12">
          <el-button @click="addUserToHospital" style="float:right;margin-right: 12px;">确认</el-button>
      </el-col>
      <el-col :span="12">
          <el-button @click="closeSelectUser" style="float:left;margin-left: 12px;">取消</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getStructUser } from '../../api/userServices'
export default {
  props: {
    selectedRow: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      value: '',
      show: true,
      options: []
    }
  },
  mounted () {
    this.getTheHospitalUser()
  },
  methods: {
    getTheHospitalUser () {
      const params = {
        structId: this.selectedRow.structId
      }
      getStructUser(params).then(res => {
        if (res.data.code === '200') {
          const data = res.data.data
          for (let index = 0; index < data.length; index++) {
            const e = data[index]
            const obj = {
              label: e.name,
              value: e.id
            }
            if (e.checked === 'true') {
              console.log(e)
            } else {
              this.options.push(obj)
            }
          }
        }
      })
    },
    getSelectedUser () {
      for (let index = 0; index < this.options.length; index++) {
        const element = this.options[index]
        if (element.value === this.value) {
          return element
        }
      }
    },
    addUserToHospital () {
      const user = this.getSelectedUser()
      if (user) {
        for (let i = 0; i < this.options.length; i++) {
          const o = this.options[i]
          if (o.label === user.label && o.value === user.value) {
            this.options.splice(i, 1)
          }
        }
        this.$emit('selectUserToHospital', user)
      } else {
        this.$message('请选择用户')
      }
    },
    closeSelectUser () {
      this.$emit('closeSelectUser')
    }
  }
}
</script>
<style scoped>
#userSelect {
  position: fixed;
  width: 100%;
  height: 20rem;
  bottom: 0;
  background: #fff;
}
</style>
