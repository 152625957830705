var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.detail)?_c('div',{style:(_vm.cardStyle),attrs:{"id":"card"}},[_vm._l((_vm.detailList),function(item,index){return _c('div',{key:index,staticClass:"main",on:{"click":function($event){return _vm.follow(item.bemangered_id, item.id, item.cgm, item.name, item.targetLow, item.targetHigh)}}},[_c('img',{staticClass:"img",style:(_vm.imgStyle),attrs:{"src":item.images ? item.images : _vm.img}}),_c('div',{staticClass:"text"},[_c('label',{staticClass:"title",style:(_vm.titleStyle)},[_vm._v(_vm._s(item.name)+" "),_c('van-icon',{attrs:{"name":"edit"},on:{"click":function($event){$event.stopPropagation();return _vm.editName(item)}}}),_c('span',{staticClass:"sugarNum",style:(`${
            item.sugar > 10 || item.sugar < 3.9
              ? 'color: red;'
              : 'color: green;'
          }`)},[_vm._v(_vm._s(item.sugar))]),_c('span',{staticClass:"sugarJian",style:(`${
            item.sugar > 10 || item.sugar < 3.9
              ? 'color: red;'
              : 'color: green;'
          }`)},[_vm._v(_vm._s(item.sugarS == "0" || item.sugarS == "--" ? "→" : item.sugarS == "1" || item.sugarS == "2" ? "↗" : "↘"))])],1),_c('label',{staticClass:"detail"},[_vm._v("手机号："+_vm._s(item.iphone))]),_c('label',{staticClass:"detail"},[_vm._v("血糖更新时间："+_vm._s(item.time))])])])}),_c('van-dialog',{attrs:{"title":"修改备注名","show-cancel-button":""},on:{"confirm":function($event){return _vm.updateName()}},model:{value:(_vm.shownameDialog),callback:function ($$v) {_vm.shownameDialog=$$v},expression:"shownameDialog"}},[_c('van-field',{attrs:{"input-align":"center","placeholder":"请输入备注名"},model:{value:(_vm.showNameOb.name),callback:function ($$v) {_vm.$set(_vm.showNameOb, "name", $$v)},expression:"showNameOb.name"}})],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }