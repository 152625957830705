<template>
  <div>
    <van-nav-bar title="待审核列表" left-text="返回" @click-left="goBack" left-arrow>
    </van-nav-bar>
    <div class="sweep" v-for="item in hosList" :key="item.id">
      <img src="../../../static/pic/hospital.png" alt="" />
      <div class="title">{{item.structName}}</div>
      <div class="info">{{item.city}}{{item.region}}</div>
    </div>

  </div>
</template>
<script>
import { getHospitalById } from '../../../api/relationship'
// import { Notify } from 'vant'
export default {
  data() {
    return {
      hosList: [],
      form: {
        createId: '',
        status: 0
      }
    }
  },

  mounted() {},
  created() {
    this.form.createId = JSON.parse(localStorage.getItem('appuser')).id
    this.getNoPassHos()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getNoPassHos() {
      getHospitalById(this.form).then(({ data }) => {
        this.hosList = data.data
      })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
