<template>
  <div id="card" :style="cardStyle" v-if="detail">
    <div
      class="main"
      v-for="(item, index) in detailList"
      :key="index"
      @click="follow(item.bemangered_id, item.id, item.cgm, item.name, item.targetLow, item.targetHigh)"
    >
      <img
        class="img"
        :src="item.images ? item.images : img"
        :style="imgStyle"
      />
      <div class="text">
        <label class="title" :style="titleStyle"
          >{{ item.name }} <van-icon name="edit" @click.stop="editName(item)" />
          <span
            :style="`${
              item.sugar > 10 || item.sugar < 3.9
                ? 'color: red;'
                : 'color: green;'
            }`"
            class="sugarNum"
            >{{ item.sugar }}</span
          >
          <span
            class="sugarJian"
            :style="`${
              item.sugar > 10 || item.sugar < 3.9
                ? 'color: red;'
                : 'color: green;'
            }`"
            >{{
              item.sugarS == "0" || item.sugarS == "--"
                ? "→"
                : item.sugarS == "1" || item.sugarS == "2"
                ? "↗"
                : "↘"
            }}</span
          >
        </label>
        <label class="detail">手机号：{{ item.iphone }}</label>
        <label class="detail">血糖更新时间：{{ item.time }}</label>
      </div>
    </div>

    <van-dialog
      v-model="shownameDialog"
      title="修改备注名"
      show-cancel-button
      @confirm="updateName()"
    >
      <van-field
        v-model="showNameOb.name"
        input-align="center"
        placeholder="请输入备注名"
      />
    </van-dialog>
  </div>
</template>

<script>
import img from "../../static/pic/avatar.png";
import { relationShowname } from "../../api/userServices"
import { Toast } from 'vant';
export default {
  name: "Card",
  props: {
    detail: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: "https://file.zhtsu.cn/light-radius.png",
    },
    title: {
      type: String,
      default: "标题",
    },
    height: {
      type: String,
      default: "100",
    },
    color: {
      type: String,
      default: "#000",
    },
  },
  data() {
    return {
      ht: this.height,
      cardStyle: "",
      titleColor: this.color,
      titleStyle: "",
      imgStyle: "",
      sugar: "",
      detailList: [],
      img: img,
      shownameDialog:false,
      showNameOb: {
        id:'',
        name:'',
      }
    };
  },
  watch: {
    // detail: {
    //   handler: function (newVal, oldVal) {
    //     if (oldVal) {
    //       this.$nextTick(() => {
    //         this.getSugerDetail();
    //       });
    //     }
    //   },
    // },
    loading: {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.$nextTick(() => {
            this.getSugerDetail();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getSugerDetail();
  },
  mounted() {
    this.cardStyle = `width: 95%; height: ${this.ht}px;`;
    this.imgStyle = `width: ${this.ht - 20}px; height: ${this.ht - 20}px;`;
    this.titleStyle = `color: ${this.titleColor}`;
  },
  methods: {
    // 修改备注名
    editName(item) {
      this.shownameDialog = true
      this.showNameOb.id = item.id;
      this.showNameOb.name = item.name
      console.log(this.showNameOb,'item');
    },
    updateName() {
      relationShowname(this.showNameOb).then((res) => {
          if (res.data.code == 200) {
            Toast('修改成功！')
            this.shownameDialog = false
            this.$emit('onRefresh')
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    follow(id, relation_id, cgm, name, targetLow, targetHigh) {
      this.$emit("followView", id, relation_id, cgm, name, targetLow, targetHigh);
    },

    getSugerDetail() {
      this.detailList = [];
      // if ((this.loading = true)) {
      this.detail.map((item) => {
        setTimeout(() => {
          const nowTime = this.$moment().format("YYYY-MM-DD HH:mm:ss");
          // const minutes = calculateMinutes(
          //   item.bg ? item.bg.create_time : "2000-01-01 00:00:00",
          //   nowTime
          // );
          const minutes = this.$moment(nowTime).diff(
            this.$moment(item.bg ? item.bg.create_time : "2000-01-01 00:00:00"),
            "minutes"
          );
          this.detailList.push({
            name: item.name,
            iphone: item.iphone,
            bemangered_id: item.bemangered_id,
            targetLow: item.targetLow,
            targetHigh: item.targetHigh,
            cgm: item.cgm,
            id: item.id,
            images: item.images,
            time: item.bg && minutes <= 30 ? item.bg.create_time : "--",
            sugar: item.bg && minutes <= 30 ? JSON.parse(item.bg.v) : "--",
            sugarS: item.bg && item.bg.s ? JSON.parse(item.bg.s) : "--",
          });
        }, 300);
      });
      // }
    },
  },
};
</script>

<style scoped>
.main {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  border-radius: 10px;
  margin: 10px;
}
.main:hover {
  background: rgba(255, 255, 255, 0.65);
  transform: scale(102%, 102%);
}
.img {
  position: relative;
  width: 64px !important;
  height: 64px !important;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 20px;
  float: left;
}
.text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  margin-left: 25%;
}
.title {
  display: block;
  font-size: 18px;
  margin: 5px 0;
  font-weight: bold;
  /* text-shadow: 1px 1px 1px grey; */
}
.detail {
  display: block;
  font-size: 15px;
  display: block;
  margin-top: 10px;
  color: #7b7b7b;
}
.sugar {
  color: #7b7b7b;
  font-size: 16px;
  text-shadow: none;
  margin-left: 2rem;
}
.sugarNum {
  font-size: 24px;
  position: absolute;
  right: 50px;
}
.sugarJian {
  font-size: 24px;
  position: absolute;
  right: 20px;
}
</style>
