<template>
  <div>
    <canvas id="QRCode_header" style="width: 280px; height: 280px"></canvas>
  </div>
</template>
<script>
import QRCode from "qrcode"; // 引入生成二维码插件
export default {
  props: ["qrcType"],
  data() {
    return {
      qrUrl: "",
      structId: '',
    };
  },
  watch: {
    structId(newVal, oldVal) {
      this.getQRCode()
    }
  },
  mounted() {
    if(this.qrcType == 2) {
      this.structId = JSON.parse(localStorage.getItem('hospital')).struct_id
    }
    this.getQRCode();
  },
  created() {},
  methods: {
    getQRCode() {
      // 生成的二维码为URL地址js
      const appuser = JSON.parse(localStorage.getItem("appuser"));
      const hospital = JSON.parse(localStorage.getItem("hospital"));
      if (this.qrcType == 1) {
        this.qrUrl =
          '{"id":"' +
          appuser.id +
          '","name":"' +
          appuser.name +
          '","qrcType":"' +
          this.qrcType +
          '"}';
      } else if (this.qrcType == 2) {
        this.qrUrl =
          '{"id":"' +
          appuser.id +
          '","name":"' +
          appuser.name +
          '","structName":"' +
          hospital.struct_name +
          '","structId":"' +
          hospital.struct_id +
          '","qrcType":"' +
          this.qrcType +
          '"}';
      }

      const opts = {
        errorCorrectionLevel: "H", // 容错级别
        type: "image/png", // 生成的二维码类型
        quality: 0.3, // 二维码质量
        margin: 0, // 二维码留白边距
        width: 280, // 宽
        height: 280, // 高
        text: "http://www.xxx.com", // 二维码内容
        color: {
          dark: "#333333", // 前景色
          light: "#fff", // 背景色
        },
      };

      const msg = document.getElementById("QRCode_header");
      // 将获取到的数据(val)画到msg(canvas)上
      QRCode.toCanvas(msg, this.qrUrl, opts, function (error) {
        if (error) {
          console.log("二维码加载失败", error);
          this.$message.error("二维码加载失败");
        }
      });
    },
  },
};
</script>
<style scoped></style>
