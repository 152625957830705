<template>
  <div class="container" v-loading="loading">
    <van-nav-bar
      title="查看闭环信息"
      left-text="返回"
      @click-left="goBack"
      fixed
      left-arrow
    >
    </van-nav-bar>
    <div class="title">闭环信息</div>
    <van-row type="flex" justify="space-around">
      <van-col span="10"
        >名称
        <span>{{ loopStatus.loop ? loopStatus.loop.name : "-" }}</span></van-col
      >
      <van-col span="13"
        >更新时间<span>{{
          loopStatus.create_time ? loopStatus.create_time : "-"
        }}</span></van-col
      >
    </van-row>
    <van-row type="flex" justify="space-around">
      <van-col span="10"
        >活性碳水COB<span
          ><span>{{
            loopStatus.loop ? loopStatus.loop.cob.cob.toFixed(2) : "-"
          }}</span
          >g</span
        ></van-col
      >
      <van-col span="13"
        >活性胰岛素IOB<span
          ><span>{{
            loopStatus.loop ? loopStatus.loop.iob.iob.toFixed(2) : "-"
          }}</span
          >U</span
        ></van-col
      >
    </van-row>

    <div class="title">胰岛素泵信息</div>
    <van-row type="flex" justify="space-around">
      <van-col span="11"
        >名称
        <span>{{
          loopStatus.pump ? loopStatus.pump.manufacturer : "-"
        }}</span></van-col
      >
      <van-col span="11"
        >类型
        <span>{{
          loopStatus.pump ? loopStatus.pump.model : "-"
        }}</span></van-col
      >
    </van-row>
    <van-row type="flex" justify="space-around">
      <van-col span="23"
        >胰岛素泵
        <span>
          {{ loopStatus.pump ? loopStatus.pump.reservoir : "-" }}U
          {{ loopStatus.pump ? loopStatus.pump.battery.percent : "-" }}%
          {{ loopStatus.device ? loopStatus.device : "" }}</span
        ></van-col
      >
    </van-row>

    <div class="title">设备信息</div>
    <van-row type="flex" justify="space-around">
      <van-col span="11"
        >设备
        <span>{{
          loopStatus.uploader ? loopStatus.uploader.name : "-"
        }}</span></van-col
      >
      <van-col span="11"
        >电量
        <span>{{
          loopStatus.uploader ? loopStatus.uploader.battery : "-"
        }}</span></van-col
      >
    </van-row>
    <van-row type="flex" justify="space-around">
      <van-col span="23"
        >更新时间
        <span>{{
          loopStatus.create_time ? loopStatus.create_time : "-"
        }}</span></van-col
      >
    </van-row>

    <div class="title">配置信息</div>
    <div class="setting">当前基础率</div>
    <van-row
      type="flex"
      justify="space-around"
      v-for="(item, index) in loopCurrent.store
        ? loopCurrent.store.Default.basal
        : []"
      :key="index"
    >
      <van-col span="11"
        >时间段 <span>{{ item.time }}</span></van-col
      >
      <van-col span="11"
        >数值 <span>{{ item.value }}U</span></van-col
      >
    </van-row>
    <div class="setting">当前碳水化合物系数</div>
    <van-row
      type="flex"
      justify="space-around"
      v-for="(item, index) in loopCurrent.store
        ? loopCurrent.store.Default.carbratio
        : []"
      :key="index"
    >
      <van-col span="11"
        >时间段 <span>{{ item.time }}</span></van-col
      >
      <van-col span="11"
        >数值 <span>{{ item.value }}U/10g</span></van-col
      >
    </van-row>
    <div class="setting">胰岛素敏感系数</div>
    <van-row
      type="flex"
      justify="space-around"
      v-for="(item, index) in loopCurrent.store
        ? loopCurrent.store.Default.sens
        : []"
      :key="index"
    >
      <van-col span="11"
        >时间段 <span>{{ item.time }}</span></van-col
      >
      <van-col span="11"
        >数值 <span>{{ item.value }}mmol/L</span></van-col
      >
    </van-row>
    <div class="setting">最高目标值</div>
    <van-row
      type="flex"
      justify="space-around"
      v-for="(item, index) in loopCurrent.store
        ? loopCurrent.store.Default.target_high
        : []"
      :key="index"
    >
      <van-col span="11"
        >时间段 <span>{{ item.time }}</span></van-col
      >
      <van-col span="11"
        >数值 <span>{{ item.value }}mmol/L</span></van-col
      >
    </van-row>
    <div class="setting">最低目标值</div>
    <van-row
      type="flex"
      justify="space-around"
      v-for="(item, index) in loopCurrent.store
        ? loopCurrent.store.Default.target_low
        : []"
      :key="index"
    >
      <van-col span="11"
        >时间段 <span>{{ item.time }}</span></van-col
      >
      <van-col span="11"
        >数值 <span>{{ item.value }}mmol/L</span></van-col
      >
    </van-row>
  </div>
</template>

<script>
import { loopDevicestatus, loopCurrent } from "@/api/relationship";
export default {
  data() {
    return {
      loopStatus: {},
      loopCurrent: {},
      loading: false,
    };
  },
  mounted() {
    this.getLoopStatus();
    this.getLoopCurrent();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 获取闭环设备信息
    getLoopStatus() {
      loopDevicestatus({ uid: this.$route.query.id }).then((res) => {
        this.loopStatus = res.data;
      });
    },
    getLoopCurrent() {
      this.loading = true;
      loopCurrent({ uid: this.$route.query.id }).then((res) => {
        this.loopCurrent = res.data;
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.container {
  padding: 50px 10px 100px;
}
.van-col {
  height: 3rem;
  line-height: 3rem;
  background: #d3eeff;
  margin-bottom: 5px;
  border-radius: 5px;
}
span {
  background: cadetblue;
  color: #fff;
  padding: 4px;
  border-radius: 5px;
  margin: 0 2px;
}
img {
  vertical-align: middle;
}
.title {
  font-size: 16px;
  padding: 4px;
  margin: 8px;
  text-align: left;
  color: #0286f0;
  border-left: 4px #0286f0 solid;
}
.setting {
  font-size: 15px;
  margin: 10px;
}
</style>
