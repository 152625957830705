<template>
    <div id="addCalibration">
        <div style="height: 48px;line-height: 48px;font-size: 1.6rem;font-weight: bold;">添加校准</div>
        <div style="height: 12px;line-height: 12px;">
            <span style="color:#7accfe"><i class="el-icon-warning-outline"></i></span> <span style="font-size: 0.8rem;">校准功能仅作为血糖数据对比，不得用于任何诊断或治疗目的</span>
        </div>
        <el-divider></el-divider>
        <div style="line-height: 3rem;height: 240px;">
            <div style="width: 60%;height: 36px;
            position: absolute;left: 20%;top: 174px;background: rgb(53 176 255 / 20%);
            border-radius: 12px;z-index: 9;"></div>
            <van-picker
            :columns="columns"
            item-height="3rem"
            :default-index="40"
            @change="onChange"
            />
        </div>
        <div>
            <el-button style="width: 94%;margin-left: 3%;height: 48px;background: #7ccdff;border-radius: 16px;font-size: 1.6rem;color:#fff" @click="saveSelectedColums">保 存</el-button>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant'
export default {
  name: 'addCalibration',
  data () {
    return {
      selectedColumns: '0.0mmol/L',
      temp: '0.0mmol/L',
      columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州']
    }
  },
  mounted () {
    this.setColumnsValue()
  },
  methods: {
    setColumnsValue () {
      this.columns = []
      for (let unit = -4; unit < 4; unit++) {
        for (let decimal = 0; decimal <= 9; decimal++) {
          const str = unit + '.' + decimal + 'mmol/L'
          this.columns.push(str)
        }
      }
    },
    onChange (picker, value, index) {
      this.temp = value
      console.log('校准值为', value)
    },
    saveSelectedColums () {
      this.selectedColumns = this.temp
      Toast(this.selectedColumns)
    }
  }
}
</script>
<style scoped>
#addCalibration {
    height: 30rem;
}
.el-divider--horizontal {
    margin:12px 0 !important
}
</style>
<style>
.van-hairline-unset--top-bottom {
    display: none !important;
}
</style>
