<template>
  <div id="QRCode">
    <div style="width: 80vw;height: 80vw;">
      <div style="height: 30vw;
    width: 30vw;
    margin-left: 10vw;
    margin-top: 10vw;
    float: left;">
        <img style="width: 30vw;border-radius: 15vw;
    height: 30vw;" :src="basicInfo.imageUrl">
      </div>
      <div style="    height: 30vw;
    width: 30vw;
    margin-top: 10vw;
    float: left;">
        <div style="height: 15vw;
    line-height: 20vw;
    text-align: left;
    font-size: 1.6rem;
    font-weight: bold;
    color: rgb(0, 0, 0);
    margin-left: 5vw;">{{ basicInfo.label }}</div>
        <div style="height: 5vw;
    line-height: 5vw;
    text-align: left;
    font-size: 1.2rem;
    margin-left: 5vw; ">{{ basicInfo.role }}</div>
      </div>
    </div>
    <canvas id="canvas"></canvas>
    <div style="width: 80vw;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    margin-top: 50vw;">扫一扫</div>
    <div v-if="basicInfo.role == '医生' || basicInfo.role == '护士' || basicInfo.role == '科室主任'" style="width: 80vw;
    height: 24px;
    line-height: 24px;
    font-size: 14px;">加入到该医生名下</div>
    <div v-if="basicInfo.role == '患者'" style="width: 80vw;
    height: 24px;
    line-height: 24px;
    font-size: 14px;">添加该患者到自己名下</div>
  </div>
</template>
<script>
import QRCode from 'qrcode'
export default {
  props: {
    basicInfo: {
      type: Object,
      default: function () {
        return { id: null, label: '---null', role: '' }
      }
    }
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {
    useqrcode (contentString) {
      const canvas = document.getElementById('canvas')
      const content = 'http://192.168.110.191:8080/appendUser?' + contentString
      console.log(content)
      QRCode.toCanvas(canvas, content, function (error) {
        if (error) {
          console.error(error)
        } else {
          console.log('生成二维码成功!')
        }
      })
    }
  }
}
</script>
<style scoped>
#QRCode {
  margin: auto;
  border-radius: 12px;
  margin-top: 40px;
  width: 80vw;
  height: 150vw;
  background: #fff;
  border: 1px solid #9e9e9e;
  position: relative;
  box-shadow: 1px 2px 2px 1px #000;
}

#canvas {
  width: 80vw !important;
  height: 80vw !important;
  position: absolute;
  top: 50vw;
  right: 0;
  border-radius: 14px;
}
</style>
