<template>
  <div v-loading="loading">
    <van-nav-bar
      title="患者列表"
      left-arrow
      left-text="返回"
      fixed
      @click-left="goBackMineView"
    />
    <div
      id="card"
      :style="cardStyle"
      v-if="detailList && detailList.length !== 0"
    >
      <div
        class="main"
        v-for="item in detailList"
        :key="item.id"
        @click="toInfo(item)"
      >
        <img
          class="img"
          :src="
            item.images
              ? item.images
              : 'https://i.postimg.cc/2SrXwLXR/litangbao.png'
          "
          :style="imgStyle"
        />
        <div class="text">
          <label class="title" :style="titleStyle"
            >{{ item.showname ? item.showname : item.name }}
          </label>
          <label class="detail">手机号：{{ item.iphone }}</label>
        </div>
        <div class="right-button">
          <van-button type="info" v-if="item.followStatus" disabled
            >已关注</van-button
          >
          <van-button type="info" v-else @click.stop="follow(item)"
            >关注</van-button
          >
        </div>
      </div>
    </div>
    <noData v-else></noData>
  </div>
</template>

<script>
import {
  getPatientByStructId,
  insertRelation,
  selectbymanageid,
} from "../../../api/relationship";
import noData from "@/components/noData.vue";
import { Notify } from "vant";
export default {
  name: "Card",
  components: {
    noData,
  },
  data() {
    return {
      loading: false,
      ht: this.height,
      cardStyle: "",
      titleColor: this.color,
      titleStyle: "",
      imgStyle: "",
      sugar: "",
      detailList: [],
      timer: null,
      followList: [],
    };
  },
  created() {
    this.getSugerDetail();
  },
  mounted() {
    this.cardStyle = `width: 95%; height: ${this.ht}px;padding: 4rem 0 8rem 0;`;
    this.imgStyle = `width: ${this.ht - 20}px; height: ${this.ht - 20}px;`;
    this.titleStyle = `color: ${this.titleColor}`;
  },
  methods: {
    goBackMineView() {
      this.$router.go(-1);
    },
    toInfo(item) {
      this.$router.push({
        path: "/followView",
        query: {
          id: item.patient_id,
          cgm: item.cgm,
          fromPatients: 1,
          targetLow: item.target_low ? item.target_low : "3.9",
          targetHigh: item.target_high ? item.target_high : "10",
        },
      });
    },
    follow(item) {
      const appuser = JSON.parse(localStorage.getItem("appuser"));
      let params = {
        bemangeredId: item.patient_id,
        bemangeredName: item.name,
        mangerId: appuser.id,
        mangerName: appuser.name,
        showName: item.showname,
      };
      insertRelation(params).then((res) => {
        if (res.data.code == 200) {
          this.$toast("关注成功");
          this.$router.push("/RecordDetail");
        } else if (res.data.code == 201) {
          this.$toast("已经关注过该用户了");
        } else {
          Notify({ type: "warning", message: res.data.msg });
        }
      });
    },

    getSugerDetail() {
      this.loading = true;
      const appuser = localStorage.getItem("appuser");
      const params = {
        mangerId: JSON.parse(appuser).id,
        status: 1,
      };
      selectbymanageid(params).then((res) => {
        if (res.data.code == 200) {
          this.followList = res.data.data;
        } else {
          this.followList = [];
        }

        this.detailList = [];
        getPatientByStructId({
          structId: this.$route.query.id,
          status: 1,
          page: 1,
          size: 1000,
        }).then((res) => {
          let eventArray = res.data.data.list;
          eventArray.forEach((v) => {
            // v.uid
            this.followList.forEach((v2) => {
              if (v.patient_id == v2.bemangered_id) {
                v.followStatus = true;
              }
            });
          });

          this.detailList = eventArray;

          this.loading = false;
        });
      });
    },
  },
};
</script>

<style scoped>
#card {
  min-height: 100vh;
}
.main {
  position: relative;
  width: 100%;
  height: 100px;
  background-color: white;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  border-radius: 10px;
  margin: 10px;
}
.main:hover {
  background: rgba(255, 255, 255, 0.65);
  transform: scale(102%, 102%);
}
.img {
  position: relative;
  width: 64px !important;
  height: 64px !important;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 20px;
  float: left;
}
.text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  margin-left: 30%;
}
.title {
  display: block;
  font-size: 18px;
  margin: 5px;
  font-weight: bold;
  /* text-shadow: 1px 1px 1px grey; */
}
.detail {
  display: block;
  font-size: 15px;
  display: block;
  margin-top: 22px;
  color: #7b7b7b;
}
.sugar {
  color: #7b7b7b;
  font-size: 16px;
  text-shadow: none;
  margin-left: 2rem;
}
.sugarNum {
  font-size: 24px;
  position: absolute;
  right: 50px;
}
.sugarJian {
  font-size: 24px;
  position: absolute;
  right: 20px;
}

.right-button {
  position: absolute;
  right: 10px;
  top: 28px;
}
.van-button {
  height: 40px;
  border-radius: 6px;
}
</style>
