<template>
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <div class="container">
      <van-nav-bar title="血糖关注" @click-right="onAdd" class="van-bar">
        <template #right>
          <van-icon name="friends-o" @click="onAdd" size="26" />
        </template>
      </van-nav-bar>
      <!-- <van-contact-card add-text="添加关注" @click="onAdd"> </van-contact-card> -->
      <div class="sweep" @click="onAdd">
        <img src="../../static/pic/follow.png" alt="" />
        <div class="title">添加关注</div>
        <van-icon name="arrow" class="arrow" size="24" />
      </div>
      <!-- <div class="sweep" @click="noFollow">
        <img src="../../static/pic/nofollow.png" alt="" />
        <div class="title">未关注列表</div>
        <van-icon name="arrow" class="arrow" size="24" />
      </div> -->
      <!-- <div class="sweep" @click="goHospital">
      <img src="../../static/pic/myhospital.png" alt="" />
      <div class="title">我的医院</div>
      <van-icon name="arrow" class="arrow" size="24" />
    </div> -->

      <div class="tip" v-show="hospitalList.length > 0">我的医院</div>
      <div
        class="my-hospital"
        v-for="(item, index) in hospitalList"
        :key="index"
        @click="goPatients(item.struct_id)"
      >
        <img src="../../static/pic/hospital.png" alt="" />
        {{ item.struct_name }}
      </div>

      <div class="tip">已关注</div>
      <div v-loading="loading">
        <recordCard
          :detail="patientList"
          :loading="loading"
          @followView="followView"
          @onRefresh="onRefresh"
        />
      </div>
    </div>
  </van-pull-refresh>
</template>

<script>
import recordCard from "./recordCard.vue";
import {
  selectbymanageid,
  getHospitalByDoctor,
  getLatestBGAll,
} from "../../api/relationship";
export default {
  components: {
    recordCard,
  },
  data() {
    return {
      hospitalList: [],
      patientList: [],
      idArray: [],
      timer: null,
      loading: false,
      isLoading: false,
    };
  },
  created() {
    this.getRecordList();
    this.getHospitals();
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getRecordList();
    }, 2 * 60 * 1000);
    this.$once("hook:beforeDestroy", () => {
      // 页面关闭
      console.log("关闭定时器");
      clearInterval(this.timer); // 停止
    });
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.getRecordList();
      this.getHospitals();
    },
    // 我的医院列表
    getHospitals() {
      const appuser = localStorage.getItem("appuser");
      const params = {
        doctorId: JSON.parse(appuser).id,
        status: 1,
      };
      getHospitalByDoctor(params).then((res) => {
        this.isLoading = false;
        if (res.data.code == 200) {
          this.hospitalList = res.data.data;
        }
      });
    },
    // 医院下患者列表
    goPatients(structId) {
      this.$router.push({
        path: "/myPatients",
        query: { id: structId },
      });
    },
    getRecordList() {
      this.patientList = [];
      this.idArray = [];
      const appuser = localStorage.getItem("appuser");
      const params = {
        mangerId: JSON.parse(appuser).id,
        status: 1,
      };
      selectbymanageid(params).then((res) => {
        if (res.data.code == 200) {
          for (const obj of res.data.data) {
            this.idArray.push(obj.bemangered_id); // 将每个对象的id值添加到新数组中
            const patient = {
              id: obj.id,
              bemangered_id: obj.bemangered_id,
              name:obj.show_name?obj.show_name: obj.name,
              iphone: obj.iphone,
              targetLow: obj.target_low?obj.target_low: '3.9',
              targetHigh: obj.target_high?obj.target_high: '10',
              cgm: obj.cgm,
              images:
                !obj.images || obj.images == "未设置"
                  ? "https://i.postimg.cc/2SrXwLXR/litangbao.png"
                  : obj.images,
            };
            this.patientList.push(patient);
          }
          this.getAllSugar();
        }
      });
    },
    getAllSugar() {
      this.loading = true;
      const params = {
        ls: this.idArray + "",
      };
      getLatestBGAll(params)
        .then((result) => {
          for (let index = 0; index < result.data.data.length; index++) {
            const chartsData = JSON.parse(
              JSON.stringify(result.data.data[index].bg)
            );
            this.patientList[index].bg = chartsData;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$toast("数据获取失败");
        });
    },
    noFollow() {
      this.$router.push("/noFollow");
    },
    goHospital() {
      this.$router.push("/myHospital");
    },
    onAdd() {
      this.$router.push("/recordAdd");
    },
    followView(id, relation_id, cgm, name, targetLow, targetHigh) {
      this.$router.push({
        path: "/followView",
        query: { id: id, relation_id: relation_id, cgm: cgm, name:name, targetLow: targetLow, targetHigh: targetHigh},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding-bottom: 12rem;
  overflow: hidden;
}
.navigation {
  background: #d3eeff;
  width: 100vw;
  height: 4rem;
  line-height: 4rem;
  overflow: hidden;
  z-index: 99;
}
.addicon {
  position: absolute;
  right: 1.5rem;
  font-size: 2rem;
}
.tip {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  text-align: left;
  padding-left: 2rem;
  font-size: 16px;
  font-weight: 700;
}
.van-bar {
  background: #d3eeff;
  height: 4rem;
  line-height: 4rem;
}
::v-deep .van-nav-bar__title {
  color: #7accff;
  font-size: 1.5rem;
}
.sweep {
  color: #000;
  height: 5rem;
  line-height: 4rem;
}
.my-hospital {
  display: flex;
  font-size: 18px;
  margin: 8px;
  height: 4rem;
  line-height: 4rem;
  font-weight: bold;
  border-bottom: 1px solid #ebedf0;
  padding-bottom: 1rem;
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
</style>
