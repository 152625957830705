<template>
  <div class="container">
    <van-nav-bar
      title="我的医院"
      left-arrow
      left-text="返回"
      @click-left="goBackMineView"
    />
    <div v-if="currentContact && currentContact.length !== 0">
      <myHospitalCard :detail="currentContact" @followView="followView" />
    </div>
    <noData v-else></noData>
  </div>
</template>

<script>
import myHospitalCard from "./myHospitalCard.vue";
import { getHospitalById } from "../../../api/relationship";
import noData from "@/components/noData.vue";
export default {
  components: {
    myHospitalCard,
    noData,
  },
  data() {
    return {
      currentContact: [],
    };
  },
  created() {
    this.getRecordList();
  },
  mounted() {},
  methods: {
    goBackMineView() {
      this.$router.go(-1);
    },
    getRecordList() {
      const appuser = localStorage.getItem("appuser");
      getHospitalById(JSON.parse(appuser).id).then((res) => {
        if (res.data.code == 200) {
          console.log(res, "ressssssss");
          // this.currentContact = res.data.data
          this.currentContact = [
            {
              structId: "0459848625724017a3c7cd8291288af1",
              province: "北京市",
              city: "北京市",
              region: "东城区",
              structName: "1",
              department: "2",
              createId: "3",
              adminId: null,
              createTime: "2023-08-25 09:35:23",
              status: "1",
            },
          ];
        }
      });
    },
    followView(structId) {
      this.$router.push({
        path: "/myPatients",
        query: { id: structId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding-bottom: 8rem;
  overflow: hidden;
}
.navigation {
  background: #d3eeff;
  width: 100vw;
  height: 4rem;
  line-height: 4rem;
  overflow: hidden;
  z-index: 99;
}
.addicon {
  position: absolute;
  right: 1.5rem;
  font-size: 2rem;
}
.tip {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  text-align: left;
  padding-left: 2rem;
  font-size: 16px;
  font-weight: 700;
}
.van-bar {
  background: #d3eeff;
  height: 4rem;
  line-height: 4rem;
}
.sweep {
  color: #000;
  height: 5rem;
  line-height: 4rem;
}
</style>
