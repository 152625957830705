<template>
  <div id="PersonnelManagement" class="flow-container">

    <!-- 顶部栏 -->
    <van-nav-bar
      :title="'我的患者(' + patientsList.length + ')'"
      left-arrow
      left-text="返回"
      fixed
      @click-left="goBackBlood"
    />

    <!-- 患者卡片区域 -->
    <div v-loading="loading" style="width: 100%;margin-top: 50px;">
      <div class="personelLabel" v-for="item in patientsList" :key="item.id">
        <div class="patientImage flml12">
          <img class="img" src="../../static/pic//doctor.png" />
        </div>
        <div class="flml12">
          <div style="line-height: 32px; font-size: 1.4rem; font-weight: bold">
            {{ item.showname || item.name }}
          </div>
          <div style="line-height: 22px; color: #aaa">TIR优秀率:{{ item.tir?(item.tir * 100).toFixed(0) + '%':'无' }}</div>
        </div>
        <button @click="showPatientDetails(item)">报告</button>
        <button class="lastBtn" @click="toCases(item)">病例</button>
      </div>
    </div>
  </div>
</template>
<script>
import { getPatientByDoctorid } from "@/api/relationship";

export default {
  name: "PersonnelManagement",
  data() {
    return {
      dialogFormVisible: false,
      loading: true,
      showMore: false,
      selectedNav: "1",
      selectedUser: "",
      showQRCCOde: false,
      userOptions: [],
      activeNames: [],
      patientsList: [],
      queryForm: {
        structId: "",
        doctorId: JSON.parse(localStorage.getItem("appuser")).id,
        status: 1,
        page: 1,
        size: 10000,
      },
    };
  },
  mounted() {
    // window.waitIOSCamera = this.waitIOSCamera
    // this.findUserBymanageid()
    this.getPatient();
  },
  methods: {

    goBackBlood() {
      this.$router.push("/hospitalList");
    },
    showPatientDetails(item) {
      this.$router.push({
        path: "/ReportCenter",
        query: item,
      });
    },
    getPatient() {
      this.queryForm.structId = this.$route.query.structId;
      this.loading = true;
      getPatientByDoctorid(this.queryForm).then(({ data }) => {
        console.log(data, "data");
        if (data.code == 200) {
          this.patientsList = data.data.list;
          this.loading = false;
        }
      });
    },
    toCases(item) {
      this.$router.push({
        path: '/patientCases',
        query: {
          structId: item.struct_id,
          patientId: item.patient_id,
          name: item.name
        }
      })
    }

  },
};
</script>
<style scoped lang="less">
#PersonnelManagement {
  width: 100vw;
  height: 100vh;
  background: #fff;
  position: fixed;
  z-index: 100;
  overflow: auto;
}

.personelLabel {
  position: relative;
  margin: 10px auto;
  padding-top: 6px;
  width: 95%;
  background: #fff;
  text-align: left;
  line-height: 64px;
  height: 72px;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  border-radius: 6px;
  .img {
    width: 5rem;
    height: 5rem;
  }
  button {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 50px;
    height: 25px;
    line-height: 0;
    border: 0;
    border-radius: 4px;
    background-color: #7accff;
    color: #fff;
  }
  .lastBtn {
    right: 80px;
  }
}

.flml12 {
  float: left;
  margin-left: 12px;
  margin-top: 4px;
  height: 64px;
}

.unSelectedNav {
  background: #fff;
  color: #000;
}
.patientImage {
  border-radius: 6px;
  overflow: hidden;
  width: 64px;
}
#qrcCode {
  position: absolute;
  top: 240px;
  width: 88px;
  height: 88px;
  margin: auto;
}
</style>
