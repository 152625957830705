<template>
  <div id="ydView">
    <div class="topHeader">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <div>运动打卡</div>
    </div>
    <div class="mainBody">
      <van-cell
        title="运动时间"
        is-link
        :value="selectTime"
        @click="openSelectTime"
      />
      <!-- <van-cell title="结束时间" is-link :value="endTime" @click="openEndTime" /> -->
      <van-field
        label="运动时长"
        input-align="right"
        v-model="endTime"
        placeholder="请输入/分钟"
        style="padding-right: 3rem"
      />
      <van-field
        label="备注"
        input-align="right"
        v-model="notes"
        placeholder="请输入"
        style="padding-right: 3rem"
      />
      <van-field
        v-model="kcal"
        input-align="right"
        type="number"
        label="消耗卡路里（kcal）"
        placeholder="请输入"
        style="padding-right: 3rem"
      />
    </div>
    <el-col :offset="2" :span="20">
      <el-button
        @click="addyd"
        style="
          width: 100%;
          color: #fff;
          background: #00dac4;
          border-radius: 1rem;
          margin-bottom: 6rem;
          height: 3rem;
        "
      >
        完成
      </el-button></el-col
    >
    <van-popup
      v-model="showTimeSelect"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择完整时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="saveTime"
        :formatter="formatter"
      />
    </van-popup>
    <!-- <van-popup v-model="showEndTime" position="bottom" :style="{ height: '40%' }">
            <van-datetime-picker v-model="EndDate" type="datetime" title="选择完整时间" :min-date="minDate"
                :max-date="maxDate" @confirm="saveEnd" :formatter="formatter" />
        </van-popup> -->
  </div>
</template>
<script>
import { addEvent } from "@/api/userServices";
export default {
  name: "ydView",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      notes: "",
      kcal: "",
      showTimeSelect: false,
      showEndTime: false,
      selectTime: this.getFormatDate(new Date()),
      // endTime: this.getFormatDate(new Date()),
      endTime: "",
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      EndDate: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    goBack() {
      this.$router.push("/login");
    },
    openSelectTime() {
      this.showTimeSelect = true;
    },
    openEndTime() {
      this.showEndTime = true;
    },
    saveTime(value) {
      this.selectTime = this.getFormatDate(value);
      this.showTimeSelect = false;
    },
    saveEnd(value) {
      this.endTime = this.getFormatDate(value);
      this.showEndTime = false;
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    getFormatDate(time) {
      const date = new Date(time);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hour = hour < 10 ? "0" + hour : hour;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      const currentDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds;
      return currentDate;
    },
    addyd() {
      const params = {
        type: "yd",
        createTime: this.selectTime,
        scene: this.endTime,
        way: this.notes,
        slopeOrdinal:
          localStorage.getItem("slopeOrdinal") == "undefined" ||
          !localStorage.getItem("slopeOrdinal")
            ? 3
            : localStorage.getItem("slopeOrdinal"),
        value: localStorage.getItem("bloodSuger")
          ? localStorage.getItem("bloodSuger")
          : "5",
        eventValue: this.kcal,
      };

      addEvent(params).then((res) => {
        if (res.data.code === "200") {
          this.$message.success("添加成功!");
          this.$router.go(-1);
        } else {
          this.$message.error("添加失败，请输入完整信息！");
        }
      });
      // }
    },
  },
};
</script>
<style scoped>
#ydView {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #fff;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 100;
}

.topHeader {
  height: 4rem;
  line-height: 4rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
}

.mainBody {
  text-align: left;
  /* font-weight: bolder; */
  font-size: 14px;
  color: black;
}
.el-icon-arrow-left {
  position: absolute;
  top: 0;
  left: 24px;
  line-height: 4rem;
  font-size: 2rem;
}
</style>
