<template>
  <div>
    <van-nav-bar
      title="添加医生"
      left-arrow
      left-text="返回"
      @click-left="goBackMineView"
    />
    <div class="doctor-certification">
      <van-form
        ref="form"
        label-width="120px"
        :model="registerForm"
        style="margin: 10px 0"
      >
        <van-field
          v-model="registerForm.name"
          label="姓名"
          placeholder="请输入姓名"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="registerForm.phone"
          label="联系电话"
          placeholder="请输入联系电话"
          :rules="[
            { required: true },
            { pattern: /^1\d{10}$/, message: '手机号格式不正确' },
          ]"
        />
        <van-field
          v-model="registerForm.password"
          label="密码"
          placeholder="请输入密码"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="role"
          label="身份"
          placeholder="请选择身份"
          :rules="[{ required: true, message: '请选择身份' }]"
          @click="isShow = true"
          readonly
        />
        <van-popup v-model="isShow" position="bottom" :overlay="false">
          <van-picker
            :columns="columns"
            @cancel="onCancel"
            @confirm="onConfirm"
            show-toolbar
          />
        </van-popup>
        <div class="getcode">
          <van-field
            class="code"
            v-model="registerForm.code"
            label="验证码"
            placeholder="请输入验证码"
            :rules="[{ required: true }]"
          />
          <button class="button" type="button" @click="getVerify" ref="code">
            {{ count }}
          </button>
        </div>
      </van-form>
      <van-button type="primary" block @click="submit">添加</van-button>
    </div>
  </div>
</template>

<script>
import {
  addDoctorOrHospital,
  registerUser,
  getCode,
} from "../../../api/relationship";
import { Toast } from "vant";
export default {
  data() {
    return {
      isShow: false,
      role: "",
      columns: [
        { text: "技术", value: "0" },
        { text: "主任", value: "1" },
        { text: "医生", value: "2" },
        { text: "护士", value: "3" },
      ],
      registerForm: {},
      count: "获取验证码",
    };
  },
  methods: {
    onConfirm(value, index) {
      console.log(value, "value");
      this.registerForm.role = value.value;
      this.role = value.text;
      this.isShow = false;
    },
    onCancel() {
      this.isShow = false;
    },
    goBackMineView() {
      this.$router.go(-1);
    },
    getVerify() {
      const regtel = /^1\d{10}$/;
      if (
        !regtel.test(this.registerForm.phone) ||
        this.registerForm.phone.length !== 11
      ) {
        Toast("手机号不正确");
        return;
      }
      getCode(this.registerForm.phone).then(({ data }) => {
        if (data.code == 200) {
          this.count = 60;
          this.$refs.code.disabled = true;
          const timer = setInterval(() => {
            this.count--;
            if (this.count <= 0) {
              this.count = "获取验证码";
              this.$refs.code.disabled = false;
              clearInterval(timer);
            }
          }, 1000);
        }
      });
    },
    submit() {
      if (
        this.registerForm.name &&
        this.registerForm.phone &&
        this.registerForm.password &&
        this.registerForm.role &&
        this.registerForm.code
      ) {
        registerUser(this.registerForm).then(({ data }) => {
          if (data.code == 200 || data.code == 201) {
            const addOption = {
              doctorId: data.data.appuser.id,
              structId: this.$route.query.structId,
              name: this.registerForm.name,
              role: this.registerForm.role,
            };
            addDoctorOrHospital(addOption).then(({ data }) => {
              if (data.code == 200) {
                this.$message.success("添加成功");
                this.$router.go(-1);
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      } else {
        this.$message.error("请检查表单");
      }
    },
  },
};
</script>

<style scoped>
.doctor-certification {
  padding: 0 20px 20px 20px;
}
::v-deep .van-ellipsis {
  height: 16px;
  line-height: 16px;
}
.getcode {
  display: flex;
}
.button {
  width: 100px;
  border: 1px solid #fefefe;
  color: #646566;
  text-align: center;
}
.button:active {
  background-color: #646566;
  color: #fff;
}
/* .button:active {

} */
</style>
