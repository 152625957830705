export const adressList = [
    {
        code: '11',
        text: '北京市',
        children: [
            {
                code: '1101',
                text: '市辖区',
                children: [
                    {
                        code: '110101', text: '东城区'
                    },
                    {
                        code: '110102', text: '西城区'
                    },
                    {
                        code: '110105', text: '朝阳区'
                    },
                    {
                        code: '110106', text: '丰台区'
                    },
                    {
                        code: '110107', text: '石景山区'
                    },
                    {
                        code: '110108', text: '海淀区'
                    },
                    {
                        code: '110109', text: '门头沟区'
                    },
                    {
                        code: '110111', text: '房山区'
                    },
                    {
                        code: '110112', text: '通州区'
                    },
                    {
                        code: '110113', text: '顺义区'
                    },
                    {
                        code: '110114', text: '昌平区'
                    },
                    {
                        code: '110115', text: '大兴区'
                    },
                    {
                        code: '110116', text: '怀柔区'
                    },
                    {
                        code: '110117', text: '平谷区'
                    },
                    {
                        code: '110118', text: '密云区'
                    },
                    {
                        code: '110119', text: '延庆区'
                    }
                ]
            }
        ]
    },
    {
        code: '12',
        text: '天津市',
        children: [
            {
                code: '1201',
                text: '市辖区',
                children: [
                    {
                        code: '120101', text: '和平区'
                    },
                    {
                        code: '120102', text: '河东区'
                    },
                    {
                        code: '120103', text: '河西区'
                    },
                    {
                        code: '120104', text: '南开区'
                    },
                    {
                        code: '120105', text: '河北区'
                    },
                    {
                        code: '120106', text: '红桥区'
                    },
                    {
                        code: '120110', text: '东丽区'
                    },
                    {
                        code: '120111', text: '西青区'
                    },
                    {
                        code: '120112', text: '津南区'
                    },
                    {
                        code: '120113', text: '北辰区'
                    },
                    {
                        code: '120114', text: '武清区'
                    },
                    {
                        code: '120115', text: '宝坻区'
                    },
                    {
                        code: '120116', text: '滨海新区'
                    },
                    {
                        code: '120117', text: '宁河区'
                    },
                    {
                        code: '120118', text: '静海区'
                    },
                    {
                        code: '120119', text: '蓟州区'
                    }
                ]
            }
        ]
    },
    {
        code: '13',
        text: '河北省',
        children: [
            {
                code: '1301',
                text: '石家庄市',
                children: [
                    {
                        code: '130102', text: '长安区'
                    },
                    {
                        code: '130104', text: '桥西区'
                    },
                    {
                        code: '130105', text: '新华区'
                    },
                    {
                        code: '130107', text: '井陉矿区'
                    },
                    {
                        code: '130108', text: '裕华区'
                    },
                    {
                        code: '130109', text: '藁城区'
                    },
                    {
                        code: '130110', text: '鹿泉区'
                    },
                    {
                        code: '130111', text: '栾城区'
                    },
                    {
                        code: '130121', text: '井陉县'
                    },
                    {
                        code: '130123', text: '正定县'
                    },
                    {
                        code: '130125', text: '行唐县'
                    },
                    {
                        code: '130126', text: '灵寿县'
                    },
                    {
                        code: '130127', text: '高邑县'
                    },
                    {
                        code: '130128', text: '深泽县'
                    },
                    {
                        code: '130129', text: '赞皇县'
                    },
                    {
                        code: '130130', text: '无极县'
                    },
                    {
                        code: '130131', text: '平山县'
                    },
                    {
                        code: '130132', text: '元氏县'
                    },
                    {
                        code: '130133', text: '赵县'
                    },
                    {
                        code: '130171', text: '石家庄高新技术产业开发区'
                    },
                    {
                        code: '130172', text: '石家庄循环化工园区'
                    },
                    {
                        code: '130181', text: '辛集市'
                    },
                    {
                        code: '130183', text: '晋州市'
                    },
                    {
                        code: '130184', text: '新乐市'
                    }
                ]
            },
            {
                code: '1302',
                text: '唐山市',
                children: [
                    {
                        code: '130202', text: '路南区'
                    },
                    {
                        code: '130203', text: '路北区'
                    },
                    {
                        code: '130204', text: '古冶区'
                    },
                    {
                        code: '130205', text: '开平区'
                    },
                    {
                        code: '130207', text: '丰南区'
                    },
                    {
                        code: '130208', text: '丰润区'
                    },
                    {
                        code: '130209', text: '曹妃甸区'
                    },
                    {
                        code: '130223', text: '滦县'
                    },
                    {
                        code: '130224', text: '滦南县'
                    },
                    {
                        code: '130225', text: '乐亭县'
                    },
                    {
                        code: '130227', text: '迁西县'
                    },
                    {
                        code: '130229', text: '玉田县'
                    },
                    {
                        code: '130271', text: '唐山市芦台经济技术开发区'
                    },
                    {
                        code: '130272', text: '唐山市汉沽管理区'
                    },
                    {
                        code: '130273', text: '唐山高新技术产业开发区'
                    },
                    {
                        code: '130274', text: '河北唐山海港经济开发区'
                    },
                    {
                        code: '130281', text: '遵化市'
                    },
                    {
                        code: '130283', text: '迁安市'
                    }
                ]
            },
            {
                code: '1303',
                text: '秦皇岛市',
                children: [
                    {
                        code: '130302', text: '海港区'
                    },
                    {
                        code: '130303', text: '山海关区'
                    },
                    {
                        code: '130304', text: '北戴河区'
                    },
                    {
                        code: '130306', text: '抚宁区'
                    },
                    {
                        code: '130321', text: '青龙满族自治县'
                    },
                    {
                        code: '130322', text: '昌黎县'
                    },
                    {
                        code: '130324', text: '卢龙县'
                    },
                    {
                        code: '130371', text: '秦皇岛市经济技术开发区'
                    },
                    {
                        code: '130372', text: '北戴河新区'
                    }
                ]
            },
            {
                code: '1304',
                text: '邯郸市',
                children: [
                    {
                        code: '130402', text: '邯山区'
                    },
                    {
                        code: '130403', text: '丛台区'
                    },
                    {
                        code: '130404', text: '复兴区'
                    },
                    {
                        code: '130406', text: '峰峰矿区'
                    },
                    {
                        code: '130407', text: '肥乡区'
                    },
                    {
                        code: '130408', text: '永年区'
                    },
                    {
                        code: '130423', text: '临漳县'
                    },
                    {
                        code: '130424', text: '成安县'
                    },
                    {
                        code: '130425', text: '大名县'
                    },
                    {
                        code: '130426', text: '涉县'
                    },
                    {
                        code: '130427', text: '磁县'
                    },
                    {
                        code: '130430', text: '邱县'
                    },
                    {
                        code: '130431', text: '鸡泽县'
                    },
                    {
                        code: '130432', text: '广平县'
                    },
                    {
                        code: '130433', text: '馆陶县'
                    },
                    {
                        code: '130434', text: '魏县'
                    },
                    {
                        code: '130435', text: '曲周县'
                    },
                    {
                        code: '130471', text: '邯郸经济技术开发区'
                    },
                    {
                        code: '130473', text: '邯郸冀南新区'
                    },
                    {
                        code: '130481', text: '武安市'
                    }
                ]
            },
            {
                code: '1305',
                text: '邢台市',
                children: [
                    {
                        code: '130502', text: '桥东区'
                    },
                    {
                        code: '130503', text: '桥西区'
                    },
                    {
                        code: '130521', text: '邢台县'
                    },
                    {
                        code: '130522', text: '临城县'
                    },
                    {
                        code: '130523', text: '内丘县'
                    },
                    {
                        code: '130524', text: '柏乡县'
                    },
                    {
                        code: '130525', text: '隆尧县'
                    },
                    {
                        code: '130526', text: '任县'
                    },
                    {
                        code: '130527', text: '南和县'
                    },
                    {
                        code: '130528', text: '宁晋县'
                    },
                    {
                        code: '130529', text: '巨鹿县'
                    },
                    {
                        code: '130530', text: '新河县'
                    },
                    {
                        code: '130531', text: '广宗县'
                    },
                    {
                        code: '130532', text: '平乡县'
                    },
                    {
                        code: '130533', text: '威县'
                    },
                    {
                        code: '130534', text: '清河县'
                    },
                    {
                        code: '130535', text: '临西县'
                    },
                    {
                        code: '130571', text: '河北邢台经济开发区'
                    },
                    {
                        code: '130581', text: '南宫市'
                    },
                    {
                        code: '130582', text: '沙河市'
                    }
                ]
            },
            {
                code: '1306',
                text: '保定市',
                children: [
                    {
                        code: '130602', text: '竞秀区'
                    },
                    {
                        code: '130606', text: '莲池区'
                    },
                    {
                        code: '130607', text: '满城区'
                    },
                    {
                        code: '130608', text: '清苑区'
                    },
                    {
                        code: '130609', text: '徐水区'
                    },
                    {
                        code: '130623', text: '涞水县'
                    },
                    {
                        code: '130624', text: '阜平县'
                    },
                    {
                        code: '130626', text: '定兴县'
                    },
                    {
                        code: '130627', text: '唐县'
                    },
                    {
                        code: '130628', text: '高阳县'
                    },
                    {
                        code: '130629', text: '容城县'
                    },
                    {
                        code: '130630', text: '涞源县'
                    },
                    {
                        code: '130631', text: '望都县'
                    },
                    {
                        code: '130632', text: '安新县'
                    },
                    {
                        code: '130633', text: '易县'
                    },
                    {
                        code: '130634', text: '曲阳县'
                    },
                    {
                        code: '130635', text: '蠡县'
                    },
                    {
                        code: '130636', text: '顺平县'
                    },
                    {
                        code: '130637', text: '博野县'
                    },
                    {
                        code: '130638', text: '雄县'
                    },
                    {
                        code: '130671', text: '保定高新技术产业开发区'
                    },
                    {
                        code: '130672', text: '保定白沟新城'
                    },
                    {
                        code: '130681', text: '涿州市'
                    },
                    {
                        code: '130682', text: '定州市'
                    },
                    {
                        code: '130683', text: '安国市'
                    },
                    {
                        code: '130684', text: '高碑店市'
                    }
                ]
            },
            {
                code: '1307',
                text: '张家口市',
                children: [
                    {
                        code: '130702', text: '桥东区'
                    },
                    {
                        code: '130703', text: '桥西区'
                    },
                    {
                        code: '130705', text: '宣化区'
                    },
                    {
                        code: '130706', text: '下花园区'
                    },
                    {
                        code: '130708', text: '万全区'
                    },
                    {
                        code: '130709', text: '崇礼区'
                    },
                    {
                        code: '130722', text: '张北县'
                    },
                    {
                        code: '130723', text: '康保县'
                    },
                    {
                        code: '130724', text: '沽源县'
                    },
                    {
                        code: '130725', text: '尚义县'
                    },
                    {
                        code: '130726', text: '蔚县'
                    },
                    {
                        code: '130727', text: '阳原县'
                    },
                    {
                        code: '130728', text: '怀安县'
                    },
                    {
                        code: '130730', text: '怀来县'
                    },
                    {
                        code: '130731', text: '涿鹿县'
                    },
                    {
                        code: '130732', text: '赤城县'
                    },
                    {
                        code: '130771', text: '张家口市高新技术产业开发区'
                    },
                    {
                        code: '130772', text: '张家口市察北管理区'
                    },
                    {
                        code: '130773', text: '张家口市塞北管理区'
                    }
                ]
            },
            {
                code: '1308',
                text: '承德市',
                children: [
                    {
                        code: '130802', text: '双桥区'
                    },
                    {
                        code: '130803', text: '双滦区'
                    },
                    {
                        code: '130804', text: '鹰手营子矿区'
                    },
                    {
                        code: '130821', text: '承德县'
                    },
                    {
                        code: '130822', text: '兴隆县'
                    },
                    {
                        code: '130824', text: '滦平县'
                    },
                    {
                        code: '130825', text: '隆化县'
                    },
                    {
                        code: '130826', text: '丰宁满族自治县'
                    },
                    {
                        code: '130827', text: '宽城满族自治县'
                    },
                    {
                        code: '130828', text: '围场满族蒙古族自治县'
                    },
                    {
                        code: '130871', text: '承德高新技术产业开发区'
                    },
                    {
                        code: '130881', text: '平泉市'
                    }
                ]
            },
            {
                code: '1309',
                text: '沧州市',
                children: [
                    {
                        code: '130902', text: '新华区'
                    },
                    {
                        code: '130903', text: '运河区'
                    },
                    {
                        code: '130921', text: '沧县'
                    },
                    {
                        code: '130922', text: '青县'
                    },
                    {
                        code: '130923', text: '东光县'
                    },
                    {
                        code: '130924', text: '海兴县'
                    },
                    {
                        code: '130925', text: '盐山县'
                    },
                    {
                        code: '130926', text: '肃宁县'
                    },
                    {
                        code: '130927', text: '南皮县'
                    },
                    {
                        code: '130928', text: '吴桥县'
                    },
                    {
                        code: '130929', text: '献县'
                    },
                    {
                        code: '130930', text: '孟村回族自治县'
                    },
                    {
                        code: '130971', text: '河北沧州经济开发区'
                    },
                    {
                        code: '130972', text: '沧州高新技术产业开发区'
                    },
                    {
                        code: '130973', text: '沧州渤海新区'
                    },
                    {
                        code: '130981', text: '泊头市'
                    },
                    {
                        code: '130982', text: '任丘市'
                    },
                    {
                        code: '130983', text: '黄骅市'
                    },
                    {
                        code: '130984', text: '河间市'
                    }
                ]
            },
            {
                code: '1310',
                text: '廊坊市',
                children: [
                    {
                        code: '131002', text: '安次区'
                    },
                    {
                        code: '131003', text: '广阳区'
                    },
                    {
                        code: '131022', text: '固安县'
                    },
                    {
                        code: '131023', text: '永清县'
                    },
                    {
                        code: '131024', text: '香河县'
                    },
                    {
                        code: '131025', text: '大城县'
                    },
                    {
                        code: '131026', text: '文安县'
                    },
                    {
                        code: '131028', text: '大厂回族自治县'
                    },
                    {
                        code: '131071', text: '廊坊经济技术开发区'
                    },
                    {
                        code: '131081', text: '霸州市'
                    },
                    {
                        code: '131082', text: '三河市'
                    }
                ]
            },
            {
                code: '1311',
                text: '衡水市',
                children: [
                    {
                        code: '131102', text: '桃城区'
                    },
                    {
                        code: '131103', text: '冀州区'
                    },
                    {
                        code: '131121', text: '枣强县'
                    },
                    {
                        code: '131122', text: '武邑县'
                    },
                    {
                        code: '131123', text: '武强县'
                    },
                    {
                        code: '131124', text: '饶阳县'
                    },
                    {
                        code: '131125', text: '安平县'
                    },
                    {
                        code: '131126', text: '故城县'
                    },
                    {
                        code: '131127', text: '景县'
                    },
                    {
                        code: '131128', text: '阜城县'
                    },
                    {
                        code: '131171', text: '河北衡水经济开发区'
                    },
                    {
                        code: '131172', text: '衡水滨湖新区'
                    },
                    {
                        code: '131182', text: '深州市'
                    }
                ]
            }
        ]
    },
    {
        code: '14',
        text: '山西省',
        children: [
            {
                code: '1401',
                text: '太原市',
                children: [
                    {
                        code: '140105', text: '小店区'
                    },
                    {
                        code: '140106', text: '迎泽区'
                    },
                    {
                        code: '140107', text: '杏花岭区'
                    },
                    {
                        code: '140108', text: '尖草坪区'
                    },
                    {
                        code: '140109', text: '万柏林区'
                    },
                    {
                        code: '140110', text: '晋源区'
                    },
                    {
                        code: '140121', text: '清徐县'
                    },
                    {
                        code: '140122', text: '阳曲县'
                    },
                    {
                        code: '140123', text: '娄烦县'
                    },
                    {
                        code: '140171', text: '山西转型综合改革示范区'
                    },
                    {
                        code: '140181', text: '古交市'
                    }
                ]
            },
            {
                code: '1402',
                text: '大同市',
                children: [
                    {
                        code: '140202', text: '城区'
                    },
                    {
                        code: '140203', text: '矿区'
                    },
                    {
                        code: '140211', text: '南郊区'
                    },
                    {
                        code: '140212', text: '新荣区'
                    },
                    {
                        code: '140221', text: '阳高县'
                    },
                    {
                        code: '140222', text: '天镇县'
                    },
                    {
                        code: '140223', text: '广灵县'
                    },
                    {
                        code: '140224', text: '灵丘县'
                    },
                    {
                        code: '140225', text: '浑源县'
                    },
                    {
                        code: '140226', text: '左云县'
                    },
                    {
                        code: '140227', text: '大同县'
                    },
                    {
                        code: '140271', text: '山西大同经济开发区'
                    }
                ]
            },
            {
                code: '1403',
                text: '阳泉市',
                children: [
                    {
                        code: '140302', text: '城区'
                    },
                    {
                        code: '140303', text: '矿区'
                    },
                    {
                        code: '140311', text: '郊区'
                    },
                    {
                        code: '140321', text: '平定县'
                    },
                    {
                        code: '140322', text: '盂县'
                    },
                    {
                        code: '140371', text: '山西阳泉经济开发区'
                    }
                ]
            },
            {
                code: '1404',
                text: '长治市',
                children: [
                    {
                        code: '140402', text: '城区'
                    },
                    {
                        code: '140411', text: '郊区'
                    },
                    {
                        code: '140421', text: '长治县'
                    },
                    {
                        code: '140423', text: '襄垣县'
                    },
                    {
                        code: '140424', text: '屯留县'
                    },
                    {
                        code: '140425', text: '平顺县'
                    },
                    {
                        code: '140426', text: '黎城县'
                    },
                    {
                        code: '140427', text: '壶关县'
                    },
                    {
                        code: '140428', text: '长子县'
                    },
                    {
                        code: '140429', text: '武乡县'
                    },
                    {
                        code: '140430', text: '沁县'
                    },
                    {
                        code: '140431', text: '沁源县'
                    },
                    {
                        code: '140471', text: '山西长治高新技术产业园区'
                    },
                    {
                        code: '140481', text: '潞城市'
                    }
                ]
            },
            {
                code: '1405',
                text: '晋城市',
                children: [
                    {
                        code: '140502', text: '城区'
                    },
                    {
                        code: '140521', text: '沁水县'
                    },
                    {
                        code: '140522', text: '阳城县'
                    },
                    {
                        code: '140524', text: '陵川县'
                    },
                    {
                        code: '140525', text: '泽州县'
                    },
                    {
                        code: '140581', text: '高平市'
                    }
                ]
            },
            {
                code: '1406',
                text: '朔州市',
                children: [
                    {
                        code: '140602', text: '朔城区'
                    },
                    {
                        code: '140603', text: '平鲁区'
                    },
                    {
                        code: '140621', text: '山阴县'
                    },
                    {
                        code: '140622', text: '应县'
                    },
                    {
                        code: '140623', text: '右玉县'
                    },
                    {
                        code: '140624', text: '怀仁县'
                    },
                    {
                        code: '140671', text: '山西朔州经济开发区'
                    }
                ]
            },
            {
                code: '1407',
                text: '晋中市',
                children: [
                    {
                        code: '140702', text: '榆次区'
                    },
                    {
                        code: '140721', text: '榆社县'
                    },
                    {
                        code: '140722', text: '左权县'
                    },
                    {
                        code: '140723', text: '和顺县'
                    },
                    {
                        code: '140724', text: '昔阳县'
                    },
                    {
                        code: '140725', text: '寿阳县'
                    },
                    {
                        code: '140726', text: '太谷县'
                    },
                    {
                        code: '140727', text: '祁县'
                    },
                    {
                        code: '140728', text: '平遥县'
                    },
                    {
                        code: '140729', text: '灵石县'
                    },
                    {
                        code: '140781', text: '介休市'
                    }
                ]
            },
            {
                code: '1408',
                text: '运城市',
                children: [
                    {
                        code: '140802', text: '盐湖区'
                    },
                    {
                        code: '140821', text: '临猗县'
                    },
                    {
                        code: '140822', text: '万荣县'
                    },
                    {
                        code: '140823', text: '闻喜县'
                    },
                    {
                        code: '140824', text: '稷山县'
                    },
                    {
                        code: '140825', text: '新绛县'
                    },
                    {
                        code: '140826', text: '绛县'
                    },
                    {
                        code: '140827', text: '垣曲县'
                    },
                    {
                        code: '140828', text: '夏县'
                    },
                    {
                        code: '140829', text: '平陆县'
                    },
                    {
                        code: '140830', text: '芮城县'
                    },
                    {
                        code: '140881', text: '永济市'
                    },
                    {
                        code: '140882', text: '河津市'
                    }
                ]
            },
            {
                code: '1409',
                text: '忻州市',
                children: [
                    {
                        code: '140902', text: '忻府区'
                    },
                    {
                        code: '140921', text: '定襄县'
                    },
                    {
                        code: '140922', text: '五台县'
                    },
                    {
                        code: '140923', text: '代县'
                    },
                    {
                        code: '140924', text: '繁峙县'
                    },
                    {
                        code: '140925', text: '宁武县'
                    },
                    {
                        code: '140926', text: '静乐县'
                    },
                    {
                        code: '140927', text: '神池县'
                    },
                    {
                        code: '140928', text: '五寨县'
                    },
                    {
                        code: '140929', text: '岢岚县'
                    },
                    {
                        code: '140930', text: '河曲县'
                    },
                    {
                        code: '140931', text: '保德县'
                    },
                    {
                        code: '140932', text: '偏关县'
                    },
                    {
                        code: '140971', text: '五台山风景名胜区'
                    },
                    {
                        code: '140981', text: '原平市'
                    }
                ]
            },
            {
                code: '1410',
                text: '临汾市',
                children: [
                    {
                        code: '141002', text: '尧都区'
                    },
                    {
                        code: '141021', text: '曲沃县'
                    },
                    {
                        code: '141022', text: '翼城县'
                    },
                    {
                        code: '141023', text: '襄汾县'
                    },
                    {
                        code: '141024', text: '洪洞县'
                    },
                    {
                        code: '141025', text: '古县'
                    },
                    {
                        code: '141026', text: '安泽县'
                    },
                    {
                        code: '141027', text: '浮山县'
                    },
                    {
                        code: '141028', text: '吉县'
                    },
                    {
                        code: '141029', text: '乡宁县'
                    },
                    {
                        code: '141030', text: '大宁县'
                    },
                    {
                        code: '141031', text: '隰县'
                    },
                    {
                        code: '141032', text: '永和县'
                    },
                    {
                        code: '141033', text: '蒲县'
                    },
                    {
                        code: '141034', text: '汾西县'
                    },
                    {
                        code: '141081', text: '侯马市'
                    },
                    {
                        code: '141082', text: '霍州市'
                    }
                ]
            },
            {
                code: '1411',
                text: '吕梁市',
                children: [
                    {
                        code: '141102', text: '离石区'
                    },
                    {
                        code: '141121', text: '文水县'
                    },
                    {
                        code: '141122', text: '交城县'
                    },
                    {
                        code: '141123', text: '兴县'
                    },
                    {
                        code: '141124', text: '临县'
                    },
                    {
                        code: '141125', text: '柳林县'
                    },
                    {
                        code: '141126', text: '石楼县'
                    },
                    {
                        code: '141127', text: '岚县'
                    },
                    {
                        code: '141128', text: '方山县'
                    },
                    {
                        code: '141129', text: '中阳县'
                    },
                    {
                        code: '141130', text: '交口县'
                    },
                    {
                        code: '141181', text: '孝义市'
                    },
                    {
                        code: '141182', text: '汾阳市'
                    }
                ]
            }
        ]
    },
    {
        code: '15',
        text: '内蒙古自治区',
        children: [
            {
                code: '1501',
                text: '呼和浩特市',
                children: [
                    {
                        code: '150102', text: '新城区'
                    },
                    {
                        code: '150103', text: '回民区'
                    },
                    {
                        code: '150104', text: '玉泉区'
                    },
                    {
                        code: '150105', text: '赛罕区'
                    },
                    {
                        code: '150121', text: '土默特左旗'
                    },
                    {
                        code: '150122', text: '托克托县'
                    },
                    {
                        code: '150123', text: '和林格尔县'
                    },
                    {
                        code: '150124', text: '清水河县'
                    },
                    {
                        code: '150125', text: '武川县'
                    },
                    {
                        code: '150171', text: '呼和浩特金海工业园区'
                    },
                    {
                        code: '150172', text: '呼和浩特经济技术开发区'
                    }
                ]
            },
            {
                code: '1502',
                text: '包头市',
                children: [
                    {
                        code: '150202', text: '东河区'
                    },
                    {
                        code: '150203', text: '昆都仑区'
                    },
                    {
                        code: '150204', text: '青山区'
                    },
                    {
                        code: '150205', text: '石拐区'
                    },
                    {
                        code: '150206', text: '白云鄂博矿区'
                    },
                    {
                        code: '150207', text: '九原区'
                    },
                    {
                        code: '150221', text: '土默特右旗'
                    },
                    {
                        code: '150222', text: '固阳县'
                    },
                    {
                        code: '150223', text: '达尔罕茂明安联合旗'
                    },
                    {
                        code: '150271', text: '包头稀土高新技术产业开发区'
                    }
                ]
            },
            {
                code: '1503',
                text: '乌海市',
                children: [
                    {
                        code: '150302', text: '海勃湾区'
                    },
                    {
                        code: '150303', text: '海南区'
                    },
                    {
                        code: '150304', text: '乌达区'
                    }
                ]
            },
            {
                code: '1504',
                text: '赤峰市',
                children: [
                    {
                        code: '150402', text: '红山区'
                    },
                    {
                        code: '150403', text: '元宝山区'
                    },
                    {
                        code: '150404', text: '松山区'
                    },
                    {
                        code: '150421', text: '阿鲁科尔沁旗'
                    },
                    {
                        code: '150422', text: '巴林左旗'
                    },
                    {
                        code: '150423', text: '巴林右旗'
                    },
                    {
                        code: '150424', text: '林西县'
                    },
                    {
                        code: '150425', text: '克什克腾旗'
                    },
                    {
                        code: '150426', text: '翁牛特旗'
                    },
                    {
                        code: '150428', text: '喀喇沁旗'
                    },
                    {
                        code: '150429', text: '宁城县'
                    },
                    {
                        code: '150430', text: '敖汉旗'
                    }
                ]
            },
            {
                code: '1505',
                text: '通辽市',
                children: [
                    {
                        code: '150502', text: '科尔沁区'
                    },
                    {
                        code: '150521', text: '科尔沁左翼中旗'
                    },
                    {
                        code: '150522', text: '科尔沁左翼后旗'
                    },
                    {
                        code: '150523', text: '开鲁县'
                    },
                    {
                        code: '150524', text: '库伦旗'
                    },
                    {
                        code: '150525', text: '奈曼旗'
                    },
                    {
                        code: '150526', text: '扎鲁特旗'
                    },
                    {
                        code: '150571', text: '通辽经济技术开发区'
                    },
                    {
                        code: '150581', text: '霍林郭勒市'
                    }
                ]
            },
            {
                code: '1506',
                text: '鄂尔多斯市',
                children: [
                    {
                        code: '150602', text: '东胜区'
                    },
                    {
                        code: '150603', text: '康巴什区'
                    },
                    {
                        code: '150621', text: '达拉特旗'
                    },
                    {
                        code: '150622', text: '准格尔旗'
                    },
                    {
                        code: '150623', text: '鄂托克前旗'
                    },
                    {
                        code: '150624', text: '鄂托克旗'
                    },
                    {
                        code: '150625', text: '杭锦旗'
                    },
                    {
                        code: '150626', text: '乌审旗'
                    },
                    {
                        code: '150627', text: '伊金霍洛旗'
                    }
                ]
            },
            {
                code: '1507',
                text: '呼伦贝尔市',
                children: [
                    {
                        code: '150702', text: '海拉尔区'
                    },
                    {
                        code: '150703', text: '扎赉诺尔区'
                    },
                    {
                        code: '150721', text: '阿荣旗'
                    },
                    {
                        code: '150722', text: '莫力达瓦达斡尔族自治旗'
                    },
                    {
                        code: '150723', text: '鄂伦春自治旗'
                    },
                    {
                        code: '150724', text: '鄂温克族自治旗'
                    },
                    {
                        code: '150725', text: '陈巴尔虎旗'
                    },
                    {
                        code: '150726', text: '新巴尔虎左旗'
                    },
                    {
                        code: '150727', text: '新巴尔虎右旗'
                    },
                    {
                        code: '150781', text: '满洲里市'
                    },
                    {
                        code: '150782', text: '牙克石市'
                    },
                    {
                        code: '150783', text: '扎兰屯市'
                    },
                    {
                        code: '150784', text: '额尔古纳市'
                    },
                    {
                        code: '150785', text: '根河市'
                    }
                ]
            },
            {
                code: '1508',
                text: '巴彦淖尔市',
                children: [
                    {
                        code: '150802', text: '临河区'
                    },
                    {
                        code: '150821', text: '五原县'
                    },
                    {
                        code: '150822', text: '磴口县'
                    },
                    {
                        code: '150823', text: '乌拉特前旗'
                    },
                    {
                        code: '150824', text: '乌拉特中旗'
                    },
                    {
                        code: '150825', text: '乌拉特后旗'
                    },
                    {
                        code: '150826', text: '杭锦后旗'
                    }
                ]
            },
            {
                code: '1509',
                text: '乌兰察布市',
                children: [
                    {
                        code: '150902', text: '集宁区'
                    },
                    {
                        code: '150921', text: '卓资县'
                    },
                    {
                        code: '150922', text: '化德县'
                    },
                    {
                        code: '150923', text: '商都县'
                    },
                    {
                        code: '150924', text: '兴和县'
                    },
                    {
                        code: '150925', text: '凉城县'
                    },
                    {
                        code: '150926', text: '察哈尔右翼前旗'
                    },
                    {
                        code: '150927', text: '察哈尔右翼中旗'
                    },
                    {
                        code: '150928', text: '察哈尔右翼后旗'
                    },
                    {
                        code: '150929', text: '四子王旗'
                    },
                    {
                        code: '150981', text: '丰镇市'
                    }
                ]
            },
            {
                code: '1522',
                text: '兴安盟',
                children: [
                    {
                        code: '152201', text: '乌兰浩特市'
                    },
                    {
                        code: '152202', text: '阿尔山市'
                    },
                    {
                        code: '152221', text: '科尔沁右翼前旗'
                    },
                    {
                        code: '152222', text: '科尔沁右翼中旗'
                    },
                    {
                        code: '152223', text: '扎赉特旗'
                    },
                    {
                        code: '152224', text: '突泉县'
                    }
                ]
            },
            {
                code: '1525',
                text: '锡林郭勒盟',
                children: [
                    {
                        code: '152501', text: '二连浩特市'
                    },
                    {
                        code: '152502', text: '锡林浩特市'
                    },
                    {
                        code: '152522', text: '阿巴嘎旗'
                    },
                    {
                        code: '152523', text: '苏尼特左旗'
                    },
                    {
                        code: '152524', text: '苏尼特右旗'
                    },
                    {
                        code: '152525', text: '东乌珠穆沁旗'
                    },
                    {
                        code: '152526', text: '西乌珠穆沁旗'
                    },
                    {
                        code: '152527', text: '太仆寺旗'
                    },
                    {
                        code: '152528', text: '镶黄旗'
                    },
                    {
                        code: '152529', text: '正镶白旗'
                    },
                    {
                        code: '152530', text: '正蓝旗'
                    },
                    {
                        code: '152531', text: '多伦县'
                    },
                    {
                        code: '152571', text: '乌拉盖管委会'
                    }
                ]
            },
            {
                code: '1529',
                text: '阿拉善盟',
                children: [
                    {
                        code: '152921', text: '阿拉善左旗'
                    },
                    {
                        code: '152922', text: '阿拉善右旗'
                    },
                    {
                        code: '152923', text: '额济纳旗'
                    },
                    {
                        code: '152971', text: '内蒙古阿拉善经济开发区'
                    }
                ]
            }
        ]
    },
    {
        code: '21',
        text: '辽宁省',
        children: [
            {
                code: '2101',
                text: '沈阳市',
                children: [
                    {
                        code: '210102', text: '和平区'
                    },
                    {
                        code: '210103', text: '沈河区'
                    },
                    {
                        code: '210104', text: '大东区'
                    },
                    {
                        code: '210105', text: '皇姑区'
                    },
                    {
                        code: '210106', text: '铁西区'
                    },
                    {
                        code: '210111', text: '苏家屯区'
                    },
                    {
                        code: '210112', text: '浑南区'
                    },
                    {
                        code: '210113', text: '沈北新区'
                    },
                    {
                        code: '210114', text: '于洪区'
                    },
                    {
                        code: '210115', text: '辽中区'
                    },
                    {
                        code: '210123', text: '康平县'
                    },
                    {
                        code: '210124', text: '法库县'
                    },
                    {
                        code: '210181', text: '新民市'
                    }
                ]
            },
            {
                code: '2102',
                text: '大连市',
                children: [
                    {
                        code: '210202', text: '中山区'
                    },
                    {
                        code: '210203', text: '西岗区'
                    },
                    {
                        code: '210204', text: '沙河口区'
                    },
                    {
                        code: '210211', text: '甘井子区'
                    },
                    {
                        code: '210212', text: '旅顺口区'
                    },
                    {
                        code: '210213', text: '金州区'
                    },
                    {
                        code: '210214', text: '普兰店区'
                    },
                    {
                        code: '210224', text: '长海县'
                    },
                    {
                        code: '210281', text: '瓦房店市'
                    },
                    {
                        code: '210283', text: '庄河市'
                    }
                ]
            },
            {
                code: '2103',
                text: '鞍山市',
                children: [
                    {
                        code: '210302', text: '铁东区'
                    },
                    {
                        code: '210303', text: '铁西区'
                    },
                    {
                        code: '210304', text: '立山区'
                    },
                    {
                        code: '210311', text: '千山区'
                    },
                    {
                        code: '210321', text: '台安县'
                    },
                    {
                        code: '210323', text: '岫岩满族自治县'
                    },
                    {
                        code: '210381', text: '海城市'
                    }
                ]
            },
            {
                code: '2104',
                text: '抚顺市',
                children: [
                    {
                        code: '210402', text: '新抚区'
                    },
                    {
                        code: '210403', text: '东洲区'
                    },
                    {
                        code: '210404', text: '望花区'
                    },
                    {
                        code: '210411', text: '顺城区'
                    },
                    {
                        code: '210421', text: '抚顺县'
                    },
                    {
                        code: '210422', text: '新宾满族自治县'
                    },
                    {
                        code: '210423', text: '清原满族自治县'
                    }
                ]
            },
            {
                code: '2105',
                text: '本溪市',
                children: [
                    {
                        code: '210502', text: '平山区'
                    },
                    {
                        code: '210503', text: '溪湖区'
                    },
                    {
                        code: '210504', text: '明山区'
                    },
                    {
                        code: '210505', text: '南芬区'
                    },
                    {
                        code: '210521', text: '本溪满族自治县'
                    },
                    {
                        code: '210522', text: '桓仁满族自治县'
                    }
                ]
            },
            {
                code: '2106',
                text: '丹东市',
                children: [
                    {
                        code: '210602', text: '元宝区'
                    },
                    {
                        code: '210603', text: '振兴区'
                    },
                    {
                        code: '210604', text: '振安区'
                    },
                    {
                        code: '210624', text: '宽甸满族自治县'
                    },
                    {
                        code: '210681', text: '东港市'
                    },
                    {
                        code: '210682', text: '凤城市'
                    }
                ]
            },
            {
                code: '2107',
                text: '锦州市',
                children: [
                    {
                        code: '210702', text: '古塔区'
                    },
                    {
                        code: '210703', text: '凌河区'
                    },
                    {
                        code: '210711', text: '太和区'
                    },
                    {
                        code: '210726', text: '黑山县'
                    },
                    {
                        code: '210727', text: '义县'
                    },
                    {
                        code: '210781', text: '凌海市'
                    },
                    {
                        code: '210782', text: '北镇市'
                    }
                ]
            },
            {
                code: '2108',
                text: '营口市',
                children: [
                    {
                        code: '210802', text: '站前区'
                    },
                    {
                        code: '210803', text: '西市区'
                    },
                    {
                        code: '210804', text: '鲅鱼圈区'
                    },
                    {
                        code: '210811', text: '老边区'
                    },
                    {
                        code: '210881', text: '盖州市'
                    },
                    {
                        code: '210882', text: '大石桥市'
                    }
                ]
            },
            {
                code: '2109',
                text: '阜新市',
                children: [
                    {
                        code: '210902', text: '海州区'
                    },
                    {
                        code: '210903', text: '新邱区'
                    },
                    {
                        code: '210904', text: '太平区'
                    },
                    {
                        code: '210905', text: '清河门区'
                    },
                    {
                        code: '210911', text: '细河区'
                    },
                    {
                        code: '210921', text: '阜新蒙古族自治县'
                    },
                    {
                        code: '210922', text: '彰武县'
                    }
                ]
            },
            {
                code: '2110',
                text: '辽阳市',
                children: [
                    {
                        code: '211002', text: '白塔区'
                    },
                    {
                        code: '211003', text: '文圣区'
                    },
                    {
                        code: '211004', text: '宏伟区'
                    },
                    {
                        code: '211005', text: '弓长岭区'
                    },
                    {
                        code: '211011', text: '太子河区'
                    },
                    {
                        code: '211021', text: '辽阳县'
                    },
                    {
                        code: '211081', text: '灯塔市'
                    }
                ]
            },
            {
                code: '2111',
                text: '盘锦市',
                children: [
                    {
                        code: '211102', text: '双台子区'
                    },
                    {
                        code: '211103', text: '兴隆台区'
                    },
                    {
                        code: '211104', text: '大洼区'
                    },
                    {
                        code: '211122', text: '盘山县'
                    }
                ]
            },
            {
                code: '2112',
                text: '铁岭市',
                children: [
                    {
                        code: '211202', text: '银州区'
                    },
                    {
                        code: '211204', text: '清河区'
                    },
                    {
                        code: '211221', text: '铁岭县'
                    },
                    {
                        code: '211223', text: '西丰县'
                    },
                    {
                        code: '211224', text: '昌图县'
                    },
                    {
                        code: '211281', text: '调兵山市'
                    },
                    {
                        code: '211282', text: '开原市'
                    }
                ]
            },
            {
                code: '2113',
                text: '朝阳市',
                children: [
                    {
                        code: '211302', text: '双塔区'
                    },
                    {
                        code: '211303', text: '龙城区'
                    },
                    {
                        code: '211321', text: '朝阳县'
                    },
                    {
                        code: '211322', text: '建平县'
                    },
                    {
                        code: '211324', text: '喀喇沁左翼蒙古族自治县'
                    },
                    {
                        code: '211381', text: '北票市'
                    },
                    {
                        code: '211382', text: '凌源市'
                    }
                ]
            },
            {
                code: '2114',
                text: '葫芦岛市',
                children: [
                    {
                        code: '211402', text: '连山区'
                    },
                    {
                        code: '211403', text: '龙港区'
                    },
                    {
                        code: '211404', text: '南票区'
                    },
                    {
                        code: '211421', text: '绥中县'
                    },
                    {
                        code: '211422', text: '建昌县'
                    },
                    {
                        code: '211481', text: '兴城市'
                    }
                ]
            }
        ]
    },
    {
        code: '22',
        text: '吉林省',
        children: [
            {
                code: '2201',
                text: '长春市',
                children: [
                    {
                        code: '220102', text: '南关区'
                    },
                    {
                        code: '220103', text: '宽城区'
                    },
                    {
                        code: '220104', text: '朝阳区'
                    },
                    {
                        code: '220105', text: '二道区'
                    },
                    {
                        code: '220106', text: '绿园区'
                    },
                    {
                        code: '220112', text: '双阳区'
                    },
                    {
                        code: '220113', text: '九台区'
                    },
                    {
                        code: '220122', text: '农安县'
                    },
                    {
                        code: '220171', text: '长春经济技术开发区'
                    },
                    {
                        code: '220172', text: '长春净月高新技术产业开发区'
                    },
                    {
                        code: '220173', text: '长春高新技术产业开发区'
                    },
                    {
                        code: '220174', text: '长春汽车经济技术开发区'
                    },
                    {
                        code: '220182', text: '榆树市'
                    },
                    {
                        code: '220183', text: '德惠市'
                    }
                ]
            },
            {
                code: '2202',
                text: '吉林市',
                children: [
                    {
                        code: '220202', text: '昌邑区'
                    },
                    {
                        code: '220203', text: '龙潭区'
                    },
                    {
                        code: '220204', text: '船营区'
                    },
                    {
                        code: '220211', text: '丰满区'
                    },
                    {
                        code: '220221', text: '永吉县'
                    },
                    {
                        code: '220271', text: '吉林经济开发区'
                    },
                    {
                        code: '220272', text: '吉林高新技术产业开发区'
                    },
                    {
                        code: '220273', text: '吉林中国新加坡食品区'
                    },
                    {
                        code: '220281', text: '蛟河市'
                    },
                    {
                        code: '220282', text: '桦甸市'
                    },
                    {
                        code: '220283', text: '舒兰市'
                    },
                    {
                        code: '220284', text: '磐石市'
                    }
                ]
            },
            {
                code: '2203',
                text: '四平市',
                children: [
                    {
                        code: '220302', text: '铁西区'
                    },
                    {
                        code: '220303', text: '铁东区'
                    },
                    {
                        code: '220322', text: '梨树县'
                    },
                    {
                        code: '220323', text: '伊通满族自治县'
                    },
                    {
                        code: '220381', text: '公主岭市'
                    },
                    {
                        code: '220382', text: '双辽市'
                    }
                ]
            },
            {
                code: '2204',
                text: '辽源市',
                children: [
                    {
                        code: '220402', text: '龙山区'
                    },
                    {
                        code: '220403', text: '西安区'
                    },
                    {
                        code: '220421', text: '东丰县'
                    },
                    {
                        code: '220422', text: '东辽县'
                    }
                ]
            },
            {
                code: '2205',
                text: '通化市',
                children: [
                    {
                        code: '220502', text: '东昌区'
                    },
                    {
                        code: '220503', text: '二道江区'
                    },
                    {
                        code: '220521', text: '通化县'
                    },
                    {
                        code: '220523', text: '辉南县'
                    },
                    {
                        code: '220524', text: '柳河县'
                    },
                    {
                        code: '220581', text: '梅河口市'
                    },
                    {
                        code: '220582', text: '集安市'
                    }
                ]
            },
            {
                code: '2206',
                text: '白山市',
                children: [
                    {
                        code: '220602', text: '浑江区'
                    },
                    {
                        code: '220605', text: '江源区'
                    },
                    {
                        code: '220621', text: '抚松县'
                    },
                    {
                        code: '220622', text: '靖宇县'
                    },
                    {
                        code: '220623', text: '长白朝鲜族自治县'
                    },
                    {
                        code: '220681', text: '临江市'
                    }
                ]
            },
            {
                code: '2207',
                text: '松原市',
                children: [
                    {
                        code: '220702', text: '宁江区'
                    },
                    {
                        code: '220721', text: '前郭尔罗斯蒙古族自治县'
                    },
                    {
                        code: '220722', text: '长岭县'
                    },
                    {
                        code: '220723', text: '乾安县'
                    },
                    {
                        code: '220771', text: '吉林松原经济开发区'
                    },
                    {
                        code: '220781', text: '扶余市'
                    }
                ]
            },
            {
                code: '2208',
                text: '白城市',
                children: [
                    {
                        code: '220802', text: '洮北区'
                    },
                    {
                        code: '220821', text: '镇赉县'
                    },
                    {
                        code: '220822', text: '通榆县'
                    },
                    {
                        code: '220871', text: '吉林白城经济开发区'
                    },
                    {
                        code: '220881', text: '洮南市'
                    },
                    {
                        code: '220882', text: '大安市'
                    }
                ]
            },
            {
                code: '2224',
                text: '延边朝鲜族自治州',
                children: [
                    {
                        code: '222401', text: '延吉市'
                    },
                    {
                        code: '222402', text: '图们市'
                    },
                    {
                        code: '222403', text: '敦化市'
                    },
                    {
                        code: '222404', text: '珲春市'
                    },
                    {
                        code: '222405', text: '龙井市'
                    },
                    {
                        code: '222406', text: '和龙市'
                    },
                    {
                        code: '222424', text: '汪清县'
                    },
                    {
                        code: '222426', text: '安图县'
                    }
                ]
            }
        ]
    },
    {
        code: '23',
        text: '黑龙江省',
        children: [
            {
                code: '2301',
                text: '哈尔滨市',
                children: [
                    {
                        code: '230102', text: '道里区'
                    },
                    {
                        code: '230103', text: '南岗区'
                    },
                    {
                        code: '230104', text: '道外区'
                    },
                    {
                        code: '230108', text: '平房区'
                    },
                    {
                        code: '230109', text: '松北区'
                    },
                    {
                        code: '230110', text: '香坊区'
                    },
                    {
                        code: '230111', text: '呼兰区'
                    },
                    {
                        code: '230112', text: '阿城区'
                    },
                    {
                        code: '230113', text: '双城区'
                    },
                    {
                        code: '230123', text: '依兰县'
                    },
                    {
                        code: '230124', text: '方正县'
                    },
                    {
                        code: '230125', text: '宾县'
                    },
                    {
                        code: '230126', text: '巴彦县'
                    },
                    {
                        code: '230127', text: '木兰县'
                    },
                    {
                        code: '230128', text: '通河县'
                    },
                    {
                        code: '230129', text: '延寿县'
                    },
                    {
                        code: '230183', text: '尚志市'
                    },
                    {
                        code: '230184', text: '五常市'
                    }
                ]
            },
            {
                code: '2302',
                text: '齐齐哈尔市',
                children: [
                    {
                        code: '230202', text: '龙沙区'
                    },
                    {
                        code: '230203', text: '建华区'
                    },
                    {
                        code: '230204', text: '铁锋区'
                    },
                    {
                        code: '230205', text: '昂昂溪区'
                    },
                    {
                        code: '230206', text: '富拉尔基区'
                    },
                    {
                        code: '230207', text: '碾子山区'
                    },
                    {
                        code: '230208', text: '梅里斯达斡尔族区'
                    },
                    {
                        code: '230221', text: '龙江县'
                    },
                    {
                        code: '230223', text: '依安县'
                    },
                    {
                        code: '230224', text: '泰来县'
                    },
                    {
                        code: '230225', text: '甘南县'
                    },
                    {
                        code: '230227', text: '富裕县'
                    },
                    {
                        code: '230229', text: '克山县'
                    },
                    {
                        code: '230230', text: '克东县'
                    },
                    {
                        code: '230231', text: '拜泉县'
                    },
                    {
                        code: '230281', text: '讷河市'
                    }
                ]
            },
            {
                code: '2303',
                text: '鸡西市',
                children: [
                    {
                        code: '230302', text: '鸡冠区'
                    },
                    {
                        code: '230303', text: '恒山区'
                    },
                    {
                        code: '230304', text: '滴道区'
                    },
                    {
                        code: '230305', text: '梨树区'
                    },
                    {
                        code: '230306', text: '城子河区'
                    },
                    {
                        code: '230307', text: '麻山区'
                    },
                    {
                        code: '230321', text: '鸡东县'
                    },
                    {
                        code: '230381', text: '虎林市'
                    },
                    {
                        code: '230382', text: '密山市'
                    }
                ]
            },
            {
                code: '2304',
                text: '鹤岗市',
                children: [
                    {
                        code: '230402', text: '向阳区'
                    },
                    {
                        code: '230403', text: '工农区'
                    },
                    {
                        code: '230404', text: '南山区'
                    },
                    {
                        code: '230405', text: '兴安区'
                    },
                    {
                        code: '230406', text: '东山区'
                    },
                    {
                        code: '230407', text: '兴山区'
                    },
                    {
                        code: '230421', text: '萝北县'
                    },
                    {
                        code: '230422', text: '绥滨县'
                    }
                ]
            },
            {
                code: '2305',
                text: '双鸭山市',
                children: [
                    {
                        code: '230502', text: '尖山区'
                    },
                    {
                        code: '230503', text: '岭东区'
                    },
                    {
                        code: '230505', text: '四方台区'
                    },
                    {
                        code: '230506', text: '宝山区'
                    },
                    {
                        code: '230521', text: '集贤县'
                    },
                    {
                        code: '230522', text: '友谊县'
                    },
                    {
                        code: '230523', text: '宝清县'
                    },
                    {
                        code: '230524', text: '饶河县'
                    }
                ]
            },
            {
                code: '2306',
                text: '大庆市',
                children: [
                    {
                        code: '230602', text: '萨尔图区'
                    },
                    {
                        code: '230603', text: '龙凤区'
                    },
                    {
                        code: '230604', text: '让胡路区'
                    },
                    {
                        code: '230605', text: '红岗区'
                    },
                    {
                        code: '230606', text: '大同区'
                    },
                    {
                        code: '230621', text: '肇州县'
                    },
                    {
                        code: '230622', text: '肇源县'
                    },
                    {
                        code: '230623', text: '林甸县'
                    },
                    {
                        code: '230624', text: '杜尔伯特蒙古族自治县'
                    },
                    {
                        code: '230671', text: '大庆高新技术产业开发区'
                    }
                ]
            },
            {
                code: '2307',
                text: '伊春市',
                children: [
                    {
                        code: '230702', text: '伊春区'
                    },
                    {
                        code: '230703', text: '南岔区'
                    },
                    {
                        code: '230704', text: '友好区'
                    },
                    {
                        code: '230705', text: '西林区'
                    },
                    {
                        code: '230706', text: '翠峦区'
                    },
                    {
                        code: '230707', text: '新青区'
                    },
                    {
                        code: '230708', text: '美溪区'
                    },
                    {
                        code: '230709', text: '金山屯区'
                    },
                    {
                        code: '230710', text: '五营区'
                    },
                    {
                        code: '230711', text: '乌马河区'
                    },
                    {
                        code: '230712', text: '汤旺河区'
                    },
                    {
                        code: '230713', text: '带岭区'
                    },
                    {
                        code: '230714', text: '乌伊岭区'
                    },
                    {
                        code: '230715', text: '红星区'
                    },
                    {
                        code: '230716', text: '上甘岭区'
                    },
                    {
                        code: '230722', text: '嘉荫县'
                    },
                    {
                        code: '230781', text: '铁力市'
                    }
                ]
            },
            {
                code: '2308',
                text: '佳木斯市',
                children: [
                    {
                        code: '230803', text: '向阳区'
                    },
                    {
                        code: '230804', text: '前进区'
                    },
                    {
                        code: '230805', text: '东风区'
                    },
                    {
                        code: '230811', text: '郊区'
                    },
                    {
                        code: '230822', text: '桦南县'
                    },
                    {
                        code: '230826', text: '桦川县'
                    },
                    {
                        code: '230828', text: '汤原县'
                    },
                    {
                        code: '230881', text: '同江市'
                    },
                    {
                        code: '230882', text: '富锦市'
                    },
                    {
                        code: '230883', text: '抚远市'
                    }
                ]
            },
            {
                code: '2309',
                text: '七台河市',
                children: [
                    {
                        code: '230902', text: '新兴区'
                    },
                    {
                        code: '230903', text: '桃山区'
                    },
                    {
                        code: '230904', text: '茄子河区'
                    },
                    {
                        code: '230921', text: '勃利县'
                    }
                ]
            },
            {
                code: '2310',
                text: '牡丹江市',
                children: [
                    {
                        code: '231002', text: '东安区'
                    },
                    {
                        code: '231003', text: '阳明区'
                    },
                    {
                        code: '231004', text: '爱民区'
                    },
                    {
                        code: '231005', text: '西安区'
                    },
                    {
                        code: '231025', text: '林口县'
                    },
                    {
                        code: '231071', text: '牡丹江经济技术开发区'
                    },
                    {
                        code: '231081', text: '绥芬河市'
                    },
                    {
                        code: '231083', text: '海林市'
                    },
                    {
                        code: '231084', text: '宁安市'
                    },
                    {
                        code: '231085', text: '穆棱市'
                    },
                    {
                        code: '231086', text: '东宁市'
                    }
                ]
            },
            {
                code: '2311',
                text: '黑河市',
                children: [
                    {
                        code: '231102', text: '爱辉区'
                    },
                    {
                        code: '231121', text: '嫩江县'
                    },
                    {
                        code: '231123', text: '逊克县'
                    },
                    {
                        code: '231124', text: '孙吴县'
                    },
                    {
                        code: '231181', text: '北安市'
                    },
                    {
                        code: '231182', text: '五大连池市'
                    }
                ]
            },
            {
                code: '2312',
                text: '绥化市',
                children: [
                    {
                        code: '231202', text: '北林区'
                    },
                    {
                        code: '231221', text: '望奎县'
                    },
                    {
                        code: '231222', text: '兰西县'
                    },
                    {
                        code: '231223', text: '青冈县'
                    },
                    {
                        code: '231224', text: '庆安县'
                    },
                    {
                        code: '231225', text: '明水县'
                    },
                    {
                        code: '231226', text: '绥棱县'
                    },
                    {
                        code: '231281', text: '安达市'
                    },
                    {
                        code: '231282', text: '肇东市'
                    },
                    {
                        code: '231283', text: '海伦市'
                    }
                ]
            },
            {
                code: '2327',
                text: '大兴安岭地区',
                children: [
                    {
                        code: '232701', text: '加格达奇区'
                    },
                    {
                        code: '232702', text: '松岭区'
                    },
                    {
                        code: '232703', text: '新林区'
                    },
                    {
                        code: '232704', text: '呼中区'
                    },
                    {
                        code: '232721', text: '呼玛县'
                    },
                    {
                        code: '232722', text: '塔河县'
                    },
                    {
                        code: '232723', text: '漠河县'
                    }
                ]
            }
        ]
    },
    {
        code: '31',
        text: '上海市',
        children: [
            {
                code: '3101',
                text: '市辖区',
                children: [
                    {
                        code: '310101', text: '黄浦区'
                    },
                    {
                        code: '310104', text: '徐汇区'
                    },
                    {
                        code: '310105', text: '长宁区'
                    },
                    {
                        code: '310106', text: '静安区'
                    },
                    {
                        code: '310107', text: '普陀区'
                    },
                    {
                        code: '310109', text: '虹口区'
                    },
                    {
                        code: '310110', text: '杨浦区'
                    },
                    {
                        code: '310112', text: '闵行区'
                    },
                    {
                        code: '310113', text: '宝山区'
                    },
                    {
                        code: '310114', text: '嘉定区'
                    },
                    {
                        code: '310115', text: '浦东新区'
                    },
                    {
                        code: '310116', text: '金山区'
                    },
                    {
                        code: '310117', text: '松江区'
                    },
                    {
                        code: '310118', text: '青浦区'
                    },
                    {
                        code: '310120', text: '奉贤区'
                    },
                    {
                        code: '310151', text: '崇明区'
                    }
                ]
            }
        ]
    },
    {
        code: '32',
        text: '江苏省',
        children: [
            {
                code: '3201',
                text: '南京市',
                children: [
                    {
                        code: '320102', text: '玄武区'
                    },
                    {
                        code: '320104', text: '秦淮区'
                    },
                    {
                        code: '320105', text: '建邺区'
                    },
                    {
                        code: '320106', text: '鼓楼区'
                    },
                    {
                        code: '320111', text: '浦口区'
                    },
                    {
                        code: '320113', text: '栖霞区'
                    },
                    {
                        code: '320114', text: '雨花台区'
                    },
                    {
                        code: '320115', text: '江宁区'
                    },
                    {
                        code: '320116', text: '六合区'
                    },
                    {
                        code: '320117', text: '溧水区'
                    },
                    {
                        code: '320118', text: '高淳区'
                    }
                ]
            },
            {
                code: '3202',
                text: '无锡市',
                children: [
                    {
                        code: '320205', text: '锡山区'
                    },
                    {
                        code: '320206', text: '惠山区'
                    },
                    {
                        code: '320211', text: '滨湖区'
                    },
                    {
                        code: '320213', text: '梁溪区'
                    },
                    {
                        code: '320214', text: '新吴区'
                    },
                    {
                        code: '320281', text: '江阴市'
                    },
                    {
                        code: '320282', text: '宜兴市'
                    }
                ]
            },
            {
                code: '3203',
                text: '徐州市',
                children: [
                    {
                        code: '320302', text: '鼓楼区'
                    },
                    {
                        code: '320303', text: '云龙区'
                    },
                    {
                        code: '320305', text: '贾汪区'
                    },
                    {
                        code: '320311', text: '泉山区'
                    },
                    {
                        code: '320312', text: '铜山区'
                    },
                    {
                        code: '320321', text: '丰县'
                    },
                    {
                        code: '320322', text: '沛县'
                    },
                    {
                        code: '320324', text: '睢宁县'
                    },
                    {
                        code: '320371', text: '徐州经济技术开发区'
                    },
                    {
                        code: '320381', text: '新沂市'
                    },
                    {
                        code: '320382', text: '邳州市'
                    }
                ]
            },
            {
                code: '3204',
                text: '常州市',
                children: [
                    {
                        code: '320402', text: '天宁区'
                    },
                    {
                        code: '320404', text: '钟楼区'
                    },
                    {
                        code: '320411', text: '新北区'
                    },
                    {
                        code: '320412', text: '武进区'
                    },
                    {
                        code: '320413', text: '金坛区'
                    },
                    {
                        code: '320481', text: '溧阳市'
                    }
                ]
            },
            {
                code: '3205',
                text: '苏州市',
                children: [
                    {
                        code: '320505', text: '虎丘区'
                    },
                    {
                        code: '320506', text: '吴中区'
                    },
                    {
                        code: '320507', text: '相城区'
                    },
                    {
                        code: '320508', text: '姑苏区'
                    },
                    {
                        code: '320509', text: '吴江区'
                    },
                    {
                        code: '320571', text: '苏州工业园区'
                    },
                    {
                        code: '320581', text: '常熟市'
                    },
                    {
                        code: '320582', text: '张家港市'
                    },
                    {
                        code: '320583', text: '昆山市'
                    },
                    {
                        code: '320585', text: '太仓市'
                    }
                ]
            },
            {
                code: '3206',
                text: '南通市',
                children: [
                    {
                        code: '320602', text: '崇川区'
                    },
                    {
                        code: '320611', text: '港闸区'
                    },
                    {
                        code: '320612', text: '通州区'
                    },
                    {
                        code: '320621', text: '海安县'
                    },
                    {
                        code: '320623', text: '如东县'
                    },
                    {
                        code: '320671', text: '南通经济技术开发区'
                    },
                    {
                        code: '320681', text: '启东市'
                    },
                    {
                        code: '320682', text: '如皋市'
                    },
                    {
                        code: '320684', text: '海门市'
                    }
                ]
            },
            {
                code: '3207',
                text: '连云港市',
                children: [
                    {
                        code: '320703', text: '连云区'
                    },
                    {
                        code: '320706', text: '海州区'
                    },
                    {
                        code: '320707', text: '赣榆区'
                    },
                    {
                        code: '320722', text: '东海县'
                    },
                    {
                        code: '320723', text: '灌云县'
                    },
                    {
                        code: '320724', text: '灌南县'
                    },
                    {
                        code: '320771', text: '连云港经济技术开发区'
                    },
                    {
                        code: '320772', text: '连云港高新技术产业开发区'
                    }
                ]
            },
            {
                code: '3208',
                text: '淮安市',
                children: [
                    {
                        code: '320803', text: '淮安区'
                    },
                    {
                        code: '320804', text: '淮阴区'
                    },
                    {
                        code: '320812', text: '清江浦区'
                    },
                    {
                        code: '320813', text: '洪泽区'
                    },
                    {
                        code: '320826', text: '涟水县'
                    },
                    {
                        code: '320830', text: '盱眙县'
                    },
                    {
                        code: '320831', text: '金湖县'
                    },
                    {
                        code: '320871', text: '淮安经济技术开发区'
                    }
                ]
            },
            {
                code: '3209',
                text: '盐城市',
                children: [
                    {
                        code: '320902', text: '亭湖区'
                    },
                    {
                        code: '320903', text: '盐都区'
                    },
                    {
                        code: '320904', text: '大丰区'
                    },
                    {
                        code: '320921', text: '响水县'
                    },
                    {
                        code: '320922', text: '滨海县'
                    },
                    {
                        code: '320923', text: '阜宁县'
                    },
                    {
                        code: '320924', text: '射阳县'
                    },
                    {
                        code: '320925', text: '建湖县'
                    },
                    {
                        code: '320971', text: '盐城经济技术开发区'
                    },
                    {
                        code: '320981', text: '东台市'
                    }
                ]
            },
            {
                code: '3210',
                text: '扬州市',
                children: [
                    {
                        code: '321002', text: '广陵区'
                    },
                    {
                        code: '321003', text: '邗江区'
                    },
                    {
                        code: '321012', text: '江都区'
                    },
                    {
                        code: '321023', text: '宝应县'
                    },
                    {
                        code: '321071', text: '扬州经济技术开发区'
                    },
                    {
                        code: '321081', text: '仪征市'
                    },
                    {
                        code: '321084', text: '高邮市'
                    }
                ]
            },
            {
                code: '3211',
                text: '镇江市',
                children: [
                    {
                        code: '321102', text: '京口区'
                    },
                    {
                        code: '321111', text: '润州区'
                    },
                    {
                        code: '321112', text: '丹徒区'
                    },
                    {
                        code: '321171', text: '镇江新区'
                    },
                    {
                        code: '321181', text: '丹阳市'
                    },
                    {
                        code: '321182', text: '扬中市'
                    },
                    {
                        code: '321183', text: '句容市'
                    }
                ]
            },
            {
                code: '3212',
                text: '泰州市',
                children: [
                    {
                        code: '321202', text: '海陵区'
                    },
                    {
                        code: '321203', text: '高港区'
                    },
                    {
                        code: '321204', text: '姜堰区'
                    },
                    {
                        code: '321271', text: '泰州医药高新技术产业开发区'
                    },
                    {
                        code: '321281', text: '兴化市'
                    },
                    {
                        code: '321282', text: '靖江市'
                    },
                    {
                        code: '321283', text: '泰兴市'
                    }
                ]
            },
            {
                code: '3213',
                text: '宿迁市',
                children: [
                    {
                        code: '321302', text: '宿城区'
                    },
                    {
                        code: '321311', text: '宿豫区'
                    },
                    {
                        code: '321322', text: '沭阳县'
                    },
                    {
                        code: '321323', text: '泗阳县'
                    },
                    {
                        code: '321324', text: '泗洪县'
                    },
                    {
                        code: '321371', text: '宿迁经济技术开发区'
                    }
                ]
            }
        ]
    },
    {
        code: '33',
        text: '浙江省',
        children: [
            {
                code: '3301',
                text: '杭州市',
                children: [
                    {
                        code: '330102', text: '上城区'
                    },
                    {
                        code: '330103', text: '下城区'
                    },
                    {
                        code: '330104', text: '江干区'
                    },
                    {
                        code: '330105', text: '拱墅区'
                    },
                    {
                        code: '330106', text: '西湖区'
                    },
                    {
                        code: '330108', text: '滨江区'
                    },
                    {
                        code: '330109', text: '萧山区'
                    },
                    {
                        code: '330110', text: '余杭区'
                    },
                    {
                        code: '330111', text: '富阳区'
                    },
                    {
                        code: '330112', text: '临安区'
                    },
                    {
                        code: '330122', text: '桐庐县'
                    },
                    {
                        code: '330127', text: '淳安县'
                    },
                    {
                        code: '330182', text: '建德市'
                    }
                ]
            },
            {
                code: '3302',
                text: '宁波市',
                children: [
                    {
                        code: '330203', text: '海曙区'
                    },
                    {
                        code: '330205', text: '江北区'
                    },
                    {
                        code: '330206', text: '北仑区'
                    },
                    {
                        code: '330211', text: '镇海区'
                    },
                    {
                        code: '330212', text: '鄞州区'
                    },
                    {
                        code: '330213', text: '奉化区'
                    },
                    {
                        code: '330225', text: '象山县'
                    },
                    {
                        code: '330226', text: '宁海县'
                    },
                    {
                        code: '330281', text: '余姚市'
                    },
                    {
                        code: '330282', text: '慈溪市'
                    }
                ]
            },
            {
                code: '3303',
                text: '温州市',
                children: [
                    {
                        code: '330302', text: '鹿城区'
                    },
                    {
                        code: '330303', text: '龙湾区'
                    },
                    {
                        code: '330304', text: '瓯海区'
                    },
                    {
                        code: '330305', text: '洞头区'
                    },
                    {
                        code: '330324', text: '永嘉县'
                    },
                    {
                        code: '330326', text: '平阳县'
                    },
                    {
                        code: '330327', text: '苍南县'
                    },
                    {
                        code: '330328', text: '文成县'
                    },
                    {
                        code: '330329', text: '泰顺县'
                    },
                    {
                        code: '330371', text: '温州经济技术开发区'
                    },
                    {
                        code: '330381', text: '瑞安市'
                    },
                    {
                        code: '330382', text: '乐清市'
                    }
                ]
            },
            {
                code: '3304',
                text: '嘉兴市',
                children: [
                    {
                        code: '330402', text: '南湖区'
                    },
                    {
                        code: '330411', text: '秀洲区'
                    },
                    {
                        code: '330421', text: '嘉善县'
                    },
                    {
                        code: '330424', text: '海盐县'
                    },
                    {
                        code: '330481', text: '海宁市'
                    },
                    {
                        code: '330482', text: '平湖市'
                    },
                    {
                        code: '330483', text: '桐乡市'
                    }
                ]
            },
            {
                code: '3305',
                text: '湖州市',
                children: [
                    {
                        code: '330502', text: '吴兴区'
                    },
                    {
                        code: '330503', text: '南浔区'
                    },
                    {
                        code: '330521', text: '德清县'
                    },
                    {
                        code: '330522', text: '长兴县'
                    },
                    {
                        code: '330523', text: '安吉县'
                    }
                ]
            },
            {
                code: '3306',
                text: '绍兴市',
                children: [
                    {
                        code: '330602', text: '越城区'
                    },
                    {
                        code: '330603', text: '柯桥区'
                    },
                    {
                        code: '330604', text: '上虞区'
                    },
                    {
                        code: '330624', text: '新昌县'
                    },
                    {
                        code: '330681', text: '诸暨市'
                    },
                    {
                        code: '330683', text: '嵊州市'
                    }
                ]
            },
            {
                code: '3307',
                text: '金华市',
                children: [
                    {
                        code: '330702', text: '婺城区'
                    },
                    {
                        code: '330703', text: '金东区'
                    },
                    {
                        code: '330723', text: '武义县'
                    },
                    {
                        code: '330726', text: '浦江县'
                    },
                    {
                        code: '330727', text: '磐安县'
                    },
                    {
                        code: '330781', text: '兰溪市'
                    },
                    {
                        code: '330782', text: '义乌市'
                    },
                    {
                        code: '330783', text: '东阳市'
                    },
                    {
                        code: '330784', text: '永康市'
                    }
                ]
            },
            {
                code: '3308',
                text: '衢州市',
                children: [
                    {
                        code: '330802', text: '柯城区'
                    },
                    {
                        code: '330803', text: '衢江区'
                    },
                    {
                        code: '330822', text: '常山县'
                    },
                    {
                        code: '330824', text: '开化县'
                    },
                    {
                        code: '330825', text: '龙游县'
                    },
                    {
                        code: '330881', text: '江山市'
                    }
                ]
            },
            {
                code: '3309',
                text: '舟山市',
                children: [
                    {
                        code: '330902', text: '定海区'
                    },
                    {
                        code: '330903', text: '普陀区'
                    },
                    {
                        code: '330921', text: '岱山县'
                    },
                    {
                        code: '330922', text: '嵊泗县'
                    }
                ]
            },
            {
                code: '3310',
                text: '台州市',
                children: [
                    {
                        code: '331002', text: '椒江区'
                    },
                    {
                        code: '331003', text: '黄岩区'
                    },
                    {
                        code: '331004', text: '路桥区'
                    },
                    {
                        code: '331022', text: '三门县'
                    },
                    {
                        code: '331023', text: '天台县'
                    },
                    {
                        code: '331024', text: '仙居县'
                    },
                    {
                        code: '331081', text: '温岭市'
                    },
                    {
                        code: '331082', text: '临海市'
                    },
                    {
                        code: '331083', text: '玉环市'
                    }
                ]
            },
            {
                code: '3311',
                text: '丽水市',
                children: [
                    {
                        code: '331102', text: '莲都区'
                    },
                    {
                        code: '331121', text: '青田县'
                    },
                    {
                        code: '331122', text: '缙云县'
                    },
                    {
                        code: '331123', text: '遂昌县'
                    },
                    {
                        code: '331124', text: '松阳县'
                    },
                    {
                        code: '331125', text: '云和县'
                    },
                    {
                        code: '331126', text: '庆元县'
                    },
                    {
                        code: '331127', text: '景宁畲族自治县'
                    },
                    {
                        code: '331181', text: '龙泉市'
                    }
                ]
            }
        ]
    },
    {
        code: '34',
        text: '安徽省',
        children: [
            {
                code: '3401',
                text: '合肥市',
                children: [
                    {
                        code: '340102', text: '瑶海区'
                    },
                    {
                        code: '340103', text: '庐阳区'
                    },
                    {
                        code: '340104', text: '蜀山区'
                    },
                    {
                        code: '340111', text: '包河区'
                    },
                    {
                        code: '340121', text: '长丰县'
                    },
                    {
                        code: '340122', text: '肥东县'
                    },
                    {
                        code: '340123', text: '肥西县'
                    },
                    {
                        code: '340124', text: '庐江县'
                    },
                    {
                        code: '340171', text: '合肥高新技术产业开发区'
                    },
                    {
                        code: '340172', text: '合肥经济技术开发区'
                    },
                    {
                        code: '340173', text: '合肥新站高新技术产业开发区'
                    },
                    {
                        code: '340181', text: '巢湖市'
                    }
                ]
            },
            {
                code: '3402',
                text: '芜湖市',
                children: [
                    {
                        code: '340202', text: '镜湖区'
                    },
                    {
                        code: '340203', text: '弋江区'
                    },
                    {
                        code: '340207', text: '鸠江区'
                    },
                    {
                        code: '340208', text: '三山区'
                    },
                    {
                        code: '340221', text: '芜湖县'
                    },
                    {
                        code: '340222', text: '繁昌县'
                    },
                    {
                        code: '340223', text: '南陵县'
                    },
                    {
                        code: '340225', text: '无为县'
                    },
                    {
                        code: '340271', text: '芜湖经济技术开发区'
                    },
                    {
                        code: '340272', text: '安徽芜湖长江大桥经济开发区'
                    }
                ]
            },
            {
                code: '3403',
                text: '蚌埠市',
                children: [
                    {
                        code: '340302', text: '龙子湖区'
                    },
                    {
                        code: '340303', text: '蚌山区'
                    },
                    {
                        code: '340304', text: '禹会区'
                    },
                    {
                        code: '340311', text: '淮上区'
                    },
                    {
                        code: '340321', text: '怀远县'
                    },
                    {
                        code: '340322', text: '五河县'
                    },
                    {
                        code: '340323', text: '固镇县'
                    },
                    {
                        code: '340371', text: '蚌埠市高新技术开发区'
                    },
                    {
                        code: '340372', text: '蚌埠市经济开发区'
                    }
                ]
            },
            {
                code: '3404',
                text: '淮南市',
                children: [
                    {
                        code: '340402', text: '大通区'
                    },
                    {
                        code: '340403', text: '田家庵区'
                    },
                    {
                        code: '340404', text: '谢家集区'
                    },
                    {
                        code: '340405', text: '八公山区'
                    },
                    {
                        code: '340406', text: '潘集区'
                    },
                    {
                        code: '340421', text: '凤台县'
                    },
                    {
                        code: '340422', text: '寿县'
                    }
                ]
            },
            {
                code: '3405',
                text: '马鞍山市',
                children: [
                    {
                        code: '340503', text: '花山区'
                    },
                    {
                        code: '340504', text: '雨山区'
                    },
                    {
                        code: '340506', text: '博望区'
                    },
                    {
                        code: '340521', text: '当涂县'
                    },
                    {
                        code: '340522', text: '含山县'
                    },
                    {
                        code: '340523', text: '和县'
                    }
                ]
            },
            {
                code: '3406',
                text: '淮北市',
                children: [
                    {
                        code: '340602', text: '杜集区'
                    },
                    {
                        code: '340603', text: '相山区'
                    },
                    {
                        code: '340604', text: '烈山区'
                    },
                    {
                        code: '340621', text: '濉溪县'
                    }
                ]
            },
            {
                code: '3407',
                text: '铜陵市',
                children: [
                    {
                        code: '340705', text: '铜官区'
                    },
                    {
                        code: '340706', text: '义安区'
                    },
                    {
                        code: '340711', text: '郊区'
                    },
                    {
                        code: '340722', text: '枞阳县'
                    }
                ]
            },
            {
                code: '3408',
                text: '安庆市',
                children: [
                    {
                        code: '340802', text: '迎江区'
                    },
                    {
                        code: '340803', text: '大观区'
                    },
                    {
                        code: '340811', text: '宜秀区'
                    },
                    {
                        code: '340822', text: '怀宁县'
                    },
                    {
                        code: '340824', text: '潜山县'
                    },
                    {
                        code: '340825', text: '太湖县'
                    },
                    {
                        code: '340826', text: '宿松县'
                    },
                    {
                        code: '340827', text: '望江县'
                    },
                    {
                        code: '340828', text: '岳西县'
                    },
                    {
                        code: '340871', text: '安徽安庆经济开发区'
                    },
                    {
                        code: '340881', text: '桐城市'
                    }
                ]
            },
            {
                code: '3410',
                text: '黄山市',
                children: [
                    {
                        code: '341002', text: '屯溪区'
                    },
                    {
                        code: '341003', text: '黄山区'
                    },
                    {
                        code: '341004', text: '徽州区'
                    },
                    {
                        code: '341021', text: '歙县'
                    },
                    {
                        code: '341022', text: '休宁县'
                    },
                    {
                        code: '341023', text: '黟县'
                    },
                    {
                        code: '341024', text: '祁门县'
                    }
                ]
            },
            {
                code: '3411',
                text: '滁州市',
                children: [
                    {
                        code: '341102', text: '琅琊区'
                    },
                    {
                        code: '341103', text: '南谯区'
                    },
                    {
                        code: '341122', text: '来安县'
                    },
                    {
                        code: '341124', text: '全椒县'
                    },
                    {
                        code: '341125', text: '定远县'
                    },
                    {
                        code: '341126', text: '凤阳县'
                    },
                    {
                        code: '341171', text: '苏滁现代产业园'
                    },
                    {
                        code: '341172', text: '滁州经济技术开发区'
                    },
                    {
                        code: '341181', text: '天长市'
                    },
                    {
                        code: '341182', text: '明光市'
                    }
                ]
            },
            {
                code: '3412',
                text: '阜阳市',
                children: [
                    {
                        code: '341202', text: '颍州区'
                    },
                    {
                        code: '341203', text: '颍东区'
                    },
                    {
                        code: '341204', text: '颍泉区'
                    },
                    {
                        code: '341221', text: '临泉县'
                    },
                    {
                        code: '341222', text: '太和县'
                    },
                    {
                        code: '341225', text: '阜南县'
                    },
                    {
                        code: '341226', text: '颍上县'
                    },
                    {
                        code: '341271', text: '阜阳合肥现代产业园区'
                    },
                    {
                        code: '341272', text: '阜阳经济技术开发区'
                    },
                    {
                        code: '341282', text: '界首市'
                    }
                ]
            },
            {
                code: '3413',
                text: '宿州市',
                children: [
                    {
                        code: '341302', text: '埇桥区'
                    },
                    {
                        code: '341321', text: '砀山县'
                    },
                    {
                        code: '341322', text: '萧县'
                    },
                    {
                        code: '341323', text: '灵璧县'
                    },
                    {
                        code: '341324', text: '泗县'
                    },
                    {
                        code: '341371', text: '宿州马鞍山现代产业园区'
                    },
                    {
                        code: '341372', text: '宿州经济技术开发区'
                    }
                ]
            },
            {
                code: '3415',
                text: '六安市',
                children: [
                    {
                        code: '341502', text: '金安区'
                    },
                    {
                        code: '341503', text: '裕安区'
                    },
                    {
                        code: '341504', text: '叶集区'
                    },
                    {
                        code: '341522', text: '霍邱县'
                    },
                    {
                        code: '341523', text: '舒城县'
                    },
                    {
                        code: '341524', text: '金寨县'
                    },
                    {
                        code: '341525', text: '霍山县'
                    }
                ]
            },
            {
                code: '3416',
                text: '亳州市',
                children: [
                    {
                        code: '341602', text: '谯城区'
                    },
                    {
                        code: '341621', text: '涡阳县'
                    },
                    {
                        code: '341622', text: '蒙城县'
                    },
                    {
                        code: '341623', text: '利辛县'
                    }
                ]
            },
            {
                code: '3417',
                text: '池州市',
                children: [
                    {
                        code: '341702', text: '贵池区'
                    },
                    {
                        code: '341721', text: '东至县'
                    },
                    {
                        code: '341722', text: '石台县'
                    },
                    {
                        code: '341723', text: '青阳县'
                    }
                ]
            },
            {
                code: '3418',
                text: '宣城市',
                children: [
                    {
                        code: '341802', text: '宣州区'
                    },
                    {
                        code: '341821', text: '郎溪县'
                    },
                    {
                        code: '341822', text: '广德县'
                    },
                    {
                        code: '341823', text: '泾县'
                    },
                    {
                        code: '341824', text: '绩溪县'
                    },
                    {
                        code: '341825', text: '旌德县'
                    },
                    {
                        code: '341871', text: '宣城市经济开发区'
                    },
                    {
                        code: '341881', text: '宁国市'
                    }
                ]
            }
        ]
    },
    {
        code: '35',
        text: '福建省',
        children: [
            {
                code: '3501',
                text: '福州市',
                children: [
                    {
                        code: '350102', text: '鼓楼区'
                    },
                    {
                        code: '350103', text: '台江区'
                    },
                    {
                        code: '350104', text: '仓山区'
                    },
                    {
                        code: '350105', text: '马尾区'
                    },
                    {
                        code: '350111', text: '晋安区'
                    },
                    {
                        code: '350121', text: '闽侯县'
                    },
                    {
                        code: '350122', text: '连江县'
                    },
                    {
                        code: '350123', text: '罗源县'
                    },
                    {
                        code: '350124', text: '闽清县'
                    },
                    {
                        code: '350125', text: '永泰县'
                    },
                    {
                        code: '350128', text: '平潭县'
                    },
                    {
                        code: '350181', text: '福清市'
                    },
                    {
                        code: '350182', text: '长乐市'
                    }
                ]
            },
            {
                code: '3502',
                text: '厦门市',
                children: [
                    {
                        code: '350203', text: '思明区'
                    },
                    {
                        code: '350205', text: '海沧区'
                    },
                    {
                        code: '350206', text: '湖里区'
                    },
                    {
                        code: '350211', text: '集美区'
                    },
                    {
                        code: '350212', text: '同安区'
                    },
                    {
                        code: '350213', text: '翔安区'
                    }
                ]
            },
            {
                code: '3503',
                text: '莆田市',
                children: [
                    {
                        code: '350302', text: '城厢区'
                    },
                    {
                        code: '350303', text: '涵江区'
                    },
                    {
                        code: '350304', text: '荔城区'
                    },
                    {
                        code: '350305', text: '秀屿区'
                    },
                    {
                        code: '350322', text: '仙游县'
                    }
                ]
            },
            {
                code: '3504',
                text: '三明市',
                children: [
                    {
                        code: '350402', text: '梅列区'
                    },
                    {
                        code: '350403', text: '三元区'
                    },
                    {
                        code: '350421', text: '明溪县'
                    },
                    {
                        code: '350423', text: '清流县'
                    },
                    {
                        code: '350424', text: '宁化县'
                    },
                    {
                        code: '350425', text: '大田县'
                    },
                    {
                        code: '350426', text: '尤溪县'
                    },
                    {
                        code: '350427', text: '沙县'
                    },
                    {
                        code: '350428', text: '将乐县'
                    },
                    {
                        code: '350429', text: '泰宁县'
                    },
                    {
                        code: '350430', text: '建宁县'
                    },
                    {
                        code: '350481', text: '永安市'
                    }
                ]
            },
            {
                code: '3505',
                text: '泉州市',
                children: [
                    {
                        code: '350502', text: '鲤城区'
                    },
                    {
                        code: '350503', text: '丰泽区'
                    },
                    {
                        code: '350504', text: '洛江区'
                    },
                    {
                        code: '350505', text: '泉港区'
                    },
                    {
                        code: '350521', text: '惠安县'
                    },
                    {
                        code: '350524', text: '安溪县'
                    },
                    {
                        code: '350525', text: '永春县'
                    },
                    {
                        code: '350526', text: '德化县'
                    },
                    {
                        code: '350527', text: '金门县'
                    },
                    {
                        code: '350581', text: '石狮市'
                    },
                    {
                        code: '350582', text: '晋江市'
                    },
                    {
                        code: '350583', text: '南安市'
                    }
                ]
            },
            {
                code: '3506',
                text: '漳州市',
                children: [
                    {
                        code: '350602', text: '芗城区'
                    },
                    {
                        code: '350603', text: '龙文区'
                    },
                    {
                        code: '350622', text: '云霄县'
                    },
                    {
                        code: '350623', text: '漳浦县'
                    },
                    {
                        code: '350624', text: '诏安县'
                    },
                    {
                        code: '350625', text: '长泰县'
                    },
                    {
                        code: '350626', text: '东山县'
                    },
                    {
                        code: '350627', text: '南靖县'
                    },
                    {
                        code: '350628', text: '平和县'
                    },
                    {
                        code: '350629', text: '华安县'
                    },
                    {
                        code: '350681', text: '龙海市'
                    }
                ]
            },
            {
                code: '3507',
                text: '南平市',
                children: [
                    {
                        code: '350702', text: '延平区'
                    },
                    {
                        code: '350703', text: '建阳区'
                    },
                    {
                        code: '350721', text: '顺昌县'
                    },
                    {
                        code: '350722', text: '浦城县'
                    },
                    {
                        code: '350723', text: '光泽县'
                    },
                    {
                        code: '350724', text: '松溪县'
                    },
                    {
                        code: '350725', text: '政和县'
                    },
                    {
                        code: '350781', text: '邵武市'
                    },
                    {
                        code: '350782', text: '武夷山市'
                    },
                    {
                        code: '350783', text: '建瓯市'
                    }
                ]
            },
            {
                code: '3508',
                text: '龙岩市',
                children: [
                    {
                        code: '350802', text: '新罗区'
                    },
                    {
                        code: '350803', text: '永定区'
                    },
                    {
                        code: '350821', text: '长汀县'
                    },
                    {
                        code: '350823', text: '上杭县'
                    },
                    {
                        code: '350824', text: '武平县'
                    },
                    {
                        code: '350825', text: '连城县'
                    },
                    {
                        code: '350881', text: '漳平市'
                    }
                ]
            },
            {
                code: '3509',
                text: '宁德市',
                children: [
                    {
                        code: '350902', text: '蕉城区'
                    },
                    {
                        code: '350921', text: '霞浦县'
                    },
                    {
                        code: '350922', text: '古田县'
                    },
                    {
                        code: '350923', text: '屏南县'
                    },
                    {
                        code: '350924', text: '寿宁县'
                    },
                    {
                        code: '350925', text: '周宁县'
                    },
                    {
                        code: '350926', text: '柘荣县'
                    },
                    {
                        code: '350981', text: '福安市'
                    },
                    {
                        code: '350982', text: '福鼎市'
                    }
                ]
            }
        ]
    },
    {
        code: '36',
        text: '江西省',
        children: [
            {
                code: '3601',
                text: '南昌市',
                children: [
                    {
                        code: '360102', text: '东湖区'
                    },
                    {
                        code: '360103', text: '西湖区'
                    },
                    {
                        code: '360104', text: '青云谱区'
                    },
                    {
                        code: '360105', text: '湾里区'
                    },
                    {
                        code: '360111', text: '青山湖区'
                    },
                    {
                        code: '360112', text: '新建区'
                    },
                    {
                        code: '360121', text: '南昌县'
                    },
                    {
                        code: '360123', text: '安义县'
                    },
                    {
                        code: '360124', text: '进贤县'
                    }
                ]
            },
            {
                code: '3602',
                text: '景德镇市',
                children: [
                    {
                        code: '360202', text: '昌江区'
                    },
                    {
                        code: '360203', text: '珠山区'
                    },
                    {
                        code: '360222', text: '浮梁县'
                    },
                    {
                        code: '360281', text: '乐平市'
                    }
                ]
            },
            {
                code: '3603',
                text: '萍乡市',
                children: [
                    {
                        code: '360302', text: '安源区'
                    },
                    {
                        code: '360313', text: '湘东区'
                    },
                    {
                        code: '360321', text: '莲花县'
                    },
                    {
                        code: '360322', text: '上栗县'
                    },
                    {
                        code: '360323', text: '芦溪县'
                    }
                ]
            },
            {
                code: '3604',
                text: '九江市',
                children: [
                    {
                        code: '360402', text: '濂溪区'
                    },
                    {
                        code: '360403', text: '浔阳区'
                    },
                    {
                        code: '360404', text: '柴桑区'
                    },
                    {
                        code: '360423', text: '武宁县'
                    },
                    {
                        code: '360424', text: '修水县'
                    },
                    {
                        code: '360425', text: '永修县'
                    },
                    {
                        code: '360426', text: '德安县'
                    },
                    {
                        code: '360428', text: '都昌县'
                    },
                    {
                        code: '360429', text: '湖口县'
                    },
                    {
                        code: '360430', text: '彭泽县'
                    },
                    {
                        code: '360481', text: '瑞昌市'
                    },
                    {
                        code: '360482', text: '共青城市'
                    },
                    {
                        code: '360483', text: '庐山市'
                    }
                ]
            },
            {
                code: '3605',
                text: '新余市',
                children: [
                    {
                        code: '360502', text: '渝水区'
                    },
                    {
                        code: '360521', text: '分宜县'
                    }
                ]
            },
            {
                code: '3606',
                text: '鹰潭市',
                children: [
                    {
                        code: '360602', text: '月湖区'
                    },
                    {
                        code: '360622', text: '余江县'
                    },
                    {
                        code: '360681', text: '贵溪市'
                    }
                ]
            },
            {
                code: '3607',
                text: '赣州市',
                children: [
                    {
                        code: '360702', text: '章贡区'
                    },
                    {
                        code: '360703', text: '南康区'
                    },
                    {
                        code: '360704', text: '赣县区'
                    },
                    {
                        code: '360722', text: '信丰县'
                    },
                    {
                        code: '360723', text: '大余县'
                    },
                    {
                        code: '360724', text: '上犹县'
                    },
                    {
                        code: '360725', text: '崇义县'
                    },
                    {
                        code: '360726', text: '安远县'
                    },
                    {
                        code: '360727', text: '龙南县'
                    },
                    {
                        code: '360728', text: '定南县'
                    },
                    {
                        code: '360729', text: '全南县'
                    },
                    {
                        code: '360730', text: '宁都县'
                    },
                    {
                        code: '360731', text: '于都县'
                    },
                    {
                        code: '360732', text: '兴国县'
                    },
                    {
                        code: '360733', text: '会昌县'
                    },
                    {
                        code: '360734', text: '寻乌县'
                    },
                    {
                        code: '360735', text: '石城县'
                    },
                    {
                        code: '360781', text: '瑞金市'
                    }
                ]
            },
            {
                code: '3608',
                text: '吉安市',
                children: [
                    {
                        code: '360802', text: '吉州区'
                    },
                    {
                        code: '360803', text: '青原区'
                    },
                    {
                        code: '360821', text: '吉安县'
                    },
                    {
                        code: '360822', text: '吉水县'
                    },
                    {
                        code: '360823', text: '峡江县'
                    },
                    {
                        code: '360824', text: '新干县'
                    },
                    {
                        code: '360825', text: '永丰县'
                    },
                    {
                        code: '360826', text: '泰和县'
                    },
                    {
                        code: '360827', text: '遂川县'
                    },
                    {
                        code: '360828', text: '万安县'
                    },
                    {
                        code: '360829', text: '安福县'
                    },
                    {
                        code: '360830', text: '永新县'
                    },
                    {
                        code: '360881', text: '井冈山市'
                    }
                ]
            },
            {
                code: '3609',
                text: '宜春市',
                children: [
                    {
                        code: '360902', text: '袁州区'
                    },
                    {
                        code: '360921', text: '奉新县'
                    },
                    {
                        code: '360922', text: '万载县'
                    },
                    {
                        code: '360923', text: '上高县'
                    },
                    {
                        code: '360924', text: '宜丰县'
                    },
                    {
                        code: '360925', text: '靖安县'
                    },
                    {
                        code: '360926', text: '铜鼓县'
                    },
                    {
                        code: '360981', text: '丰城市'
                    },
                    {
                        code: '360982', text: '樟树市'
                    },
                    {
                        code: '360983', text: '高安市'
                    }
                ]
            },
            {
                code: '3610',
                text: '抚州市',
                children: [
                    {
                        code: '361002', text: '临川区'
                    },
                    {
                        code: '361003', text: '东乡区'
                    },
                    {
                        code: '361021', text: '南城县'
                    },
                    {
                        code: '361022', text: '黎川县'
                    },
                    {
                        code: '361023', text: '南丰县'
                    },
                    {
                        code: '361024', text: '崇仁县'
                    },
                    {
                        code: '361025', text: '乐安县'
                    },
                    {
                        code: '361026', text: '宜黄县'
                    },
                    {
                        code: '361027', text: '金溪县'
                    },
                    {
                        code: '361028', text: '资溪县'
                    },
                    {
                        code: '361030', text: '广昌县'
                    }
                ]
            },
            {
                code: '3611',
                text: '上饶市',
                children: [
                    {
                        code: '361102', text: '信州区'
                    },
                    {
                        code: '361103', text: '广丰区'
                    },
                    {
                        code: '361121', text: '上饶县'
                    },
                    {
                        code: '361123', text: '玉山县'
                    },
                    {
                        code: '361124', text: '铅山县'
                    },
                    {
                        code: '361125', text: '横峰县'
                    },
                    {
                        code: '361126', text: '弋阳县'
                    },
                    {
                        code: '361127', text: '余干县'
                    },
                    {
                        code: '361128', text: '鄱阳县'
                    },
                    {
                        code: '361129', text: '万年县'
                    },
                    {
                        code: '361130', text: '婺源县'
                    },
                    {
                        code: '361181', text: '德兴市'
                    }
                ]
            }
        ]
    },
    {
        code: '37',
        text: '山东省',
        children: [
            {
                code: '3701',
                text: '济南市',
                children: [
                    {
                        code: '370102', text: '历下区'
                    },
                    {
                        code: '370103', text: '市中区'
                    },
                    {
                        code: '370104', text: '槐荫区'
                    },
                    {
                        code: '370105', text: '天桥区'
                    },
                    {
                        code: '370112', text: '历城区'
                    },
                    {
                        code: '370113', text: '长清区'
                    },
                    {
                        code: '370114', text: '章丘区'
                    },
                    {
                        code: '370124', text: '平阴县'
                    },
                    {
                        code: '370125', text: '济阳县'
                    },
                    {
                        code: '370126', text: '商河县'
                    },
                    {
                        code: '370171', text: '济南高新技术产业开发区'
                    }
                ]
            },
            {
                code: '3702',
                text: '青岛市',
                children: [
                    {
                        code: '370202', text: '市南区'
                    },
                    {
                        code: '370203', text: '市北区'
                    },
                    {
                        code: '370211', text: '黄岛区'
                    },
                    {
                        code: '370212', text: '崂山区'
                    },
                    {
                        code: '370213', text: '李沧区'
                    },
                    {
                        code: '370214', text: '城阳区'
                    },
                    {
                        code: '370215', text: '即墨区'
                    },
                    {
                        code: '370271', text: '青岛高新技术产业开发区'
                    },
                    {
                        code: '370281', text: '胶州市'
                    },
                    {
                        code: '370283', text: '平度市'
                    },
                    {
                        code: '370285', text: '莱西市'
                    }
                ]
            },
            {
                code: '3703',
                text: '淄博市',
                children: [
                    {
                        code: '370302', text: '淄川区'
                    },
                    {
                        code: '370303', text: '张店区'
                    },
                    {
                        code: '370304', text: '博山区'
                    },
                    {
                        code: '370305', text: '临淄区'
                    },
                    {
                        code: '370306', text: '周村区'
                    },
                    {
                        code: '370321', text: '桓台县'
                    },
                    {
                        code: '370322', text: '高青县'
                    },
                    {
                        code: '370323', text: '沂源县'
                    }
                ]
            },
            {
                code: '3704',
                text: '枣庄市',
                children: [
                    {
                        code: '370402', text: '市中区'
                    },
                    {
                        code: '370403', text: '薛城区'
                    },
                    {
                        code: '370404', text: '峄城区'
                    },
                    {
                        code: '370405', text: '台儿庄区'
                    },
                    {
                        code: '370406', text: '山亭区'
                    },
                    {
                        code: '370481', text: '滕州市'
                    }
                ]
            },
            {
                code: '3705',
                text: '东营市',
                children: [
                    {
                        code: '370502', text: '东营区'
                    },
                    {
                        code: '370503', text: '河口区'
                    },
                    {
                        code: '370505', text: '垦利区'
                    },
                    {
                        code: '370522', text: '利津县'
                    },
                    {
                        code: '370523', text: '广饶县'
                    },
                    {
                        code: '370571', text: '东营经济技术开发区'
                    },
                    {
                        code: '370572', text: '东营港经济开发区'
                    }
                ]
            },
            {
                code: '3706',
                text: '烟台市',
                children: [
                    {
                        code: '370602', text: '芝罘区'
                    },
                    {
                        code: '370611', text: '福山区'
                    },
                    {
                        code: '370612', text: '牟平区'
                    },
                    {
                        code: '370613', text: '莱山区'
                    },
                    {
                        code: '370634', text: '长岛县'
                    },
                    {
                        code: '370671', text: '烟台高新技术产业开发区'
                    },
                    {
                        code: '370672', text: '烟台经济技术开发区'
                    },
                    {
                        code: '370681', text: '龙口市'
                    },
                    {
                        code: '370682', text: '莱阳市'
                    },
                    {
                        code: '370683', text: '莱州市'
                    },
                    {
                        code: '370684', text: '蓬莱市'
                    },
                    {
                        code: '370685', text: '招远市'
                    },
                    {
                        code: '370686', text: '栖霞市'
                    },
                    {
                        code: '370687', text: '海阳市'
                    }
                ]
            },
            {
                code: '3707',
                text: '潍坊市',
                children: [
                    {
                        code: '370702', text: '潍城区'
                    },
                    {
                        code: '370703', text: '寒亭区'
                    },
                    {
                        code: '370704', text: '坊子区'
                    },
                    {
                        code: '370705', text: '奎文区'
                    },
                    {
                        code: '370724', text: '临朐县'
                    },
                    {
                        code: '370725', text: '昌乐县'
                    },
                    {
                        code: '370772', text: '潍坊滨海经济技术开发区'
                    },
                    {
                        code: '370781', text: '青州市'
                    },
                    {
                        code: '370782', text: '诸城市'
                    },
                    {
                        code: '370783', text: '寿光市'
                    },
                    {
                        code: '370784', text: '安丘市'
                    },
                    {
                        code: '370785', text: '高密市'
                    },
                    {
                        code: '370786', text: '昌邑市'
                    }
                ]
            },
            {
                code: '3708',
                text: '济宁市',
                children: [
                    {
                        code: '370811', text: '任城区'
                    },
                    {
                        code: '370812', text: '兖州区'
                    },
                    {
                        code: '370826', text: '微山县'
                    },
                    {
                        code: '370827', text: '鱼台县'
                    },
                    {
                        code: '370828', text: '金乡县'
                    },
                    {
                        code: '370829', text: '嘉祥县'
                    },
                    {
                        code: '370830', text: '汶上县'
                    },
                    {
                        code: '370831', text: '泗水县'
                    },
                    {
                        code: '370832', text: '梁山县'
                    },
                    {
                        code: '370871', text: '济宁高新技术产业开发区'
                    },
                    {
                        code: '370881', text: '曲阜市'
                    },
                    {
                        code: '370883', text: '邹城市'
                    }
                ]
            },
            {
                code: '3709',
                text: '泰安市',
                children: [
                    {
                        code: '370902', text: '泰山区'
                    },
                    {
                        code: '370911', text: '岱岳区'
                    },
                    {
                        code: '370921', text: '宁阳县'
                    },
                    {
                        code: '370923', text: '东平县'
                    },
                    {
                        code: '370982', text: '新泰市'
                    },
                    {
                        code: '370983', text: '肥城市'
                    }
                ]
            },
            {
                code: '3710',
                text: '威海市',
                children: [
                    {
                        code: '371002', text: '环翠区'
                    },
                    {
                        code: '371003', text: '文登区'
                    },
                    {
                        code: '371071', text: '威海火炬高技术产业开发区'
                    },
                    {
                        code: '371072', text: '威海经济技术开发区'
                    },
                    {
                        code: '371073', text: '威海临港经济技术开发区'
                    },
                    {
                        code: '371082', text: '荣成市'
                    },
                    {
                        code: '371083', text: '乳山市'
                    }
                ]
            },
            {
                code: '3711',
                text: '日照市',
                children: [
                    {
                        code: '371102', text: '东港区'
                    },
                    {
                        code: '371103', text: '岚山区'
                    },
                    {
                        code: '371121', text: '五莲县'
                    },
                    {
                        code: '371122', text: '莒县'
                    },
                    {
                        code: '371171', text: '日照经济技术开发区'
                    },
                    {
                        code: '371172', text: '日照国际海洋城'
                    }
                ]
            },
            {
                code: '3712',
                text: '莱芜市',
                children: [
                    {
                        code: '371202', text: '莱城区'
                    },
                    {
                        code: '371203', text: '钢城区'
                    }
                ]
            },
            {
                code: '3713',
                text: '临沂市',
                children: [
                    {
                        code: '371302', text: '兰山区'
                    },
                    {
                        code: '371311', text: '罗庄区'
                    },
                    {
                        code: '371312', text: '河东区'
                    },
                    {
                        code: '371321', text: '沂南县'
                    },
                    {
                        code: '371322', text: '郯城县'
                    },
                    {
                        code: '371323', text: '沂水县'
                    },
                    {
                        code: '371324', text: '兰陵县'
                    },
                    {
                        code: '371325', text: '费县'
                    },
                    {
                        code: '371326', text: '平邑县'
                    },
                    {
                        code: '371327', text: '莒南县'
                    },
                    {
                        code: '371328', text: '蒙阴县'
                    },
                    {
                        code: '371329', text: '临沭县'
                    },
                    {
                        code: '371371', text: '临沂高新技术产业开发区'
                    },
                    {
                        code: '371372', text: '临沂经济技术开发区'
                    },
                    {
                        code: '371373', text: '临沂临港经济开发区'
                    }
                ]
            },
            {
                code: '3714',
                text: '德州市',
                children: [
                    {
                        code: '371402', text: '德城区'
                    },
                    {
                        code: '371403', text: '陵城区'
                    },
                    {
                        code: '371422', text: '宁津县'
                    },
                    {
                        code: '371423', text: '庆云县'
                    },
                    {
                        code: '371424', text: '临邑县'
                    },
                    {
                        code: '371425', text: '齐河县'
                    },
                    {
                        code: '371426', text: '平原县'
                    },
                    {
                        code: '371427', text: '夏津县'
                    },
                    {
                        code: '371428', text: '武城县'
                    },
                    {
                        code: '371471', text: '德州经济技术开发区'
                    },
                    {
                        code: '371472', text: '德州运河经济开发区'
                    },
                    {
                        code: '371481', text: '乐陵市'
                    },
                    {
                        code: '371482', text: '禹城市'
                    }
                ]
            },
            {
                code: '3715',
                text: '聊城市',
                children: [
                    {
                        code: '371502', text: '东昌府区'
                    },
                    {
                        code: '371521', text: '阳谷县'
                    },
                    {
                        code: '371522', text: '莘县'
                    },
                    {
                        code: '371523', text: '茌平县'
                    },
                    {
                        code: '371524', text: '东阿县'
                    },
                    {
                        code: '371525', text: '冠县'
                    },
                    {
                        code: '371526', text: '高唐县'
                    },
                    {
                        code: '371581', text: '临清市'
                    }
                ]
            },
            {
                code: '3716',
                text: '滨州市',
                children: [
                    {
                        code: '371602', text: '滨城区'
                    },
                    {
                        code: '371603', text: '沾化区'
                    },
                    {
                        code: '371621', text: '惠民县'
                    },
                    {
                        code: '371622', text: '阳信县'
                    },
                    {
                        code: '371623', text: '无棣县'
                    },
                    {
                        code: '371625', text: '博兴县'
                    },
                    {
                        code: '371626', text: '邹平县'
                    }
                ]
            },
            {
                code: '3717',
                text: '菏泽市',
                children: [
                    {
                        code: '371702', text: '牡丹区'
                    },
                    {
                        code: '371703', text: '定陶区'
                    },
                    {
                        code: '371721', text: '曹县'
                    },
                    {
                        code: '371722', text: '单县'
                    },
                    {
                        code: '371723', text: '成武县'
                    },
                    {
                        code: '371724', text: '巨野县'
                    },
                    {
                        code: '371725', text: '郓城县'
                    },
                    {
                        code: '371726', text: '鄄城县'
                    },
                    {
                        code: '371728', text: '东明县'
                    },
                    {
                        code: '371771', text: '菏泽经济技术开发区'
                    },
                    {
                        code: '371772', text: '菏泽高新技术开发区'
                    }
                ]
            }
        ]
    },
    {
        code: '41',
        text: '河南省',
        children: [
            {
                code: '4101',
                text: '郑州市',
                children: [
                    {
                        code: '410102', text: '中原区'
                    },
                    {
                        code: '410103', text: '二七区'
                    },
                    {
                        code: '410104', text: '管城回族区'
                    },
                    {
                        code: '410105', text: '金水区'
                    },
                    {
                        code: '410106', text: '上街区'
                    },
                    {
                        code: '410108', text: '惠济区'
                    },
                    {
                        code: '410122', text: '中牟县'
                    },
                    {
                        code: '410171', text: '郑州经济技术开发区'
                    },
                    {
                        code: '410172', text: '郑州高新技术产业开发区'
                    },
                    {
                        code: '410173', text: '郑州航空港经济综合实验区'
                    },
                    {
                        code: '410181', text: '巩义市'
                    },
                    {
                        code: '410182', text: '荥阳市'
                    },
                    {
                        code: '410183', text: '新密市'
                    },
                    {
                        code: '410184', text: '新郑市'
                    },
                    {
                        code: '410185', text: '登封市'
                    }
                ]
            },
            {
                code: '4102',
                text: '开封市',
                children: [
                    {
                        code: '410202', text: '龙亭区'
                    },
                    {
                        code: '410203', text: '顺河回族区'
                    },
                    {
                        code: '410204', text: '鼓楼区'
                    },
                    {
                        code: '410205', text: '禹王台区'
                    },
                    {
                        code: '410212', text: '祥符区'
                    },
                    {
                        code: '410221', text: '杞县'
                    },
                    {
                        code: '410222', text: '通许县'
                    },
                    {
                        code: '410223', text: '尉氏县'
                    },
                    {
                        code: '410225', text: '兰考县'
                    }
                ]
            },
            {
                code: '4103',
                text: '洛阳市',
                children: [
                    {
                        code: '410302', text: '老城区'
                    },
                    {
                        code: '410303', text: '西工区'
                    },
                    {
                        code: '410304', text: '瀍河回族区'
                    },
                    {
                        code: '410305', text: '涧西区'
                    },
                    {
                        code: '410306', text: '吉利区'
                    },
                    {
                        code: '410311', text: '洛龙区'
                    },
                    {
                        code: '410322', text: '孟津县'
                    },
                    {
                        code: '410323', text: '新安县'
                    },
                    {
                        code: '410324', text: '栾川县'
                    },
                    {
                        code: '410325', text: '嵩县'
                    },
                    {
                        code: '410326', text: '汝阳县'
                    },
                    {
                        code: '410327', text: '宜阳县'
                    },
                    {
                        code: '410328', text: '洛宁县'
                    },
                    {
                        code: '410329', text: '伊川县'
                    },
                    {
                        code: '410371', text: '洛阳高新技术产业开发区'
                    },
                    {
                        code: '410381', text: '偃师市'
                    }
                ]
            },
            {
                code: '4104',
                text: '平顶山市',
                children: [
                    {
                        code: '410402', text: '新华区'
                    },
                    {
                        code: '410403', text: '卫东区'
                    },
                    {
                        code: '410404', text: '石龙区'
                    },
                    {
                        code: '410411', text: '湛河区'
                    },
                    {
                        code: '410421', text: '宝丰县'
                    },
                    {
                        code: '410422', text: '叶县'
                    },
                    {
                        code: '410423', text: '鲁山县'
                    },
                    {
                        code: '410425', text: '郏县'
                    },
                    {
                        code: '410471', text: '平顶山高新技术产业开发区'
                    },
                    {
                        code: '410472', text: '平顶山市新城区'
                    },
                    {
                        code: '410481', text: '舞钢市'
                    },
                    {
                        code: '410482', text: '汝州市'
                    }
                ]
            },
            {
                code: '4105',
                text: '安阳市',
                children: [
                    {
                        code: '410502', text: '文峰区'
                    },
                    {
                        code: '410503', text: '北关区'
                    },
                    {
                        code: '410505', text: '殷都区'
                    },
                    {
                        code: '410506', text: '龙安区'
                    },
                    {
                        code: '410522', text: '安阳县'
                    },
                    {
                        code: '410523', text: '汤阴县'
                    },
                    {
                        code: '410526', text: '滑县'
                    },
                    {
                        code: '410527', text: '内黄县'
                    },
                    {
                        code: '410571', text: '安阳高新技术产业开发区'
                    },
                    {
                        code: '410581', text: '林州市'
                    }
                ]
            },
            {
                code: '4106',
                text: '鹤壁市',
                children: [
                    {
                        code: '410602', text: '鹤山区'
                    },
                    {
                        code: '410603', text: '山城区'
                    },
                    {
                        code: '410611', text: '淇滨区'
                    },
                    {
                        code: '410621', text: '浚县'
                    },
                    {
                        code: '410622', text: '淇县'
                    },
                    {
                        code: '410671', text: '鹤壁经济技术开发区'
                    }
                ]
            },
            {
                code: '4107',
                text: '新乡市',
                children: [
                    {
                        code: '410702', text: '红旗区'
                    },
                    {
                        code: '410703', text: '卫滨区'
                    },
                    {
                        code: '410704', text: '凤泉区'
                    },
                    {
                        code: '410711', text: '牧野区'
                    },
                    {
                        code: '410721', text: '新乡县'
                    },
                    {
                        code: '410724', text: '获嘉县'
                    },
                    {
                        code: '410725', text: '原阳县'
                    },
                    {
                        code: '410726', text: '延津县'
                    },
                    {
                        code: '410727', text: '封丘县'
                    },
                    {
                        code: '410728', text: '长垣县'
                    },
                    {
                        code: '410771', text: '新乡高新技术产业开发区'
                    },
                    {
                        code: '410772', text: '新乡经济技术开发区'
                    },
                    {
                        code: '410773', text: '新乡市平原城乡一体化示范区'
                    },
                    {
                        code: '410781', text: '卫辉市'
                    },
                    {
                        code: '410782', text: '辉县市'
                    }
                ]
            },
            {
                code: '4108',
                text: '焦作市',
                children: [
                    {
                        code: '410802', text: '解放区'
                    },
                    {
                        code: '410803', text: '中站区'
                    },
                    {
                        code: '410804', text: '马村区'
                    },
                    {
                        code: '410811', text: '山阳区'
                    },
                    {
                        code: '410821', text: '修武县'
                    },
                    {
                        code: '410822', text: '博爱县'
                    },
                    {
                        code: '410823', text: '武陟县'
                    },
                    {
                        code: '410825', text: '温县'
                    },
                    {
                        code: '410871', text: '焦作城乡一体化示范区'
                    },
                    {
                        code: '410882', text: '沁阳市'
                    },
                    {
                        code: '410883', text: '孟州市'
                    }
                ]
            },
            {
                code: '4109',
                text: '濮阳市',
                children: [
                    {
                        code: '410902', text: '华龙区'
                    },
                    {
                        code: '410922', text: '清丰县'
                    },
                    {
                        code: '410923', text: '南乐县'
                    },
                    {
                        code: '410926', text: '范县'
                    },
                    {
                        code: '410927', text: '台前县'
                    },
                    {
                        code: '410928', text: '濮阳县'
                    },
                    {
                        code: '410971', text: '河南濮阳工业园区'
                    },
                    {
                        code: '410972', text: '濮阳经济技术开发区'
                    }
                ]
            },
            {
                code: '4110',
                text: '许昌市',
                children: [
                    {
                        code: '411002', text: '魏都区'
                    },
                    {
                        code: '411003', text: '建安区'
                    },
                    {
                        code: '411024', text: '鄢陵县'
                    },
                    {
                        code: '411025', text: '襄城县'
                    },
                    {
                        code: '411071', text: '许昌经济技术开发区'
                    },
                    {
                        code: '411081', text: '禹州市'
                    },
                    {
                        code: '411082', text: '长葛市'
                    }
                ]
            },
            {
                code: '4111',
                text: '漯河市',
                children: [
                    {
                        code: '411102', text: '源汇区'
                    },
                    {
                        code: '411103', text: '郾城区'
                    },
                    {
                        code: '411104', text: '召陵区'
                    },
                    {
                        code: '411121', text: '舞阳县'
                    },
                    {
                        code: '411122', text: '临颍县'
                    },
                    {
                        code: '411171', text: '漯河经济技术开发区'
                    }
                ]
            },
            {
                code: '4112',
                text: '三门峡市',
                children: [
                    {
                        code: '411202', text: '湖滨区'
                    },
                    {
                        code: '411203', text: '陕州区'
                    },
                    {
                        code: '411221', text: '渑池县'
                    },
                    {
                        code: '411224', text: '卢氏县'
                    },
                    {
                        code: '411271', text: '河南三门峡经济开发区'
                    },
                    {
                        code: '411281', text: '义马市'
                    },
                    {
                        code: '411282', text: '灵宝市'
                    }
                ]
            },
            {
                code: '4113',
                text: '南阳市',
                children: [
                    {
                        code: '411302', text: '宛城区'
                    },
                    {
                        code: '411303', text: '卧龙区'
                    },
                    {
                        code: '411321', text: '南召县'
                    },
                    {
                        code: '411322', text: '方城县'
                    },
                    {
                        code: '411323', text: '西峡县'
                    },
                    {
                        code: '411324', text: '镇平县'
                    },
                    {
                        code: '411325', text: '内乡县'
                    },
                    {
                        code: '411326', text: '淅川县'
                    },
                    {
                        code: '411327', text: '社旗县'
                    },
                    {
                        code: '411328', text: '唐河县'
                    },
                    {
                        code: '411329', text: '新野县'
                    },
                    {
                        code: '411330', text: '桐柏县'
                    },
                    {
                        code: '411371', text: '南阳高新技术产业开发区'
                    },
                    {
                        code: '411372', text: '南阳市城乡一体化示范区'
                    },
                    {
                        code: '411381', text: '邓州市'
                    }
                ]
            },
            {
                code: '4114',
                text: '商丘市',
                children: [
                    {
                        code: '411402', text: '梁园区'
                    },
                    {
                        code: '411403', text: '睢阳区'
                    },
                    {
                        code: '411421', text: '民权县'
                    },
                    {
                        code: '411422', text: '睢县'
                    },
                    {
                        code: '411423', text: '宁陵县'
                    },
                    {
                        code: '411424', text: '柘城县'
                    },
                    {
                        code: '411425', text: '虞城县'
                    },
                    {
                        code: '411426', text: '夏邑县'
                    },
                    {
                        code: '411471', text: '豫东综合物流产业聚集区'
                    },
                    {
                        code: '411472', text: '河南商丘经济开发区'
                    },
                    {
                        code: '411481', text: '永城市'
                    }
                ]
            },
            {
                code: '4115',
                text: '信阳市',
                children: [
                    {
                        code: '411502', text: '浉河区'
                    },
                    {
                        code: '411503', text: '平桥区'
                    },
                    {
                        code: '411521', text: '罗山县'
                    },
                    {
                        code: '411522', text: '光山县'
                    },
                    {
                        code: '411523', text: '新县'
                    },
                    {
                        code: '411524', text: '商城县'
                    },
                    {
                        code: '411525', text: '固始县'
                    },
                    {
                        code: '411526', text: '潢川县'
                    },
                    {
                        code: '411527', text: '淮滨县'
                    },
                    {
                        code: '411528', text: '息县'
                    },
                    {
                        code: '411571', text: '信阳高新技术产业开发区'
                    }
                ]
            },
            {
                code: '4116',
                text: '周口市',
                children: [
                    {
                        code: '411602', text: '川汇区'
                    },
                    {
                        code: '411621', text: '扶沟县'
                    },
                    {
                        code: '411622', text: '西华县'
                    },
                    {
                        code: '411623', text: '商水县'
                    },
                    {
                        code: '411624', text: '沈丘县'
                    },
                    {
                        code: '411625', text: '郸城县'
                    },
                    {
                        code: '411626', text: '淮阳县'
                    },
                    {
                        code: '411627', text: '太康县'
                    },
                    {
                        code: '411628', text: '鹿邑县'
                    },
                    {
                        code: '411671', text: '河南周口经济开发区'
                    },
                    {
                        code: '411681', text: '项城市'
                    }
                ]
            },
            {
                code: '4117',
                text: '驻马店市',
                children: [
                    {
                        code: '411702', text: '驿城区'
                    },
                    {
                        code: '411721', text: '西平县'
                    },
                    {
                        code: '411722', text: '上蔡县'
                    },
                    {
                        code: '411723', text: '平舆县'
                    },
                    {
                        code: '411724', text: '正阳县'
                    },
                    {
                        code: '411725', text: '确山县'
                    },
                    {
                        code: '411726', text: '泌阳县'
                    },
                    {
                        code: '411727', text: '汝南县'
                    },
                    {
                        code: '411728', text: '遂平县'
                    },
                    {
                        code: '411729', text: '新蔡县'
                    },
                    {
                        code: '411771', text: '河南驻马店经济开发区'
                    }
                ]
            },
            {
                code: '4190',
                text: '省直辖县级行政区划',
                children: [
                    {
                        code: '419001', text: '济源市'
                    }
                ]
            }
        ]
    },
    {
        code: '42',
        text: '湖北省',
        children: [
            {
                code: '4201',
                text: '武汉市',
                children: [
                    {
                        code: '420102', text: '江岸区'
                    },
                    {
                        code: '420103', text: '江汉区'
                    },
                    {
                        code: '420104', text: '硚口区'
                    },
                    {
                        code: '420105', text: '汉阳区'
                    },
                    {
                        code: '420106', text: '武昌区'
                    },
                    {
                        code: '420107', text: '青山区'
                    },
                    {
                        code: '420111', text: '洪山区'
                    },
                    {
                        code: '420112', text: '东西湖区'
                    },
                    {
                        code: '420113', text: '汉南区'
                    },
                    {
                        code: '420114', text: '蔡甸区'
                    },
                    {
                        code: '420115', text: '江夏区'
                    },
                    {
                        code: '420116', text: '黄陂区'
                    },
                    {
                        code: '420117', text: '新洲区'
                    }
                ]
            },
            {
                code: '4202',
                text: '黄石市',
                children: [
                    {
                        code: '420202', text: '黄石港区'
                    },
                    {
                        code: '420203', text: '西塞山区'
                    },
                    {
                        code: '420204', text: '下陆区'
                    },
                    {
                        code: '420205', text: '铁山区'
                    },
                    {
                        code: '420222', text: '阳新县'
                    },
                    {
                        code: '420281', text: '大冶市'
                    }
                ]
            },
            {
                code: '4203',
                text: '十堰市',
                children: [
                    {
                        code: '420302', text: '茅箭区'
                    },
                    {
                        code: '420303', text: '张湾区'
                    },
                    {
                        code: '420304', text: '郧阳区'
                    },
                    {
                        code: '420322', text: '郧西县'
                    },
                    {
                        code: '420323', text: '竹山县'
                    },
                    {
                        code: '420324', text: '竹溪县'
                    },
                    {
                        code: '420325', text: '房县'
                    },
                    {
                        code: '420381', text: '丹江口市'
                    }
                ]
            },
            {
                code: '4205',
                text: '宜昌市',
                children: [
                    {
                        code: '420502', text: '西陵区'
                    },
                    {
                        code: '420503', text: '伍家岗区'
                    },
                    {
                        code: '420504', text: '点军区'
                    },
                    {
                        code: '420505', text: '猇亭区'
                    },
                    {
                        code: '420506', text: '夷陵区'
                    },
                    {
                        code: '420525', text: '远安县'
                    },
                    {
                        code: '420526', text: '兴山县'
                    },
                    {
                        code: '420527', text: '秭归县'
                    },
                    {
                        code: '420528', text: '长阳土家族自治县'
                    },
                    {
                        code: '420529', text: '五峰土家族自治县'
                    },
                    {
                        code: '420581', text: '宜都市'
                    },
                    {
                        code: '420582', text: '当阳市'
                    },
                    {
                        code: '420583', text: '枝江市'
                    }
                ]
            },
            {
                code: '4206',
                text: '襄阳市',
                children: [
                    {
                        code: '420602', text: '襄城区'
                    },
                    {
                        code: '420606', text: '樊城区'
                    },
                    {
                        code: '420607', text: '襄州区'
                    },
                    {
                        code: '420624', text: '南漳县'
                    },
                    {
                        code: '420625', text: '谷城县'
                    },
                    {
                        code: '420626', text: '保康县'
                    },
                    {
                        code: '420682', text: '老河口市'
                    },
                    {
                        code: '420683', text: '枣阳市'
                    },
                    {
                        code: '420684', text: '宜城市'
                    }
                ]
            },
            {
                code: '4207',
                text: '鄂州市',
                children: [
                    {
                        code: '420702', text: '梁子湖区'
                    },
                    {
                        code: '420703', text: '华容区'
                    },
                    {
                        code: '420704', text: '鄂城区'
                    }
                ]
            },
            {
                code: '4208',
                text: '荆门市',
                children: [
                    {
                        code: '420802', text: '东宝区'
                    },
                    {
                        code: '420804', text: '掇刀区'
                    },
                    {
                        code: '420821', text: '京山县'
                    },
                    {
                        code: '420822', text: '沙洋县'
                    },
                    {
                        code: '420881', text: '钟祥市'
                    }
                ]
            },
            {
                code: '4209',
                text: '孝感市',
                children: [
                    {
                        code: '420902', text: '孝南区'
                    },
                    {
                        code: '420921', text: '孝昌县'
                    },
                    {
                        code: '420922', text: '大悟县'
                    },
                    {
                        code: '420923', text: '云梦县'
                    },
                    {
                        code: '420981', text: '应城市'
                    },
                    {
                        code: '420982', text: '安陆市'
                    },
                    {
                        code: '420984', text: '汉川市'
                    }
                ]
            },
            {
                code: '4210',
                text: '荆州市',
                children: [
                    {
                        code: '421002', text: '沙市区'
                    },
                    {
                        code: '421003', text: '荆州区'
                    },
                    {
                        code: '421022', text: '公安县'
                    },
                    {
                        code: '421023', text: '监利县'
                    },
                    {
                        code: '421024', text: '江陵县'
                    },
                    {
                        code: '421071', text: '荆州经济技术开发区'
                    },
                    {
                        code: '421081', text: '石首市'
                    },
                    {
                        code: '421083', text: '洪湖市'
                    },
                    {
                        code: '421087', text: '松滋市'
                    }
                ]
            },
            {
                code: '4211',
                text: '黄冈市',
                children: [
                    {
                        code: '421102', text: '黄州区'
                    },
                    {
                        code: '421121', text: '团风县'
                    },
                    {
                        code: '421122', text: '红安县'
                    },
                    {
                        code: '421123', text: '罗田县'
                    },
                    {
                        code: '421124', text: '英山县'
                    },
                    {
                        code: '421125', text: '浠水县'
                    },
                    {
                        code: '421126', text: '蕲春县'
                    },
                    {
                        code: '421127', text: '黄梅县'
                    },
                    {
                        code: '421171', text: '龙感湖管理区'
                    },
                    {
                        code: '421181', text: '麻城市'
                    },
                    {
                        code: '421182', text: '武穴市'
                    }
                ]
            },
            {
                code: '4212',
                text: '咸宁市',
                children: [
                    {
                        code: '421202', text: '咸安区'
                    },
                    {
                        code: '421221', text: '嘉鱼县'
                    },
                    {
                        code: '421222', text: '通城县'
                    },
                    {
                        code: '421223', text: '崇阳县'
                    },
                    {
                        code: '421224', text: '通山县'
                    },
                    {
                        code: '421281', text: '赤壁市'
                    }
                ]
            },
            {
                code: '4213',
                text: '随州市',
                children: [
                    {
                        code: '421303', text: '曾都区'
                    },
                    {
                        code: '421321', text: '随县'
                    },
                    {
                        code: '421381', text: '广水市'
                    }
                ]
            },
            {
                code: '4228',
                text: '恩施土家族苗族自治州',
                children: [
                    {
                        code: '422801', text: '恩施市'
                    },
                    {
                        code: '422802', text: '利川市'
                    },
                    {
                        code: '422822', text: '建始县'
                    },
                    {
                        code: '422823', text: '巴东县'
                    },
                    {
                        code: '422825', text: '宣恩县'
                    },
                    {
                        code: '422826', text: '咸丰县'
                    },
                    {
                        code: '422827', text: '来凤县'
                    },
                    {
                        code: '422828', text: '鹤峰县'
                    }
                ]
            },
            {
                code: '4290',
                text: '省直辖县级行政区划',
                children: [
                    {
                        code: '429004', text: '仙桃市'
                    },
                    {
                        code: '429005', text: '潜江市'
                    },
                    {
                        code: '429006', text: '天门市'
                    },
                    {
                        code: '429021', text: '神农架林区'
                    }
                ]
            }
        ]
    },
    {
        code: '43',
        text: '湖南省',
        children: [
            {
                code: '4301',
                text: '长沙市',
                children: [
                    {
                        code: '430102', text: '芙蓉区'
                    },
                    {
                        code: '430103', text: '天心区'
                    },
                    {
                        code: '430104', text: '岳麓区'
                    },
                    {
                        code: '430105', text: '开福区'
                    },
                    {
                        code: '430111', text: '雨花区'
                    },
                    {
                        code: '430112', text: '望城区'
                    },
                    {
                        code: '430121', text: '长沙县'
                    },
                    {
                        code: '430181', text: '浏阳市'
                    },
                    {
                        code: '430182', text: '宁乡市'
                    }
                ]
            },
            {
                code: '4302',
                text: '株洲市',
                children: [
                    {
                        code: '430202', text: '荷塘区'
                    },
                    {
                        code: '430203', text: '芦淞区'
                    },
                    {
                        code: '430204', text: '石峰区'
                    },
                    {
                        code: '430211', text: '天元区'
                    },
                    {
                        code: '430221', text: '株洲县'
                    },
                    {
                        code: '430223', text: '攸县'
                    },
                    {
                        code: '430224', text: '茶陵县'
                    },
                    {
                        code: '430225', text: '炎陵县'
                    },
                    {
                        code: '430271', text: '云龙示范区'
                    },
                    {
                        code: '430281', text: '醴陵市'
                    }
                ]
            },
            {
                code: '4303',
                text: '湘潭市',
                children: [
                    {
                        code: '430302', text: '雨湖区'
                    },
                    {
                        code: '430304', text: '岳塘区'
                    },
                    {
                        code: '430321', text: '湘潭县'
                    },
                    {
                        code: '430371', text: '湖南湘潭高新技术产业园区'
                    },
                    {
                        code: '430372', text: '湘潭昭山示范区'
                    },
                    {
                        code: '430373', text: '湘潭九华示范区'
                    },
                    {
                        code: '430381', text: '湘乡市'
                    },
                    {
                        code: '430382', text: '韶山市'
                    }
                ]
            },
            {
                code: '4304',
                text: '衡阳市',
                children: [
                    {
                        code: '430405', text: '珠晖区'
                    },
                    {
                        code: '430406', text: '雁峰区'
                    },
                    {
                        code: '430407', text: '石鼓区'
                    },
                    {
                        code: '430408', text: '蒸湘区'
                    },
                    {
                        code: '430412', text: '南岳区'
                    },
                    {
                        code: '430421', text: '衡阳县'
                    },
                    {
                        code: '430422', text: '衡南县'
                    },
                    {
                        code: '430423', text: '衡山县'
                    },
                    {
                        code: '430424', text: '衡东县'
                    },
                    {
                        code: '430426', text: '祁东县'
                    },
                    {
                        code: '430471', text: '衡阳综合保税区'
                    },
                    {
                        code: '430472', text: '湖南衡阳高新技术产业园区'
                    },
                    {
                        code: '430473', text: '湖南衡阳松木经济开发区'
                    },
                    {
                        code: '430481', text: '耒阳市'
                    },
                    {
                        code: '430482', text: '常宁市'
                    }
                ]
            },
            {
                code: '4305',
                text: '邵阳市',
                children: [
                    {
                        code: '430502', text: '双清区'
                    },
                    {
                        code: '430503', text: '大祥区'
                    },
                    {
                        code: '430511', text: '北塔区'
                    },
                    {
                        code: '430521', text: '邵东县'
                    },
                    {
                        code: '430522', text: '新邵县'
                    },
                    {
                        code: '430523', text: '邵阳县'
                    },
                    {
                        code: '430524', text: '隆回县'
                    },
                    {
                        code: '430525', text: '洞口县'
                    },
                    {
                        code: '430527', text: '绥宁县'
                    },
                    {
                        code: '430528', text: '新宁县'
                    },
                    {
                        code: '430529', text: '城步苗族自治县'
                    },
                    {
                        code: '430581', text: '武冈市'
                    }
                ]
            },
            {
                code: '4306',
                text: '岳阳市',
                children: [
                    {
                        code: '430602', text: '岳阳楼区'
                    },
                    {
                        code: '430603', text: '云溪区'
                    },
                    {
                        code: '430611', text: '君山区'
                    },
                    {
                        code: '430621', text: '岳阳县'
                    },
                    {
                        code: '430623', text: '华容县'
                    },
                    {
                        code: '430624', text: '湘阴县'
                    },
                    {
                        code: '430626', text: '平江县'
                    },
                    {
                        code: '430671', text: '岳阳市屈原管理区'
                    },
                    {
                        code: '430681', text: '汨罗市'
                    },
                    {
                        code: '430682', text: '临湘市'
                    }
                ]
            },
            {
                code: '4307',
                text: '常德市',
                children: [
                    {
                        code: '430702', text: '武陵区'
                    },
                    {
                        code: '430703', text: '鼎城区'
                    },
                    {
                        code: '430721', text: '安乡县'
                    },
                    {
                        code: '430722', text: '汉寿县'
                    },
                    {
                        code: '430723', text: '澧县'
                    },
                    {
                        code: '430724', text: '临澧县'
                    },
                    {
                        code: '430725', text: '桃源县'
                    },
                    {
                        code: '430726', text: '石门县'
                    },
                    {
                        code: '430771', text: '常德市西洞庭管理区'
                    },
                    {
                        code: '430781', text: '津市市'
                    }
                ]
            },
            {
                code: '4308',
                text: '张家界市',
                children: [
                    {
                        code: '430802', text: '永定区'
                    },
                    {
                        code: '430811', text: '武陵源区'
                    },
                    {
                        code: '430821', text: '慈利县'
                    },
                    {
                        code: '430822', text: '桑植县'
                    }
                ]
            },
            {
                code: '4309',
                text: '益阳市',
                children: [
                    {
                        code: '430902', text: '资阳区'
                    },
                    {
                        code: '430903', text: '赫山区'
                    },
                    {
                        code: '430921', text: '南县'
                    },
                    {
                        code: '430922', text: '桃江县'
                    },
                    {
                        code: '430923', text: '安化县'
                    },
                    {
                        code: '430971', text: '益阳市大通湖管理区'
                    },
                    {
                        code: '430972', text: '湖南益阳高新技术产业园区'
                    },
                    {
                        code: '430981', text: '沅江市'
                    }
                ]
            },
            {
                code: '4310',
                text: '郴州市',
                children: [
                    {
                        code: '431002', text: '北湖区'
                    },
                    {
                        code: '431003', text: '苏仙区'
                    },
                    {
                        code: '431021', text: '桂阳县'
                    },
                    {
                        code: '431022', text: '宜章县'
                    },
                    {
                        code: '431023', text: '永兴县'
                    },
                    {
                        code: '431024', text: '嘉禾县'
                    },
                    {
                        code: '431025', text: '临武县'
                    },
                    {
                        code: '431026', text: '汝城县'
                    },
                    {
                        code: '431027', text: '桂东县'
                    },
                    {
                        code: '431028', text: '安仁县'
                    },
                    {
                        code: '431081', text: '资兴市'
                    }
                ]
            },
            {
                code: '4311',
                text: '永州市',
                children: [
                    {
                        code: '431102', text: '零陵区'
                    },
                    {
                        code: '431103', text: '冷水滩区'
                    },
                    {
                        code: '431121', text: '祁阳县'
                    },
                    {
                        code: '431122', text: '东安县'
                    },
                    {
                        code: '431123', text: '双牌县'
                    },
                    {
                        code: '431124', text: '道县'
                    },
                    {
                        code: '431125', text: '江永县'
                    },
                    {
                        code: '431126', text: '宁远县'
                    },
                    {
                        code: '431127', text: '蓝山县'
                    },
                    {
                        code: '431128', text: '新田县'
                    },
                    {
                        code: '431129', text: '江华瑶族自治县'
                    },
                    {
                        code: '431171', text: '永州经济技术开发区'
                    },
                    {
                        code: '431172', text: '永州市金洞管理区'
                    },
                    {
                        code: '431173', text: '永州市回龙圩管理区'
                    }
                ]
            },
            {
                code: '4312',
                text: '怀化市',
                children: [
                    {
                        code: '431202', text: '鹤城区'
                    },
                    {
                        code: '431221', text: '中方县'
                    },
                    {
                        code: '431222', text: '沅陵县'
                    },
                    {
                        code: '431223', text: '辰溪县'
                    },
                    {
                        code: '431224', text: '溆浦县'
                    },
                    {
                        code: '431225', text: '会同县'
                    },
                    {
                        code: '431226', text: '麻阳苗族自治县'
                    },
                    {
                        code: '431227', text: '新晃侗族自治县'
                    },
                    {
                        code: '431228', text: '芷江侗族自治县'
                    },
                    {
                        code: '431229', text: '靖州苗族侗族自治县'
                    },
                    {
                        code: '431230', text: '通道侗族自治县'
                    },
                    {
                        code: '431271', text: '怀化市洪江管理区'
                    },
                    {
                        code: '431281', text: '洪江市'
                    }
                ]
            },
            {
                code: '4313',
                text: '娄底市',
                children: [
                    {
                        code: '431302', text: '娄星区'
                    },
                    {
                        code: '431321', text: '双峰县'
                    },
                    {
                        code: '431322', text: '新化县'
                    },
                    {
                        code: '431381', text: '冷水江市'
                    },
                    {
                        code: '431382', text: '涟源市'
                    }
                ]
            },
            {
                code: '4331',
                text: '湘西土家族苗族自治州',
                children: [
                    {
                        code: '433101', text: '吉首市'
                    },
                    {
                        code: '433122', text: '泸溪县'
                    },
                    {
                        code: '433123', text: '凤凰县'
                    },
                    {
                        code: '433124', text: '花垣县'
                    },
                    {
                        code: '433125', text: '保靖县'
                    },
                    {
                        code: '433126', text: '古丈县'
                    },
                    {
                        code: '433127', text: '永顺县'
                    },
                    {
                        code: '433130', text: '龙山县'
                    },
                    {
                        code: '433172', text: '湖南吉首经济开发区'
                    },
                    {
                        code: '433173', text: '湖南永顺经济开发区'
                    }
                ]
            }
        ]
    },
    {
        code: '44',
        text: '广东省',
        children: [
            {
                code: '4401',
                text: '广州市',
                children: [
                    {
                        code: '440103', text: '荔湾区'
                    },
                    {
                        code: '440104', text: '越秀区'
                    },
                    {
                        code: '440105', text: '海珠区'
                    },
                    {
                        code: '440106', text: '天河区'
                    },
                    {
                        code: '440111', text: '白云区'
                    },
                    {
                        code: '440112', text: '黄埔区'
                    },
                    {
                        code: '440113', text: '番禺区'
                    },
                    {
                        code: '440114', text: '花都区'
                    },
                    {
                        code: '440115', text: '南沙区'
                    },
                    {
                        code: '440117', text: '从化区'
                    },
                    {
                        code: '440118', text: '增城区'
                    }
                ]
            },
            {
                code: '4402',
                text: '韶关市',
                children: [
                    {
                        code: '440203', text: '武江区'
                    },
                    {
                        code: '440204', text: '浈江区'
                    },
                    {
                        code: '440205', text: '曲江区'
                    },
                    {
                        code: '440222', text: '始兴县'
                    },
                    {
                        code: '440224', text: '仁化县'
                    },
                    {
                        code: '440229', text: '翁源县'
                    },
                    {
                        code: '440232', text: '乳源瑶族自治县'
                    },
                    {
                        code: '440233', text: '新丰县'
                    },
                    {
                        code: '440281', text: '乐昌市'
                    },
                    {
                        code: '440282', text: '南雄市'
                    }
                ]
            },
            {
                code: '4403',
                text: '深圳市',
                children: [
                    {
                        code: '440303', text: '罗湖区'
                    },
                    {
                        code: '440304', text: '福田区'
                    },
                    {
                        code: '440305', text: '南山区'
                    },
                    {
                        code: '440306', text: '宝安区'
                    },
                    {
                        code: '440307', text: '龙岗区'
                    },
                    {
                        code: '440308', text: '盐田区'
                    },
                    {
                        code: '440309', text: '龙华区'
                    },
                    {
                        code: '440310', text: '坪山区'
                    }
                ]
            },
            {
                code: '4404',
                text: '珠海市',
                children: [
                    {
                        code: '440402', text: '香洲区'
                    },
                    {
                        code: '440403', text: '斗门区'
                    },
                    {
                        code: '440404', text: '金湾区'
                    }
                ]
            },
            {
                code: '4405',
                text: '汕头市',
                children: [
                    {
                        code: '440507', text: '龙湖区'
                    },
                    {
                        code: '440511', text: '金平区'
                    },
                    {
                        code: '440512', text: '濠江区'
                    },
                    {
                        code: '440513', text: '潮阳区'
                    },
                    {
                        code: '440514', text: '潮南区'
                    },
                    {
                        code: '440515', text: '澄海区'
                    },
                    {
                        code: '440523', text: '南澳县'
                    }
                ]
            },
            {
                code: '4406',
                text: '佛山市',
                children: [
                    {
                        code: '440604', text: '禅城区'
                    },
                    {
                        code: '440605', text: '南海区'
                    },
                    {
                        code: '440606', text: '顺德区'
                    },
                    {
                        code: '440607', text: '三水区'
                    },
                    {
                        code: '440608', text: '高明区'
                    }
                ]
            },
            {
                code: '4407',
                text: '江门市',
                children: [
                    {
                        code: '440703', text: '蓬江区'
                    },
                    {
                        code: '440704', text: '江海区'
                    },
                    {
                        code: '440705', text: '新会区'
                    },
                    {
                        code: '440781', text: '台山市'
                    },
                    {
                        code: '440783', text: '开平市'
                    },
                    {
                        code: '440784', text: '鹤山市'
                    },
                    {
                        code: '440785', text: '恩平市'
                    }
                ]
            },
            {
                code: '4408',
                text: '湛江市',
                children: [
                    {
                        code: '440802', text: '赤坎区'
                    },
                    {
                        code: '440803', text: '霞山区'
                    },
                    {
                        code: '440804', text: '坡头区'
                    },
                    {
                        code: '440811', text: '麻章区'
                    },
                    {
                        code: '440823', text: '遂溪县'
                    },
                    {
                        code: '440825', text: '徐闻县'
                    },
                    {
                        code: '440881', text: '廉江市'
                    },
                    {
                        code: '440882', text: '雷州市'
                    },
                    {
                        code: '440883', text: '吴川市'
                    }
                ]
            },
            {
                code: '4409',
                text: '茂名市',
                children: [
                    {
                        code: '440902', text: '茂南区'
                    },
                    {
                        code: '440904', text: '电白区'
                    },
                    {
                        code: '440981', text: '高州市'
                    },
                    {
                        code: '440982', text: '化州市'
                    },
                    {
                        code: '440983', text: '信宜市'
                    }
                ]
            },
            {
                code: '4412',
                text: '肇庆市',
                children: [
                    {
                        code: '441202', text: '端州区'
                    },
                    {
                        code: '441203', text: '鼎湖区'
                    },
                    {
                        code: '441204', text: '高要区'
                    },
                    {
                        code: '441223', text: '广宁县'
                    },
                    {
                        code: '441224', text: '怀集县'
                    },
                    {
                        code: '441225', text: '封开县'
                    },
                    {
                        code: '441226', text: '德庆县'
                    },
                    {
                        code: '441284', text: '四会市'
                    }
                ]
            },
            {
                code: '4413',
                text: '惠州市',
                children: [
                    {
                        code: '441302', text: '惠城区'
                    },
                    {
                        code: '441303', text: '惠阳区'
                    },
                    {
                        code: '441322', text: '博罗县'
                    },
                    {
                        code: '441323', text: '惠东县'
                    },
                    {
                        code: '441324', text: '龙门县'
                    }
                ]
            },
            {
                code: '4414',
                text: '梅州市',
                children: [
                    {
                        code: '441402', text: '梅江区'
                    },
                    {
                        code: '441403', text: '梅县区'
                    },
                    {
                        code: '441422', text: '大埔县'
                    },
                    {
                        code: '441423', text: '丰顺县'
                    },
                    {
                        code: '441424', text: '五华县'
                    },
                    {
                        code: '441426', text: '平远县'
                    },
                    {
                        code: '441427', text: '蕉岭县'
                    },
                    {
                        code: '441481', text: '兴宁市'
                    }
                ]
            },
            {
                code: '4415',
                text: '汕尾市',
                children: [
                    {
                        code: '441502', text: '城区'
                    },
                    {
                        code: '441521', text: '海丰县'
                    },
                    {
                        code: '441523', text: '陆河县'
                    },
                    {
                        code: '441581', text: '陆丰市'
                    }
                ]
            },
            {
                code: '4416',
                text: '河源市',
                children: [
                    {
                        code: '441602', text: '源城区'
                    },
                    {
                        code: '441621', text: '紫金县'
                    },
                    {
                        code: '441622', text: '龙川县'
                    },
                    {
                        code: '441623', text: '连平县'
                    },
                    {
                        code: '441624', text: '和平县'
                    },
                    {
                        code: '441625', text: '东源县'
                    }
                ]
            },
            {
                code: '4417',
                text: '阳江市',
                children: [
                    {
                        code: '441702', text: '江城区'
                    },
                    {
                        code: '441704', text: '阳东区'
                    },
                    {
                        code: '441721', text: '阳西县'
                    },
                    {
                        code: '441781', text: '阳春市'
                    }
                ]
            },
            {
                code: '4418',
                text: '清远市',
                children: [
                    {
                        code: '441802', text: '清城区'
                    },
                    {
                        code: '441803', text: '清新区'
                    },
                    {
                        code: '441821', text: '佛冈县'
                    },
                    {
                        code: '441823', text: '阳山县'
                    },
                    {
                        code: '441825', text: '连山壮族瑶族自治县'
                    },
                    {
                        code: '441826', text: '连南瑶族自治县'
                    },
                    {
                        code: '441881', text: '英德市'
                    },
                    {
                        code: '441882', text: '连州市'
                    }
                ]
            },
            {
                code: '4419',
                text: '东莞市',
                children: [
                    {
                        code: '441900003', text: '东城街道'
                    },
                    {
                        code: '441900004', text: '南城街道'
                    },
                    {
                        code: '441900005', text: '万江街道'
                    },
                    {
                        code: '441900006', text: '莞城街道'
                    },
                    {
                        code: '441900101', text: '石碣镇'
                    },
                    {
                        code: '441900102', text: '石龙镇'
                    },
                    {
                        code: '441900103', text: '茶山镇'
                    },
                    {
                        code: '441900104', text: '石排镇'
                    },
                    {
                        code: '441900105', text: '企石镇'
                    },
                    {
                        code: '441900106', text: '横沥镇'
                    },
                    {
                        code: '441900107', text: '桥头镇'
                    },
                    {
                        code: '441900108', text: '谢岗镇'
                    },
                    {
                        code: '441900109', text: '东坑镇'
                    },
                    {
                        code: '441900110', text: '常平镇'
                    },
                    {
                        code: '441900111', text: '寮步镇'
                    },
                    {
                        code: '441900112', text: '樟木头镇'
                    },
                    {
                        code: '441900113', text: '大朗镇'
                    },
                    {
                        code: '441900114', text: '黄江镇'
                    },
                    {
                        code: '441900115', text: '清溪镇'
                    },
                    {
                        code: '441900116', text: '塘厦镇'
                    },
                    {
                        code: '441900117', text: '凤岗镇'
                    },
                    {
                        code: '441900118', text: '大岭山镇'
                    },
                    {
                        code: '441900119', text: '长安镇'
                    },
                    {
                        code: '441900121', text: '虎门镇'
                    },
                    {
                        code: '441900122', text: '厚街镇'
                    },
                    {
                        code: '441900123', text: '沙田镇'
                    },
                    {
                        code: '441900124', text: '道滘镇'
                    },
                    {
                        code: '441900125', text: '洪梅镇'
                    },
                    {
                        code: '441900126', text: '麻涌镇'
                    },
                    {
                        code: '441900127', text: '望牛墩镇'
                    },
                    {
                        code: '441900128', text: '中堂镇'
                    },
                    {
                        code: '441900129', text: '高埗镇'
                    },
                    {
                        code: '441900401', text: '松山湖管委会'
                    },
                    {
                        code: '441900402', text: '东莞港'
                    },
                    {
                        code: '441900403', text: '东莞生态园'
                    }
                ]
            },
            {
                code: '4420',
                text: '中山市',
                children: [
                    {
                        code: '442000001', text: '石岐区街道'
                    },
                    {
                        code: '442000002', text: '东区街道'
                    },
                    {
                        code: '442000003', text: '火炬开发区街道'
                    },
                    {
                        code: '442000004', text: '西区街道'
                    },
                    {
                        code: '442000005', text: '南区街道'
                    },
                    {
                        code: '442000006', text: '五桂山街道'
                    },
                    {
                        code: '442000100', text: '小榄镇'
                    },
                    {
                        code: '442000101', text: '黄圃镇'
                    },
                    {
                        code: '442000102', text: '民众镇'
                    },
                    {
                        code: '442000103', text: '东凤镇'
                    },
                    {
                        code: '442000104', text: '东升镇'
                    },
                    {
                        code: '442000105', text: '古镇镇'
                    },
                    {
                        code: '442000106', text: '沙溪镇'
                    },
                    {
                        code: '442000107', text: '坦洲镇'
                    },
                    {
                        code: '442000108', text: '港口镇'
                    },
                    {
                        code: '442000109', text: '三角镇'
                    },
                    {
                        code: '442000110', text: '横栏镇'
                    },
                    {
                        code: '442000111', text: '南头镇'
                    },
                    {
                        code: '442000112', text: '阜沙镇'
                    },
                    {
                        code: '442000113', text: '南朗镇'
                    },
                    {
                        code: '442000114', text: '三乡镇'
                    },
                    {
                        code: '442000115', text: '板芙镇'
                    },
                    {
                        code: '442000116', text: '大涌镇'
                    },
                    {
                        code: '442000117', text: '神湾镇'
                    }
                ]
            },
            {
                code: '4451',
                text: '潮州市',
                children: [
                    {
                        code: '445102', text: '湘桥区'
                    },
                    {
                        code: '445103', text: '潮安区'
                    },
                    {
                        code: '445122', text: '饶平县'
                    }
                ]
            },
            {
                code: '4452',
                text: '揭阳市',
                children: [
                    {
                        code: '445202', text: '榕城区'
                    },
                    {
                        code: '445203', text: '揭东区'
                    },
                    {
                        code: '445222', text: '揭西县'
                    },
                    {
                        code: '445224', text: '惠来县'
                    },
                    {
                        code: '445281', text: '普宁市'
                    }
                ]
            },
            {
                code: '4453',
                text: '云浮市',
                children: [
                    {
                        code: '445302', text: '云城区'
                    },
                    {
                        code: '445303', text: '云安区'
                    },
                    {
                        code: '445321', text: '新兴县'
                    },
                    {
                        code: '445322', text: '郁南县'
                    },
                    {
                        code: '445381', text: '罗定市'
                    }
                ]
            }
        ]
    },
    {
        code: '45',
        text: '广西壮族自治区',
        children: [
            {
                code: '4501',
                text: '南宁市',
                children: [
                    {
                        code: '450102', text: '兴宁区'
                    },
                    {
                        code: '450103', text: '青秀区'
                    },
                    {
                        code: '450105', text: '江南区'
                    },
                    {
                        code: '450107', text: '西乡塘区'
                    },
                    {
                        code: '450108', text: '良庆区'
                    },
                    {
                        code: '450109', text: '邕宁区'
                    },
                    {
                        code: '450110', text: '武鸣区'
                    },
                    {
                        code: '450123', text: '隆安县'
                    },
                    {
                        code: '450124', text: '马山县'
                    },
                    {
                        code: '450125', text: '上林县'
                    },
                    {
                        code: '450126', text: '宾阳县'
                    },
                    {
                        code: '450127', text: '横县'
                    }
                ]
            },
            {
                code: '4502',
                text: '柳州市',
                children: [
                    {
                        code: '450202', text: '城中区'
                    },
                    {
                        code: '450203', text: '鱼峰区'
                    },
                    {
                        code: '450204', text: '柳南区'
                    },
                    {
                        code: '450205', text: '柳北区'
                    },
                    {
                        code: '450206', text: '柳江区'
                    },
                    {
                        code: '450222', text: '柳城县'
                    },
                    {
                        code: '450223', text: '鹿寨县'
                    },
                    {
                        code: '450224', text: '融安县'
                    },
                    {
                        code: '450225', text: '融水苗族自治县'
                    },
                    {
                        code: '450226', text: '三江侗族自治县'
                    }
                ]
            },
            {
                code: '4503',
                text: '桂林市',
                children: [
                    {
                        code: '450302', text: '秀峰区'
                    },
                    {
                        code: '450303', text: '叠彩区'
                    },
                    {
                        code: '450304', text: '象山区'
                    },
                    {
                        code: '450305', text: '七星区'
                    },
                    {
                        code: '450311', text: '雁山区'
                    },
                    {
                        code: '450312', text: '临桂区'
                    },
                    {
                        code: '450321', text: '阳朔县'
                    },
                    {
                        code: '450323', text: '灵川县'
                    },
                    {
                        code: '450324', text: '全州县'
                    },
                    {
                        code: '450325', text: '兴安县'
                    },
                    {
                        code: '450326', text: '永福县'
                    },
                    {
                        code: '450327', text: '灌阳县'
                    },
                    {
                        code: '450328', text: '龙胜各族自治县'
                    },
                    {
                        code: '450329', text: '资源县'
                    },
                    {
                        code: '450330', text: '平乐县'
                    },
                    {
                        code: '450331', text: '荔浦县'
                    },
                    {
                        code: '450332', text: '恭城瑶族自治县'
                    }
                ]
            },
            {
                code: '4504',
                text: '梧州市',
                children: [
                    {
                        code: '450403', text: '万秀区'
                    },
                    {
                        code: '450405', text: '长洲区'
                    },
                    {
                        code: '450406', text: '龙圩区'
                    },
                    {
                        code: '450421', text: '苍梧县'
                    },
                    {
                        code: '450422', text: '藤县'
                    },
                    {
                        code: '450423', text: '蒙山县'
                    },
                    {
                        code: '450481', text: '岑溪市'
                    }
                ]
            },
            {
                code: '4505',
                text: '北海市',
                children: [
                    {
                        code: '450502', text: '海城区'
                    },
                    {
                        code: '450503', text: '银海区'
                    },
                    {
                        code: '450512', text: '铁山港区'
                    },
                    {
                        code: '450521', text: '合浦县'
                    }
                ]
            },
            {
                code: '4506',
                text: '防城港市',
                children: [
                    {
                        code: '450602', text: '港口区'
                    },
                    {
                        code: '450603', text: '防城区'
                    },
                    {
                        code: '450621', text: '上思县'
                    },
                    {
                        code: '450681', text: '东兴市'
                    }
                ]
            },
            {
                code: '4507',
                text: '钦州市',
                children: [
                    {
                        code: '450702', text: '钦南区'
                    },
                    {
                        code: '450703', text: '钦北区'
                    },
                    {
                        code: '450721', text: '灵山县'
                    },
                    {
                        code: '450722', text: '浦北县'
                    }
                ]
            },
            {
                code: '4508',
                text: '贵港市',
                children: [
                    {
                        code: '450802', text: '港北区'
                    },
                    {
                        code: '450803', text: '港南区'
                    },
                    {
                        code: '450804', text: '覃塘区'
                    },
                    {
                        code: '450821', text: '平南县'
                    },
                    {
                        code: '450881', text: '桂平市'
                    }
                ]
            },
            {
                code: '4509',
                text: '玉林市',
                children: [
                    {
                        code: '450902', text: '玉州区'
                    },
                    {
                        code: '450903', text: '福绵区'
                    },
                    {
                        code: '450921', text: '容县'
                    },
                    {
                        code: '450922', text: '陆川县'
                    },
                    {
                        code: '450923', text: '博白县'
                    },
                    {
                        code: '450924', text: '兴业县'
                    },
                    {
                        code: '450981', text: '北流市'
                    }
                ]
            },
            {
                code: '4510',
                text: '百色市',
                children: [
                    {
                        code: '451002', text: '右江区'
                    },
                    {
                        code: '451021', text: '田阳县'
                    },
                    {
                        code: '451022', text: '田东县'
                    },
                    {
                        code: '451023', text: '平果县'
                    },
                    {
                        code: '451024', text: '德保县'
                    },
                    {
                        code: '451026', text: '那坡县'
                    },
                    {
                        code: '451027', text: '凌云县'
                    },
                    {
                        code: '451028', text: '乐业县'
                    },
                    {
                        code: '451029', text: '田林县'
                    },
                    {
                        code: '451030', text: '西林县'
                    },
                    {
                        code: '451031', text: '隆林各族自治县'
                    },
                    {
                        code: '451081', text: '靖西市'
                    }
                ]
            },
            {
                code: '4511',
                text: '贺州市',
                children: [
                    {
                        code: '451102', text: '八步区'
                    },
                    {
                        code: '451103', text: '平桂区'
                    },
                    {
                        code: '451121', text: '昭平县'
                    },
                    {
                        code: '451122', text: '钟山县'
                    },
                    {
                        code: '451123', text: '富川瑶族自治县'
                    }
                ]
            },
            {
                code: '4512',
                text: '河池市',
                children: [
                    {
                        code: '451202', text: '金城江区'
                    },
                    {
                        code: '451203', text: '宜州区'
                    },
                    {
                        code: '451221', text: '南丹县'
                    },
                    {
                        code: '451222', text: '天峨县'
                    },
                    {
                        code: '451223', text: '凤山县'
                    },
                    {
                        code: '451224', text: '东兰县'
                    },
                    {
                        code: '451225', text: '罗城仫佬族自治县'
                    },
                    {
                        code: '451226', text: '环江毛南族自治县'
                    },
                    {
                        code: '451227', text: '巴马瑶族自治县'
                    },
                    {
                        code: '451228', text: '都安瑶族自治县'
                    },
                    {
                        code: '451229', text: '大化瑶族自治县'
                    }
                ]
            },
            {
                code: '4513',
                text: '来宾市',
                children: [
                    {
                        code: '451302', text: '兴宾区'
                    },
                    {
                        code: '451321', text: '忻城县'
                    },
                    {
                        code: '451322', text: '象州县'
                    },
                    {
                        code: '451323', text: '武宣县'
                    },
                    {
                        code: '451324', text: '金秀瑶族自治县'
                    },
                    {
                        code: '451381', text: '合山市'
                    }
                ]
            },
            {
                code: '4514',
                text: '崇左市',
                children: [
                    {
                        code: '451402', text: '江州区'
                    },
                    {
                        code: '451421', text: '扶绥县'
                    },
                    {
                        code: '451422', text: '宁明县'
                    },
                    {
                        code: '451423', text: '龙州县'
                    },
                    {
                        code: '451424', text: '大新县'
                    },
                    {
                        code: '451425', text: '天等县'
                    },
                    {
                        code: '451481', text: '凭祥市'
                    }
                ]
            }
        ]
    },
    {
        code: '46',
        text: '海南省',
        children: [
            {
                code: '4601',
                text: '海口市',
                children: [
                    {
                        code: '460105', text: '秀英区'
                    },
                    {
                        code: '460106', text: '龙华区'
                    },
                    {
                        code: '460107', text: '琼山区'
                    },
                    {
                        code: '460108', text: '美兰区'
                    }
                ]
            },
            {
                code: '4602',
                text: '三亚市',
                children: [
                    {
                        code: '460202', text: '海棠区'
                    },
                    {
                        code: '460203', text: '吉阳区'
                    },
                    {
                        code: '460204', text: '天涯区'
                    },
                    {
                        code: '460205', text: '崖州区'
                    }
                ]
            },
            {
                code: '4603',
                text: '三沙市',
                children: [
                    {
                        code: '460321', text: '西沙群岛'
                    },
                    {
                        code: '460322', text: '南沙群岛'
                    },
                    {
                        code: '460323', text: '中沙群岛的岛礁及其海域'
                    }
                ]
            },
            {
                code: '4604',
                text: '儋州市',
                children: [
                    {
                        code: '460400100', text: '那大镇'
                    },
                    {
                        code: '460400101', text: '和庆镇'
                    },
                    {
                        code: '460400102', text: '南丰镇'
                    },
                    {
                        code: '460400103', text: '大成镇'
                    },
                    {
                        code: '460400104', text: '雅星镇'
                    },
                    {
                        code: '460400105', text: '兰洋镇'
                    },
                    {
                        code: '460400106', text: '光村镇'
                    },
                    {
                        code: '460400107', text: '木棠镇'
                    },
                    {
                        code: '460400108', text: '海头镇'
                    },
                    {
                        code: '460400109', text: '峨蔓镇'
                    },
                    {
                        code: '460400111', text: '王五镇'
                    },
                    {
                        code: '460400112', text: '白马井镇'
                    },
                    {
                        code: '460400113', text: '中和镇'
                    },
                    {
                        code: '460400114', text: '排浦镇'
                    },
                    {
                        code: '460400115', text: '东成镇'
                    },
                    {
                        code: '460400116', text: '新州镇'
                    },
                    {
                        code: '460400499', text: '洋浦经济开发区'
                    },
                    {
                        code: '460400500', text: '华南热作学院'
                    }
                ]
            },
            {
                code: '4690',
                text: '省直辖县级行政区划',
                children: [
                    {
                        code: '469001', text: '五指山市'
                    },
                    {
                        code: '469002', text: '琼海市'
                    },
                    {
                        code: '469005', text: '文昌市'
                    },
                    {
                        code: '469006', text: '万宁市'
                    },
                    {
                        code: '469007', text: '东方市'
                    },
                    {
                        code: '469021', text: '定安县'
                    },
                    {
                        code: '469022', text: '屯昌县'
                    },
                    {
                        code: '469023', text: '澄迈县'
                    },
                    {
                        code: '469024', text: '临高县'
                    },
                    {
                        code: '469025', text: '白沙黎族自治县'
                    },
                    {
                        code: '469026', text: '昌江黎族自治县'
                    },
                    {
                        code: '469027', text: '乐东黎族自治县'
                    },
                    {
                        code: '469028', text: '陵水黎族自治县'
                    },
                    {
                        code: '469029', text: '保亭黎族苗族自治县'
                    },
                    {
                        code: '469030', text: '琼中黎族苗族自治县'
                    }
                ]
            }
        ]
    },
    {
        code: '50',
        text: '重庆市',
        children: [
            {
                code: '5001',
                text: '市辖区',
                children: [
                    {
                        code: '500101', text: '万州区'
                    },
                    {
                        code: '500102', text: '涪陵区'
                    },
                    {
                        code: '500103', text: '渝中区'
                    },
                    {
                        code: '500104', text: '大渡口区'
                    },
                    {
                        code: '500105', text: '江北区'
                    },
                    {
                        code: '500106', text: '沙坪坝区'
                    },
                    {
                        code: '500107', text: '九龙坡区'
                    },
                    {
                        code: '500108', text: '南岸区'
                    },
                    {
                        code: '500109', text: '北碚区'
                    },
                    {
                        code: '500110', text: '綦江区'
                    },
                    {
                        code: '500111', text: '大足区'
                    },
                    {
                        code: '500112', text: '渝北区'
                    },
                    {
                        code: '500113', text: '巴南区'
                    },
                    {
                        code: '500114', text: '黔江区'
                    },
                    {
                        code: '500115', text: '长寿区'
                    },
                    {
                        code: '500116', text: '江津区'
                    },
                    {
                        code: '500117', text: '合川区'
                    },
                    {
                        code: '500118', text: '永川区'
                    },
                    {
                        code: '500119', text: '南川区'
                    },
                    {
                        code: '500120', text: '璧山区'
                    },
                    {
                        code: '500151', text: '铜梁区'
                    },
                    {
                        code: '500152', text: '潼南区'
                    },
                    {
                        code: '500153', text: '荣昌区'
                    },
                    {
                        code: '500154', text: '开州区'
                    },
                    {
                        code: '500155', text: '梁平区'
                    },
                    {
                        code: '500156', text: '武隆区'
                    }
                ]
            },
            {
                code: '5002',
                text: '县',
                children: [
                    {
                        code: '500229', text: '城口县'
                    },
                    {
                        code: '500230', text: '丰都县'
                    },
                    {
                        code: '500231', text: '垫江县'
                    },
                    {
                        code: '500233', text: '忠县'
                    },
                    {
                        code: '500235', text: '云阳县'
                    },
                    {
                        code: '500236', text: '奉节县'
                    },
                    {
                        code: '500237', text: '巫山县'
                    },
                    {
                        code: '500238', text: '巫溪县'
                    },
                    {
                        code: '500240', text: '石柱土家族自治县'
                    },
                    {
                        code: '500241', text: '秀山土家族苗族自治县'
                    },
                    {
                        code: '500242', text: '酉阳土家族苗族自治县'
                    },
                    {
                        code: '500243', text: '彭水苗族土家族自治县'
                    }
                ]
            }
        ]
    },
    {
        code: '51',
        text: '四川省',
        children: [
            {
                code: '5101',
                text: '成都市',
                children: [
                    {
                        code: '510104', text: '锦江区'
                    },
                    {
                        code: '510105', text: '青羊区'
                    },
                    {
                        code: '510106', text: '金牛区'
                    },
                    {
                        code: '510107', text: '武侯区'
                    },
                    {
                        code: '510108', text: '成华区'
                    },
                    {
                        code: '510112', text: '龙泉驿区'
                    },
                    {
                        code: '510113', text: '青白江区'
                    },
                    {
                        code: '510114', text: '新都区'
                    },
                    {
                        code: '510115', text: '温江区'
                    },
                    {
                        code: '510116', text: '双流区'
                    },
                    {
                        code: '510117', text: '郫都区'
                    },
                    {
                        code: '510121', text: '金堂县'
                    },
                    {
                        code: '510129', text: '大邑县'
                    },
                    {
                        code: '510131', text: '蒲江县'
                    },
                    {
                        code: '510132', text: '新津县'
                    },
                    {
                        code: '510181', text: '都江堰市'
                    },
                    {
                        code: '510182', text: '彭州市'
                    },
                    {
                        code: '510183', text: '邛崃市'
                    },
                    {
                        code: '510184', text: '崇州市'
                    },
                    {
                        code: '510185', text: '简阳市'
                    }
                ]
            },
            {
                code: '5103',
                text: '自贡市',
                children: [
                    {
                        code: '510302', text: '自流井区'
                    },
                    {
                        code: '510303', text: '贡井区'
                    },
                    {
                        code: '510304', text: '大安区'
                    },
                    {
                        code: '510311', text: '沿滩区'
                    },
                    {
                        code: '510321', text: '荣县'
                    },
                    {
                        code: '510322', text: '富顺县'
                    }
                ]
            },
            {
                code: '5104',
                text: '攀枝花市',
                children: [
                    {
                        code: '510402', text: '东区'
                    },
                    {
                        code: '510403', text: '西区'
                    },
                    {
                        code: '510411', text: '仁和区'
                    },
                    {
                        code: '510421', text: '米易县'
                    },
                    {
                        code: '510422', text: '盐边县'
                    }
                ]
            },
            {
                code: '5105',
                text: '泸州市',
                children: [
                    {
                        code: '510502', text: '江阳区'
                    },
                    {
                        code: '510503', text: '纳溪区'
                    },
                    {
                        code: '510504', text: '龙马潭区'
                    },
                    {
                        code: '510521', text: '泸县'
                    },
                    {
                        code: '510522', text: '合江县'
                    },
                    {
                        code: '510524', text: '叙永县'
                    },
                    {
                        code: '510525', text: '古蔺县'
                    }
                ]
            },
            {
                code: '5106',
                text: '德阳市',
                children: [
                    {
                        code: '510603', text: '旌阳区'
                    },
                    {
                        code: '510604', text: '罗江区'
                    },
                    {
                        code: '510623', text: '中江县'
                    },
                    {
                        code: '510681', text: '广汉市'
                    },
                    {
                        code: '510682', text: '什邡市'
                    },
                    {
                        code: '510683', text: '绵竹市'
                    }
                ]
            },
            {
                code: '5107',
                text: '绵阳市',
                children: [
                    {
                        code: '510703', text: '涪城区'
                    },
                    {
                        code: '510704', text: '游仙区'
                    },
                    {
                        code: '510705', text: '安州区'
                    },
                    {
                        code: '510722', text: '三台县'
                    },
                    {
                        code: '510723', text: '盐亭县'
                    },
                    {
                        code: '510725', text: '梓潼县'
                    },
                    {
                        code: '510726', text: '北川羌族自治县'
                    },
                    {
                        code: '510727', text: '平武县'
                    },
                    {
                        code: '510781', text: '江油市'
                    }
                ]
            },
            {
                code: '5108',
                text: '广元市',
                children: [
                    {
                        code: '510802', text: '利州区'
                    },
                    {
                        code: '510811', text: '昭化区'
                    },
                    {
                        code: '510812', text: '朝天区'
                    },
                    {
                        code: '510821', text: '旺苍县'
                    },
                    {
                        code: '510822', text: '青川县'
                    },
                    {
                        code: '510823', text: '剑阁县'
                    },
                    {
                        code: '510824', text: '苍溪县'
                    }
                ]
            },
            {
                code: '5109',
                text: '遂宁市',
                children: [
                    {
                        code: '510903', text: '船山区'
                    },
                    {
                        code: '510904', text: '安居区'
                    },
                    {
                        code: '510921', text: '蓬溪县'
                    },
                    {
                        code: '510922', text: '射洪县'
                    },
                    {
                        code: '510923', text: '大英县'
                    }
                ]
            },
            {
                code: '5110',
                text: '内江市',
                children: [
                    {
                        code: '511002', text: '市中区'
                    },
                    {
                        code: '511011', text: '东兴区'
                    },
                    {
                        code: '511024', text: '威远县'
                    },
                    {
                        code: '511025', text: '资中县'
                    },
                    {
                        code: '511071', text: '内江经济开发区'
                    },
                    {
                        code: '511083', text: '隆昌市'
                    }
                ]
            },
            {
                code: '5111',
                text: '乐山市',
                children: [
                    {
                        code: '511102', text: '市中区'
                    },
                    {
                        code: '511111', text: '沙湾区'
                    },
                    {
                        code: '511112', text: '五通桥区'
                    },
                    {
                        code: '511113', text: '金口河区'
                    },
                    {
                        code: '511123', text: '犍为县'
                    },
                    {
                        code: '511124', text: '井研县'
                    },
                    {
                        code: '511126', text: '夹江县'
                    },
                    {
                        code: '511129', text: '沐川县'
                    },
                    {
                        code: '511132', text: '峨边彝族自治县'
                    },
                    {
                        code: '511133', text: '马边彝族自治县'
                    },
                    {
                        code: '511181', text: '峨眉山市'
                    }
                ]
            },
            {
                code: '5113',
                text: '南充市',
                children: [
                    {
                        code: '511302', text: '顺庆区'
                    },
                    {
                        code: '511303', text: '高坪区'
                    },
                    {
                        code: '511304', text: '嘉陵区'
                    },
                    {
                        code: '511321', text: '南部县'
                    },
                    {
                        code: '511322', text: '营山县'
                    },
                    {
                        code: '511323', text: '蓬安县'
                    },
                    {
                        code: '511324', text: '仪陇县'
                    },
                    {
                        code: '511325', text: '西充县'
                    },
                    {
                        code: '511381', text: '阆中市'
                    }
                ]
            },
            {
                code: '5114',
                text: '眉山市',
                children: [
                    {
                        code: '511402', text: '东坡区'
                    },
                    {
                        code: '511403', text: '彭山区'
                    },
                    {
                        code: '511421', text: '仁寿县'
                    },
                    {
                        code: '511423', text: '洪雅县'
                    },
                    {
                        code: '511424', text: '丹棱县'
                    },
                    {
                        code: '511425', text: '青神县'
                    }
                ]
            },
            {
                code: '5115',
                text: '宜宾市',
                children: [
                    {
                        code: '511502', text: '翠屏区'
                    },
                    {
                        code: '511503', text: '南溪区'
                    },
                    {
                        code: '511521', text: '宜宾县'
                    },
                    {
                        code: '511523', text: '江安县'
                    },
                    {
                        code: '511524', text: '长宁县'
                    },
                    {
                        code: '511525', text: '高县'
                    },
                    {
                        code: '511526', text: '珙县'
                    },
                    {
                        code: '511527', text: '筠连县'
                    },
                    {
                        code: '511528', text: '兴文县'
                    },
                    {
                        code: '511529', text: '屏山县'
                    }
                ]
            },
            {
                code: '5116',
                text: '广安市',
                children: [
                    {
                        code: '511602', text: '广安区'
                    },
                    {
                        code: '511603', text: '前锋区'
                    },
                    {
                        code: '511621', text: '岳池县'
                    },
                    {
                        code: '511622', text: '武胜县'
                    },
                    {
                        code: '511623', text: '邻水县'
                    },
                    {
                        code: '511681', text: '华蓥市'
                    }
                ]
            },
            {
                code: '5117',
                text: '达州市',
                children: [
                    {
                        code: '511702', text: '通川区'
                    },
                    {
                        code: '511703', text: '达川区'
                    },
                    {
                        code: '511722', text: '宣汉县'
                    },
                    {
                        code: '511723', text: '开江县'
                    },
                    {
                        code: '511724', text: '大竹县'
                    },
                    {
                        code: '511725', text: '渠县'
                    },
                    {
                        code: '511771', text: '达州经济开发区'
                    },
                    {
                        code: '511781', text: '万源市'
                    }
                ]
            },
            {
                code: '5118',
                text: '雅安市',
                children: [
                    {
                        code: '511802', text: '雨城区'
                    },
                    {
                        code: '511803', text: '名山区'
                    },
                    {
                        code: '511822', text: '荥经县'
                    },
                    {
                        code: '511823', text: '汉源县'
                    },
                    {
                        code: '511824', text: '石棉县'
                    },
                    {
                        code: '511825', text: '天全县'
                    },
                    {
                        code: '511826', text: '芦山县'
                    },
                    {
                        code: '511827', text: '宝兴县'
                    }
                ]
            },
            {
                code: '5119',
                text: '巴中市',
                children: [
                    {
                        code: '511902', text: '巴州区'
                    },
                    {
                        code: '511903', text: '恩阳区'
                    },
                    {
                        code: '511921', text: '通江县'
                    },
                    {
                        code: '511922', text: '南江县'
                    },
                    {
                        code: '511923', text: '平昌县'
                    },
                    {
                        code: '511971', text: '巴中经济开发区'
                    }
                ]
            },
            {
                code: '5120',
                text: '资阳市',
                children: [
                    {
                        code: '512002', text: '雁江区'
                    },
                    {
                        code: '512021', text: '安岳县'
                    },
                    {
                        code: '512022', text: '乐至县'
                    }
                ]
            },
            {
                code: '5132',
                text: '阿坝藏族羌族自治州',
                children: [
                    {
                        code: '513201', text: '马尔康市'
                    },
                    {
                        code: '513221', text: '汶川县'
                    },
                    {
                        code: '513222', text: '理县'
                    },
                    {
                        code: '513223', text: '茂县'
                    },
                    {
                        code: '513224', text: '松潘县'
                    },
                    {
                        code: '513225', text: '九寨沟县'
                    },
                    {
                        code: '513226', text: '金川县'
                    },
                    {
                        code: '513227', text: '小金县'
                    },
                    {
                        code: '513228', text: '黑水县'
                    },
                    {
                        code: '513230', text: '壤塘县'
                    },
                    {
                        code: '513231', text: '阿坝县'
                    },
                    {
                        code: '513232', text: '若尔盖县'
                    },
                    {
                        code: '513233', text: '红原县'
                    }
                ]
            },
            {
                code: '5133',
                text: '甘孜藏族自治州',
                children: [
                    {
                        code: '513301', text: '康定市'
                    },
                    {
                        code: '513322', text: '泸定县'
                    },
                    {
                        code: '513323', text: '丹巴县'
                    },
                    {
                        code: '513324', text: '九龙县'
                    },
                    {
                        code: '513325', text: '雅江县'
                    },
                    {
                        code: '513326', text: '道孚县'
                    },
                    {
                        code: '513327', text: '炉霍县'
                    },
                    {
                        code: '513328', text: '甘孜县'
                    },
                    {
                        code: '513329', text: '新龙县'
                    },
                    {
                        code: '513330', text: '德格县'
                    },
                    {
                        code: '513331', text: '白玉县'
                    },
                    {
                        code: '513332', text: '石渠县'
                    },
                    {
                        code: '513333', text: '色达县'
                    },
                    {
                        code: '513334', text: '理塘县'
                    },
                    {
                        code: '513335', text: '巴塘县'
                    },
                    {
                        code: '513336', text: '乡城县'
                    },
                    {
                        code: '513337', text: '稻城县'
                    },
                    {
                        code: '513338', text: '得荣县'
                    }
                ]
            },
            {
                code: '5134',
                text: '凉山彝族自治州',
                children: [
                    {
                        code: '513401', text: '西昌市'
                    },
                    {
                        code: '513422', text: '木里藏族自治县'
                    },
                    {
                        code: '513423', text: '盐源县'
                    },
                    {
                        code: '513424', text: '德昌县'
                    },
                    {
                        code: '513425', text: '会理县'
                    },
                    {
                        code: '513426', text: '会东县'
                    },
                    {
                        code: '513427', text: '宁南县'
                    },
                    {
                        code: '513428', text: '普格县'
                    },
                    {
                        code: '513429', text: '布拖县'
                    },
                    {
                        code: '513430', text: '金阳县'
                    },
                    {
                        code: '513431', text: '昭觉县'
                    },
                    {
                        code: '513432', text: '喜德县'
                    },
                    {
                        code: '513433', text: '冕宁县'
                    },
                    {
                        code: '513434', text: '越西县'
                    },
                    {
                        code: '513435', text: '甘洛县'
                    },
                    {
                        code: '513436', text: '美姑县'
                    },
                    {
                        code: '513437', text: '雷波县'
                    }
                ]
            }
        ]
    },
    {
        code: '52',
        text: '贵州省',
        children: [
            {
                code: '5201',
                text: '贵阳市',
                children: [
                    {
                        code: '520102', text: '南明区'
                    },
                    {
                        code: '520103', text: '云岩区'
                    },
                    {
                        code: '520111', text: '花溪区'
                    },
                    {
                        code: '520112', text: '乌当区'
                    },
                    {
                        code: '520113', text: '白云区'
                    },
                    {
                        code: '520115', text: '观山湖区'
                    },
                    {
                        code: '520121', text: '开阳县'
                    },
                    {
                        code: '520122', text: '息烽县'
                    },
                    {
                        code: '520123', text: '修文县'
                    },
                    {
                        code: '520181', text: '清镇市'
                    }
                ]
            },
            {
                code: '5202',
                text: '六盘水市',
                children: [
                    {
                        code: '520201', text: '钟山区'
                    },
                    {
                        code: '520203', text: '六枝特区'
                    },
                    {
                        code: '520221', text: '水城县'
                    },
                    {
                        code: '520281', text: '盘州市'
                    }
                ]
            },
            {
                code: '5203',
                text: '遵义市',
                children: [
                    {
                        code: '520302', text: '红花岗区'
                    },
                    {
                        code: '520303', text: '汇川区'
                    },
                    {
                        code: '520304', text: '播州区'
                    },
                    {
                        code: '520322', text: '桐梓县'
                    },
                    {
                        code: '520323', text: '绥阳县'
                    },
                    {
                        code: '520324', text: '正安县'
                    },
                    {
                        code: '520325', text: '道真仡佬族苗族自治县'
                    },
                    {
                        code: '520326', text: '务川仡佬族苗族自治县'
                    },
                    {
                        code: '520327', text: '凤冈县'
                    },
                    {
                        code: '520328', text: '湄潭县'
                    },
                    {
                        code: '520329', text: '余庆县'
                    },
                    {
                        code: '520330', text: '习水县'
                    },
                    {
                        code: '520381', text: '赤水市'
                    },
                    {
                        code: '520382', text: '仁怀市'
                    }
                ]
            },
            {
                code: '5204',
                text: '安顺市',
                children: [
                    {
                        code: '520402', text: '西秀区'
                    },
                    {
                        code: '520403', text: '平坝区'
                    },
                    {
                        code: '520422', text: '普定县'
                    },
                    {
                        code: '520423', text: '镇宁布依族苗族自治县'
                    },
                    {
                        code: '520424', text: '关岭布依族苗族自治县'
                    },
                    {
                        code: '520425', text: '紫云苗族布依族自治县'
                    }
                ]
            },
            {
                code: '5205',
                text: '毕节市',
                children: [
                    {
                        code: '520502', text: '七星关区'
                    },
                    {
                        code: '520521', text: '大方县'
                    },
                    {
                        code: '520522', text: '黔西县'
                    },
                    {
                        code: '520523', text: '金沙县'
                    },
                    {
                        code: '520524', text: '织金县'
                    },
                    {
                        code: '520525', text: '纳雍县'
                    },
                    {
                        code: '520526', text: '威宁彝族回族苗族自治县'
                    },
                    {
                        code: '520527', text: '赫章县'
                    }
                ]
            },
            {
                code: '5206',
                text: '铜仁市',
                children: [
                    {
                        code: '520602', text: '碧江区'
                    },
                    {
                        code: '520603', text: '万山区'
                    },
                    {
                        code: '520621', text: '江口县'
                    },
                    {
                        code: '520622', text: '玉屏侗族自治县'
                    },
                    {
                        code: '520623', text: '石阡县'
                    },
                    {
                        code: '520624', text: '思南县'
                    },
                    {
                        code: '520625', text: '印江土家族苗族自治县'
                    },
                    {
                        code: '520626', text: '德江县'
                    },
                    {
                        code: '520627', text: '沿河土家族自治县'
                    },
                    {
                        code: '520628', text: '松桃苗族自治县'
                    }
                ]
            },
            {
                code: '5223',
                text: '黔西南布依族苗族自治州',
                children: [
                    {
                        code: '522301', text: '兴义市'
                    },
                    {
                        code: '522322', text: '兴仁县'
                    },
                    {
                        code: '522323', text: '普安县'
                    },
                    {
                        code: '522324', text: '晴隆县'
                    },
                    {
                        code: '522325', text: '贞丰县'
                    },
                    {
                        code: '522326', text: '望谟县'
                    },
                    {
                        code: '522327', text: '册亨县'
                    },
                    {
                        code: '522328', text: '安龙县'
                    }
                ]
            },
            {
                code: '5226',
                text: '黔东南苗族侗族自治州',
                children: [
                    {
                        code: '522601', text: '凯里市'
                    },
                    {
                        code: '522622', text: '黄平县'
                    },
                    {
                        code: '522623', text: '施秉县'
                    },
                    {
                        code: '522624', text: '三穗县'
                    },
                    {
                        code: '522625', text: '镇远县'
                    },
                    {
                        code: '522626', text: '岑巩县'
                    },
                    {
                        code: '522627', text: '天柱县'
                    },
                    {
                        code: '522628', text: '锦屏县'
                    },
                    {
                        code: '522629', text: '剑河县'
                    },
                    {
                        code: '522630', text: '台江县'
                    },
                    {
                        code: '522631', text: '黎平县'
                    },
                    {
                        code: '522632', text: '榕江县'
                    },
                    {
                        code: '522633', text: '从江县'
                    },
                    {
                        code: '522634', text: '雷山县'
                    },
                    {
                        code: '522635', text: '麻江县'
                    },
                    {
                        code: '522636', text: '丹寨县'
                    }
                ]
            },
            {
                code: '5227',
                text: '黔南布依族苗族自治州',
                children: [
                    {
                        code: '522701', text: '都匀市'
                    },
                    {
                        code: '522702', text: '福泉市'
                    },
                    {
                        code: '522722', text: '荔波县'
                    },
                    {
                        code: '522723', text: '贵定县'
                    },
                    {
                        code: '522725', text: '瓮安县'
                    },
                    {
                        code: '522726', text: '独山县'
                    },
                    {
                        code: '522727', text: '平塘县'
                    },
                    {
                        code: '522728', text: '罗甸县'
                    },
                    {
                        code: '522729', text: '长顺县'
                    },
                    {
                        code: '522730', text: '龙里县'
                    },
                    {
                        code: '522731', text: '惠水县'
                    },
                    {
                        code: '522732', text: '三都水族自治县'
                    }
                ]
            }
        ]
    },
    {
        code: '53',
        text: '云南省',
        children: [
            {
                code: '5301',
                text: '昆明市',
                children: [
                    {
                        code: '530102', text: '五华区'
                    },
                    {
                        code: '530103', text: '盘龙区'
                    },
                    {
                        code: '530111', text: '官渡区'
                    },
                    {
                        code: '530112', text: '西山区'
                    },
                    {
                        code: '530113', text: '东川区'
                    },
                    {
                        code: '530114', text: '呈贡区'
                    },
                    {
                        code: '530115', text: '晋宁区'
                    },
                    {
                        code: '530124', text: '富民县'
                    },
                    {
                        code: '530125', text: '宜良县'
                    },
                    {
                        code: '530126', text: '石林彝族自治县'
                    },
                    {
                        code: '530127', text: '嵩明县'
                    },
                    {
                        code: '530128', text: '禄劝彝族苗族自治县'
                    },
                    {
                        code: '530129', text: '寻甸回族彝族自治县'
                    },
                    {
                        code: '530181', text: '安宁市'
                    }
                ]
            },
            {
                code: '5303',
                text: '曲靖市',
                children: [
                    {
                        code: '530302', text: '麒麟区'
                    },
                    {
                        code: '530303', text: '沾益区'
                    },
                    {
                        code: '530321', text: '马龙县'
                    },
                    {
                        code: '530322', text: '陆良县'
                    },
                    {
                        code: '530323', text: '师宗县'
                    },
                    {
                        code: '530324', text: '罗平县'
                    },
                    {
                        code: '530325', text: '富源县'
                    },
                    {
                        code: '530326', text: '会泽县'
                    },
                    {
                        code: '530381', text: '宣威市'
                    }
                ]
            },
            {
                code: '5304',
                text: '玉溪市',
                children: [
                    {
                        code: '530402', text: '红塔区'
                    },
                    {
                        code: '530403', text: '江川区'
                    },
                    {
                        code: '530422', text: '澄江县'
                    },
                    {
                        code: '530423', text: '通海县'
                    },
                    {
                        code: '530424', text: '华宁县'
                    },
                    {
                        code: '530425', text: '易门县'
                    },
                    {
                        code: '530426', text: '峨山彝族自治县'
                    },
                    {
                        code: '530427', text: '新平彝族傣族自治县'
                    },
                    {
                        code: '530428', text: '元江哈尼族彝族傣族自治县'
                    }
                ]
            },
            {
                code: '5305',
                text: '保山市',
                children: [
                    {
                        code: '530502', text: '隆阳区'
                    },
                    {
                        code: '530521', text: '施甸县'
                    },
                    {
                        code: '530523', text: '龙陵县'
                    },
                    {
                        code: '530524', text: '昌宁县'
                    },
                    {
                        code: '530581', text: '腾冲市'
                    }
                ]
            },
            {
                code: '5306',
                text: '昭通市',
                children: [
                    {
                        code: '530602', text: '昭阳区'
                    },
                    {
                        code: '530621', text: '鲁甸县'
                    },
                    {
                        code: '530622', text: '巧家县'
                    },
                    {
                        code: '530623', text: '盐津县'
                    },
                    {
                        code: '530624', text: '大关县'
                    },
                    {
                        code: '530625', text: '永善县'
                    },
                    {
                        code: '530626', text: '绥江县'
                    },
                    {
                        code: '530627', text: '镇雄县'
                    },
                    {
                        code: '530628', text: '彝良县'
                    },
                    {
                        code: '530629', text: '威信县'
                    },
                    {
                        code: '530630', text: '水富县'
                    }
                ]
            },
            {
                code: '5307',
                text: '丽江市',
                children: [
                    {
                        code: '530702', text: '古城区'
                    },
                    {
                        code: '530721', text: '玉龙纳西族自治县'
                    },
                    {
                        code: '530722', text: '永胜县'
                    },
                    {
                        code: '530723', text: '华坪县'
                    },
                    {
                        code: '530724', text: '宁蒗彝族自治县'
                    }
                ]
            },
            {
                code: '5308',
                text: '普洱市',
                children: [
                    {
                        code: '530802', text: '思茅区'
                    },
                    {
                        code: '530821', text: '宁洱哈尼族彝族自治县'
                    },
                    {
                        code: '530822', text: '墨江哈尼族自治县'
                    },
                    {
                        code: '530823', text: '景东彝族自治县'
                    },
                    {
                        code: '530824', text: '景谷傣族彝族自治县'
                    },
                    {
                        code: '530825', text: '镇沅彝族哈尼族拉祜族自治县'
                    },
                    {
                        code: '530826', text: '江城哈尼族彝族自治县'
                    },
                    {
                        code: '530827', text: '孟连傣族拉祜族佤族自治县'
                    },
                    {
                        code: '530828', text: '澜沧拉祜族自治县'
                    },
                    {
                        code: '530829', text: '西盟佤族自治县'
                    }
                ]
            },
            {
                code: '5309',
                text: '临沧市',
                children: [
                    {
                        code: '530902', text: '临翔区'
                    },
                    {
                        code: '530921', text: '凤庆县'
                    },
                    {
                        code: '530922', text: '云县'
                    },
                    {
                        code: '530923', text: '永德县'
                    },
                    {
                        code: '530924', text: '镇康县'
                    },
                    {
                        code: '530925', text: '双江拉祜族佤族布朗族傣族自治县'
                    },
                    {
                        code: '530926', text: '耿马傣族佤族自治县'
                    },
                    {
                        code: '530927', text: '沧源佤族自治县'
                    }
                ]
            },
            {
                code: '5323',
                text: '楚雄彝族自治州',
                children: [
                    {
                        code: '532301', text: '楚雄市'
                    },
                    {
                        code: '532322', text: '双柏县'
                    },
                    {
                        code: '532323', text: '牟定县'
                    },
                    {
                        code: '532324', text: '南华县'
                    },
                    {
                        code: '532325', text: '姚安县'
                    },
                    {
                        code: '532326', text: '大姚县'
                    },
                    {
                        code: '532327', text: '永仁县'
                    },
                    {
                        code: '532328', text: '元谋县'
                    },
                    {
                        code: '532329', text: '武定县'
                    },
                    {
                        code: '532331', text: '禄丰县'
                    }
                ]
            },
            {
                code: '5325',
                text: '红河哈尼族彝族自治州',
                children: [
                    {
                        code: '532501', text: '个旧市'
                    },
                    {
                        code: '532502', text: '开远市'
                    },
                    {
                        code: '532503', text: '蒙自市'
                    },
                    {
                        code: '532504', text: '弥勒市'
                    },
                    {
                        code: '532523', text: '屏边苗族自治县'
                    },
                    {
                        code: '532524', text: '建水县'
                    },
                    {
                        code: '532525', text: '石屏县'
                    },
                    {
                        code: '532527', text: '泸西县'
                    },
                    {
                        code: '532528', text: '元阳县'
                    },
                    {
                        code: '532529', text: '红河县'
                    },
                    {
                        code: '532530', text: '金平苗族瑶族傣族自治县'
                    },
                    {
                        code: '532531', text: '绿春县'
                    },
                    {
                        code: '532532', text: '河口瑶族自治县'
                    }
                ]
            },
            {
                code: '5326',
                text: '文山壮族苗族自治州',
                children: [
                    {
                        code: '532601', text: '文山市'
                    },
                    {
                        code: '532622', text: '砚山县'
                    },
                    {
                        code: '532623', text: '西畴县'
                    },
                    {
                        code: '532624', text: '麻栗坡县'
                    },
                    {
                        code: '532625', text: '马关县'
                    },
                    {
                        code: '532626', text: '丘北县'
                    },
                    {
                        code: '532627', text: '广南县'
                    },
                    {
                        code: '532628', text: '富宁县'
                    }
                ]
            },
            {
                code: '5328',
                text: '西双版纳傣族自治州',
                children: [
                    {
                        code: '532801', text: '景洪市'
                    },
                    {
                        code: '532822', text: '勐海县'
                    },
                    {
                        code: '532823', text: '勐腊县'
                    }
                ]
            },
            {
                code: '5329',
                text: '大理白族自治州',
                children: [
                    {
                        code: '532901', text: '大理市'
                    },
                    {
                        code: '532922', text: '漾濞彝族自治县'
                    },
                    {
                        code: '532923', text: '祥云县'
                    },
                    {
                        code: '532924', text: '宾川县'
                    },
                    {
                        code: '532925', text: '弥渡县'
                    },
                    {
                        code: '532926', text: '南涧彝族自治县'
                    },
                    {
                        code: '532927', text: '巍山彝族回族自治县'
                    },
                    {
                        code: '532928', text: '永平县'
                    },
                    {
                        code: '532929', text: '云龙县'
                    },
                    {
                        code: '532930', text: '洱源县'
                    },
                    {
                        code: '532931', text: '剑川县'
                    },
                    {
                        code: '532932', text: '鹤庆县'
                    }
                ]
            },
            {
                code: '5331',
                text: '德宏傣族景颇族自治州',
                children: [
                    {
                        code: '533102', text: '瑞丽市'
                    },
                    {
                        code: '533103', text: '芒市'
                    },
                    {
                        code: '533122', text: '梁河县'
                    },
                    {
                        code: '533123', text: '盈江县'
                    },
                    {
                        code: '533124', text: '陇川县'
                    }
                ]
            },
            {
                code: '5333',
                text: '怒江傈僳族自治州',
                children: [
                    {
                        code: '533301', text: '泸水市'
                    },
                    {
                        code: '533323', text: '福贡县'
                    },
                    {
                        code: '533324', text: '贡山独龙族怒族自治县'
                    },
                    {
                        code: '533325', text: '兰坪白族普米族自治县'
                    }
                ]
            },
            {
                code: '5334',
                text: '迪庆藏族自治州',
                children: [
                    {
                        code: '533401', text: '香格里拉市'
                    },
                    {
                        code: '533422', text: '德钦县'
                    },
                    {
                        code: '533423', text: '维西傈僳族自治县'
                    }
                ]
            }
        ]
    },
    {
        code: '54',
        text: '西藏自治区',
        children: [
            {
                code: '5401',
                text: '拉萨市',
                children: [
                    {
                        code: '540102', text: '城关区'
                    },
                    {
                        code: '540103', text: '堆龙德庆区'
                    },
                    {
                        code: '540121', text: '林周县'
                    },
                    {
                        code: '540122', text: '当雄县'
                    },
                    {
                        code: '540123', text: '尼木县'
                    },
                    {
                        code: '540124', text: '曲水县'
                    },
                    {
                        code: '540126', text: '达孜县'
                    },
                    {
                        code: '540127', text: '墨竹工卡县'
                    },
                    {
                        code: '540171', text: '格尔木藏青工业园区'
                    },
                    {
                        code: '540172', text: '拉萨经济技术开发区'
                    },
                    {
                        code: '540173', text: '西藏文化旅游创意园区'
                    },
                    {
                        code: '540174', text: '达孜工业园区'
                    }
                ]
            },
            {
                code: '5402',
                text: '日喀则市',
                children: [
                    {
                        code: '540202', text: '桑珠孜区'
                    },
                    {
                        code: '540221', text: '南木林县'
                    },
                    {
                        code: '540222', text: '江孜县'
                    },
                    {
                        code: '540223', text: '定日县'
                    },
                    {
                        code: '540224', text: '萨迦县'
                    },
                    {
                        code: '540225', text: '拉孜县'
                    },
                    {
                        code: '540226', text: '昂仁县'
                    },
                    {
                        code: '540227', text: '谢通门县'
                    },
                    {
                        code: '540228', text: '白朗县'
                    },
                    {
                        code: '540229', text: '仁布县'
                    },
                    {
                        code: '540230', text: '康马县'
                    },
                    {
                        code: '540231', text: '定结县'
                    },
                    {
                        code: '540232', text: '仲巴县'
                    },
                    {
                        code: '540233', text: '亚东县'
                    },
                    {
                        code: '540234', text: '吉隆县'
                    },
                    {
                        code: '540235', text: '聂拉木县'
                    },
                    {
                        code: '540236', text: '萨嘎县'
                    },
                    {
                        code: '540237', text: '岗巴县'
                    }
                ]
            },
            {
                code: '5403',
                text: '昌都市',
                children: [
                    {
                        code: '540302', text: '卡若区'
                    },
                    {
                        code: '540321', text: '江达县'
                    },
                    {
                        code: '540322', text: '贡觉县'
                    },
                    {
                        code: '540323', text: '类乌齐县'
                    },
                    {
                        code: '540324', text: '丁青县'
                    },
                    {
                        code: '540325', text: '察雅县'
                    },
                    {
                        code: '540326', text: '八宿县'
                    },
                    {
                        code: '540327', text: '左贡县'
                    },
                    {
                        code: '540328', text: '芒康县'
                    },
                    {
                        code: '540329', text: '洛隆县'
                    },
                    {
                        code: '540330', text: '边坝县'
                    }
                ]
            },
            {
                code: '5404',
                text: '林芝市',
                children: [
                    {
                        code: '540402', text: '巴宜区'
                    },
                    {
                        code: '540421', text: '工布江达县'
                    },
                    {
                        code: '540422', text: '米林县'
                    },
                    {
                        code: '540423', text: '墨脱县'
                    },
                    {
                        code: '540424', text: '波密县'
                    },
                    {
                        code: '540425', text: '察隅县'
                    },
                    {
                        code: '540426', text: '朗县'
                    }
                ]
            },
            {
                code: '5405',
                text: '山南市',
                children: [
                    {
                        code: '540502', text: '乃东区'
                    },
                    {
                        code: '540521', text: '扎囊县'
                    },
                    {
                        code: '540522', text: '贡嘎县'
                    },
                    {
                        code: '540523', text: '桑日县'
                    },
                    {
                        code: '540524', text: '琼结县'
                    },
                    {
                        code: '540525', text: '曲松县'
                    },
                    {
                        code: '540526', text: '措美县'
                    },
                    {
                        code: '540527', text: '洛扎县'
                    },
                    {
                        code: '540528', text: '加查县'
                    },
                    {
                        code: '540529', text: '隆子县'
                    },
                    {
                        code: '540530', text: '错那县'
                    },
                    {
                        code: '540531', text: '浪卡子县'
                    }
                ]
            },
            {
                code: '5424',
                text: '那曲地区',
                children: [
                    {
                        code: '542421', text: '那曲县'
                    },
                    {
                        code: '542422', text: '嘉黎县'
                    },
                    {
                        code: '542423', text: '比如县'
                    },
                    {
                        code: '542424', text: '聂荣县'
                    },
                    {
                        code: '542425', text: '安多县'
                    },
                    {
                        code: '542426', text: '申扎县'
                    },
                    {
                        code: '542427', text: '索县'
                    },
                    {
                        code: '542428', text: '班戈县'
                    },
                    {
                        code: '542429', text: '巴青县'
                    },
                    {
                        code: '542430', text: '尼玛县'
                    },
                    {
                        code: '542431', text: '双湖县'
                    }
                ]
            },
            {
                code: '5425',
                text: '阿里地区',
                children: [
                    {
                        code: '542521', text: '普兰县'
                    },
                    {
                        code: '542522', text: '札达县'
                    },
                    {
                        code: '542523', text: '噶尔县'
                    },
                    {
                        code: '542524', text: '日土县'
                    },
                    {
                        code: '542525', text: '革吉县'
                    },
                    {
                        code: '542526', text: '改则县'
                    },
                    {
                        code: '542527', text: '措勤县'
                    }
                ]
            }
        ]
    },
    {
        code: '61',
        text: '陕西省',
        children: [
            {
                code: '6101',
                text: '西安市',
                children: [
                    {
                        code: '610102', text: '新城区'
                    },
                    {
                        code: '610103', text: '碑林区'
                    },
                    {
                        code: '610104', text: '莲湖区'
                    },
                    {
                        code: '610111', text: '灞桥区'
                    },
                    {
                        code: '610112', text: '未央区'
                    },
                    {
                        code: '610113', text: '雁塔区'
                    },
                    {
                        code: '610114', text: '阎良区'
                    },
                    {
                        code: '610115', text: '临潼区'
                    },
                    {
                        code: '610116', text: '长安区'
                    },
                    {
                        code: '610117', text: '高陵区'
                    },
                    {
                        code: '610118', text: '鄠邑区'
                    },
                    {
                        code: '610122', text: '蓝田县'
                    },
                    {
                        code: '610124', text: '周至县'
                    }
                ]
            },
            {
                code: '6102',
                text: '铜川市',
                children: [
                    {
                        code: '610202', text: '王益区'
                    },
                    {
                        code: '610203', text: '印台区'
                    },
                    {
                        code: '610204', text: '耀州区'
                    },
                    {
                        code: '610222', text: '宜君县'
                    }
                ]
            },
            {
                code: '6103',
                text: '宝鸡市',
                children: [
                    {
                        code: '610302', text: '渭滨区'
                    },
                    {
                        code: '610303', text: '金台区'
                    },
                    {
                        code: '610304', text: '陈仓区'
                    },
                    {
                        code: '610322', text: '凤翔县'
                    },
                    {
                        code: '610323', text: '岐山县'
                    },
                    {
                        code: '610324', text: '扶风县'
                    },
                    {
                        code: '610326', text: '眉县'
                    },
                    {
                        code: '610327', text: '陇县'
                    },
                    {
                        code: '610328', text: '千阳县'
                    },
                    {
                        code: '610329', text: '麟游县'
                    },
                    {
                        code: '610330', text: '凤县'
                    },
                    {
                        code: '610331', text: '太白县'
                    }
                ]
            },
            {
                code: '6104',
                text: '咸阳市',
                children: [
                    {
                        code: '610402', text: '秦都区'
                    },
                    {
                        code: '610403', text: '杨陵区'
                    },
                    {
                        code: '610404', text: '渭城区'
                    },
                    {
                        code: '610422', text: '三原县'
                    },
                    {
                        code: '610423', text: '泾阳县'
                    },
                    {
                        code: '610424', text: '乾县'
                    },
                    {
                        code: '610425', text: '礼泉县'
                    },
                    {
                        code: '610426', text: '永寿县'
                    },
                    {
                        code: '610427', text: '彬县'
                    },
                    {
                        code: '610428', text: '长武县'
                    },
                    {
                        code: '610429', text: '旬邑县'
                    },
                    {
                        code: '610430', text: '淳化县'
                    },
                    {
                        code: '610431', text: '武功县'
                    },
                    {
                        code: '610481', text: '兴平市'
                    }
                ]
            },
            {
                code: '6105',
                text: '渭南市',
                children: [
                    {
                        code: '610502', text: '临渭区'
                    },
                    {
                        code: '610503', text: '华州区'
                    },
                    {
                        code: '610522', text: '潼关县'
                    },
                    {
                        code: '610523', text: '大荔县'
                    },
                    {
                        code: '610524', text: '合阳县'
                    },
                    {
                        code: '610525', text: '澄城县'
                    },
                    {
                        code: '610526', text: '蒲城县'
                    },
                    {
                        code: '610527', text: '白水县'
                    },
                    {
                        code: '610528', text: '富平县'
                    },
                    {
                        code: '610581', text: '韩城市'
                    },
                    {
                        code: '610582', text: '华阴市'
                    }
                ]
            },
            {
                code: '6106',
                text: '延安市',
                children: [
                    {
                        code: '610602', text: '宝塔区'
                    },
                    {
                        code: '610603', text: '安塞区'
                    },
                    {
                        code: '610621', text: '延长县'
                    },
                    {
                        code: '610622', text: '延川县'
                    },
                    {
                        code: '610623', text: '子长县'
                    },
                    {
                        code: '610625', text: '志丹县'
                    },
                    {
                        code: '610626', text: '吴起县'
                    },
                    {
                        code: '610627', text: '甘泉县'
                    },
                    {
                        code: '610628', text: '富县'
                    },
                    {
                        code: '610629', text: '洛川县'
                    },
                    {
                        code: '610630', text: '宜川县'
                    },
                    {
                        code: '610631', text: '黄龙县'
                    },
                    {
                        code: '610632', text: '黄陵县'
                    }
                ]
            },
            {
                code: '6107',
                text: '汉中市',
                children: [
                    {
                        code: '610702', text: '汉台区'
                    },
                    {
                        code: '610703', text: '南郑区'
                    },
                    {
                        code: '610722', text: '城固县'
                    },
                    {
                        code: '610723', text: '洋县'
                    },
                    {
                        code: '610724', text: '西乡县'
                    },
                    {
                        code: '610725', text: '勉县'
                    },
                    {
                        code: '610726', text: '宁强县'
                    },
                    {
                        code: '610727', text: '略阳县'
                    },
                    {
                        code: '610728', text: '镇巴县'
                    },
                    {
                        code: '610729', text: '留坝县'
                    },
                    {
                        code: '610730', text: '佛坪县'
                    }
                ]
            },
            {
                code: '6108',
                text: '榆林市',
                children: [
                    {
                        code: '610802', text: '榆阳区'
                    },
                    {
                        code: '610803', text: '横山区'
                    },
                    {
                        code: '610822', text: '府谷县'
                    },
                    {
                        code: '610824', text: '靖边县'
                    },
                    {
                        code: '610825', text: '定边县'
                    },
                    {
                        code: '610826', text: '绥德县'
                    },
                    {
                        code: '610827', text: '米脂县'
                    },
                    {
                        code: '610828', text: '佳县'
                    },
                    {
                        code: '610829', text: '吴堡县'
                    },
                    {
                        code: '610830', text: '清涧县'
                    },
                    {
                        code: '610831', text: '子洲县'
                    },
                    {
                        code: '610881', text: '神木市'
                    }
                ]
            },
            {
                code: '6109',
                text: '安康市',
                children: [
                    {
                        code: '610902', text: '汉滨区'
                    },
                    {
                        code: '610921', text: '汉阴县'
                    },
                    {
                        code: '610922', text: '石泉县'
                    },
                    {
                        code: '610923', text: '宁陕县'
                    },
                    {
                        code: '610924', text: '紫阳县'
                    },
                    {
                        code: '610925', text: '岚皋县'
                    },
                    {
                        code: '610926', text: '平利县'
                    },
                    {
                        code: '610927', text: '镇坪县'
                    },
                    {
                        code: '610928', text: '旬阳县'
                    },
                    {
                        code: '610929', text: '白河县'
                    }
                ]
            },
            {
                code: '6110',
                text: '商洛市',
                children: [
                    {
                        code: '611002', text: '商州区'
                    },
                    {
                        code: '611021', text: '洛南县'
                    },
                    {
                        code: '611022', text: '丹凤县'
                    },
                    {
                        code: '611023', text: '商南县'
                    },
                    {
                        code: '611024', text: '山阳县'
                    },
                    {
                        code: '611025', text: '镇安县'
                    },
                    {
                        code: '611026', text: '柞水县'
                    }
                ]
            }
        ]
    },
    {
        code: '62',
        text: '甘肃省',
        children: [
            {
                code: '6201',
                text: '兰州市',
                children: [
                    {
                        code: '620102', text: '城关区'
                    },
                    {
                        code: '620103', text: '七里河区'
                    },
                    {
                        code: '620104', text: '西固区'
                    },
                    {
                        code: '620105', text: '安宁区'
                    },
                    {
                        code: '620111', text: '红古区'
                    },
                    {
                        code: '620121', text: '永登县'
                    },
                    {
                        code: '620122', text: '皋兰县'
                    },
                    {
                        code: '620123', text: '榆中县'
                    },
                    {
                        code: '620171', text: '兰州新区'
                    }
                ]
            },
            {
                code: '6202',
                text: '嘉峪关市',
                children: [
                    {
                        code: '620201100', text: '新城镇'
                    },
                    {
                        code: '620201101', text: '峪泉镇'
                    },
                    {
                        code: '620201102', text: '文殊镇'
                    },
                    {
                        code: '620201401', text: '雄关区'
                    },
                    {
                        code: '620201402', text: '镜铁区'
                    },
                    {
                        code: '620201403', text: '长城区'
                    }
                ]
            },
            {
                code: '6203',
                text: '金昌市',
                children: [
                    {
                        code: '620302', text: '金川区'
                    },
                    {
                        code: '620321', text: '永昌县'
                    }
                ]
            },
            {
                code: '6204',
                text: '白银市',
                children: [
                    {
                        code: '620402', text: '白银区'
                    },
                    {
                        code: '620403', text: '平川区'
                    },
                    {
                        code: '620421', text: '靖远县'
                    },
                    {
                        code: '620422', text: '会宁县'
                    },
                    {
                        code: '620423', text: '景泰县'
                    }
                ]
            },
            {
                code: '6205',
                text: '天水市',
                children: [
                    {
                        code: '620502', text: '秦州区'
                    },
                    {
                        code: '620503', text: '麦积区'
                    },
                    {
                        code: '620521', text: '清水县'
                    },
                    {
                        code: '620522', text: '秦安县'
                    },
                    {
                        code: '620523', text: '甘谷县'
                    },
                    {
                        code: '620524', text: '武山县'
                    },
                    {
                        code: '620525', text: '张家川回族自治县'
                    }
                ]
            },
            {
                code: '6206',
                text: '武威市',
                children: [
                    {
                        code: '620602', text: '凉州区'
                    },
                    {
                        code: '620621', text: '民勤县'
                    },
                    {
                        code: '620622', text: '古浪县'
                    },
                    {
                        code: '620623', text: '天祝藏族自治县'
                    }
                ]
            },
            {
                code: '6207',
                text: '张掖市',
                children: [
                    {
                        code: '620702', text: '甘州区'
                    },
                    {
                        code: '620721', text: '肃南裕固族自治县'
                    },
                    {
                        code: '620722', text: '民乐县'
                    },
                    {
                        code: '620723', text: '临泽县'
                    },
                    {
                        code: '620724', text: '高台县'
                    },
                    {
                        code: '620725', text: '山丹县'
                    }
                ]
            },
            {
                code: '6208',
                text: '平凉市',
                children: [
                    {
                        code: '620802', text: '崆峒区'
                    },
                    {
                        code: '620821', text: '泾川县'
                    },
                    {
                        code: '620822', text: '灵台县'
                    },
                    {
                        code: '620823', text: '崇信县'
                    },
                    {
                        code: '620824', text: '华亭县'
                    },
                    {
                        code: '620825', text: '庄浪县'
                    },
                    {
                        code: '620826', text: '静宁县'
                    },
                    {
                        code: '620871', text: '平凉工业园区'
                    }
                ]
            },
            {
                code: '6209',
                text: '酒泉市',
                children: [
                    {
                        code: '620902', text: '肃州区'
                    },
                    {
                        code: '620921', text: '金塔县'
                    },
                    {
                        code: '620922', text: '瓜州县'
                    },
                    {
                        code: '620923', text: '肃北蒙古族自治县'
                    },
                    {
                        code: '620924', text: '阿克塞哈萨克族自治县'
                    },
                    {
                        code: '620981', text: '玉门市'
                    },
                    {
                        code: '620982', text: '敦煌市'
                    }
                ]
            },
            {
                code: '6210',
                text: '庆阳市',
                children: [
                    {
                        code: '621002', text: '西峰区'
                    },
                    {
                        code: '621021', text: '庆城县'
                    },
                    {
                        code: '621022', text: '环县'
                    },
                    {
                        code: '621023', text: '华池县'
                    },
                    {
                        code: '621024', text: '合水县'
                    },
                    {
                        code: '621025', text: '正宁县'
                    },
                    {
                        code: '621026', text: '宁县'
                    },
                    {
                        code: '621027', text: '镇原县'
                    }
                ]
            },
            {
                code: '6211',
                text: '定西市',
                children: [
                    {
                        code: '621102', text: '安定区'
                    },
                    {
                        code: '621121', text: '通渭县'
                    },
                    {
                        code: '621122', text: '陇西县'
                    },
                    {
                        code: '621123', text: '渭源县'
                    },
                    {
                        code: '621124', text: '临洮县'
                    },
                    {
                        code: '621125', text: '漳县'
                    },
                    {
                        code: '621126', text: '岷县'
                    }
                ]
            },
            {
                code: '6212',
                text: '陇南市',
                children: [
                    {
                        code: '621202', text: '武都区'
                    },
                    {
                        code: '621221', text: '成县'
                    },
                    {
                        code: '621222', text: '文县'
                    },
                    {
                        code: '621223', text: '宕昌县'
                    },
                    {
                        code: '621224', text: '康县'
                    },
                    {
                        code: '621225', text: '西和县'
                    },
                    {
                        code: '621226', text: '礼县'
                    },
                    {
                        code: '621227', text: '徽县'
                    },
                    {
                        code: '621228', text: '两当县'
                    }
                ]
            },
            {
                code: '6229',
                text: '临夏回族自治州',
                children: [
                    {
                        code: '622901', text: '临夏市'
                    },
                    {
                        code: '622921', text: '临夏县'
                    },
                    {
                        code: '622922', text: '康乐县'
                    },
                    {
                        code: '622923', text: '永靖县'
                    },
                    {
                        code: '622924', text: '广河县'
                    },
                    {
                        code: '622925', text: '和政县'
                    },
                    {
                        code: '622926', text: '东乡族自治县'
                    },
                    {
                        code: '622927', text: '积石山保安族东乡族撒拉族自治县'
                    }
                ]
            },
            {
                code: '6230',
                text: '甘南藏族自治州',
                children: [
                    {
                        code: '623001', text: '合作市'
                    },
                    {
                        code: '623021', text: '临潭县'
                    },
                    {
                        code: '623022', text: '卓尼县'
                    },
                    {
                        code: '623023', text: '舟曲县'
                    },
                    {
                        code: '623024', text: '迭部县'
                    },
                    {
                        code: '623025', text: '玛曲县'
                    },
                    {
                        code: '623026', text: '碌曲县'
                    },
                    {
                        code: '623027', text: '夏河县'
                    }
                ]
            }
        ]
    },
    {
        code: '63',
        text: '青海省',
        children: [
            {
                code: '6301',
                text: '西宁市',
                children: [
                    {
                        code: '630102', text: '城东区'
                    },
                    {
                        code: '630103', text: '城中区'
                    },
                    {
                        code: '630104', text: '城西区'
                    },
                    {
                        code: '630105', text: '城北区'
                    },
                    {
                        code: '630121', text: '大通回族土族自治县'
                    },
                    {
                        code: '630122', text: '湟中县'
                    },
                    {
                        code: '630123', text: '湟源县'
                    }
                ]
            },
            {
                code: '6302',
                text: '海东市',
                children: [
                    {
                        code: '630202', text: '乐都区'
                    },
                    {
                        code: '630203', text: '平安区'
                    },
                    {
                        code: '630222', text: '民和回族土族自治县'
                    },
                    {
                        code: '630223', text: '互助土族自治县'
                    },
                    {
                        code: '630224', text: '化隆回族自治县'
                    },
                    {
                        code: '630225', text: '循化撒拉族自治县'
                    }
                ]
            },
            {
                code: '6322',
                text: '海北藏族自治州',
                children: [
                    {
                        code: '632221', text: '门源回族自治县'
                    },
                    {
                        code: '632222', text: '祁连县'
                    },
                    {
                        code: '632223', text: '海晏县'
                    },
                    {
                        code: '632224', text: '刚察县'
                    }
                ]
            },
            {
                code: '6323',
                text: '黄南藏族自治州',
                children: [
                    {
                        code: '632321', text: '同仁县'
                    },
                    {
                        code: '632322', text: '尖扎县'
                    },
                    {
                        code: '632323', text: '泽库县'
                    },
                    {
                        code: '632324', text: '河南蒙古族自治县'
                    }
                ]
            },
            {
                code: '6325',
                text: '海南藏族自治州',
                children: [
                    {
                        code: '632521', text: '共和县'
                    },
                    {
                        code: '632522', text: '同德县'
                    },
                    {
                        code: '632523', text: '贵德县'
                    },
                    {
                        code: '632524', text: '兴海县'
                    },
                    {
                        code: '632525', text: '贵南县'
                    }
                ]
            },
            {
                code: '6326',
                text: '果洛藏族自治州',
                children: [
                    {
                        code: '632621', text: '玛沁县'
                    },
                    {
                        code: '632622', text: '班玛县'
                    },
                    {
                        code: '632623', text: '甘德县'
                    },
                    {
                        code: '632624', text: '达日县'
                    },
                    {
                        code: '632625', text: '久治县'
                    },
                    {
                        code: '632626', text: '玛多县'
                    }
                ]
            },
            {
                code: '6327',
                text: '玉树藏族自治州',
                children: [
                    {
                        code: '632701', text: '玉树市'
                    },
                    {
                        code: '632722', text: '杂多县'
                    },
                    {
                        code: '632723', text: '称多县'
                    },
                    {
                        code: '632724', text: '治多县'
                    },
                    {
                        code: '632725', text: '囊谦县'
                    },
                    {
                        code: '632726', text: '曲麻莱县'
                    }
                ]
            },
            {
                code: '6328',
                text: '海西蒙古族藏族自治州',
                children: [
                    {
                        code: '632801', text: '格尔木市'
                    },
                    {
                        code: '632802', text: '德令哈市'
                    },
                    {
                        code: '632821', text: '乌兰县'
                    },
                    {
                        code: '632822', text: '都兰县'
                    },
                    {
                        code: '632823', text: '天峻县'
                    },
                    {
                        code: '632857', text: '大柴旦行政委员会'
                    },
                    {
                        code: '632858', text: '冷湖行政委员会'
                    },
                    {
                        code: '632859', text: '茫崖行政委员会'
                    }
                ]
            }
        ]
    },
    {
        code: '64',
        text: '宁夏回族自治区',
        children: [
            {
                code: '6401',
                text: '银川市',
                children: [
                    {
                        code: '640104', text: '兴庆区'
                    },
                    {
                        code: '640105', text: '西夏区'
                    },
                    {
                        code: '640106', text: '金凤区'
                    },
                    {
                        code: '640121', text: '永宁县'
                    },
                    {
                        code: '640122', text: '贺兰县'
                    },
                    {
                        code: '640181', text: '灵武市'
                    }
                ]
            },
            {
                code: '6402',
                text: '石嘴山市',
                children: [
                    {
                        code: '640202', text: '大武口区'
                    },
                    {
                        code: '640205', text: '惠农区'
                    },
                    {
                        code: '640221', text: '平罗县'
                    }
                ]
            },
            {
                code: '6403',
                text: '吴忠市',
                children: [
                    {
                        code: '640302', text: '利通区'
                    },
                    {
                        code: '640303', text: '红寺堡区'
                    },
                    {
                        code: '640323', text: '盐池县'
                    },
                    {
                        code: '640324', text: '同心县'
                    },
                    {
                        code: '640381', text: '青铜峡市'
                    }
                ]
            },
            {
                code: '6404',
                text: '固原市',
                children: [
                    {
                        code: '640402', text: '原州区'
                    },
                    {
                        code: '640422', text: '西吉县'
                    },
                    {
                        code: '640423', text: '隆德县'
                    },
                    {
                        code: '640424', text: '泾源县'
                    },
                    {
                        code: '640425', text: '彭阳县'
                    }
                ]
            },
            {
                code: '6405',
                text: '中卫市',
                children: [
                    {
                        code: '640502', text: '沙坡头区'
                    },
                    {
                        code: '640521', text: '中宁县'
                    },
                    {
                        code: '640522', text: '海原县'
                    }
                ]
            }
        ]
    },
    {
        code: '65',
        text: '新疆维吾尔自治区',
        children: [
            {
                code: '6501',
                text: '乌鲁木齐市',
                children: [
                    {
                        code: '650102', text: '天山区'
                    },
                    {
                        code: '650103', text: '沙依巴克区'
                    },
                    {
                        code: '650104', text: '新市区'
                    },
                    {
                        code: '650105', text: '水磨沟区'
                    },
                    {
                        code: '650106', text: '头屯河区'
                    },
                    {
                        code: '650107', text: '达坂城区'
                    },
                    {
                        code: '650109', text: '米东区'
                    },
                    {
                        code: '650121', text: '乌鲁木齐县'
                    },
                    {
                        code: '650171', text: '乌鲁木齐经济技术开发区'
                    },
                    {
                        code: '650172', text: '乌鲁木齐高新技术产业开发区'
                    }
                ]
            },
            {
                code: '6502',
                text: '克拉玛依市',
                children: [
                    {
                        code: '650202', text: '独山子区'
                    },
                    {
                        code: '650203', text: '克拉玛依区'
                    },
                    {
                        code: '650204', text: '白碱滩区'
                    },
                    {
                        code: '650205', text: '乌尔禾区'
                    }
                ]
            },
            {
                code: '6504',
                text: '吐鲁番市',
                children: [
                    {
                        code: '650402', text: '高昌区'
                    },
                    {
                        code: '650421', text: '鄯善县'
                    },
                    {
                        code: '650422', text: '托克逊县'
                    }
                ]
            },
            {
                code: '6505',
                text: '哈密市',
                children: [
                    {
                        code: '650502', text: '伊州区'
                    },
                    {
                        code: '650521', text: '巴里坤哈萨克自治县'
                    },
                    {
                        code: '650522', text: '伊吾县'
                    }
                ]
            },
            {
                code: '6523',
                text: '昌吉回族自治州',
                children: [
                    {
                        code: '652301', text: '昌吉市'
                    },
                    {
                        code: '652302', text: '阜康市'
                    },
                    {
                        code: '652323', text: '呼图壁县'
                    },
                    {
                        code: '652324', text: '玛纳斯县'
                    },
                    {
                        code: '652325', text: '奇台县'
                    },
                    {
                        code: '652327', text: '吉木萨尔县'
                    },
                    {
                        code: '652328', text: '木垒哈萨克自治县'
                    }
                ]
            },
            {
                code: '6527',
                text: '博尔塔拉蒙古自治州',
                children: [
                    {
                        code: '652701', text: '博乐市'
                    },
                    {
                        code: '652702', text: '阿拉山口市'
                    },
                    {
                        code: '652722', text: '精河县'
                    },
                    {
                        code: '652723', text: '温泉县'
                    }
                ]
            },
            {
                code: '6528',
                text: '巴音郭楞蒙古自治州',
                children: [
                    {
                        code: '652801', text: '库尔勒市'
                    },
                    {
                        code: '652822', text: '轮台县'
                    },
                    {
                        code: '652823', text: '尉犁县'
                    },
                    {
                        code: '652824', text: '若羌县'
                    },
                    {
                        code: '652825', text: '且末县'
                    },
                    {
                        code: '652826', text: '焉耆回族自治县'
                    },
                    {
                        code: '652827', text: '和静县'
                    },
                    {
                        code: '652828', text: '和硕县'
                    },
                    {
                        code: '652829', text: '博湖县'
                    },
                    {
                        code: '652871', text: '库尔勒经济技术开发区'
                    }
                ]
            },
            {
                code: '6529',
                text: '阿克苏地区',
                children: [
                    {
                        code: '652901', text: '阿克苏市'
                    },
                    {
                        code: '652922', text: '温宿县'
                    },
                    {
                        code: '652923', text: '库车县'
                    },
                    {
                        code: '652924', text: '沙雅县'
                    },
                    {
                        code: '652925', text: '新和县'
                    },
                    {
                        code: '652926', text: '拜城县'
                    },
                    {
                        code: '652927', text: '乌什县'
                    },
                    {
                        code: '652928', text: '阿瓦提县'
                    },
                    {
                        code: '652929', text: '柯坪县'
                    }
                ]
            },
            {
                code: '6530',
                text: '克孜勒苏柯尔克孜自治州',
                children: [
                    {
                        code: '653001', text: '阿图什市'
                    },
                    {
                        code: '653022', text: '阿克陶县'
                    },
                    {
                        code: '653023', text: '阿合奇县'
                    },
                    {
                        code: '653024', text: '乌恰县'
                    }
                ]
            },
            {
                code: '6531',
                text: '喀什地区',
                children: [
                    {
                        code: '653101', text: '喀什市'
                    },
                    {
                        code: '653121', text: '疏附县'
                    },
                    {
                        code: '653122', text: '疏勒县'
                    },
                    {
                        code: '653123', text: '英吉沙县'
                    },
                    {
                        code: '653124', text: '泽普县'
                    },
                    {
                        code: '653125', text: '莎车县'
                    },
                    {
                        code: '653126', text: '叶城县'
                    },
                    {
                        code: '653127', text: '麦盖提县'
                    },
                    {
                        code: '653128', text: '岳普湖县'
                    },
                    {
                        code: '653129', text: '伽师县'
                    },
                    {
                        code: '653130', text: '巴楚县'
                    },
                    {
                        code: '653131', text: '塔什库尔干塔吉克自治县'
                    }
                ]
            },
            {
                code: '6532',
                text: '和田地区',
                children: [
                    {
                        code: '653201', text: '和田市'
                    },
                    {
                        code: '653221', text: '和田县'
                    },
                    {
                        code: '653222', text: '墨玉县'
                    },
                    {
                        code: '653223', text: '皮山县'
                    },
                    {
                        code: '653224', text: '洛浦县'
                    },
                    {
                        code: '653225', text: '策勒县'
                    },
                    {
                        code: '653226', text: '于田县'
                    },
                    {
                        code: '653227', text: '民丰县'
                    }
                ]
            },
            {
                code: '6540',
                text: '伊犁哈萨克自治州',
                children: [
                    {
                        code: '654002', text: '伊宁市'
                    },
                    {
                        code: '654003', text: '奎屯市'
                    },
                    {
                        code: '654004', text: '霍尔果斯市'
                    },
                    {
                        code: '654021', text: '伊宁县'
                    },
                    {
                        code: '654022', text: '察布查尔锡伯自治县'
                    },
                    {
                        code: '654023', text: '霍城县'
                    },
                    {
                        code: '654024', text: '巩留县'
                    },
                    {
                        code: '654025', text: '新源县'
                    },
                    {
                        code: '654026', text: '昭苏县'
                    },
                    {
                        code: '654027', text: '特克斯县'
                    },
                    {
                        code: '654028', text: '尼勒克县'
                    }
                ]
            },
            {
                code: '6542',
                text: '塔城地区',
                children: [
                    {
                        code: '654201', text: '塔城市'
                    },
                    {
                        code: '654202', text: '乌苏市'
                    },
                    {
                        code: '654221', text: '额敏县'
                    },
                    {
                        code: '654223', text: '沙湾县'
                    },
                    {
                        code: '654224', text: '托里县'
                    },
                    {
                        code: '654225', text: '裕民县'
                    },
                    {
                        code: '654226', text: '和布克赛尔蒙古自治县'
                    }
                ]
            },
            {
                code: '6543',
                text: '阿勒泰地区',
                children: [
                    {
                        code: '654301', text: '阿勒泰市'
                    },
                    {
                        code: '654321', text: '布尔津县'
                    },
                    {
                        code: '654322', text: '富蕴县'
                    },
                    {
                        code: '654323', text: '福海县'
                    },
                    {
                        code: '654324', text: '哈巴河县'
                    },
                    {
                        code: '654325', text: '青河县'
                    },
                    {
                        code: '654326', text: '吉木乃县'
                    }
                ]
            },
            {
                code: '6590',
                text: '自治区直辖县级行政区划',
                children: [
                    {
                        code: '659001', text: '石河子市'
                    },
                    {
                        code: '659002', text: '阿拉尔市'
                    },
                    {
                        code: '659003', text: '图木舒克市'
                    },
                    {
                        code: '659004', text: '五家渠市'
                    },
                    {
                        code: '659006', text: '铁门关市'
                    }
                ]
            }
        ]
    }
]
export default adressList
