// 数据处理的方法

// 列出来7天总血糖数据
export const formatData = function formatData(l) {
  const sugerarr = [];
  l.data.data.forEach((ele) => {
    const date = new Date(ele.t);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    hour = hour < 10 ? "0" + hour : hour;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    const enddate =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;
    sugerarr.push({
      date: enddate,
      data: ele.v,
    });
  });
  const sugerlist = [];
  sugerarr.forEach((element) => {
    sugerlist.push(element.data);
  });
  return sugerlist;
};

// 获得 低血糖(<3.9)读取的百分比
export const get7LowRate = function get7LowRate(l) {
  let n = 0;
  l.forEach((element) => {
    if (element < 3.9) {
      n++;
    }
  });
  // 低血糖数量n/读取数据总数
  return (n / l.length).toFixed(2);
};

// 获得 范围内 读取的百分比
export const get7NormalRate = function get7NormalRate(l) {
  let n = 0;
  l.forEach((element) => {
    if (element >= 3.9 && element <= 10) {
      n++;
    }
  });
  // 范围内（>=4.5且<10）的血糖数据个数除以血糖数据总个数
  return (n / l.length).toFixed(2);
};

// 获得 高血糖 读取的百分比
export const get7HighRate = function get7HighRate(l) {
  let n = 0;
  l.forEach((element) => {
    if (element > 10) {
      n++;
    }
  });
  // 高血糖数据个数n除以血糖数据总个数
  return (n / l.length).toFixed(2);
};

// 获得 低血糖(<3.9)读取的数量
export const get7LowNum = function get7LowNum(l) {
  let n = 0;
  l.forEach((element) => {
    if (element < 3.9) {
      n++;
    }
  });
  return n;
};

// 获得 范围内 读取的数量
export const get7NormalNum = function get7NormalNum(l) {
  let n = 0;
  l.forEach((element) => {
    if (element >= 3.9 && element <= 10) {
      n++;
    }
  });
  return n;
};

// 获得 高血糖 读取的数量
export const get7HighNum = function get7HighNum(l) {
  let n = 0;
  l.forEach((element) => {
    if (element > 10) {
      n++;
    }
  });
  return n;
};

// 获得7天总数据中低血糖的平均数
export const get7LowAverage = function get7LowAverage(l) {
  let n = 0;
  let m = 0;
  l.forEach((e) => {
    if (e < 3.9) {
      n += parseFloat(e);
      m++;
    }
  });
  // 低血糖数据总和n除以低血糖数据个数m
  return (n / m).toFixed(1);
};

// 获得7天总数据中范围内的平均数
export const get7NormalAverage = function get7NormalAverage(l) {
  let n = 0;
  let m = 0;
  l.forEach((e) => {
    if (e >= 3.9 && e <= 10) {
      n += parseFloat(e);
      m++;
    }
  });
  // 范围内数据总和n除以范围内数据总个数
  return (n / m).toFixed(1);
};

// 获得7天总数据中高血糖的平均数
export const get7HighAverage = function get7HighAverage(l) {
  let n = 0;
  let m = 0;
  l.forEach((e) => {
    if (e > 10) {
      n += parseFloat(e);
      m++;
    }
  });
  // 高血糖数据总和n除以高血糖数据总个数m
  return (n / m).toFixed(1);
};

// 获取7天总数据的平均数
export const get7Average = function get7Average(l) {
  let n = 0;
  l.forEach((e) => {
    n += parseFloat(e);
  });
  //7天血糖数据总和除以7天读取的血糖总数量
  return (n / l.length).toFixed(1);
};

// 对7天总数据中低血糖进行百分位数处理
export const get7lowpercentile = function get7lowpercentile(l, percentage) {
  const low = [];
  l.forEach((e) => {
    if (e < 3.9) {
      low.push(e); // 低血糖数据存入low中
    }
  });
  const arrList = [];
  const position = (low.length + 1) * (percentage / 100);
  // 根据percentage计算四分位数的位置
  //  第一个四分位数的位置= (n+1) × 0.25
  //  第二个四分位数的位置= (n+1) × 0.5
  //  第三个四分位数的位置= (n+1) × 0.75
  for (var i = 0; i < low.length; i++) {
    arrList.push(low[i]);
  }
  const hadSort = arrList.sort(); // 将低血糖数据从小到大排序存入arrList
  if (position < 1) {
    return "数据不足";
  } else {
    // 举例 数列 1 2 3 4 5 6 7 8
    // Q1在 （8+1）/4=2.25位， 介于第二和第三位之间，但是更靠近第二位。所以第二位数权重占75%，第三位数权重占25%。Q1=（2*0.75+3*0.25）/(0.75+0.25)=2.25
    // Q2在 （8+1）/2=4.5位，即第4和第5位的平均数，Q2=3.9
    // 同理Q3在（8+1）/4*3=6.75位，在第六位和第七位之间，更靠近第7位。所以第7位权重75%，第6位权重25%。
    // Q3=(7*0.75+6*0.25)/(0.75+0.25)=6.75
    return toFF2(
      toFF2(hadSort[parseInt(position)]) *
        (1 - (position - parseInt(position))) +
        toFF2(hadSort[parseInt(position)] + 1) * (position - parseInt(position))
    );
  }
};

// 对7天总数据中范围内进行百分位数处理（计算方法同上）
export const get7normalpercentile = function get7normalpercentile(
  l,
  percentage
) {
  const normal = [];
  l.forEach((e) => {
    if (e >= 3.9 && e <= 10) {
      normal.push(e); // 范围内数据存入normal中
    }
  });
  const arrList = [];
  const position = (normal.length + 1) * (percentage / 100);
  for (var i = 0; i < normal.length; i++) {
    arrList.push(normal[i]);
  }
  const hadSort = arrList.sort();
  if (position < 1) {
    return "数据不足";
  } else {
    return toFF2(
      toFF2(hadSort[parseInt(position)]) *
        (1 - (position - parseInt(position))) +
        toFF2(hadSort[parseInt(position)] + 1) * (position - parseInt(position))
    );
  }
};

// 对7天总数据中高血糖进行百分位数处理(计算方法同上)
export const get7highpercentile = function get7highpercentile(l, percentage) {
  const high = [];
  l.forEach((e) => {
    if (e > 10) {
      high.push(e); //将高血糖数据存入数据high中
    }
  });
  const arrList = [];
  const position = (high.length + 1) * (percentage / 100);
  for (var i = 0; i < high.length; i++) {
    arrList.push(high[i]);
  }
  const hadSort = arrList.sort();
  if (position < 1) {
    return "数据不足";
  } else {
    return toFF2(
      toFF2(hadSort[parseInt(position)]) *
        (1 - (position - parseInt(position))) +
        toFF2(hadSort[parseInt(position)] + 1) * (position - parseInt(position))
    );
  }
};

// 对7天总数据进行百分位数处理（计算方法同上）
export const get7percentile = function get7percentile(l, percentage) {
  const arrList = [];
  const position = (l.length + 1) * (percentage / 100);
  for (var i = 0; i < l.length; i++) {
    arrList.push(l[i]); //将七天总血糖数据存入数组arrList中
  }
  const hadSort = arrList.sort();
  if (position < 1) {
    return "数据不足";
  } else {
    return toFF2(
      toFF2(hadSort[parseInt(position)]) *
        (1 - (position - parseInt(position))) +
        toFF2(hadSort[parseInt(position)] + 1) * (position - parseInt(position))
    );
  }
};

// 获取7天总数据中低血糖的标准偏差
export const get7lowstandard = function get7lowstandard(m, avg) {
  const l = [];
  m.forEach((ele) => {
    if (ele < 3.9) {
      l.push(ele); // 将低血糖数据存入数组l中
    }
  });
  let square = 0;
  l.forEach((element) => {
    const mis = Math.max(element, avg) - Math.min(element, avg); //计算每个数据与平均数的差值
    const multiply = mis * mis; // 计算每个差值的平方
    square += multiply; // 所有平方相加
  });
  const num = square / l.length; // 平方相加之和除以血糖总数量
  const number = Math.sqrt(num); // 将计算结果开方，得出标准偏差
  return number.toFixed(2);
};

// 获取7天总数据中范围内的标准偏差（计算方法同上）
export const get7normalstandard = function get7normalstandard(m, avg) {
  const l = [];
  m.forEach((ele) => {
    if (ele >= 3.9 && ele <= 10) {
      l.push(ele);
    } // 将范围内数据存入数组l中
  });
  let square = 0;
  l.forEach((element) => {
    const mis = Math.max(element, avg) - Math.min(element, avg);
    const multiply = mis * mis;
    square += multiply;
  });
  const num = square / l.length;
  const number = Math.sqrt(num);
  return number.toFixed(2);
};
// 获取7天总数据中高血糖的标准偏差（计算方法同上）
export const get7highstandard = function get7highstandard(m, avg) {
  const l = [];
  m.forEach((ele) => {
    if (ele > 10) {
      l.push(ele);
    } // 将高血糖数据存入数组l中
  });
  let square = 0;
  l.forEach((element) => {
    const mis = Math.max(element, avg) - Math.min(element, avg);
    const multiply = mis * mis;
    square += multiply;
  });
  const num = square / l.length;
  const number = Math.sqrt(num);
  return number.toFixed(2);
};
// 获取7天总数据的标准偏差（计算方法同上）
export const get7standard = function get7standard(m, avg) {
  let square = 0;
  m.forEach((element) => {
    const mis = Math.max(element, avg) - Math.min(element, avg);
    const multiply = mis * mis;
    square += multiply;
  });
  const num = square / m.length;
  const number = Math.sqrt(num);
  return number.toFixed(2);
};

// 对数据进行糖化血红蛋白估算
// 糖化血红蛋白 = （平时的平均血糖+2.59）/1.59
export const get7protein = function get7protein(m) {
  let n = 0;
  m.forEach((e) => {
    n += parseFloat(e);
    // parseFloat(e)
  }); //计算血糖数据总和为n
  const avg = n / m.length; //计算平均血糖
  const protein = (avg + 2.59) / 1.59;
  return protein.toFixed(2);
};

// 求当天血糖最小值
export const getMin = function getMin(l) {
  let min = 0;
  l.data.forEach((element) => {
    min = element < min ? element : min;
    min = min === 0 ? element : min;
  });
  return min;
};
// 求当天血糖最大值
export const getMax = function getMax(l) {
  let max = 0;
  l.data.forEach((element) => {
    max = element > max ? element : max;
  });
  return max;
};

// 对数据进行取平均数处理
export const Average = function Average(l) {
  let n = 0;
  l.data.forEach((e) => {
    n += parseFloat(e);
  });
  return (n / l.data.length).toFixed(1);
};

// 对数据进行百分位数处理
export const percentile = function percentile(l, percentage) {
  const arrList = [];
  const position = (l.data.length + 1) * (percentage / 100);
  // 根据percentage计算出四分位数的位置
  //  第一个四分位数的位置= (n+1) × 0.25
  //  第二个四分位数的位置= (n+1) × 0.5
  //  第三个四分位数的位置= (n+1) × 0.75
  for (var i = 0; i < l.data.length; i++) {
    arrList.push(l.data[i]);
  }
  const hadSort = arrList.sort(); // 将数据进行从小到大排序
  if (position < 1) {
    return "数据不足";
  } else {
    // 举例 数列 1 2 3 4 5 6 7 8
    // Q1在 （8+1）/4=2.25位， 介于第二和第三位之间，但是更靠近第二位。所以第二位数权重占75%，第三位数权重占25%。Q1=（2*0.75+3*0.25）/(0.75+0.25)=2.25
    // Q2在 （8+1）/2=4.5位，即第4和第5位的平均数，Q2=3.9
    // 同理Q3在（8+1）/4*3=6.75位，在第六位和第七位之间，更靠近第7位。所以第7位权重75%，第6位权重25%。
    // Q3=(7*0.75+6*0.25)/(0.75+0.25)=6.75
    return toFF2(
      toFF2(hadSort[parseInt(position)]) *
        (1 - (position - parseInt(position))) +
        toFF2(hadSort[parseInt(position)] + 1) * (position - parseInt(position))
    );
  }
};

// 根据某一天的数据和低血糖阈值获取当天的低血糖率
export const getLowRate = function getLowRate(data, low) {
  let n = 0;
  data.data.forEach((element) => {
    if (element < low) {
      n++;
    }
  });
  // n为低血糖数据的数量
  const rate = n === 0 ? 0 : n / data.data.length;
  return rate;
};
// 根据某一天的数据和高血糖阈值获取当天的高血糖率
export const getHighRate = function getHighRate(data, high) {
  let n = 0;
  data.data.forEach((element) => {
    if (element > high) {
      n++;
    }
  });
  const rate = n === 0 ? 0 : n / data.data.length;
  return rate;
};

function toFF2(str) {
  return parseFloat(parseFloat(str).toFixed(2));
}

// 处理数据 每h一组数据
export const get1HourData = function get1HourData(res) {
  const sugerarr = [];
  res.data.data.forEach((ele) => {
    const date = new Date(ele.t);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    hour = hour < 10 ? "0" + hour : hour;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    const enddate =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;
    sugerarr.push({
      date: enddate,
      data: ele.v,
    }); //将血糖数据按照时间和数据存入数组sugerarr中
  });
  const resList = [];
  sugerarr.forEach((element) => {
    if (element.date.split(":")[1] === "00") {
      resList.push({
        data: element.data,
        date: element.date,
      }); //将整时的血糖数据和时间存入数组resList中
    }
  });
  const suger = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];
  sugerarr.forEach((element) => {
    for (let i = 0; i < 24; i++) {
      if (element.date.substr(11, 2) === "0" + i) {
        suger[i].push(element.data);
      }
    }
    for (let i = 10; i < 24; i++) {
      if (element.date.substr(11, 2) === String(i)) {
        suger[i].push(element.data);
      } // 将每小时的血糖数据分组存入数组suger中
    }
  });
  return suger;
  // 返回一天的每h所有血糖数据
};

//获取每整时时间
export const gethourdate = function gethourdate() {
  const datelist = [];
  for (let i = 0; i < 10; i++) {
    datelist.push("0" + i + ":" + "00");
  }
  for (let i = 10; i < 24; i++) {
    datelist.push(i + ":" + "00");
  }
  return datelist; //将整点时间转化成01：00、02：00……格式存入数组datelist中
};

// 每小时血糖平均血值
export const hourAverage = function hourAverage(l) {
  const avg = [];
  l.forEach((ele) => {
    let n = 0;
    ele.forEach((e) => {
      n += parseFloat(e);
    });
    avg.push((n /  ele.length).toFixed(1));
  });
  return avg;
};

// 每小时的血糖最小值
export const gethourMin = function gethourMin(l) {
  const minlist = [];
  let min = 0;
  l.forEach((element) => {
    // element.forEach(ele => {
    //   min = ele < min ? ele : min
    //   min = min === 0 ? ele : min
    // })
    min = Math.min(...element);
    minlist.push(min);
  });
  return minlist;
};
// 每小时血糖的百分位数
export const hourpercentile = function hourpercentile(l, percentage) {
  const arrList = [];
  l.forEach((element) => {
    const position = (element.length + 1) * (percentage / 100);
    const hadSort = element.sort();
    if (position < 1) {
      arrList;
    } else {
      let min = hadSort[Math.floor(position) - 1];
      let max = hadSort[Math.ceil(position) - 1];
      let left = (position - Math.floor(position)) * (max - min);
      let result = min + left;
      arrList.push(result.toFixed(2));
      // arrList.push(toFF2(toFF2(hadSort[parseInt(position)]) * (1 - (position - parseInt(position))) + toFF2(hadSort[parseInt(position)] + 1) * (position - parseInt(position))))
    }
  });
  return arrList;
};
// 获取每小时血糖的最大值
export const hourgetMax = function hourgetMax(l) {
  let max = 0;
  const maxlist = [];
  l.forEach((element) => {
    // element.forEach(ele => {
    //   max = ele > max ? ele : max
    // })
    max = Math.max(...element);
    maxlist.push(max);
  });
  return maxlist;
};
// 获取每小时血糖的标准偏差
export const gethourstandard = function gethourstandard(l) {
  const standardlist = [];
  let mis = 0;
  l.forEach((element) => {
    let n = 0;
    element.forEach((e) => {
      n += parseFloat(e);
    });
    const Average = (n / element.length).toFixed(1);
    l.forEach((element) => {
      element.forEach((e) => {
        mis = Math.max(e, Average) - Math.min(e, Average);
      });
    });
    let multiply = mis * mis;
    const sumsqrt = multiply++;
    const num = sumsqrt / element.length;
    const number = Math.sqrt(num);
    standardlist.push(number.toFixed(2));
  });
  return standardlist;
};
// 根据每小时的数据和低血糖阈值获取当天的低血糖率
export const gethourLowRate = function gethourLowRate(data, low) {
  let n = 0;
  data.forEach((element) => {
    if (element < low) {
      n++;
    }
  });
  const rate = n === 0 ? 0 : n / data.length;
  return rate;
};
// 根据每小时的数据和高血糖阈值获取当天的高血糖率
export const gethourHighRate = function gethourHighRate(data, high) {
  let n = 0;
  data.forEach((element) => {
    if (element > high) {
      n++;
    }
  });
  const rate = n === 0 ? 0 : n / data.length;
  return rate;
};

// 返回获得七天的血糖数据
export const getSevenData = function getSevenData(l) {
  const sugerarr = [];
  l.data.data.forEach((ele) => {
    const date = new Date(ele.t);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    hour = hour < 10 ? "0" + hour : hour;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    const enddate =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;
    sugerarr.push({
      date: enddate,
      data: ele.v,
    });
    // 将血糖数据按照date，data存入数组sugerarr中
  });
  const daysData = [];
  const dateList = []; // 2020-02-02时间格式的单条血糖数据集合
  sugerarr.forEach((element) => {
    dateList.push(element.date.split(" ")[0]); // 取所有时间年月日格式到dateList
  });
  const dateSet = new Set(dateList); // 所有的日期集合 ['2020-02-02','2020-02-03']
  dateSet.forEach((element) => {
    const list = [];
    sugerarr.forEach((ele) => {
      if (element === ele.date.split(" ")[0]) {
        list.push(ele.data); //同一天的数据存入一个数组中
      }
    });
    const l = {
      date: element,
      data: list,
    };
    daysData.push(l);
  });
  return daysData;
};
// 平均每日总变化|平均每小时变化
export const dayChange = function dayChange(l) {
  let n = 0;
  let m = 0;
  l.forEach((ele) => {
    n = Math.abs(ele - n); //取绝对值
    m += n; //m为绝对值之和（即总变化值）
  });
  return m;
};

// 波动时间
export const ChangeDate = function ChangeDate(l, a) {
  //a为表中要求数值
  let n = 0;
  let m = 0;
  l.forEach((ele) => {
    n = Math.abs(ele - n);
    if (n > a) {
      m++;
    }
  });
  return m;
};
// 获得PGS血糖状态（血糖峰值）
export const getPGS = function getPGS(l) {
  let n = 0;
  l.forEach((ele) => {
    n = ele > n ? ele : n;
  }); //范围内血糖最大值
  return n;
};

// 计算两个日期之间的天数
export const calculate = function calculate(day1, day2) {
  // console.log("这是你要比较的日期1：",day1,"这是你要比较的日期2：",day2)
  const msPerDay = 1000 * 60 * 60 * 24; //一天的毫秒数

  //将day1,day2的yyyy-mm-dd时间格式转换成：中国标准时间格式
  const newDay1 = new Date(day1);
  const newDay2 = new Date(day2);
  // console.log("来看看他们被转换成什么样啦, newDay1是：",newDay1,"newDay2是：",newDay2)

  //再给它俩换成UTC格式
  const utc1 = Date.UTC(
    newDay1.getFullYear(),
    newDay1.getMonth(),
    newDay1.getDate()
  );
  const utc2 = Date.UTC(
    newDay2.getFullYear(),
    newDay2.getMonth(),
    newDay2.getDate()
  );

  //最后再用Math.floor()来计算它们之间相差的天数~
  const result = Math.floor((utc2 - utc1) / msPerDay);

  //样我们看看结果是啥
  // console.log(result)
  return result + 1;
};
