export const options = [
  {
    text: '安徽',
    label: '安徽',
    children: [{
      text: '安庆',
      label: '安庆'
    }, {
      text: '蚌埠',
      label: '蚌埠'
    }, {
      text: '亳州',
      label: '亳州'
    }, {
      text: '池州',
      label: '池州'
    }, {
      text: '滁州',
      label: '滁州'
    }, {
      text: '阜阳',
      label: '阜阳'
    }, {
      text: '合肥',
      label: '合肥'
    }, {
      text: '淮北',
      label: '淮北'
    }, {
      text: '淮南',
      label: '淮南'
    }, {
      text: '黄山',
      label: '黄山'
    }, {
      text: '六安',
      label: '六安'
    }, {
      text: '马鞍山',
      label: '马鞍山'
    }, {
      text: '铜陵',
      label: '铜陵'
    }, {
      text: '芜湖',
      label: '芜湖'
    }, {
      text: '宿州',
      label: '宿州'
    }, {
      text: '宣城',
      label: '宣城'
    }]
  },
  {
    text: '北京',
    label: '北京',
    children: [{
      text: '北京',
      label: '北京'
    }]
  }, {
    text: '重庆',
    label: '重庆',
    children: [{
      text: '重庆',
      label: '重庆'
    }]
  },
  {
    text: '福建',
    label: '福建',
    children: [{
      text: '福州',
      label: '福州'
    }, {
      text: '龙岩',
      label: '龙岩'
    }, {
      text: '南平',
      label: '南平'
    }, {
      text: '宁德',
      label: '宁德'
    }, {
      text: '平潭',
      label: '平潭'
    }, {
      text: '莆田',
      label: '莆田'
    }, {
      text: '泉州',
      label: '泉州'
    }, {
      text: '三明',
      label: '三明'
    }, {
      text: '厦门',
      label: '厦门'
    }, {
      text: '漳州',
      label: '漳州'
    }]
  },
  {
    text: '甘肃',
    label: '甘肃',
    children: [{
      text: '白银',
      label: '白银'
    }, {
      text: '定西',
      label: '定西'
    }, {
      text: '甘南藏族自治州',
      label: '甘南藏族自治州'
    }, {
      text: '嘉峪关',
      label: '嘉峪关'
    }, {
      text: '金昌',
      label: '金昌'
    }, {
      text: '酒泉',
      label: '酒泉'
    }, {
      text: '兰州',
      label: '兰州'
    }, {
      text: '临夏回族自治州',
      label: '临夏回族自治州'
    }, {
      text: '陇南',
      label: '陇南'
    }, {
      text: '平凉',
      label: '平凉'
    }, {
      text: '庆阳',
      label: '庆阳'
    }, {
      text: '天水',
      label: '天水'
    }, {
      text: '武威',
      label: '武威'
    }, {
      text: '张掖',
      label: '张掖'
    }]
  },
  {
    text: '广东',
    label: '广东',
    children: [{
      text: '潮州',
      label: '潮州'
    }, {
      text: '东莞',
      label: '东莞'
    }, {
      text: '佛山',
      label: '佛山'
    }, {
      text: '广州',
      label: '广州'
    }, {
      text: '河源',
      label: '河源'
    }, {
      text: '惠州',
      label: '惠州'
    }, {
      text: '江门',
      label: '江门'
    }, {
      text: '揭阳',
      label: '揭阳'
    }, {
      text: '茂名',
      label: '茂名'
    }, {
      text: '梅州',
      label: '梅州'
    }, {
      text: '清远',
      label: '清远'
    }, {
      text: '汕头',
      label: '汕头'
    }, {
      text: '汕尾',
      label: '汕尾'
    }, {
      text: '韶关',
      label: '韶关'
    }, {
      text: '深圳',
      label: '深圳'
    }, {
      text: '阳江',
      label: '阳江'
    }, {
      text: '云浮',
      label: '云浮'
    }, {
      text: '湛江',
      label: '湛江'
    }, {
      text: '肇庆',
      label: '肇庆'
    }, {
      text: '中山',
      label: '中山'
    }, {
      text: '珠海',
      label: '珠海'
    }]
  },
  {
    text: '广西',
    label: '广西',
    children: [{
      text: '百色',
      label: '百色'
    }, {
      text: '北海',
      label: '北海'
    }, {
      text: '崇左',
      label: '崇左'
    }, {
      text: '防城港',
      label: '防城港'
    }, {
      text: '贵港',
      label: '贵港'
    }, {
      text: '桂林',
      label: '桂林'
    }, {
      text: '河池',
      label: '河池'
    }, {
      text: '贺州',
      label: '贺州'
    }, {
      text: '来宾',
      label: '来宾'
    }, {
      text: '柳州',
      label: '柳州'
    }, {
      text: '南宁',
      label: '南宁'
    }, {
      text: '钦州',
      label: '钦州'
    }, {
      text: '梧州',
      label: '梧州'
    }, {
      text: '玉林',
      label: '玉林'
    }]
  },
  {
    text: '贵州',
    label: '贵州',
    children: [{
      text: '安顺',
      label: '安顺'
    }, {
      text: '毕节',
      label: '毕节'
    }, {
      text: '贵阳',
      label: '贵阳'
    }, {
      text: '六盘水',
      label: '六盘水'
    }, {
      text: '黔东南苗族侗族自治州',
      label: '黔东南苗族侗族自治州'
    }, {
      text: '黔南布依族苗族自治州',
      label: '黔南布依族苗族自治州'
    }, {
      text: '黔西南布依族苗族自治州',
      label: '黔西南布依族苗族自治州'
    }, {
      text: '铜仁',
      label: '铜仁'
    }, {
      text: '遵义',
      label: '遵义'
    }]
  },
  {
    text: '海南',
    label: '海南',
    children: [{
      text: '海口',
      label: '海口'
    }, {
      text: '三沙',
      label: '三沙'
    }, {
      text: '三亚',
      label: '三亚'
    }]
  },
  {
    text: '河北',
    label: '河北',
    children: [{
      text: '保定',
      label: '保定'
    }, {
      text: '沧州',
      label: '沧州'
    }, {
      text: '承德',
      label: '承德'
    }, {
      text: '邯郸',
      label: '邯郸'
    }, {
      text: '衡水',
      label: '衡水'
    }, {
      text: '廊坊',
      label: '廊坊'
    }, {
      text: '秦皇岛',
      label: '秦皇岛'
    }, {
      text: '石家庄',
      label: '石家庄'
    }, {
      text: '唐山',
      label: '唐山'
    }, {
      text: '邢台',
      label: '邢台'
    }, {
      text: '张家口',
      label: '张家口'
    }]
  },
  {
    text: '河南',
    label: '河南',
    children: [{
      text: '安阳',
      label: '安阳'
    }, {
      text: '鹤壁',
      label: '鹤壁'
    }, {
      text: '济源',
      label: '济源'
    }, {
      text: '焦作',
      label: '焦作'
    }, {
      text: '开封',
      label: '开封'
    }, {
      text: '洛阳',
      label: '洛阳'
    }, {
      text: '漯河',
      label: '漯河'
    }, {
      text: '南阳',
      label: '南阳'
    }, {
      text: '平顶山',
      label: '平顶山'
    }, {
      text: '濮阳',
      label: '濮阳'
    }, {
      text: '三门峡',
      label: '三门峡'
    }, {
      text: '商丘',
      label: '商丘'
    }, {
      text: '新乡',
      label: '新乡'
    }, {
      text: '信阳',
      label: '信阳'
    }, {
      text: '许昌',
      label: '许昌'
    }, {
      text: '郑州',
      label: '郑州'
    }, {
      text: '周口',
      label: '周口'
    }, {
      text: '驻马店',
      label: '驻马店'
    }]
  },
  {
    text: '黑龙江',
    label: '黑龙江',
    children: [{
      text: '大庆',
      label: '大庆'
    }, {
      text: '大兴安岭',
      label: '大兴安岭'
    }, {
      text: '哈尔滨',
      label: '哈尔滨'
    }, {
      text: '鹤岗',
      label: '鹤岗'
    }, {
      text: '黑河',
      label: '黑河'
    }, {
      text: '鸡西',
      label: '鸡西'
    }, {
      text: '佳木斯',
      label: '佳木斯'
    }, {
      text: '牡丹江',
      label: '牡丹江'
    }, {
      text: '七台河',
      label: '七台河'
    }, {
      text: '齐齐哈尔',
      label: '齐齐哈尔'
    }, {
      text: '双鸭山',
      label: '双鸭山'
    }, {
      text: '绥化',
      label: '绥化'
    }, {
      text: '伊春',
      label: '伊春'
    }]
  },
  {
    text: '湖北',
    label: '湖北',
    children: [{
      text: '恩施土家族',
      label: '恩施土家族'
    }, {
      text: '黄冈',
      label: '黄冈'
    }, {
      text: '黄石',
      label: '黄石'
    }, {
      text: '荆门',
      label: '荆门'
    }, {
      text: '荆州',
      label: '荆州'
    }, {
      text: '潜江',
      label: '潜江'
    }, {
      text: '神农架林区',
      label: '神农架林区'
    }, {
      text: '十堰',
      label: '十堰'
    }, {
      text: '随州',
      label: '随州'
    }, {
      text: '天门',
      label: '天门'
    }, {
      text: '武汉',
      label: '武汉'
    }, {
      text: '仙桃',
      label: '仙桃'
    }, {
      text: '咸宁',
      label: '咸宁'
    }, {
      text: '襄阳',
      label: '襄阳'
    }, {
      text: '孝感',
      label: '孝感'
    }, {
      text: '宜昌',
      label: '宜昌'
    }]
  },
  {
    text: '湖南',
    label: '湖南',
    children: [{
      text: '常德',
      label: '常德'
    }, {
      text: '郴州',
      label: '郴州'
    }, {
      text: '衡阳',
      label: '衡阳'
    }, {
      text: '怀化',
      label: '怀化'
    }, {
      text: '娄底',
      label: '娄底'
    }, {
      text: '邵阳',
      label: '邵阳'
    }, {
      text: '湘潭',
      label: '湘潭'
    }, {
      text: '湘西土家族',
      label: '湘西土家族'
    }, {
      text: '益阳',
      label: '益阳'
    }, {
      text: '永州',
      label: '永州'
    }, {
      text: '岳阳',
      label: '岳阳'
    }, {
      text: '张家界',
      label: '张家界'
    }, {
      text: '长沙',
      label: '长沙'
    }, {
      text: '株洲',
      label: '株洲'
    }]
  },
  {
    text: '吉林',
    label: '吉林',
    children: [{
      text: '白城',
      label: '白城'
    }, {
      text: '白山',
      label: '白山'
    }, {
      text: '吉林',
      label: '吉林'
    }, {
      text: '辽源',
      label: '辽源'
    }, {
      text: '海河口',
      label: '海河口'
    }, {
      text: '四平',
      label: '四平'
    }, {
      text: '松原',
      label: '松原'
    }, {
      text: '通化',
      label: '通化'
    }, {
      text: '延边朝鲜族',
      label: '延边朝鲜族'
    }, {
      text: '长白山管委会',
      label: '长白山管委会'
    }, {
      text: '长春',
      label: '长春'
    }]
  },
  {
    text: '江苏',
    label: '江苏',
    children: [{
      text: '常州',
      label: '常州'
    }, {
      text: '淮安',
      label: '淮安'
    }, {
      text: '连云港',
      label: '连云港'
    }, {
      text: '南京',
      label: '南京'
    }, {
      text: '南通',
      label: '南通'
    }, {
      text: '苏州',
      label: '苏州'
    }, {
      text: '泰州',
      label: '泰州'
    }, {
      text: '无锡',
      label: '无锡'
    }, {
      text: '宿迁',
      label: '宿迁'
    }, {
      text: '徐州',
      label: '徐州'
    }, {
      text: '盐城',
      label: '盐城'
    }, {
      text: '扬州',
      label: '扬州'
    }, {
      text: '镇江',
      label: '镇江'
    }]
  }, {
    text: '江西',
    label: '江西',
    children: [{
      text: '抚州',
      label: '抚州'
    }, {
      text: '赣州',
      label: '赣州'
    }, {
      text: '吉安',
      label: '吉安'
    }, {
      text: '景德镇',
      label: '景德镇'
    }, {
      text: '九江',
      label: '九江'
    }, {
      text: '南昌',
      label: '南昌'
    }, {
      text: '萍乡',
      label: '萍乡'
    }, {
      text: '上饶',
      label: '上饶'
    }, {
      text: '新余',
      label: '新余'
    }, {
      text: '宜春',
      label: '宜春'
    }, {
      text: '鹰潭',
      label: '鹰潭'
    }]
  }, {
    text: '辽宁',
    label: '辽宁',
    children: [{
      text: '鞍山',
      label: '鞍山'
    }, {
      text: '本溪',
      label: '本溪'
    }, {
      text: '朝阳',
      label: '朝阳'
    }, {
      text: '大连',
      label: '大连'
    }, {
      text: '丹东',
      label: '丹东'
    }, {
      text: '抚顺',
      label: '抚顺'
    }, {
      text: '阜新',
      label: '阜新'
    }, {
      text: '葫芦岛',
      label: '葫芦岛'
    }, {
      text: '锦州',
      label: '锦州'
    }, {
      text: '辽阳',
      label: '辽阳'
    }, {
      text: '盘锦',
      label: '盘锦'
    }, {
      text: '沈阳',
      label: '沈阳'
    }, {
      text: '铁岭',
      label: '铁岭'
    }, {
      text: '营口',
      label: '营口'
    }]
  }, {
    text: '内蒙古',
    label: '内蒙古',
    children: [{
      text: '阿拉善盟',
      label: '阿拉善盟'
    }, {
      text: '巴彦淖尔',
      label: '巴彦淖尔'
    }, {
      text: '包头',
      label: '包头'
    }, {
      text: '赤峰',
      label: '赤峰'
    }, {
      text: '鄂尔多斯',
      label: '鄂尔多斯'
    }, {
      text: '呼和浩特',
      label: '呼和浩特'
    }, {
      text: '呼伦贝尔',
      label: '呼伦贝尔'
    }, {
      text: '通辽',
      label: '通辽'
    }, {
      text: '乌海',
      label: '乌海'
    }, {
      text: '乌兰察布',
      label: '乌兰察布'
    }, {
      text: '锡林郭勒盟',
      label: '锡林郭勒盟'
    }, {
      text: '兴安盟',
      label: '兴安盟'
    }]
  }, {
    text: '宁夏',
    label: '宁夏',
    children: [{
      text: '固原',
      label: '固原'
    }, {
      text: '凉山',
      label: '凉山'
    }, {
      text: '石嘴山',
      label: '石嘴山'
    }, {
      text: '吴忠',
      label: '吴忠'
    }, {
      text: '银川',
      label: '银川'
    }, {
      text: '中卫',
      label: '中卫'
    }]
  }, {
    text: '青海',
    label: '青海',
    children: [{
      text: '果洛藏族自治州',
      label: '果洛藏族自治州'
    }, {
      text: '海北藏族自治州',
      label: '海北藏族自治州'
    }, {
      text: '海东',
      label: '海东'
    }, {
      text: '海南藏族自治州',
      label: '海南藏族自治州'
    }, {
      text: '黄南藏族自治州',
      label: '黄南藏族自治州'
    }, {
      text: '西宁',
      label: '西宁'
    }, {
      text: '玉树藏族自治州',
      label: '玉树藏族自治州'
    }]
  }, {
    text: '山东',
    label: '山东',
    children: [{
      text: '滨州',
      label: '滨州'
    }, {
      text: '德州',
      label: '德州'
    }, {
      text: '东营',
      label: '东营'
    }, {
      text: '菏泽',
      label: '菏泽'
    }, {
      text: '济南',
      label: '济南'
    }, {
      text: '济宁',
      label: '济宁'
    }, {
      text: '莱芜',
      label: '莱芜'
    }, {
      text: '聊城',
      label: '聊城'
    }, {
      text: '临沂',
      label: '临沂'
    }, {
      text: '青岛',
      label: '青岛'
    }, {
      text: '日照',
      label: '日照'
    }, {
      text: '泰安',
      label: '泰安'
    }, {
      text: '威海',
      label: '威海'
    }, {
      text: '潍坊',
      label: '潍坊'
    }, {
      text: '烟台',
      label: '烟台'
    }, {
      text: '枣庄',
      label: '枣庄'
    }, {
      text: '淄博',
      label: '淄博'
    }]
  }, {
    text: '山西',
    label: '山西',
    children: [{
      text: '大同',
      label: '大同'
    }, {
      text: '晋城',
      label: '晋城'
    }, {
      text: '晋中',
      label: '晋中'
    }, {
      text: '临汾',
      label: '临汾'
    }, {
      text: '吕梁',
      label: '吕梁'
    }, {
      text: '朔州',
      label: '朔州'
    }, {
      text: '太原',
      label: '太原'
    }, {
      text: '忻州',
      label: '忻州'
    }, {
      text: '阳泉',
      label: '阳泉'
    }, {
      text: '运城',
      label: '运城'
    }, {
      text: '长治',
      label: '长治'
    }]
  }, {
    text: '陕西',
    label: '陕西',
    children: [{
      text: '安康',
      label: '安康'
    }, {
      text: '宝鸡',
      label: '宝鸡'
    }, {
      text: '汉中',
      label: '汉中'
    }, {
      text: '商洛',
      label: '商洛'
    }, {
      text: '铜川',
      label: '铜川'
    }, {
      text: '渭南',
      label: '渭南'
    }, {
      text: '西安',
      label: '西安'
    }, {
      text: '咸阳',
      label: '咸阳'
    }, {
      text: '延安',
      label: '延安'
    }]
  }, {
    text: '上海',
    label: '上海',
    children: [{
      text: '上海',
      label: '上海'
    }]
  }, {
    text: '四川',
    label: '四川',
    children: [{
      text: '阿坝藏族羌族自治州',
      label: '阿坝藏族羌族自治州'
    }, {
      text: '巴中',
      label: '巴中'
    }, {
      text: '成都',
      label: '成都'
    }, {
      text: '达州',
      label: '达州'
    }, {
      text: '德阳',
      label: '德阳'
    }, {
      text: '甘孜藏族自治州',
      label: '甘孜藏族自治州'
    }, {
      text: '广安',
      label: '广安'
    }, {
      text: '广元',
      label: '广元'
    }, {
      text: '乐山',
      label: '乐山'
    }, {
      text: '凉山彝族自治州',
      label: '凉山彝族自治州'
    }, {
      text: '泸州',
      label: '泸州'
    }, {
      text: '眉山',
      label: '眉山'
    }, {
      text: '绵阳',
      label: '绵阳'
    }, {
      text: '南充',
      label: '南充'
    }, {
      text: '内江',
      label: '内江'
    }, {
      text: '攀枝花',
      label: '攀枝花'
    }, {
      text: '遂宁',
      label: '遂宁'
    }, {
      text: '雅安',
      label: '雅安'
    }, {
      text: '宜宾',
      label: '宜宾'
    }, {
      text: '资阳',
      label: '资阳'
    }, {
      text: '自贡',
      label: '自贡'
    }]
  }, {
    text: '天津',
    label: '天津',
    children: [{
      text: '天津',
      label: '天津'
    }]
  }, {
    text: '西藏',
    label: '西藏',
    children: [{
      text: '阿里地区',
      label: '阿里地区'
    }, {
      text: '昌都',
      label: '昌都'
    }, {
      text: '拉萨',
      label: '拉萨'
    }, {
      text: '林芝地区',
      label: '林芝地区'
    }, {
      text: '那曲地区',
      label: '那曲地区'
    }, {
      text: '日喀则',
      label: '日喀则'
    }, {
      text: '山南地区',
      label: '山南地区'
    }]
  }, {
    text: '新疆',
    label: '新疆',
    children: [{
      text: '阿克苏地区',
      label: '阿克苏地区'
    }, {
      text: '阿拉尔',
      label: '阿拉尔'
    }, {
      text: '阿勒泰地区',
      label: '阿勒泰地区'
    }, {
      text: '巴音郭楞蒙古自治州',
      label: '巴音郭楞蒙古自治州'
    }, {
      text: '北屯',
      label: '北屯'
    }, {
      text: '博尔塔拉蒙古自治州',
      label: '博尔塔拉蒙古自治州'
    }, {
      text: '昌吉回族自治区',
      label: '昌吉回族自治区'
    }, {
      text: '哈密地区',
      label: '哈密地区'
    }, {
      text: '和田地区',
      label: '和田地区'
    }, {
      text: '喀什地区',
      label: '喀什地区'
    }, {
      text: '克拉玛依',
      label: '克拉玛依'
    }, {
      text: '克孜勒苏柯尔克孜自治州',
      label: '克孜勒苏柯尔克孜自治州'
    }, {
      text: '石河子',
      label: '石河子'
    }, {
      text: '双河',
      label: '双河'
    }, {
      text: '塔城地区',
      label: '塔城地区'
    }, {
      text: '铁门关',
      label: '铁门关'
    }, {
      text: '图木舒克',
      label: '图木舒克'
    }, {
      text: '吐鲁番地区',
      label: '吐鲁番地区'
    }, {
      text: '乌鲁木齐',
      label: '乌鲁木齐'
    }, {
      text: '五家渠',
      label: '五家渠'
    }, {
      text: '伊犁哈萨克自治州',
      label: '伊犁哈萨克自治州'
    }]
  }, {
    text: '云南',
    label: '云南',
    children: [{
      text: '保山',
      label: '保山'
    }, {
      text: '楚雄彝族自治州',
      label: '楚雄彝族自治州'
    }, {
      text: '大理白族自治州',
      label: '大理白族自治州'
    }, {
      text: '德宏傣族景颇族自治州',
      label: '德宏傣族景颇族自治州'
    }, {
      text: '迪庆藏族自治州',
      label: '迪庆藏族自治州'
    }, {
      text: '红河哈尼族彝族自治州',
      label: '红河哈尼族彝族自治州'
    }, {
      text: '昆明',
      label: '昆明'
    }, {
      text: '丽江',
      label: '丽江'
    }, {
      text: '临沧',
      label: '临沧'
    }, {
      text: '怒江傈僳族自治州',
      label: '怒江傈僳族自治州'
    }, {
      text: '普洱',
      label: '普洱'
    }, {
      text: '曲靖',
      label: '曲靖'
    }, {
      text: '文山壮族苗族自治州',
      label: '文山壮族苗族自治州'
    }, {
      text: '西双版纳傣族自治州',
      label: '西双版纳傣族自治州'
    }, {
      text: '玉溪',
      label: '玉溪'
    }, {
      text: '昭通',
      label: '昭通'
    }]
  }, {
    text: '浙江',
    label: '浙江',
    children: [{
      text: '杭州',
      label: '杭州'
    }, {
      text: '湖州',
      label: '湖州'
    }, {
      text: '嘉兴',
      label: '嘉兴'
    }, {
      text: '金华',
      label: '金华'
    }, {
      text: '丽水',
      label: '丽水'
    }, {
      text: '宁波',
      label: '宁波'
    }, {
      text: '衢州',
      label: '衢州'
    }, {
      text: '绍兴',
      label: '绍兴'
    }, {
      text: '台州',
      label: '台州'
    }, {
      text: '温州',
      label: '温州'
    }, {
      text: '舟山',
      label: '舟山'
    }]
  }, {
    text: '港澳台',
    label: '港澳台',
    children: [{
      text: '港澳台',
      label: '港澳台'
    }]

  }
]
export default options
