<template>
  <div>
    <van-nav-bar
      title="未关注列表"
      left-text="返回"
      @click-left="goBack"
      left-arrow
    >
    </van-nav-bar>

    <div class="sweep" v-for="item in personArr" :key="item.id">
      <img src="../../static/pic/avatar.png" alt="" />
      <div class="title">{{ item.show_name?item.show_name:item.name }}</div>
      <div class="info">{{ item.iphone }}</div>

      <div class="saveBtn">
        <!-- <van-button type="danger" size="mini" @click="refuse(item.id)">拒绝</van-button> -->
        <van-button type="primary" size="mini" @click="agree(item.id)"
          >关注</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { selectbymanageid, agreeById } from "../../api/relationship";
import { Notify } from "vant";
export default {
  data() {
    return {
      personArr: [],
    };
  },

  mounted() {},
  created() {
    this.getRecordList();
  },
  methods: {
    getRecordList() {
      const appuser = localStorage.getItem("appuser");
      const params = {
        mangerId: JSON.parse(appuser).id,
        status: 0,
      };
      selectbymanageid(params).then((res) => {
        this.personArr = res.data.data;
      });
    },
    // 同意
    agree(id) {
      let params = {
        id: id,
        status: 1,
      };
      agreeById(params).then((res) => {
        if (res.data.code == 200) {
          this.getRecordList();
          Notify({ type: "success", message: "关注成功" });
        } else {
          Notify({ type: "danger", message: res.data.msg });
        }
      });
    },
    // 拒绝
    refuse(id) {
      let params = {
        id: id,
        status: 2,
      };
      agreeById(params).then((res) => {
        if (res.data.code == 200) {
          this.getRecordList();
          Notify({ type: "success", message: "拒绝成功" });
        } else {
          Notify({ type: "danger", message: res.data.msg });
        }
      });
    },
    goBack() {
      this.$router.push("/RecordDetail");
    },
  },
};
</script>
<style lang="less" scoped>
.saveBtn {
  position: absolute;
  right: 10px;
  top: 30%;
}
</style>
