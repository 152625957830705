<template>
    <div></div>
</template>
<script>
export default {
  name: 'DoctorHome',
  data () {
    return {
    }
  }
}
</script>
<style scoped>

</style>
