// 组织架构树
import api from './services.js'
import { baseUrl } from './host.js'
const env = baseUrl.env;
export const getAll = (param) => api({
  url: param.url,
  method: param.method,
  params: param.params
})
// eslint-disable-next-line no-unused-vars
const host = baseUrl[env].host
// eslint-disable-next-line no-unused-vars
const port = baseUrl[env].port
// 创建组织机构列表
export const createStruct = (params) => api({
  url: host + port.user + 'auth/struct/create',
  method: 'post',
  data: params
})

// 查询组织机构列表
export const findStruct = (params) => api({
  url: host + port.user + 'auth/struct/getbyid',
  method: 'get',
  params: params
})
