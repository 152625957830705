<template>
  <div id="wangEmitter">
    <div style="width: 100%; height: 4rem; border-bottom: 1px solid #ddd">
      <div
        style="
          width: 100%;
          padding-left: 3%;
          height: 4rem;
          background: #fff;
          position: fixed;
          top: 0;
        "
      >
        <div
          @click="goBackBlood"
          style="
            height: 4rem;
            line-height: 4rem;
            position: absolute;
            left: 0;
            color: #8dd4ff;
            padding-left: 3%;
            font-size: 1.6rem;
          "
        >
          <i class="el-icon-arrow-left"></i>返回
        </div>
        <div style="line-height: 4rem; font-size: 1.6rem; font-weight: bold">
          旺旺发射器
        </div>
      </div>
    </div>
    <div v-loading="loading">
      <div style="width: 100%; height: 16rem; padding-top: 2rem">
        <div
          style="
            overflow: hidden;
            width: 14rem;
            height: 14rem;
            margin: auto;
            border-radius: 7rem;
          "
        >
          <img :src="wwfsq" style="width: 14rem; height: 14rem" />
        </div>
      </div>
      <div
        v-if="unScan"
        style="
          width: 94%;
          margin-left: 3%;
          background: #fff;
          height: 12rem;
          border-radius: 2rem;
          font-size: 1.2rem;
          font-weight: bold;
          text-align: left;
        "
      >
        <div class="rowStyle">
          <div style="width: 50%; float: left">探头名称</div>
          <div style="width: 50%; float: left"></div>
        </div>
        <div class="rowStyle boderTop">
          <div style="width: 50%; float: left">使用天数</div>
          <div style="width: 50%; float: left"></div>
        </div>
        <div class="rowStyle boderTop">
          <div style="width: 50%; float: left">探头状态</div>
          <div style="width: 50%; float: left"></div>
        </div>
      </div>
      <div
        v-else
        style="
          width: 94%;
          margin-left: 3%;
          background: #fff;
          height: 34rem;
          border-radius: 1rem;
          font-size: 1.2rem;
          font-weight: bold;
          text-align: left;
        "
      >
        <div class="rowStyle">
          <div style="width: 50%; float: left">探头名称</div>
          <div class="textR" style="width: 50%; float: left">
            {{ scanerInfo.cgmName }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div style="width: 50%; float: left">连接时间</div>
          <div class="textR" style="width: 50%; float: left">
            {{ scanerInfo.connectionDate }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div style="width: 50%; float: left">探头状态</div>
          <div class="textR" style="width: 50%; float: left">
            {{ scanerInfo.connectionStatus }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div style="width: 50%; float: left">探头序列号</div>
          <div class="textR" style="width: 50%; float: left">
            {{ scanerInfo.driveNO }}
          </div>
        </div>
        <div class="rowStyle">
          <div style="width: 50%; float: left">发射器类型</div>
          <div class="textR" style="width: 50%; float: left">
            {{ scanerInfo.cgmType }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div style="width: 50%; float: left">发射器状态</div>
          <div class="textR" style="width: 50%; float: left">
            {{ scanerInfo.driveStatus }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div style="width: 50%; float: left">电池电量</div>
          <div class="textR" style="width: 50%; float: left">
            {{ scanerInfo.powerPer }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div style="width: 50%; float: left">固件/硬件版本</div>
          <div class="textR" style="width: 50%; float: left">
            {{ scanerInfo.firmware + "-" + scanerInfo.hardware }}
          </div>
        </div>
      </div>

      <el-button
        v-if="unScan"
        @click="BBMiniConnect"
        style="
          margin-top: 1rem;
          background: #7ccdff;
          color: #fff;
          width: 90%;
          border-radius: 1rem;
          height: 4rem;
          font-size: 1.6rem;
        "
        >扫 描</el-button
      >
      <el-button
        v-else
        @click="deleteWW"
        style="
          margin-top: 1rem;
          background: #7ccdff;
          color: #fff;
          width: 90%;
          border-radius: 1rem;
          height: 4rem;
          font-size: 1.6rem;
        "
        >删 除</el-button
      >
      <div style="width: 100%; height: 5rem"></div>
    </div>
  </div>
</template>
<script>
import wwfsq from "../../../static/cgm/wwfsq.png";
import { upstateYP, getUserInfoById } from "../../../api/relationship";
export default {
  name: "wangEmitter",
  data() {
    return {
      wwfsq: wwfsq,
      unScan: true,
      loading: false,
      scanerInfo: {
        cgmName: "cgmName",
        cgmAddr: "cgmAddr",
        useDate: "",
        maxLife: "14天10时",
        connectionStatus: "connectionStatus",
        connectionDate: "connectionDate",
        cgmType: "cgmType",
        driveNO: "driveNO",
        driveStatus: "driveStatus",
        powerPer: "powerPer",
        firmware: "firmware",
        hardware: "hardware",
        status: "status",
      },
    };
  },
  mounted() {
    window.didConnectBluetooth = this.didConnectBluetooth;
    window.setDeviceInformation = this.setDeviceInformation;
    window.setAndroidDeviceInformation = this.setAndroidDeviceInformation;
    window.disConnectBBmini = this.disConnectBBmini;
    window.disconnectDevice = this.disconnectDevice;

    this.getWangCgmInfo();
  },
  methods: {
    // 获取旺旺设备信息
    getWangCgmInfo() {
      const appuser = JSON.parse(localStorage.getItem("appuser"));
      getUserInfoById(appuser.id).then((res) => {
        if (res.data.data.yp && res.data.data.cgm == "yp") {
          console.log("获取设备信息并连接");
          const cgmInfo = JSON.parse(res.data.data.yp);
          this.scanerInfo = {
            cgmName: cgmInfo.cgmName,
            cgmAddr: cgmInfo.cgmAddr,
            // useDate: useDate,
            connectionStatus: cgmInfo.connectionStatus,
            connectionDate: cgmInfo.connectionDate,
            cgmType: cgmInfo.cgmType,
            driveNO: cgmInfo.driveNO,
            driveStatus: cgmInfo.driveStatus,
            powerPer: cgmInfo.powerPer,
            firmware: cgmInfo.firmware,
            hardware: cgmInfo.hardware,
          };
          this.unScan = false;
          this.loading = false;
          this.$message.success("已连接");
        } else {
          return;
        }
      });
    },
    goBackBlood() {
      this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
    },
    BBMiniConnect() {
      const message = {
        message: "旺旺连接",
        body: {
          type: "Bubble / Bubble Mini",
        },
      };
      const androidMessage = JSON.stringify(message)
      const phoneType = localStorage.getItem("phoneType");
      if (phoneType == "Android") {
        window.android.openBluetooth(androidMessage);
      } else {
        window.webkit.messageHandlers.openBluetooth.postMessage(message);
      }

      this.loading = true;

      setTimeout(() => {
        if (this.loading) {
          this.loading = false;
          this.$message("自动连接失败，请重试");
        }
      }, 30000);
    },
    deleteWW() {
      const message = {
        message: "断开旺旺",
        body: {
          type: "Bubble / Bubble Mini",
        },
      };
      const androidMessage = JSON.stringify(message)
      const phoneType = localStorage.getItem("phoneType");
      if (phoneType == "Android") {
        window.android.disconnectDevice(androidMessage);
      } else {
        window.webkit.messageHandlers.disconnectDevice.postMessage(message);
      }
      // const appuser = JSON.parse(localStorage.getItem("appuser"));
      // let params = {
      //   uid: appuser.id,
      //   yp: "",
      // };
      // upstateYP(params).then((res) => {
      //   if (res.data.code == 200 || res.data.code == "200") {
      //     this.$message.success("删除成功");
      //     this.unScan = true;
      //   }
      // });
    },
    disConnectBBmini() {
      this.scanerInfo = {
        cgmName: "",
        cgmAddr: "",
        useDate: "",
        maxLife: "",
        connectionStatus: "",
        connectionDate: "",
        cgmType: "",
        driveNO: "",
        driveStatus: "",
        powerPer: "",
        firmware: "",
        hardware: "",
        status: "",
      };
      this.unScan = true;
      const appuser = JSON.parse(localStorage.getItem("appuser"));
      let params = {
        uid: appuser.id,
        yp: "",
      };
      upstateYP(params).then((res = {}));
      this.$message.success("删除成功");
      this.loading = false;
      this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
      return '确定'
    },
    setDeviceInformation(cgmInfo) {
      if (cgmInfo) {
        console.log("自动连接");
        const cgmName = cgmInfo.cgmName;
        const cgmAddr = cgmInfo.cgmAddr;
        const connectionStatus = cgmInfo.connectionStatus;
        const connectionDate = cgmInfo.connectionDate;
        const cgmType = cgmInfo.cgmType;
        const driveNO = cgmInfo.driveNO;
        const driveStatus = cgmInfo.driveStatus;
        const powerPer = cgmInfo.powerPer;
        const firmware = cgmInfo.firmware;
        const hardware = cgmInfo.hardware;
        const status = driveNO
          ? "已连接"
          : cgmType
          ? "已连接"
          : cgmAddr
          ? "已连接"
          : "未连接";
        this.scanerInfo = {
          cgmName: cgmName,
          cgmAddr: cgmAddr,
          // useDate: useDate,
          connectionStatus: connectionStatus,
          connectionDate: connectionDate,
          cgmType: cgmType,
          driveNO: driveNO,
          driveStatus: driveStatus,
          powerPer: powerPer,
          firmware: firmware,
          hardware: hardware,
          status: status,
        };
        this.unScan = false;
        this.loading = false;
        this.$message.success("自动连接成功");

        const appuser = JSON.parse(localStorage.getItem("appuser"));
        let params = {
          uid: appuser.id,
          yp: JSON.stringify(cgmInfo),
        };
        upstateYP(params).then((res = {}));

        this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
      } else {
        this.BBMiniConnect();
      }
      // this.loading = false;
      // this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
    },
    setAndroidDeviceInformation(info) {
      const cgmInfo = JSON.parse(JSON.stringify(info))
      if (cgmInfo) {
        console.log("自动连接");
        const cgmName = cgmInfo.cgmName;
        const cgmAddr = cgmInfo.cgmAddr;
        const connectionStatus = cgmInfo.connectionStatus;
        const connectionDate = cgmInfo.connectionDate;
        const cgmType = cgmInfo.cgmType;
        const driveNO = cgmInfo.driveNO;
        const driveStatus = cgmInfo.driveStatus;
        const powerPer = cgmInfo.powerPer;
        const firmware = cgmInfo.firmware;
        const hardware = cgmInfo.hardware;
        const status = driveNO
          ? "已连接"
          : cgmType
          ? "已连接"
          : cgmAddr
          ? "已连接"
          : "未连接";
        this.scanerInfo = {
          cgmName: cgmName,
          cgmAddr: cgmAddr,
          // useDate: useDate,
          connectionStatus: connectionStatus,
          connectionDate: connectionDate,
          cgmType: cgmType,
          driveNO: driveNO,
          driveStatus: driveStatus,
          powerPer: powerPer,
          firmware: firmware,
          hardware: hardware,
          status: status,
        };
        this.unScan = false;
        this.loading = false;
        this.$message.success("自动连接成功");

        const appuser = JSON.parse(localStorage.getItem("appuser"));
        let params = {
          uid: appuser.id,
          yp: JSON.stringify(cgmInfo),
        };
        upstateYP(params).then((res = {}));

        this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
      } else {
        this.BBMiniConnect();
      }
      return '确定'
    },

    didConnectBluetooth() {
      this.loading = false;
      this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
    },
  },
};
</script>
<style scoped>
#wangEmitter {
  background: #f8f8f8;
}
.boderTop {
  border-top: 1px solid #eee;
}
.rowStyle {
  width: 90%;
  margin-left: 5%;
  height: 4rem;
  line-height: 4rem;
}
.textR {
  text-align: right;
}
</style>
