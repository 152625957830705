<template>
    <div id="createUser">
        <div>
            <el-row class="inputMain">
                <el-col :span="6" class="trpr12">用户名</el-col>
                <el-col :span="14">
                    <el-input v-model="account.username" class="tlpl12"></el-input>
                </el-col>
            </el-row>
            <el-row class="inputMain">
                <el-col :span="6" class="trpr12">手机号</el-col>
                <el-col :span="14">
                    <el-input v-model="account.telphone" class="tlpl12"></el-input>
                </el-col>
            </el-row>
            <el-row class="inputMain">
                <el-col :span="6" class="trpr12">密码</el-col>
                <el-col :span="14">
                    <el-input v-model="account.password" class="tlpl12"></el-input>
                </el-col>
            </el-row>
        </div>
        <div style="width: 100%;">
            <div style="width: 90%;margin-left: 5%;height: 4rem;
              line-height:4rem; border-radius: 2rem;">
                <el-row>
                    <el-col :span="6" class="trpr12">验证码</el-col>
                    <el-col :span="11">
                        <div style="text-align: left;color:#7a7a7a;float:left">
                            <el-input placeholder="输入验证码" type="text" style="height: 3rem;line-height: 3rem;"
                                v-model="code" />
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <el-button v-if="showGetCode" @click="getCode"
                            style="width: 100%; text-align: center; font-weight: 400;">
                            获取
                        </el-button>
                        <div v-if="!showGetCode" style="width: 100%; text-align: center; font-weight: 400;">
                            {{ timeOut }}秒
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div style="margin-top:2rem">
            <el-row>
                <el-col :span="12">
                    <el-button @click="gotoCreateUser" style="float:right;margin-right: 12px;">创建</el-button>
                </el-col>
                <el-col :span="12">
                    <el-button style="float:left;margin-left: 12px;">清空</el-button>
                </el-col>
            </el-row>
        </div>
        <div style="margin-top:3rem">
            <div style="font-size: 0.8rem;text-align: left;margin-top: 2rem;margin-left:5%">当前注册成功的账号会就在下方 关闭后更新</div>
            <el-table :data="hadCreatedUser" border style="width: 90%;margin-left:5%;">
                <el-table-column prop="username" label="姓名">
                </el-table-column>
                <el-table-column prop="telphone" label="手机号">
                </el-table-column>
                <el-table-column prop="password" label="密码">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant'
// eslint-disable-next-line no-unused-vars
import { getCode, login, createUser } from '../../api/userServices'
export default {
  data () {
    return {
      account: {
        username: '',
        telphone: '',
        password: ''
      },
      hadCreatedUser: [],
      phoneNumber: '',
      timeOut: 120,
      showGetCode: true,
      hadGetCode: false,
      show: false,
      timer: '',
      code: ''
    }
  },
  methods: {
    getCode () {
      this.waitGetCode()
    },
    waitGetCode () {
      if (!this.account.telphone || this.account.telphone.length === 0) {
        Toast('请先输入手机号')
      }
      const regtel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!regtel.test(this.account.telphone) || this.account.telphone.length !== 11) {
        Toast('手机号不正确')
        return
      }
      this.hadGetCode = true
      this.showGetCode = false
      this.timeOut = 120
      getCode(this.account.telphone)
      const _this = this
      this.timer = setInterval(function () {
        if (_this.timeOut > 1) {
          _this.timeOut = _this.timeOut - 1
        } else {
          _this.closeInterval()
        }
      }, 1000)
    },
    closeInterval () {
      this.showGetCode = true
      clearInterval(this.timer)
    },
    gotoCreateUser () {
      const params = {
        phone: this.account.telphone,
        code: this.code,
        password: this.account.password,
        name: this.account.username
      }
      createUser(params).then((res) => {
        if (res.data.code === '2002' || res.data.code === '2001') {
          const code = res.data.code
          if (code === '2002') {
            Toast('账号注册成功！')
            this.hadCreatedUser.push(this.account)
          }
        } else {
          Toast(res.data.msg)
        }
      })
    }

  }
}
</script>
<style scoped>
#createUser {
    width: 100%;
    padding-top: 2rem;
    font-size: 1.2rem;
}

.inputMain {
    height: 4rem;
    line-height: 4rem;
}

.trpr12 {
    text-align: right;
    padding-right: 12px;
}

.tlpl12 {
    text-align: left;
    padding-left: 12px;
}

input {
    background-color: transparent
}
</style>
