<template>
  <div class="container">
    <van-nav-bar title="推广员" left-arrow left-text="返回" @click-left="goBackMineView" />
    <div class="sweep" @click="onAdd">
      <img src="../../../static/pic/addhospital.png" alt="" />
      <div class="title">添加医院</div>
      <van-icon name="arrow" class="arrow" size="24" />
    </div>
    <div class="sweep" @click="noFollow">
      <img src="../../../static/pic/audit.png" alt="" />
      <div class="title">待审核列表</div>
      <van-icon name="arrow" class="arrow" size="24" />
    </div>
    <div class="tip">已通过</div>
    <hospitalCard :detail="currentContact" @followView="followView" />
  </div>
</template>

<script>
import hospitalCard from './hospitalCard.vue'
import { selectbymanageid, getUserByPhone } from '../../../api/relationship'
export default {
  components: {
    hospitalCard
  },
  data() {
    return {
      currentContact: []
    }
  },
  created() {
    getUserByPhone('18130231669').then(data => {
      console.log(data, 'phone')
    })
    this.getRecordList()
  },
  mounted() {},
  methods: {
    goBackMineView() {
      this.$router.go(-1)
    },
    getRecordList() {
      const appuser = localStorage.getItem('appuser')
      const params = {
        mangerId: JSON.parse(appuser).id,
        status: 1
      }
      selectbymanageid(params).then(res => {
        if (res.data.code == 200) {
          this.currentContact = res.data.data
        }
      })
    },
    noFollow() {
      this.$router.push('/hospitalNo')
    },
    onAdd() {
      this.$router.push('/hospitalAdd')
    },
    followView(structName, structId, city, region) {
      this.$router.push({ path: '/hospitalView', query: { structName, structId, city, region } })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-bottom: 10rem;
  overflow: hidden;
}
.navigation {
  background: #d3eeff;
  width: 100vw;
  height: 4rem;
  line-height: 4rem;
  overflow: hidden;
  z-index: 99;
}
.addicon {
  position: absolute;
  right: 1.5rem;
  font-size: 2rem;
}
.tip {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  text-align: left;
  padding-left: 2rem;
  font-size: 16px;
  font-weight: 700;
}
.van-bar {
  background: #d3eeff;
  height: 4rem;
  line-height: 4rem;
}
.sweep {
  color: #000;
  height: 5rem;
  line-height: 4rem;
}
</style>
