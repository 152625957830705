<template>
  <div ref="GJEmpower" id="app">
    <div @click="closeGJEmpower">
      <i class="el-icon-close closeButton"></i>
    </div>
    <div v-if="hadEmpower" class="GJEmpower">
      <span>状态:</span><span>{{ empowerTitle }}</span>
    </div>
    <div v-if="!hadEmpower" class="GJEmpower">
      <span>状态:</span><span>{{ empowerTitle }}</span>
    </div>
    <iframe
      style="border: none; overflow: hidden;margin-top: 30px;"
      :width="searchTableWidth"
      :height="searchTableHeight"
      v-bind:src="reportUrl"
    ></iframe>
  </div>
</template>
<script>
import {
  getuserbytoken,
  updateCGM,
  synchronousGJ,
} from "../../../api/userServices";
import { getBizidTwo, getUserInfoById } from "../../../api/relationship";
import { Notify, Dialog } from "vant";
export default {
  methods: {
    widthHeight() {
      this.searchTableWidth = window.innerWidth;
      this.searchTableHeight = window.innerHeight - 75;
    },
    closeGJEmpower() {
      this.closeTimer()
      this.$router.push("/BloodSugar");
    },
    // 开启定时器
    openTimer() {
      this.empowerTitle = "等待授权结果";
      this.hadUseTime = 0;
      const _this = this;
      this.getEmpowerTimer = window.setInterval(function () {
        _this.hadUseTime += 1;
        console.log(_this.hadUseTime);
        if (_this.hadUseTime > 30) {
          _this.closeTimer();
          _this.$message.warning("授权超时！请稍后重试");
          _this.closeGJEmpower();
        }
        if (_this.hadEmpower) {
          _this.closeTimer();
        } else {
          _this.beSureHadEmpower();
        }
      }, 5000);
    },
    // 关闭定时器
    closeTimer() {
      window.clearInterval(this.getEmpowerTimer);
    },
    // 调接口查询授权信息 如已授权修改hadEmpower为true
    beSureHadEmpower() {
      const _this = this;
      getuserbytoken({}).then((res) => {
        if (res.data.code === "200") {
          // 已授权但不是重新授权
          if (res.data.data.bizid && _this.againEmpower == false) {
            _this.hadEmpower = true;
            _this.empowerTitle = "已授权";
            _this.$message.success("授权成功！");
            localStorage.setItem("appuser", JSON.stringify(res.data.data));
            this.$router.push("/BloodSugar");
            _this.closeTimer();
          }
          // 重新授权
          else if (
            res.data.data.bizid &&
            _this.againEmpower == true &&
            res.data.data.bizid2 == "1"
          ) {
            _this.hadEmpower = true;
            _this.empowerTitle = "重新授权";
            _this.$message.success("重新授权成功！");
            localStorage.setItem("appuser", JSON.stringify(res.data.data));
            _this.$router.push("/BloodSugar");
            _this.closeTimer();
          }
        }
      });
    },
  },
  data() {
    return {
      hadEmpower: false,
      getEmpowerTimer: null,
      hadUseTime: 0,
      reportUrl: "",
      searchTableHeight: 0,
      searchTableWidth: 0,
      empowerTitle: "",
      // 重新授权
      againEmpower: false,
    };
  },
  mounted() {
    const bizid = JSON.parse(localStorage.getItem("appuser")).bizid;
    if (bizid) {
      Dialog.confirm({
        title: "授权确认",
        message: "已授权，请确认是否重新授权！",
        confirmButtonText: "同步数据",
        cancelButtonText: "重新授权",
      })
        .then(() => {
          this.hadEmpower = true;
          this.empowerTitle = "已授权";
          const appuser = JSON.parse(localStorage.getItem("appuser"));
          const params = {
            id: appuser.id,
            type: "gj",
          };
          updateCGM(params).then((res) => {
            getUserInfoById(appuser.id).then((res) => {
              localStorage.setItem("appuser", JSON.stringify(res.data.data));
            });
          });
        })
        .catch(() => {
          // 重新授权判断值
          // 更新cgm
          const appuser = JSON.parse(localStorage.getItem("appuser"));
          const params = {
            id: appuser.id,
            type: "gj",
          };
          updateCGM(params).then((res) => {
            if (res.data.code == "200") {
              getBizidTwo(appuser.id).then(() => {});
            }
            getUserInfoById(appuser.id).then((res) => {
              localStorage.setItem("appuser", JSON.stringify(res.data.data));
            });
          });
          this.againEmpower = true;
          this.openTimer();
        });
    } else {
      const appuser = JSON.parse(localStorage.getItem("appuser"));
      const params = {
        id: appuser.id,
        type: "gj",
      };
      updateCGM(params).then((res) => {
        getUserInfoById(appuser.id).then((res) => {
          localStorage.setItem("appuser", JSON.stringify(res.data.data));
        });
      });
      this.againEmpower = false;
      this.openTimer();
    }
    window.onresize = () => {
      this.widthHeight(); // 自适应高宽度
    };
    this.$nextTick(function () {
      this.widthHeight();
    });
  },
  created() {
    const appuser = JSON.parse(localStorage.getItem("appuser"));
    // 从路由里动态获取 url地址   具体地址看libs下util.js里的 backendMenuToRoute  方法
    this.reportUrl =
      "https://auth-open.sisensing.com/platform_auth/index.html?appKey=1nq775g1d1akndqd&thirdBizId=" +
      "app" +
      appuser.id;
  },
  watch: {
    $route: function () {
      const appuser = JSON.parse(localStorage.getItem("appuser"));
      // 监听路由变化
      this.reportUrl =
        "https://auth-open.sisensing.com/platform_auth/index.html?appKey=1nq775g1d1akndqd&thirdBizId=" +
        "app" +
        appuser.id;

        this.closeTimer()
    },
  },
};
</script>
<style scoped>
.closeButton {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 32px;
  position: absolute;
  top: 0;
  right: 0;
}
.GJEmpower {
  width: 120px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  position: absolute;
  color: #000;
  top: 0;
  left: 4px;
  text-align: left;
}
</style>
