<template>
  <div>
    <van-nav-bar
      title="添加关注"
      left-text="返回"
      @click-left="goBack"
      left-arrow
    >
    </van-nav-bar>
    <!-- 搜索 -->
    <van-search
      v-model="phone"
      placeholder="请输入手机号"
      input-align="center"
      pattern="d*"
      @input="handleInput"
      @search="searchBtn"
      @clear="clearBtn"
    >
      <template #left-icon>
        <van-icon @click="searchBtn" name="search" />
      </template>
    </van-search>
    <!-- 扫一扫 -->
    <div class="sweep">
      <img src="../../static/pic/identify.png" alt="" />
      <div class="title" @click="openCamera">扫一扫</div>
      <div class="info">扫描二维码名片</div>

      <van-icon name="arrow" class="arrow" size="24" />
    </div>
    <!-- 个人信息 -->
    <div class="common-box" v-show="Object.keys(personInfo).length !== 0">
      <van-cell title="姓名">{{ personInfo.name }}</van-cell>
      <!-- <van-cell title="年龄">{{ personInfo.age }}</van-cell> -->
      <van-cell title="手机号">{{ personInfo.iphone }}</van-cell>
      <van-cell title="创建时间">{{ personInfo.createdate }}</van-cell>
    </div>

    <van-button
      v-show="Object.keys(personInfo).length !== 0"
      type="primary"
      style="width: 96%"
      @click="addFollow"
      >添加</van-button
    >
  </div>
</template>
<script>
import {
  getUserByPhone,
  insertRelation,
  getUserInfoById,
  agreeById,
} from "../../api/relationship";
import { Notify, Dialog } from "vant";
export default {
  data() {
    return {
      phone: "",
      personInfo: {},
    };
  },

  mounted() {
    window.waitIOSCamera = this.waitIOSCamera;
    window.waitAndroidCamera = this.waitAndroidCamera;
  },
  created() {},
  methods: {
    handleInput(e) {
      let value = e + "";
      let regSpace = /\s/;
      // 如果有空格，去掉空格
      if (regSpace.test(value)) {
        this.phone = value.replace(/\s+/g, "");
        return;
      }
      // 限制输入位数
      if (value.length > 11) {
        this.phone = value.slice(0, 11);
      }
    },
    // 扫一扫
    openCamera() {
      const message = {
        message: "扫描二维码",
        body: {},
      };
      const androidMessage = JSON.stringify(message)
      const phoneType = localStorage.getItem("phoneType");
      if (phoneType == "Android") {
        window.android.openCamera(androidMessage);
      } else {
        window.webkit.messageHandlers.openCamera.postMessage(message);
      }
    },
    waitIOSCamera(codeInfo) {
      getUserInfoById(codeInfo.id).then((res) => {
        if (res.data.code == 200) {
          this.personInfo = res.data.data;
        } else {
          Notify({ type: "warning", message: "未查询到该用户！" });
        }
      });
    },
    waitAndroidCamera(info) {
      const codeInfo = JSON.parse(JSON.stringify(info))
      getUserInfoById(codeInfo.id).then((res) => {
        if (res.data.code == 200) {
          this.personInfo = res.data.data;
        } else {
          Notify({ type: "warning", message: "未查询到该用户！" });
        }
      });
      return '确定'
    },
    // 搜索
    searchBtn() {
      getUserByPhone(this.phone).then((res) => {
        if (res.data.code == 200) {
          this.personInfo = res.data.data;
        } else {
          Notify({ type: "warning", message: "未查询到该用户！" });
        }
      });
    },
    // 清空
    clearBtn() {
      this.personInfo = {};
    },
    // 添加关注
    addFollow() {
      const appuser = JSON.parse(localStorage.getItem("appuser"));
      let params = {
        bemangeredId: this.personInfo.id,
        bemangeredName: this.personInfo.name,
        mangerId: appuser.id,
        mangerName: appuser.name,
      };
      insertRelation(params).then((res) => {
        if (res.data.code == 200) {
          Notify({ type: "success", message: "添加成功！" });
          this.$router.push("/RecordDetail");
        } else if (res.data.code == 201) {
          Dialog.confirm({
            title: "关注确认",
            message: "该用户已经添加过了，是否继续关注？",
            confirmButtonText: "继续关注",
            cancelButtonText: "取消关注",
          })
            .then(() => {
              let params = {
                id: res.data.data.id,
                status: 1,
              };
              agreeById(params).then((result) => {
                if (result.data.code == 200) {
                  this.$router.push("/RecordDetail");
                  Notify({ type: "success", message: "关注成功" });
                } else {
                  Notify({ type: "danger", message: result.data.msg });
                }
              });
              // on confirm
            })
            .catch(() => {
              let params = {
                id: res.data.data.id,
                status: 0,
              };
              agreeById(params).then((result) => {
                if (result.data.code == 200) {
                  this.$router.push("/RecordDetail");
                  Notify({ type: "success", message: "取消关注" });
                } else {
                  Notify({ type: "danger", message: result.data.msg });
                }
              });
              // on cancel
            });
        } else {
          Notify({ type: "warning", message: res.data.msg });
        }
      });
    },
    goBack() {
      this.$router.push("/RecordDetail");
    },
  },
};
</script>
<style lang="less">
.sweep {
  background: #fff;
  margin-top: 0.5rem;
  position: relative;
  height: 5rem;
  line-height: 2rem;
  padding-left: 6rem;
  padding-top: 1rem;
  img {
    position: absolute;
    left: 15px;
    width: 48px;
    height: 48px;
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }
  .info {
    text-align: left;
  }
  .arrow {
    position: absolute;
    right: 10px;
    top: 2rem;
  }
}
.van-cell__title {
  text-align: left;
}
</style>
