<template>
  <div id="kfyView">
    <div class="topHeader">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <div>口服药</div>
    </div>
    <div class="mainBody">
      <van-cell
        title="用药时间"
        is-link
        :value="selectTime"
        @click="openSelectTime"
      />
      <van-cell
        title="用药时段"
        is-link
        :value="periodOfTime"
        @click="openPeriodOfTime"
      />
    </div>
    <van-cell-group style="margin-top: 0.5rem; margin-bottom: 0.5rem">
      <div
        v-for="(item, i) in drugUsed"
        :key="'drug' + i"
        style="border-top: 10px solid #e4e7ed"
      >
        <van-field
          v-model="item.name"
          style="padding-right: 3rem"
          input-align="right"
          label="药品名称"
          placeholder="请输入药品名称"
        />
        <van-field
          v-model="item.value"
          style="padding-right: 3rem"
          input-align="right"
          label="用药量(片)"
          placeholder="请输入用药量（片）"
          type="number"
        />
      </div>
    </van-cell-group>
    <el-row style="margin-top: 2rem">
      <el-col :span="12">
        <el-button
          style="border: none; float: right; margin-right: 2rem"
          @click="addDrugUsed"
          ><i class="el-icon-circle-plus-outline"></i> 增加用药</el-button
        >
      </el-col>
      <el-col :span="12">
        <el-button
          style="border: none; float: left; margin-left: 2rem"
          @click="deleteDrugUsed"
          ><i class="el-icon-remove-outline"></i> 减少用药</el-button
        >
      </el-col>
    </el-row>
    <el-row style="margin-top: 2rem">
      <el-col :offset="2" :span="20">
        <el-button
          @click="addRecords"
          style="
            width: 100%;
            color: #fff;
            background: #00dac4;
            border-radius: 1rem;
            margin-bottom: 6rem;
            height: 3rem;
          "
        >
          完成
        </el-button>
      </el-col>
    </el-row>
    <van-popup
      v-model="showTimeSelect"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        style="line-height: 2rem"
        v-model="currentDate"
        type="datetime"
        title="选择完整时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="saveTime"
        :formatter="formatter"
        @cancel="closeTimeSelect"
      />
    </van-popup>
    <van-popup
      v-model="showPeriodOfTime"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        style="line-height: 2rem"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="closePeriodOfTime"
      />
    </van-popup>
  </div>
</template>
<script>
import { addEvent } from "../../../api/userServices";
export default {
  name: "kfyView",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      showTimeSelect: false,
      showPeriodOfTime: false,
      columns: ["餐前", "餐时", "餐后"],
      selectTime: this.getFormatDate(new Date()),
      periodOfTime: "餐前",
      drugUsed: [
        {
          name: "",
          value: "",
        },
      ],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    goBack() {
      this.$router.push("/login");
    },
    openSelectTime() {
      this.showTimeSelect = true;
    },
    openPeriodOfTime() {
      this.showPeriodOfTime = true;
    },
    closePeriodOfTime() {
      this.showPeriodOfTime = false;
    },
    closeTimeSelect() {
      this.showTimeSelect = false;
    },
    addDrugUsed() {
      this.drugUsed.push({
        name: "",
        value: "",
      });
    },
    deleteDrugUsed() {
      if (this.drugUsed.length <= 1) {
        this.$message.warning("至少保留一条嘛!");
      } else {
        this.drugUsed.pop();
      }
    },
    saveTime(value) {
      this.selectTime = this.getFormatDate(value);
      this.showTimeSelect = false;
    },
    onConfirm(value, index) {
      this.periodOfTime = value;
      this.showPeriodOfTime = false;
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    getFormatDate(time) {
      const date = new Date(time);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hour = hour < 10 ? "0" + hour : hour;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      const currentDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds;
      return currentDate;
    },
    addRecords() {
      const params = {
        type: "kfy",
        createTime: this.selectTime,
        scene: this.periodOfTime,
        way: "",
        slopeOrdinal:
          localStorage.getItem("slopeOrdinal") == "undefined" ||
          !localStorage.getItem("slopeOrdinal")
            ? 3
            : localStorage.getItem("slopeOrdinal"),

        value: localStorage.getItem("bloodSuger")
          ? localStorage.getItem("bloodSuger")
          : "5",
        eventValue: "",
      };
      for (let index = 0; index < this.drugUsed.length; index++) {
        const element = this.drugUsed[index];
        params.way = element.name;
        params.eventValue = element.value;
        addEvent(params).then((res) => {
          if (res.data.code === "200") {
            this.$message.success("添加成功!");
            this.$router.go(-1);
          } else {
            this.$message.error("添加失败，请输入完整信息！");
          }
        });
      }
    },
  },
};
</script>
<style scoped>
#kfyView {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #fff;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 100;
}

.topHeader {
  height: 4rem;
  line-height: 4rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
}

.mainBody {
  text-align: left;
}

.el-icon-arrow-left {
  position: absolute;
  top: 0;
  left: 24px;
  line-height: 4rem;
  font-size: 2rem;
}
</style>
