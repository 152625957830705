import * as qiniu from 'qiniu-js'
import { getQniuToken } from '@/api/userServices'
export function upload (file, ok) {
  const key = new Date().getTime().toString(36) + '-' + Math.random().toString(36).substr(2, 9)
  const config = { useCdnDomain: true, region: qiniu.region.z0 }
  const putExtra = { fname: file, params: {}, mimeType: null }
  const observe = {
    next(res) {
      console.log('next')
      console.log(res)
    },
    error(err) {
      console.log('error')
      console.log(err)
    },
    /* 完成后的操作 */
    complete(res) {
      console.log('complete')
      console.log(res)
      const url = 'https://oss.cgm.go-sugar.cn/' + key
      ok(url)
    }
  }
  // 开始上传  token 需要找后端获取(单独的方法)
  getQniuToken().then(res => {
    const token = res.data.data
    const headers = qiniu.getHeadersForMkFile(token)
    // file 是获取到的文件对象
    // key 是文件名字，传null将使用hash值来当作文件名
    const observable = qiniu.upload(file, key, token, headers, putExtra, config)
    observable.subscribe(observe)
  })
}
