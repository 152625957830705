<template>
  <div id="sugar-container">
    <div>
      <div style="width: 100%; height: 5rem; position: relative">
        <div
          @click="openAlarmPage"
          style="
            width: 3rem;
            height: 3rem;
            position: absolute;
            left: 1rem;
            top: 1rem;
          "
        >
          <img :src="image1" style="width: 2.8rem; height: 2.8rem" />
        </div>
        <!-- <div
          @click="openCalibration"
          style="
            width: 3rem;
            height: 3rem;
            position: absolute;
            left: 5rem;
            top: 1rem;
          "
        >
          <img :src="image2" style="width: 3rem; height: 3rem" />
        </div> -->
        <div
          @click="saveImage('html2canvas', '血糖')"
          style="
            width: 3rem;
            height: 3rem;
            position: absolute;
            left: 5rem;
            top: 0.8rem;
          "
        >
          <img :src="image5" style="width: 3rem; height: 3rem" />
        </div>
        <div
          @click="openCamera()"
          style="
            width: 3rem;
            height: 3rem;
            position: absolute;
            right: 5rem;
            top: 0.8rem;
          "
        >
          <img :src="image6" style="width: 3rem; height: 3rem" />
        </div>
        <div
          @click="openCGMDia"
          style="
            width: 3rem;
            height: 3rem;
            position: absolute;
            right: 1rem;
            top: 0.8rem;
          "
        >
          <img :src="image4" style="width: 3rem; height: 3rem" />
        </div>
      </div>

      <div id="html2canvas" ref="html2canvas">
        <div style="width: 80vw; height: 20rem; margin-left: 10vw">
          <!-- 达标时间 -->
          <div v-if="cgmTypeName">
            <div
              v-if="(qualifyTime && qualifyTime < 20) || qualifyTime == 0"
              style="position: relative; top: 2rem; left: -9rem; color: #b0b2b4"
            >
              {{ qualifyTime == 0 ? "1" : qualifyTime }}分钟前
              <!-- <span style="font-size: 1.2rem; color: #6eceb1">达标</span> -->
            </div>

            <div
              v-else
              style="position: relative; top: 2rem; left: -9rem; color: #b0b2b4"
            >
              {{ qualifyTime <= 60 ? qualifyTime : "--" }}分钟前
              <span style="font-size: 1.2rem; color: red">超时</span>
            </div>

            <!-- cgm类型 -->
            <div
              style="position: relative; top: 3rem; left: -9rem; color: #7accfe"
            >
              {{ cgmTypeName }}
            </div>
          </div>

          <div
            @click="openCgmTojs"
            style="
              width: 15rem;
              height: 15rem;
              margin: auto;
              position: relative;
            "
          >
            <img
              id="bloodSugerImg"
              :src="image3"
              style="width: 15rem; height: 15rem"
            />
            <div
              style="
                position: absolute;
                width: 8rem;
                height: 8rem;
                background: rgb(211, 238, 255);
                top: 3.5rem;
                left: 3.4rem;
                border-radius: 4rem;
              "
            >
              <div
                style="
                  font-size: 4rem;
                  color: #000;
                  line-height: 8rem;
                  height: 8rem;
                  font-weight: bold;
                "
              >
                {{ nowdata1 == "NaN" ? "-" : nowdata1 }}.<span
                  style="font-size: 3rem; font-weight: 500"
                  >{{ nowdata2 == "NaN" ? "-" : nowdata2 }}</span
                >
              </div>
              <div
                style="
                  position: absolute;
                  top: 6.5rem;
                  left: 2.4rem;
                  height: 1.2rem;
                  font-size: 1.2rem;
                  line-height: 1.2rem;
                "
              >
                mmol/L
              </div>
            </div>
          </div>
        </div>
        <MyEcharts @refresh="refresh"></MyEcharts>
      </div>

      <div
        class="loop-box"
        v-if="cgmType == 'loop' && version == 'loop' && loopTime <= 30"
        @click="goloop()"
      >
        <div class="loopTitle">闭环设备信息</div>
        <van-row type="flex" justify="space-around">
          <van-col span="10"
            >名称
            <span>{{
              loopDevice.loop ? loopDevice.loop.name : "-"
            }}</span></van-col
          >
          <van-col span="13"
            >更新时间<span>{{
              loopDevice.create_time ? loopDevice.create_time : "-"
            }}</span></van-col
          >
        </van-row>
        <van-row type="flex" justify="space-around">
          <van-col span="24"
            >胰岛素泵
            <span>
              {{ loopDevice.pump ? loopDevice.pump.reservoir : "-" }}U
              {{ loopDevice.pump ? loopDevice.pump.battery.percent : "-" }}%
              {{ loopDevice.device ? loopDevice.device : "" }}</span
            ></van-col
          >
        </van-row>
      </div>
      <div
        class="loop-box"
        v-else-if="cgmType == 'loop' && version == 'loop' && loopTime > 30"
      >
        <div class="loopTitle">闭环设备信息</div>
        <van-row type="flex" justify="space-around">
          <van-col span="23"
            ><van-icon
              name="warning"
              color="red"
              size="1.5rem"
            />闭环设备信息更新时间已超出30分钟</van-col
          >
        </van-row>
      </div>

      <!-- 血糖管家 -->
      <!-- <div class="common-box" style="padding: 1.5rem 0">
        <el-row>
          <el-col :span="5">
            <img :src="doctor" style="width: 5rem; height: 5rem" />
          </el-col>
          <el-col :span="14">
            <div
              style="
                height: 3rem;
                color: #8a8a8a;
                line-height: 3rem;
                text-align: left;
                font-size: 1.6rem;
              "
            >
              我的血糖管家
            </div>
            <div style="height: 2rem; line-height: 2rem; font-size: 0.8rem">
              <div class="eventType">问医生</div>
              <div class="eventType">看报告</div>
              <div class="eventType">分析血糖</div>
            </div>
          </el-col>
          <el-col :span="5" style="line-height: 5rem">
            <el-button
              type="primary"
              style="background: #7accff; border: 0; border-radius: 2rem"
              >咨询</el-button
            >
          </el-col>
        </el-row>
      </div> -->
    </div>
    <div v-show="hadBindCGM">
      <CGMDetails
        @editBindingCGM="editBindingCGM"
        :bindingCGM="bindingCGM"
      ></CGMDetails>
    </div>
    <van-popup
      v-model="showButtonPop"
      position="bottom"
      :style="{ height: '400px' }"
    >
      <addCalibration></addCalibration>
    </van-popup>
    <van-popup
      v-if="showCGMDia"
      v-model="showCGMDia"
      position="bottom"
      :style="{ height: '28rem' }"
    >
      <CGMView @clickCgm="clickCgm()"></CGMView>
    </van-popup>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import { Toast, Dialog } from "vant";
import image1 from "../../static/pic/image1.png";
import image2 from "../../static/pic/image2.png";
import image3 from "../../static/pic/image3.png";
import image4 from "../../static/pic/image4.png";
import image5 from "../../static/pic/share.png";
import image6 from "../../static/pic/image6.png";
import ypImage from "../../static/cgm/yp.png";
import dkImage from "../../static/cgm/dk.png";
import wwImage from "../../static/cgm/ww.png";
import gjImage from "../../static/cgm/gj.png";
import select from "../../static/pic/select.png";
import unselect from "../../static/pic/unselect.png";
import doctor from "../../static/pic/doctor.png";
import MyEcharts from "./component/pointChart.vue";
import addCalibration from "./component/addCalibration.vue";
import CGMView from "./component/CGMView.vue";
import CGMDetails from "./component/CGMDetails.vue";
import LoopDetails from "./component/loopDetails.vue";
import { insertbg, getBGbyToken, getNew } from "../../api/userServices";
import { loopDevicestatus, getUserInfoById } from "../../api/relationship";
import { dateCompute } from "../sugerData/getSugerData";
export default {
  name: "BloodSugar",
  components: { MyEcharts, CGMDetails, addCalibration, CGMView, LoopDetails },
  props: {
    noCGM: {
      type: Boolean,
      default: true,
    },
    hadBindCGM: {
      type: Boolean,
      default: false,
    },
    bindingCGM: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      // 闭环设备信息
      cgmType: JSON.parse(localStorage.getItem("appuser")).cgm,
      loopDevice: {},
      showCGMDia: false,
      nowdata1: "-",
      nowdata2: "-",
      image1: image1,
      image2: image2,
      image3: image3,
      image4: image4,
      image5: image5,
      image6: image6,
      ypImage: ypImage,
      dkImage: dkImage,
      wwImage: wwImage,
      gjImage: gjImage,
      doctor: doctor,
      select: select,
      showButtonPop: false,
      unselect: unselect,
      selectedDate: "3H",
      bloodSugerValue: {
        slopeOrdinal: 0,
        calculatedValueAsString1: " ",
        calculatedValueAsString2: " ",
        nowDate: "",
      },
      timer: null,
      qualifyTime: "",
      leaveTime: "",
      version: localStorage.getItem("version"),
      loopTime: "",
      cgmTypeName: null,
    };
  },
  created() {
    this.searchNewSugerBlood();
    if (this.cgmType == "loop" && this.version == "loop") {
      this.getLoopStatus();
    }
  },
  mounted() {
    window.unifiedInterFace = this.unifiedInterFace;

    window.waitIOSCamera = this.waitIOSCamera;
    window.waitAndroidCamera = this.waitAndroidCamera;
    window.setRealtimeBlood = this.setRealtimeBlood;
    window.setAndroidRealtimeBlood = this.setAndroidRealtimeBlood;
    window.waitIOSSetGJValue = this.waitIOSSetGJValue;
    window.waitAndroidSetGJValue = this.waitAndroidSetGJValue;
    window.saveImageToBlob = this.saveImageToBlob;
    window.openLibreNFC = this.openLibreNFC;
    this.timer = setInterval(() => {
      this.searchNewSugerBlood();
      if (this.cgmType == "loop" && this.version == "loop") {
        this.getLoopStatus();
      }
    }, 2 * 60 * 1000);
    this.$once("hook:beforeDestroy", () => {
      // 页面关闭
      console.log("关闭最后血糖定时器");
      clearInterval(this.timer); // 停止
    });
  },
  methods: {
    // 刷新血糖
    refresh() {
      this.searchNewSugerBlood();
      if (this.cgmType == "loop" && this.version == "loop") {
        this.getLoopStatus();
      }
    },
    //接收更新血糖
    unifiedInterFace() {
      this.searchNewSugerBlood();
      if (this.cgmType == "loop" && this.version == "loop") {
        this.getLoopStatus();
      }
      // 更新用户信息
      const id = JSON.parse(localStorage.getItem("appuser")).id;
      getUserInfoById(id).then((res) => {
        localStorage.setItem("appuser", JSON.stringify(res.data.data));
      });
      return "确定";
    },
    clickCgm(val) {
      this.showCGMDia = val;
    },
    // 扫一扫
    openCamera() {
      const message = {
        message: "扫描二维码",
        body: {},
      };
      const androidMessage = JSON.stringify(message);
      const phoneType = localStorage.getItem("phoneType");
      if (phoneType == "Android") {
        window.android.openCamera(androidMessage);
      } else {
        window.webkit.messageHandlers.openCamera.postMessage(message);
      }
    },
    waitIOSCamera(codeInfo) {
      console.log(codeInfo, "codeInfo");
      if (codeInfo.qrcType == 1) {
        Toast("您扫描的不是医生二维码！");
      } else if (codeInfo.qrcType == 2) {
        // 患者扫医生码加入医院并和这个医生绑定关系
        this.$router.push({
          path: "bindingInfo",
          query: codeInfo,
        });
      } else if (
        codeInfo.qrcType == 3 || // 医生扫医院码加入医院
        codeInfo.qrcType == 4 || // 患者扫医院码加入医院
        codeInfo.qrcType == 5 // 患者扫医院码加入医院并加入护士站
      ) {
        console.log(codeInfo.qrcType, "qrcType");
        this.$router.push({
          path: "joinHospital",
          query: codeInfo,
        });
      }
    },
    waitAndroidCamera(info) {
      const codeInfo = JSON.parse(JSON.stringify(info));
      if (codeInfo.qrcType == 1) {
        Toast("您扫描的不是医生二维码！");
      } else if (codeInfo.qrcType == 2) {
        // 患者扫医生码加入医院并和这个医生绑定关系
        this.$router.push({
          path: "bindingInfo",
          query: codeInfo,
        });
      } else if (
        codeInfo.qrcType == 3 || // 医生扫医院码加入医院
        codeInfo.qrcType == 4 || // 患者扫医院码加入医院
        codeInfo.qrcType == 5 // 患者扫医院码加入医院并加入护士站
      ) {
        console.log(codeInfo.qrcType, "qrcType");
        this.$router.push({
          path: "joinHospital",
          query: codeInfo,
        });
      }
      return "确定";
    },
    goloop() {
      const id = JSON.parse(localStorage.getItem("appuser")).id;
      this.$router.push({
        path: "loopDetails",
        query: { id: id },
      });
    },
    // 获取闭环设备信息
    getLoopStatus() {
      const id = JSON.parse(localStorage.getItem("appuser")).id;
      loopDevicestatus({ uid: id }).then((res) => {
        this.loopDevice = res.data;
        if (res.data.create_time) {
          // 计算loopd断开时间
          const start = res.data.create_time;
          const end = this.$moment().format("YYYY-MM-DD HH:mm:ss");
          // this.qualifyTime = calculateMinutes(start, end);

          this.loopTime = this.$moment(end).diff(
            this.$moment(start),
            "minutes"
          );
        }
      });
    },
    // 图片格式转换方法
    dataURLToBlob(dataurl) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    // 截屏方法
    saveImage(divText, imgText) {
      const phoneType = localStorage.getItem("phoneType");

      const canvasID = this.$refs[divText];
      console.log(canvasID, "canvasID");
      html2canvas(canvasID).then((canvas) => {
        const img = canvas.toDataURL("image/jpeg", 0.8).slice(23);
        console.log(img, "img");
        const message = {
          message: "图片分享",
          body: {
            blob: img,
            type: "home",
          },
        };
        console.log(message, "要传递的数据");
        const androidMessage = JSON.stringify(message);
        if (phoneType == "Android") {
          window.android.saveImageToBlob(androidMessage);
        } else {
          window.webkit.messageHandlers.saveImageToBlob.postMessage(message);
        }
      });
    },
    openCalibration() {
      this.showButtonPop = true;
    },
    openAlarmPage() {
      const phoneType = localStorage.getItem("phoneType");
      if (phoneType == "Android") {
          window.android.openNotification();
        }
      this.$router.push("BloodSugar/alarmCFG");
    },
    waitIOSSetGJValue(value) {
      this.setBGToJS(value.v);
      this.setSlopeToJS(value.s);
      this.nowdata1 = (value.v + "").split(".")[0]
        ? (value.v + "").split(".")[0]
        : "0";
      this.nowdata2 = (value.v + "").split(".")[1]
        ? (value.v + "").split(".")[1]
        : "0";
      this.nowDate = value.t;
    },
    waitAndroidSetGJValue(info) {
      const value = JSON.parse(JSON.stringify(info));
      this.setBGToJS(value.v);
      this.setSlopeToJS(value.s);
      this.nowdata1 = (value.v + "").split(".")[0]
        ? (value.v + "").split(".")[0]
        : "0";
      this.nowdata2 = (value.v + "").split(".")[1]
        ? (value.v + "").split(".")[1]
        : "0";
      this.nowDate = value.t;

      return "确定";
    },
    searchNewSugerBlood() {
      getNew({}).then((res) => {
        if (res.data.code === "200" && res.data.data) {
          switch (res.data.data.cgm) {
            case "ns":
              this.cgmTypeName = "nightscout";
              break;
            case "loop":
              this.cgmTypeName = "闭环";
              break;
            case "sg":
              this.cgmTypeName = "雅培瞬感";
              break;
            case "yp":
              this.cgmTypeName = "雅培旺旺";
              break;
            case "ww":
              this.cgmTypeName = "雅培旺旺";
              break;
            case "gj":
              this.cgmTypeName = "硅基动感";
              break;
            case "sn":
              this.cgmTypeName = "三诺爱看";
              break;

            default:
              break;
          }
          // 计算达标时间
          const start = res.data.data.create_time;
          const end = this.$moment().format("YYYY-MM-DD HH:mm:ss");
          // this.qualifyTime = calculateMinutes(start, end);

          this.qualifyTime = this.$moment(end).diff(
            this.$moment(start),
            "minutes"
          );

          // 计算传感器剩余时间
          if (res.data.data.enableTime) {
            const enableTime = this.$moment(res.data.data.enableTime).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            const lastTime = this.$moment().format("YYYY-MM-DD HH:mm:ss");
            this.leaveTime = dateCompute(enableTime, lastTime);
          }
          const sgv = parseFloat(res.data.data.v).toFixed(1);
          this.setBGToJS(sgv);
          this.setSlopeToJS(res.data.data.s);
          this.cgmType = res.data.data.cgm;

          this.nowdata1 = sgv.split(".")[0] ? sgv.split(".")[0] : "0";
          this.nowdata2 = sgv.split(".")[1] ? sgv.split(".")[1] : "0";

          // cgm过期时间，雅培和旺旺没有传，暂时引掉
          // if (res.data.data.cgm == "ns" || res.data.data.cgm == "loop") {
          //   this.nowdata1 = sgv.split(".")[0];
          //   this.nowdata2 = sgv.split(".")[1] ? sgv.split(".")[1] : "0";
          // } else {
          //   this.nowdata1 = this.leaveTime <= 0 ? "0" : sgv.split(".")[0];
          //   this.nowdata2 =
          //     this.leaveTime <= 0
          //       ? "0"
          //       : sgv.split(".")[1]
          //       ? sgv.split(".")[1]
          //       : "0";
          // }

          this.bloodSugerValue.nowDate = res.data.data.t;
          if (res.data.data.cgm == "gj") {
            this.changeBloodSugerGjStyle(res.data.data.s);
          } else if (res.data.data.cgm == "yp" || res.data.data.cgm == "sg") {
            this.changeBloodSugerYpStyle(res.data.data.s);
          }

          // const date = this.getFormatDate(res.data.data.t);
          // switch (appuser.cgm) {
          //   case 'gj':
          //   Toast("硅基CGM: " + "时间:" + date);
          //     break;
          //   case 'yp':
          //   Toast("雅培CGM: " + "时间:" + date);
          //     break;
          //   case 'sg':
          //   Toast("瞬感NFC: " + "时间:" + date);
          //     break;
          //   case 'dk':
          //   Toast("德康G6: " + "时间:" + date);
          //     break;
          //   default:
          //     break;
          // }
        } else {
          if (
            this.$route.path == "/login" ||
            this.$route.path == "/passwordLogin"
          ) {
            return;
          } else {
            Toast("获取数据失败,等待CGM推送");
          }
        }
      });
    },
    setBGToJS(bguger) {
      localStorage.setItem("bloodSuger", bguger);
    },
    setSlopeToJS(slope) {
      localStorage.setItem("slopeOrdinal", slope);
    },
    editBindingCGM(device) {
      this.$emit("editBindingCGM", device);
    },
    openCGMDia() {
      this.showCGMDia = true;
    },
    // ios端主动调用更新血糖数据
    setRealtimeBlood(info) {
      // 斜率
      const slopeOrdinal = info.slopeOrdinal;
      //   血糖
      // const calculatedValueAsString = info.calculatedValueAsString
      const bgValueRounded = info.bgValueRounded;
      // 时间戳
      const nowDate = this.getFormatDate(new Date().valueOf(info.nowDate));
      // const sgv = (parseFloat(calculatedValueAsString) / 18).toFixed(1);
      const sgv = bgValueRounded;
      this.setBGToJS(sgv);
      this.setSlopeToJS(slopeOrdinal);
      this.bloodSugerValue.slopeOrdinal = slopeOrdinal;
      this.nowdata1 = sgv.split(".")[0] ? sgv.split(".")[0] : "0";
      this.nowdata2 = sgv.split(".")[1] ? sgv.split(".")[1] : "0";
      this.bloodSugerValue.nowDate = nowDate;
      // this.changeBloodSugerImgStyle(slopeOrdinal);
      const params = {
        sgv: info.bgValueRounded,
        localTime: nowDate,
        slope: slopeOrdinal,
      };
      insertbg(params).then((res) => {
        if (res.data.code === "2002") {
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem(
            "appuser",
            JSON.stringify(res.data.data.appuser)
          );
          localStorage.setItem("loginStatus", true);
          this.$emit("checkLogin");
        }
      });
    },
    // android端主动调用更新血糖数据
    setAndroidRealtimeBlood(data) {
      const info = JSON.parse(JSON.stringify(data));
      // 斜率
      const slopeOrdinal = info.slopeOrdinal;
      //   血糖
      // const calculatedValueAsString = info.calculatedValueAsString
      const bgValueRounded = info.bgValueRounded;
      // 时间戳
      const nowDate = this.getFormatDate(new Date().valueOf(info.nowDate));
      // const sgv = (parseFloat(calculatedValueAsString) / 18).toFixed(1);
      const sgv = bgValueRounded;
      this.setBGToJS(sgv);
      this.setSlopeToJS(slopeOrdinal);
      this.bloodSugerValue.slopeOrdinal = slopeOrdinal;
      this.nowdata1 = sgv.split(".")[0] ? sgv.split(".")[0] : "0";
      this.nowdata2 = sgv.split(".")[1] ? sgv.split(".")[1] : "0";
      this.bloodSugerValue.nowDate = nowDate;
      // this.changeBloodSugerImgStyle(slopeOrdinal);
      const params = {
        sgv: info.bgValueRounded,
        localTime: nowDate,
        slope: slopeOrdinal,
      };
      insertbg(params).then((res) => {
        if (res.data.code === "2002") {
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem(
            "appuser",
            JSON.stringify(res.data.data.appuser)
          );
          localStorage.setItem("loginStatus", true);
          this.$emit("checkLogin");
        }
      });
      return "确定";
    },
    // 硅基血糖指向
    changeBloodSugerGjStyle(slopeOrdinal) {
      if (parseInt(slopeOrdinal) === -2) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(45deg)";
      } else if (parseInt(slopeOrdinal) === -1) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(22deg)";
      } else if (parseInt(slopeOrdinal) === 0) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(0deg)";
      } else if (parseInt(slopeOrdinal) === 1) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(-22deg)";
      } else if (parseInt(slopeOrdinal) === 2) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(-45deg)";
      }
    },
    // 雅培血糖指向
    changeBloodSugerYpStyle(slopeOrdinal) {
      if (parseInt(slopeOrdinal) === 7) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(67deg)";
      } else if (parseInt(slopeOrdinal) === 6) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(45deg)";
      } else if (parseInt(slopeOrdinal) === 5) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(22deg)";
      } else if (parseInt(slopeOrdinal) === 4) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(0deg)";
      } else if (parseInt(slopeOrdinal) === 3) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(-22deg)";
      } else if (parseInt(slopeOrdinal) === 2) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(-45deg)";
      } else if (parseInt(slopeOrdinal) === 1) {
        document.getElementById("bloodSugerImg").style.transform =
          "rotate(67deg)";
      }
    },
    // 跳转到cgm连接页
    openCgmTojs() {
      const cgmType = JSON.parse(localStorage.getItem("appuser")).cgm;
      if (cgmType == "gj") {
        this.$router.push("/BloodSugar/GJEmpower");
      } else if (cgmType == "yp") {
        this.$router.push("/BloodSugar/wangEmitter");
      } else if (cgmType == "sg") {
        this.openLibreNFC();
      } else if (cgmType == "sn") {
        this.$router.push("/BloodSugar/SNEmitter");
      } else {
        return;
      }
    },
    // 发射雅培
    openLibreNFC() {
      const message = {
        message: "发射雅培",
        body: {
          type: "LibreNFC",
        },
      };
      const androidMessage = JSON.stringify(message);
      const phoneType = localStorage.getItem("phoneType");
      if (phoneType == "Android") {
        window.android.openLibreNFC(androidMessage);
      } else {
        window.webkit.messageHandlers.openLibreNFC.postMessage(message);
      }
    },
    getFormatDate(time) {
      const date = new Date(parseInt(time));
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hour = hour < 10 ? "0" + hour : hour;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      const currentDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds;
      return currentDate;
    },
  },
};
</script>
<style lang="less" scoped>
#sugar-container {
  background: #f8f8f8;
  padding-bottom: 6rem;
  overflow: hidden;
}
.eventType {
  width: 4rem;
  height: 2rem;
  float: left;
  background: #d3eeff;
  color: #7ccdff;
  margin-right: 1rem;
  border-radius: 1rem;
}
.loop-box {
  padding: 5px 0;
  .loopTitle {
    font-size: 16px;
    padding: 4px;
    margin: 8px;
    text-align: left;
    color: #0286f0;
    border-left: 4px #0286f0 solid;
  }
  .van-col {
    height: 3rem;
    line-height: 3rem;
    background: #d3eeff;
    margin-bottom: 5px;
    border-radius: 5px;
    span {
      background: cadetblue;
      color: #fff;
      padding: 4px;
      border-radius: 5px;
      margin: 0 2px;
    }
    img {
      vertical-align: middle;
    }
  }
}
</style>
