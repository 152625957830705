<template>
    <div id="personnelManagement">
        <div class="mainBar">
            <h1>{{ selectedRow.structName }}</h1>
            <el-row>
                <el-col :span="8">主任</el-col>
                <el-col :span="8">医生</el-col>
                <el-col :span="8">护士</el-col>
            </el-row>
            <el-row style="margin-top:2rem">
                <el-col :span="8">
                    <div  @click="addNewDirector" class="directorAdd">
                        <div>新增 <i class="el-icon-circle-plus-outline"></i></div>
                    </div>
                    <div v-for="(item,i) in directorList"  @click="getTheHospitalUser('director', i)" :key="'director'+i" class="director">
                        <div><i class="el-icon-user-solid"></i><span>{{ item.username }}</span></div>
                        <div>{{ item.userrole }} <i v-if="item.authentication" class="el-icon-circle-check"></i>
                                <i v-if="!item.authentication" class="el-icon-circle-close"></i>
                        </div>
                    </div>

                </el-col>
                <el-col :span="8">
                    <div @click="addNewDoctor" class="directorAdd">
                        <div>新增 <i class="el-icon-circle-plus-outline"></i></div>
                    </div>
                    <div v-for="(item,i) in doctorList" @click="getTheHospitalUser('doctor', i)"  :key="'doctor'+i" class="director">
                        <div><i class="el-icon-user-solid"></i><span>{{ item.username }}</span></div>
                        <div>{{ item.userrole }} <i v-if="item.authentication" class="el-icon-circle-check"></i>
                                <i v-if="!item.authentication" class="el-icon-circle-close"></i>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div @click="addNewNurse" class="directorAdd">
                        <div>新增 <i class="el-icon-circle-plus-outline"></i></div>
                    </div>
                    <div  v-for="(item,i) in nurseList" @click="getTheHospitalUser('nurse', i)" :key="'doctor'+i" class="director">
                        <div><i class="el-icon-user-solid"></i><span>{{ item.username }}</span></div>
                        <div>{{ item.userrole }} <i v-if="item.authentication" class="el-icon-circle-check"></i>
                                <i v-if="!item.authentication" class="el-icon-circle-close"></i>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <selectUser @closeSelectUser="closeSelectUser" @selectUserToHospital="selectUserToHospital" v-show="showSelectUser" :selectedRow="selectedRow"></selectUser>
    </div>
</template>
<script>
import selectUser from './selectUser.vue'
import { update, updateStruct } from '../../api/userServices'
export default {
  name: 'personnelManagement',
  components: { selectUser },
  props: {
    selectedRow: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      showSelectUser: false,
      directorList: [],
      doctorList: [],
      nurseList: [],
      selectedClickDiv: {
        type: '',
        i: 0
      }
    }
  },
  mounted () {
    if (this.selectedRow.json) {
      const json = JSON.parse(this.selectedRow.json)
      this.directorList = json.directorList
      this.doctorList = json.doctorList
      this.nurseList = json.nurseList
    }
  },
  methods: {
    getTheHospitalUser (type, i) {
      this.showSelectUser = true
      this.selectedClickDiv.type = type
      this.selectedClickDiv.i = i
    },
    // 添加主任模板
    addNewDirector () {
      const director = {
        username: '未设置',
        userrole: '主任',
        userid: '',
        authentication: false
      }
      this.directorList.push(director)
    },
    closeSelectUser () {
      this.showSelectUser = false
    },
    selectUserToHospital (user) {
      this.showSelectUser = false
      if (this.selectedClickDiv.type === 'director') {
        this.directorList[this.selectedClickDiv.i] = {
          username: user.label,
          userrole: '主任',
          userid: user.value,
          authentication: true
        }
      } else if (this.selectedClickDiv.type === 'doctor') {
        this.doctorList[this.selectedClickDiv.i] = {
          username: user.label,
          userrole: '医生',
          userid: user.value,
          authentication: true
        }
      } else if (this.selectedClickDiv.type === 'nurse') {
        this.nurseList[this.selectedClickDiv.i] = {
          username: user.label,
          userrole: '护士',
          userid: user.value,
          authentication: true
        }
      }
      const params = {
        id: user.value,
        checked: 'true'
      }
      const json = {
        directorList: this.directorList,
        doctorList: this.doctorList,
        nurseList: this.doctorList
      }
      const row = JSON.parse(JSON.stringify(this.selectedRow))
      row.json = JSON.stringify(json)
      update(params).then(res => {})
      updateStruct(row).then(res => {})
    },
    addNewDoctor () {
      const doctor = {
        username: '未设置',
        userrole: '医生',
        userid: '',
        authentication: false
      }
      this.doctorList.push(doctor)
    },
    addNewNurse () {
      const nurse = {
        username: '未设置',
        userrole: '护士',
        userid: '',
        authentication: false
      }
      this.nurseList.push(nurse)
    }
  }
}
</script>
<style scoped>
#personnelManagement {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 100;
  overflow: auto;
}
.mainBar {
    width: 100%;
    background: #000;
    color:#fff;
    padding-top: 2rem;
    padding-bottom: 4rem;
    min-height: 96vh;
    margin-top: 1vh;
}
.director {
    width: 100px;
    background: #fff;
    color: #000;
    line-height: 28px;
    margin: auto;
    height: 56px;
    margin-bottom: 12px;
    border-radius: 4px;
}
.directorAdd {
    width: 100px;
    background: #fff;
    color: #000;
    line-height: 36px;
    margin: auto;
    height: 36px;
    margin-bottom: 12px;
    border-radius: 4px;
}
.el-icon-circle-check {
    color: #57f509;
}
.el-icon-circle-plus-outline {
    color: #57f509;
}
.el-icon-circle-close {
    color: red;
}
</style>
