<template>
  <div class="hospitalList">
    <!-- 顶部栏 -->
    <van-nav-bar
      title="选择医院"
      left-arrow
      left-text="返回"
      @click-left="goBackBlood"
    />
    <hospital-piece :comePath="comePath" />
  </div>
</template>

<script>
import HospitalPiece from "../mineView/component/hospitalPiece.vue";
export default {
  name: "HospitalList",
  components: { HospitalPiece },
  data() {
    return {
      comePath: "",
    };
  },
  created() {
    this.getComePath();
  },
  methods: {
    getComePath() {
      this.comePath = this.$route.query.path;
    },
    goBackBlood() {
      this.$router.push({ path: "/MineView", query: { index: 3 } });
    },
  },
};
</script>

<style>
.hospitalList {
  padding: 0 0 6.5rem 0;
  height: 100%;
  min-height: 100vh;
}
</style>
