<template>
  <div id="card" :style="cardStyle">
    <div class="main" v-for="item in detailList" :key="item.id" @click="follow(item.structName,item.structId, item.city, item.region)">
      <img class="img" src="../../../static/pic/hospital.png" :style="imgStyle" />
      <div class="text">
        <div class="left">
          <label class="title" :style="titleStyle">{{ item.structName }}</label>
          <label class="detail">地址：{{item.city}}{{item.region}}</label>
        </div>
      </div>
      <button type="button" @click="editHospital(item)">编辑</button>
    </div>
  </div>
</template>

<script>
import { getHospitalById } from '../../../api/relationship'
export default {
  name: 'Card',
  props: {
    image: {
      type: String,
      default: 'https://file.zhtsu.cn/light-radius.png'
    },
    title: {
      type: String,
      default: '标题'
    },
    height: {
      type: String,
      default: '100'
    },
    color: {
      type: String,
      default: '#000'
    }
  },
  data() {
    return {
      ht: this.height,
      cardStyle: '',
      titleColor: this.color,
      titleStyle: '',
      imgStyle: '',
      detailList: [],
      form: {
        createId: JSON.parse(localStorage.getItem('appuser')).id,
        status: 1
      }
    }
  },
  created() {
    this.getPassHos()
  },
  mounted() {
    this.cardStyle = `width: 95%; height: ${this.ht}px;`
    this.imgStyle = `width: ${this.ht - 20}px; height: ${this.ht - 20}px;`
    this.titleStyle = `color: ${this.titleColor}`
  },
  methods: {
    getPassHos() {
      getHospitalById(this.form).then(({ data }) => {
        console.log(data, 'data');
        this.detailList = data.data
      })
    },
    follow(structName, structId, city, region) {
      this.$emit('followView', structName, structId, city, region)
    },
    editHospital(item) {
      this.$router.push({
        path: '/hospitalAdd',
        query: item
      })
    }
  }
}
</script>

<style scoped>
.main {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  border-radius: 10px;
  margin: 10px;
}
.main:hover {
  background: rgba(255, 255, 255, 0.65);
  transform: scale(102%, 102%);
}
.img {
  position: relative;
  width: 64px !important;
  height: 64px !important;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 20px 0 10px;
  float: left;
}
.text {
  display: flex;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  margin-left: 20%;
}
.title {
  display: block;
  font-size: 18px;
  margin: 0px;
  font-weight: bold;
  /* text-shadow: 1px 1px 1px grey; */
}
.detail {
  display: block;
  font-size: 15px;
  display: block;
  margin-top: 18px;
  color: #7b7b7b;
}
button {
  position: absolute;
  right: 2rem;
  top: 1.6rem;
  width: 5.5rem;
  height: 2.5rem;
  border: 0;
  background-color: #409eff;
  border-radius: 6px;
  color: #fff;
}
</style>
