<template>
  <div class="body">
    <van-nav-bar title="查看医院" left-text="返回" @click-left="goBack" left-arrow>
    </van-nav-bar>
    <div class="panel-box">
      <div class="group-list">
        <div class="my-info">
          <div class="my-img">
            <img src="../../../static/pic/hospital.png" />
          </div>
          <div class="my-item-text">
            <div>{{hosInfo.structName}}</div>
            <div>{{ hosInfo.city }}{{ hosInfo.region }}</div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%">
      <div class="sweep" @click="addDoctor">
        <img src="../../../static/pic/follow.png" alt="" />
        <div class="title">添加医生</div>
        <van-icon name="arrow" class="arrow" size="24" />
      </div>
      <div class="tip">医生列表</div>
      <DocterCard :detail="currentContact" :structId="hosInfo.structId"></DocterCard>
    </div>
  </div>
</template>
<script>
import DocterCard from './docterCard.vue'
import { agreeById } from '../../../api/relationship'
export default {
  components: {
    DocterCard
  },
  data() {
    return {
      hosInfo: {},
      currentContact: [
        {
          name: '石总',
          iphone: '18895602543'
        }
      ]
    }
  },
  created() {
    this.hosInfo.structId = this.$route.query.structId
    this.hosInfo.structName = this.$route.query.structName
    this.hosInfo.city = this.$route.query.city
    this.hosInfo.region = this.$route.query.region
  },
  methods: {
    // 添加医生
    addDoctor() {
      this.$router.push({
        path: 'doctorAdd',
        query: {
          structId: this.hosInfo.structId
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.body {
  padding-bottom: 10rem;
  overflow: hidden;
}
.sweep {
  color: #000;
  height: 5rem;
  line-height: 4rem;
}
.tip {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  text-align: left;
  padding-left: 2rem;
  font-size: 16px;
  font-weight: 700;
}
.panel-box {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .group-list {
    margin-bottom: 1rem;
    background: #fff;
  }
  .items-list {
    display: flex;
    padding: 10px 0;
    margin: 0 20px;
    .items-img {
      width: 3rem;
      height: 3rem;
      margin-right: 2rem;
      img {
        width: 100%;
      }
    }
    .item-text {
      flex: 1;
      display: flex;
      font-size: 1.8rem;
      line-height: 3rem;
      .text-l {
        flex: 1;
      }
      .text-r {
        font-size: 1.2rem;
        color: #999999;
        display: flex;
        .the-ad {
          flex: 1;
          margin-right: 0.7rem;
        }
        .the-icon {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 0.7rem;
          position: relative;
          img {
            width: 100%;
          }
          .small-mark {
            position: absolute;
            background: #fc4041;
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 10px;
            top: -0.5rem;
            right: -0.7rem;
          }
        }
      }
    }
  }
  .my-info {
    display: flex;
    padding: 20px 0;
    margin: 0 20px;
  }
  .my-img {
    width: 6rem;
    height: 6rem;
    img {
      width: 100%;
    }
  }
  .my-item-text {
    flex: 1;
    padding-left: 2rem;
    text-align: left;
    div:nth-child(1) {
      margin-top: 0.5rem;
      font-size: 1.8rem;
      font-weight: bold;
      color: #383838;
      line-height: 3rem;
    }
    div:nth-child(2) {
      font-size: 1.4rem;
      color: #9a9a9a;
      line-height: 2rem;
    }
    div:nth-child(3) {
      font-size: 1.4rem;
      color: #9a9a9a;
      line-height: 2rem;
      margin-top: 0.2rem;
    }
  }
}
</style>
