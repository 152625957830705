<template>
    <div id="newChart">
        <div v-show="!showLandscape" class="chartBody">
          <div style="width: 85%;height: 300px;float: left;margin-left: 5%;">
              <div style="width: 100%;height: 40px;">
                  <div style="height: 40px;width:180px;float: left;">
                      <div style="float: left;width: 72px;margin-top: 8px;border-radius: 2px; line-height: 24px;height:24px;background: #e8e8e8;position: relative;">
                          <div style="border-radius: 2px;width: 16px;height: 16px;line-height: 16px;background: #fff;position: absolute;top:4px;left: 4px;">
                              {{ minuteAgo }}
                          </div>
                          <div  style="width: 40px;height: 16px;line-height: 16px;position: absolute;top:4px;left: 28px;">
                              分钟前
                          </div>
                      </div>
                      <div @click="useLine" style="float: left;width: 46px;margin-left:10px;margin-top: 8px;line-height: 24px;height:24px;position: relative;">
                          <div style="width: 14px;height: 14px;line-height: 16px;position: absolute;
                                      top:4px;left: 4px;border: 2px solid #e8e8e8;border-radius: 2px;">
                                   <i v-if="showLine" class="el-icon-check"></i>
                          </div>
                          <div  style="width: 14px;height: 16px;line-height: 16px;position: absolute;top:4px;left: 24px;">
                              线
                          </div>
                      </div>
                  </div>
                  <div style="height: 40px;width:88px;float: right;">
                      <div style="width: 78px;margin-left:10px;margin-top: 8px;line-height: 24px;height:24px;background: #e8e8e8;position: relative;">
                          <div style="width: 24px;height: 16px;line-height: 16px;background: #fff;position: absolute;top:4px;left: 4px;">
                              {{correctedValue}}
                          </div>
                          <div  style="width: 40px;height: 16px;line-height: 16px;position: absolute;top:4px;left: 36px;">
                              mmol/L
                          </div>
                      </div>
                  </div>
              </div>
              <div v-loading="loading" style="width: 100%;height: 230px;">
                  <div style="width: 100%;height: 200px;">
                      <div id="canvas" style="position: relative;width: 100%;height: 196px;border-top:2px dotted #c1b8b8;border-bottom:2px dotted #c1b8b8;">
                        <div class="point" v-for="(item,i) in xList" :key="'point'+i" :id="'point'+i"></div>
                        <!-- 定位使用绝对定位 top13-max*14 height max-min *14-2 -->
                        <div id="chartCan" style="width: 100%;background: #D3EEFF;">
                        </div>
                        <div style="position: absolute; width: 100%;height: 0;top: 139px;border-bottom:2px dotted #c1b8b8"></div>
                      </div>
                  </div>
                  <div style="margin-left: -7px;width: 100%;height: 30px;position: relative;">
                      <div class="hourList" v-for="(hour,i) in hourList" :key="'hour'+i" :id="'hour'+ i"> {{ hour }}</div>
                  </div>
              </div>
              <div style="width: 100%;height: 2rem;">
                  <div class="timeSelect" v-for="(item,i) in timeSelect" :key="'time'+i">
                      <div class="timeChild" @click="selectTheTime(i)">
                          <div v-if="selectedTime===i" style="width: 1rem;height: 1rem;position: absolute;top:0.4rem;
                                      border:1px solid #7accff;border-radius: 0.5rem;">
                                        <div style="border-radius: 0.4rem;width: 0.8rem;height: 0.8rem;margin-top: 0.1rem;margin-left:0.1rem;background: #7accff;"></div>
                                    </div>
                          <div v-if="selectedTime!=i" style="width: 1rem;height: 1rem;position: absolute;top:0.4rem;
                                      border:1px solid #d5efff;border-radius: 0.5rem;"></div>
                          <div v-if="selectedTime===i" style="width: 2rem;height: 2rem;position: absolute;left:1.2rem;color: #7accff;">
                              {{ item }}H
                          </div>
                          <div v-if="selectedTime!=i" style="width: 2rem;height: 2rem;position: absolute;left:1.2rem;color: #d5efff;">
                              {{ item }}H
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div style="width: 10%;position: relative; height: 300px;color:#e8e8e8;font-size:16px; float: left;text-align: left;">
              <div style="padding-left: 8px;position: absolute;top: 34px;">16</div>
              <div style="padding-left: 8px;position: absolute;" id="maxLine">{{ this.max }}</div>
              <div style="padding-left: 8px;position: absolute;top:174px">6</div>
              <div style="padding-left: 8px;position: absolute;" id="minLine">{{ this.min }}</div>
              <div style="padding-left: 8px;position: absolute;top: 232px;">2</div>
          </div>
        </div>
        <div  v-show="showLandscape" class="landscapeScreen">

        </div>
    </div>
</template>
<script>
import { getbghours } from '../../../api/userServices'
export default {
  data () {
    return {
      showLandscape: false,
      minuteList: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
      hourList: [],
      loading: false,
      xList: [],
      chartData: [],
      max: 11,
      min: 4.5,
      minuteAgo: 3,
      correctedValue: -0.1,
      showLine: true,
      timeSelect: [1, 3, 6, 12, 24],
      selectedTime: 1
    }
  },
  created () {
  },
  mounted () {
    this.initChart(3)
  },
  methods: {
    useLine () {
      this.showLine = !this.showLine
    },
    getSugerByHour (i) {
      const params = {
        hours: i + '',
        localTime: this.getFormatDate((new Date()).valueOf())
      }
      getbghours(params).then((res) => {
        this.initChart(i)
      })
    },

    initChart (num) {
      this.loading = true
      this.setHourList(num)
      this.setXList()
      this.setChartData()
      setTimeout(() => {
        document.getElementById('chartCan').style.height = ((this.max - this.min) * 14 - 2) + 'px'
        document.getElementById('chartCan').style.top = (16 - this.max) * 14 + 'px'
        document.getElementById('maxLine').style.top = (16 - this.max) * 14 + 36 + 'px'
        document.getElementById('minLine').style.top = (16 - this.min) * 14 + 32 + 'px'
        this.drawTheChart()
        this.loading = false
      }, 500)
    },
    saerchDaysData (i) {
      this.$emit('saerchDaysData', i)
    },
    drawTheChart () {
      const width = document.getElementById('canvas').offsetWidth
      const unitWidth = width / this.xList.length
      const height = document.getElementById('canvas').offsetHeight
      const unitHeight = height / (16 - 2)
      for (let index = 0; index < this.hourList.length; index++) {
        const ele = document.getElementById('hour' + index)
        ele.style.left = ((width / (this.hourList.length - 1)) * index).toFixed(1) + 'px'
      }
      for (let index = 0; index < this.xList.length; index++) {
        const data = this.xList[index]
        const element = document.getElementById(data.id)
        const left = (index * unitWidth).toFixed(1) + 'px'
        element.style.left = left
        const top = (16 - parseFloat(data.x)) * unitHeight + 'px'
        element.style.top = top
      }
    },
    setChartData () {
      this.chartData = []
      for (let ind = 0; ind < this.xList.length; ind++) {
        this.xList[ind].x = (Math.random() * (6.6 - 5.9) + 5.9).toFixed(1)
      }
    },
    setHourList (number) {
      this.hourList = []
      const nowDate = new Date()
      let nowHour = nowDate.getHours()
      nowHour = parseInt(nowHour)
      let startHour = nowHour - number
      startHour = startHour >= 1 ? startHour : startHour + 24
      for (let index = 0; index <= number; index++) {
        let hour = startHour + index
        hour = hour >= 0 ? hour : hour + 24
        hour = hour > 9 ? hour : '0' + hour
        this.hourList.push(hour)
      }
    },
    selectTheTime (i) {
      this.selectedTime = i
      // this.getSugerByHour(this.timeSelect[i])
      this.saerchDaysData(this.timeSelect[i])
    },
    setXList () {
      this.xList = []
      let num = 0
      for (let i = 0; i < this.hourList.length; i++) {
        const h = this.hourList[i]
        for (let ind = 0; ind < this.minuteList.length; ind++) {
          const m = this.minuteList[ind]
          const xData = {
            date: h + ':' + m,
            x: '',
            id: 'point' + num
          }
          num++
          this.xList.push(xData)
        }
      }
    },
    getAttack (attack, x) {
      const temp = attack
      for (let index = 1; index <= x; index++) {
        attack = attack * (1.02) + 5
      }
      console.log('初始伤害:' + temp + '修炼:' + x + '现在伤害:' + attack.toFixed(2) + '伤害提升:' + (100 * (attack - temp) / temp).toFixed(2) + '%')
    },
    attackList () {
      for (let index = 1; index <= 25; index++) {
        for (let attck = 100; attck <= 3000; attck = attck + 100) {
          this.getAttack(attck, index)
        }
      }
    },
    getFormatDate (time) {
      const date = new Date(parseInt(time))
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()
      // let seconds = date.getSeconds()
      month = (month < 10) ? '0' + month : month
      day = (day < 10) ? '0' + day : day
      hour = (hour < 10) ? '0' + hour : hour
      minutes = (minutes < 10) ? '0' + minutes : minutes
      seconds = (seconds < 10) ? '0' + seconds : seconds
      // seconds = (seconds < 10) ? '0' + seconds : seconds
      const currentDate = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
      return currentDate
    }
  }
}
</script>
<style scoped>
#newChart {
  width: 100%;
  height: 324px;
  color:#908f94;
  background: #fff;
  position: relative;
}
.chartBody {
  width: 94%;
  height: 300px;
  background: #fff;
  position: absolute;
  left: 3%;
  top: 12px;
  overflow: hidden;;
  border-radius: 12px;
}
#chartCan {
  width: 100%;
    background: rgb(211, 238, 255);
    position: absolute;
    border-top: 2px dotted #c1b8b8;
    border-bottom: 2px dotted #c1b8b8;
}
.point {
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  position: absolute;
  background: #000;
  z-index: 1;
}

.hourList {
  position: absolute;
  height: 20px;
  top: 10px;
}
.timeSelect {
  width: 20%;
  height: 2rem;
  float: left;
}
.timeChild {
  width: 3rem;
  height: 2rem;
  line-height: 2rem;
  position: relative;
  margin: auto;
}
</style>
