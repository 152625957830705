<template>
  <div class="content">
    <div class="left">
      <img :src="img" alt="" class="logo" />
      <div class="text">
        <h2>{{ name }}</h2>
        <p>邀请您血糖指导 ...</p>
      </div>
    </div>
    <div class="right">
      <img
        src="@/static/pic/refuse.png"
        class="refuse"
        @click="refuse()"
        :class="{ pressed: isPressed1, notPress: !isPressed1 }"
        @touchstart="isPressed1 = true"
        @touchend="isPressed1 = false"
      />
      <img
        src="@/static/pic/agree.png"
        class="agree"
        @click="agree()"
        :class="{ pressed: isPressed2, notPress: !isPressed2 }"
        @touchstart="isPressed2 = true"
        @touchend="isPressed2 = false"
      />
    </div>
  </div>
</template>

<script>
import { getBaibanParams, upstateB } from "@/api/relationship";
export default {
  props: ["userA", "baiban_id"],
  data() {
    return {
      isDoctor: "",
      name: '',
      img: "",
      timer1: null,
      timer2: null,
      isPressed1: false,
      isPressed2: false,
      callTime: "",
    };
  },
  created() {
    this.img = this.userA.images
      ? this.userA.images
      : "https://i.postimg.cc/2SrXwLXR/litangbao.png";
      this.name = this.userA.name
    this.timer1 = setInterval(() => {
      this.getStatus();
    }, 1000);
  },
  mounted() {
    window.unifiedInterSend = this.unifiedInterSend;
    // 页面关闭时关闭定时器
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer1);
      clearInterval(this.timer2);
    });
    this.timer2 = setTimeout(() => {
      this.$router.push("/BloodSugar");
    }, 30000);
  },
  methods: {
    toTeach() {
      this.$emit("getShow");
      this.$router.replace({
        path: "/baiban",
        query: {
          isDoctor: 2,
          baibanId: this.baiban_id,
        },
      });
    },
    refuse() {
      const params = {
        baiban_id: this.baiban_id,
        status: 0,
      };
      upstateB(params).then(({ data }) => {
        this.$emit("getShow");
      });
    },
    agree() {
      const params = {
        baiban_id: this.baiban_id,
        status: 1,
      };
      upstateB(params).then(({ data }) => {
        this.toTeach();
      });
    },
    getStatus() {
      const params = {
        baiban_id: this.baiban_id,
      };
      getBaibanParams(params).then(({ data }) => {
        if (data.data.statusa == 0) {
          this.$toast("对方已挂断");
          this.$emit("getShow");
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  position: absolute;
  top: 0;
  left: 4vw;
  z-index: 999999;
  display: flex;
  width: 92vw;
  height: 110px;
  align-items: center;
  justify-content: space-around;
  background-color: #363636;
  /* 阻止屏幕拖动 */
  overflow: hidden;
  touch-action: none;
  border-radius: 12px;
  .left {
    display: flex;
    align-items: center;
    .text {
      margin-left: 10px;
      text-align: left;
      h2 {
        color: #fafafa;
      }
      p {
        color: #9f9f9f;
      }
    }
  }

  img {
    width: 55px;
    height: 55px;
  }
  .agree {
    margin-left: 15px;
  }
}

.pressed {
  opacity: 0.7;
}
.notPress {
  opacity: 1;
}
.dot {
  font-family: simsun; /*固定字体避免设置的宽度无效*/
  animation: dot 3s infinite step-start;
  display: inline-block;
  width: 9px;
  vertical-align: bottom; /*始终让省略号在文字的下面*/
  overflow: hidden;
}
@keyframes dot {
  /*动态改变显示宽度, 但始终让总占据空间不变, 避免抖动*/
  0% {
    width: 0;
    margin-right: 9px;
  }
  25% {
    width: 0px;
    margin-right: 9px;
  }
  50% {
    width: 3px;
    margin-right: 6px;
  }
  75% {
    width: 6px;
    margin-right: 3px;
  }
  100% {
    width: 9px;
    margin-right: 0px;
  }
}
</style>
