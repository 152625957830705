<template>
    <div id="adminMain">
        <div style="width: 100vw;height: 4rem;background: #545c64;">
          <div @click="goBack" style="width: 22%;float:left;line-height: 4rem;color:#fff"><i class="el-icon-arrow-left"></i>返回</div>
          <div class="interval"></div>
          <div @click="selectTab('1')" class="topLab" :class="{'selectedTab': selectedTab == '1' , 'unselectedTab' :  selectedTab != '1'}">机构列表</div>
          <div  class="interval"></div>
          <div @click="selectTab('2')"  class="topLab" :class="{'selectedTab': selectedTab == '2' , 'unselectedTab' :  selectedTab != '2'}">创建账号</div>
          <div  class="interval"></div>
          <div @click="selectTab('3')" class="topLab" :class="{'selectedTab': selectedTab == '3' , 'unselectedTab' :  selectedTab != '3'}">机构管理</div>
        </div>
        <a @click="goUserManage" style="text-align: left;line-height: 24px;float: left;">用户管理</a>
        <div v-if="selectedTab=='1'">
            <departmentList @toPersonnelManagement="toPersonnelManagement" @showDialog ="showDialog"></departmentList>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="90%"
          :before-close="closeDoalog">
          <departmentView @closeDoalog="closeDoalog"></departmentView>
        </el-dialog>
        <createUser v-if="selectedTab=='2'"></createUser>
        <personnelManagement :selectedRow="selectedRow" v-if="selectedTab=='3'"></personnelManagement>
    </div>
</template>
<script>
import departmentList from '../personnelManagement/departmentList.vue'
import departmentView from '../personnelManagement/components/departmentView.vue'
import personnelManagement from './personnelManagement.vue'
import createUser from './createUser.vue'
export default {
  components: { departmentList, departmentView, createUser, personnelManagement },
  data () {
    return {
      selectedTab: '1',
      dialogVisible: false,
      selectedRow: {}
    }
  },
  mounted () {
    this.fbiSF()
  },
  methods: {
    selectTab (index) {
      this.selectedTab = index
    },
    showDialog () {
      this.dialogVisible = true
    },
    closeDoalog (done) {
      this.dialogVisible = false
    },
    goUserManage () {
      this.$router.push('/userTable')
    },
    goBack () {
      this.$router.push({ path: '/MineView', query: { index: 4 } })
    },
    fbiSF () {
      // 禁用双指放大
      document.documentElement.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      }, { passive: false })

      // 禁用双击放大
      let lastTouchEnd = 0
      document.documentElement.addEventListener('touchend', function (event) {
        const now = Date.now()
        if (now - lastTouchEnd <= 300) {
          event.preventDefault()
        }
        lastTouchEnd = now
      }, { passive: false })
    },
    toPersonnelManagement (row) {
      this.selectedRow = row
      this.selectedTab = '3'
      console.log(row)
    }
  }
}
</script>
<style>
#adminMain {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: #fff;
}
.selectedTab {
  background: #fff;
  color:#000;
  border: 1px solid #000;
  box-sizing: border-box;
  width: 25%;float:left;line-height: 4rem;
  border-bottom: none;
}
.interval {
  width: 1%;float:left;height:4rem;background: #fff;
}
.unselectedTab {
  width: 25%;float:left;line-height: 4rem;color:#fff;
}
</style>
