<template>
  <div id="card" style="width: 100%">
    <div
      class="main"
      v-for="item in detailList"
      :key="item.id"
      @click="comePath === 'myPatient' ? toMyPatient(item) : toReport(item)"
    >
      <img
        class="img"
        src="../../../static/pic/hospital.png"
        :style="imgStyle"
      />
      <div class="text">
        <div class="left">
          <label class="title" :style="titleStyle">{{
            item.struct_name
          }}</label>
          <label class="detail"
            >{{ item.province }}{{ item.city }}{{ item.region }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHospitalByDoctor } from "../../../api/relationship";
export default {
  name: "Card",
  props: {
    image: {
      type: String,
      default: "https://file.zhtsu.cn/light-radius.png",
    },
    title: {
      type: String,
      default: "标题",
    },
    height: {
      type: String,
      default: "100",
    },
    color: {
      type: String,
      default: "#000",
    },
  },
  data() {
    return {
      ht: this.height,
      cardStyle: "",
      titleColor: this.color,
      titleStyle: "",
      imgStyle: "",
      detailList: [],
      comePath: "",
      form: {
        doctorId: JSON.parse(localStorage.getItem("appuser")).id,
        status: 1,
      },
    };
  },
  created() {
    this.comePath = localStorage.getItem("comePath");
    this.getPassHos();
  },
  mounted() {
    // this.cardStyle = `width: 95%; height: ${this.ht}px;`
    this.imgStyle = `width: ${this.ht - 20}px; height: ${this.ht - 20}px;`;
    this.titleStyle = `color: ${this.titleColor}`;
  },
  methods: {
    getPassHos() {
      getHospitalByDoctor(this.form).then(({ data }) => {
        console.log(data, "data");
        this.detailList = data.data;
      });
    },
    toMyPatient(item) {
      this.$router.push({
        path: "/MineView/PersonnelManagement",
        query: {
          structId: item.struct_id,
        },
      });
    },
    toReport(item) {
      this.$router.push({
        path: "/assessReport",
        query: {
          structId: item.struct_id,
          id: JSON.parse(localStorage.getItem("appuser")).id,
          name: JSON.parse(localStorage.getItem("appuser")).name,
        },
      });
    },
  },
};
</script>

<style scoped>
.main {
  position: relative;
  width: 95%;
  height: 100px;
  background-color: white;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  border-radius: 10px;
  margin: 10px;
}
.main:hover {
  background: rgba(255, 255, 255, 0.65);
  transform: scale(102%, 102%);
}
.img {
  position: relative;
  width: 64px !important;
  height: 64px !important;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 20px 0 20px;
  float: left;
}
.text {
  display: flex;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  margin-left: 20%;
}
.title {
  display: block;
  font-size: 20px;
  margin: 0px;
  font-weight: bold;
  /* text-shadow: 1px 1px 1px grey; */
}
.detail {
  display: block;
  font-size: 16px;
  display: block;
  margin-top: 20px;
  color: #7b7b7b;
}
button {
  position: absolute;
  right: 2rem;
  top: 1.6rem;
  width: 5.5rem;
  height: 2.5rem;
  border: 0;
  background-color: #409eff;
  border-radius: 6px;
  color: #fff;
}
</style>
