<template>
  <div>
    <div class="moreBar">
      <el-row>
        <el-col :offset="2" :span="4">
          <div class="bar b1" @click="openDialog('kfyView')">
            <img class="imgNav" src="../../static/pic/kfyevent.png" />
            <div style="color: #000; margin-top: 0.5rem">口服药</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="bar b2" @click="openDialog('tsView')">
            <img class="imgNav" src="../../static/pic/tsevent.png" />
            <div style="color: #000; margin-top: 0.5rem">碳水</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="bar b3" @click="openDialog('ydsView')">
            <img class="imgNav" src="../../static/pic/ydsevent.png" />
            <div style="color: #000; margin-top: 0.5rem">胰岛素</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="bar b4" @click="openDialog('ydView')">
            <img class="imgNav" src="../../static/pic/ydevent.png" />
            <div style="color: #000; margin-top: 0.5rem">运动</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="bar b5" @click="openDialog('zxView')">
            <img class="imgNav" src="../../static/pic/zjxevent.png" />
            <div style="color: #000; margin-top: 0.5rem">指尖血</div>
          </div>
        </el-col>
      </el-row>
      <div class="cancellation" @click="closeMoreBar">
        <i class="el-icon-close"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { addEvent } from "../../api/userServices";
export default {
  name: "MoreDialog",
  data() {
    return {
      eventInfo: {
        type: "",
        createTime: "",
        value: "",
        slopeOrdinal: "-1",
        eventValue: "",
      },
    };
  },

  mounted() {
    // this.getEventList()
  },

  methods: {
    closeMoreBar() {
      this.$emit("closeMoreBar");
    },
    openDialog(type) {
      this.closeMoreBar();
      this.$router.push("/ReportCenter/" + type);
    },
    saveEvent() {
      this.eventInfo.createTime = this.getFormatDate();
      addEvent(this.eventInfo).then((res) => {
        if (res.data.code === "2002") {
          console.log(res.data);
        }
      });
    },
    getFormatDate(time) {
      const date = new Date(parseInt(time));
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      // let seconds = date.getSeconds()
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hour = hour < 10 ? "0" + hour : hour;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      const currentDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds;
      return currentDate;
    },
  },
};
</script>
<style scoped>
.moreBar {
  width: 130vw;
  background: #fff;
  box-shadow: -1px -1px #ccc;
  height: 40rem;
  border-top-left-radius: 65vw;
  border-top-right-radius: 65vw;
  position: absolute;
  top: -45vw;
  left: -15vw;
  z-index: 99;
}
.bar {
  width: 6rem;
  height: 6rem;
  border-radius: 3rem;
  position: absolute;
  color: #fff;
  z-index: 99;
}
.imgNav {
  width: 6rem;
  height: 6rem;
  border-radius: 2rem;
}
.cancellation {
  width: 6rem;
  height: 6rem;
  position: absolute;
  left: 56vw;
  top: 40vw;
  background: #7accff;
  border-radius: 3rem;
  color: #fff;
  line-height: 6rem;
  font-size: 4rem;
}
.b1 {
  top: 24vw;
  left: 20vw;
}
.b2 {
  top: 12vw;
  left: 38vw;
}
.b3 {
  top: 6vw;
  left: 58vw;
}
.b4 {
  top: 11vw;
  left: 78vw;
}
.b5 {
  top: 24vw;
  left: 94vw;
}
</style>
