<template>
  <div id="card" :style="cardStyle">
    <div class="main" v-for="item in detail" :key="item.id" @click="follow(item.structId)">
      <img class="img" src="../../../static/pic/hospital.png" :style="imgStyle" />
      <div class="text">
        <label class="title" :style="titleStyle">{{ item.structName }}
        </label>
        <label class="detail">地址：{{item.city}}{{item.region}}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: 'https://file.zhtsu.cn/light-radius.png'
    },
    title: {
      type: String,
      default: '标题'
    },
    height: {
      type: String,
      default: '100'
    },
    detail: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      ht: this.height,
      cardStyle: '',
      titleColor: this.color,
      titleStyle: '',
      imgStyle: '',
      detailList: []
    }
  },
  created() {
  },
  mounted() {
    this.cardStyle = `width: 95%; height: ${this.ht}px;`
    this.imgStyle = `width: ${this.ht - 20}px; height: ${this.ht - 20}px;`
    this.titleStyle = `color: ${this.titleColor}`
  },
  methods: {
    follow(structId) {
      this.$emit('followView', structId)
    }
  }
}
</script>

<style scoped>
.main {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  border-radius: 10px;
  margin: 10px;
}
.main:hover {
  background: rgba(255, 255, 255, 0.65);
  transform: scale(102%, 102%);
}
.img {
  position: relative;
  width: 64px !important;
  height: 64px !important;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 20px;
  float: left;
}
.text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  margin-left: 30%;
}
.title {
  display: block;
  font-size: 18px;
  margin: 5px;
  font-weight: bold;
  /* text-shadow: 1px 1px 1px grey; */
}
.detail {
  display: block;
  font-size: 15px;
  display: block;
  margin-top: 22px;
  color: #7b7b7b;
}
.sugar {
  color: #7b7b7b;
  font-size: 16px;
  text-shadow: none;
  margin-left: 2rem;
}
.sugarNum {
  font-size: 24px;
  position: absolute;
  right: 50px;
}
.sugarJian {
  font-size: 24px;
  position: absolute;
  right: 20px;
}
</style>
