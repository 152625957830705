<template>
    <div id="PatientsView">
        <div style="width: 100%;
                    height: 4rem;
                    position: fixed;
                    top: 0;
                    background: #d3eeff;
                    z-index: 99;
                    color: #7accff;">
            <div style="height:2rem;line-height: 2rem;margin-top: 2rem;">我的病人</div>
        </div>
        <div style="width: 94vw;margin-top:5rem; margin-left: 3vw;
            background: #fff;border-radius: 12px;">
            <el-tabs style="width: 88vw;margin-left: 3vw;" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="雅培用户" name="first">
                    <el-card v-for="(item,i) in patientList" :key="'patient'+i" class="box-card">{{ item.name }}</el-card>
                </el-tab-pane>
                <el-tab-pane label="硅基用户" name="second">
                    <el-card v-for="(item,i) in patientList" :key="'patient'+i" class="box-card">{{ item.name }}</el-card>
                </el-tab-pane>
                <el-tab-pane label="德康用户" name="third">
                    <el-card v-for="(item,i) in patientList" :key="'patient'+i" class="box-card">{{ item.name }}</el-card>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
export default {
  name: 'PatientsView',
  data () {
    return {
      activeName: 'first',
      patientList: [
        {
          name: '111',
          pic: '',
          data: {}
        }, {
          name: '22',
          pic: '',
          data: {}
        }, {
          name: '33',
          pic: '',
          data: {}
        }, {
          name: '44',
          pic: '',
          data: {}
        }, {
          name: '',
          pic: '',
          data: {}
        }, {
          name: '',
          pic: '',
          data: {}
        }, {
          name: '',
          pic: '',
          data: {}
        }, {
          name: '',
          pic: '',
          data: {}
        }, {
          name: '',
          pic: '',
          data: {}
        }, {
          name: '',
          pic: '',
          data: {}
        }, {
          name: '',
          pic: '',
          data: {}
        }, {
          name: '',
          pic: '',
          data: {}
        }, {
          name: '',
          pic: '',
          data: {}
        }
      ]
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style scoped>
#PatientsView {
    width: 100vw;
    background: #f8f8f8;
    height: 100vh;
    user-select: none;
}
.box-card {
    width: 40vw;
    height: 40vw;
    display: block;
    float: left;
    margin-bottom: 2vw;
    margin-left: 2vw;
}
</style>
